import {
    CARE_PASSWORD,
    SERVER_URL,
    CARE_USERNAME,
} from "../../constants/constants"
import { fileUploadMultipart, get, post } from "./methods"

/**
 * get All Agent Group List
 * @param {*} ldap
 * @returns
 */
export function getAllAgentGroupList(ldap) {
    return get({
        url: `${SERVER_URL}/care/v1/agentGroups/get/${ldap}`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

export function enforceUpdateApi(body, ldap) {
    return post({
        url: `${SERVER_URL}/care/v1/groups/enforce/updates`,
        queryParams: {
            ldap,
        },
        json: JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}

/**
 * groups/names
 * @param {*} ldap
 */
export function getAllGroupNames(ldap) {
    return get({
        url: `${SERVER_URL}/care/v1/groups/names`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
    })
}

// GET all supervisor
export function getAllSupervisorsAPI(ldap) {
    return get({
        url: `${SERVER_URL}/care/v1/agentGroups/supervisors`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
    })
}

/**
 *
 * @param {*} ldap
 * @returns
 */
export function deleteAgentGroupList(ldap, body, deleteRouting={}) {
    return post({
        url: `${SERVER_URL}/care/v1/agentGroups/delete`,
        queryParams: {
            ldap,
            ...deleteRouting,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
    })
}

/**
 *
 * @param {*} ldap
 * @returns
 */
export function addAgentGroupList(ldap, body) {
    const auth = btoa(`${CARE_USERNAME}:${CARE_PASSWORD}`)
    return fetch(
        `${SERVER_URL}/care/v1/agentGroups/add?` +
            new URLSearchParams({
                ldap,
            }).toString(),
        {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${auth}`,
            },
            body: JSON.stringify(body),
        }
    ).then((response) => {
        const contentType = response.headers.get("content-type")
        if (contentType && contentType.includes("application/json")) {
            return response
                .json()
                .then((data) => ({ status: response.status, data }))
        } else {
            return response
                .text()
                .then((text) => ({ status: response.status, text }))
        }
    })
}

/**
 *
 * @param {*} ldap
 * @returns
 */
export function editAgentGroupList(ldap, body) {
    const auth = btoa(`${CARE_USERNAME}:${CARE_PASSWORD}`)
    return fetch(
        `${SERVER_URL}/care/v1/agentGroups/edit?` +
            new URLSearchParams({
                ldap,
            }).toString(),
        {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${auth}`,
            },
            body: JSON.stringify(body),
        }
    ).then((response) => {
        const contentType = response.headers.get("content-type")
        if (contentType && contentType.includes("application/json")) {
            return response
                .json()
                .then((data) => ({ status: response.status, data }))
        } else {
            return response
                .text()
                .then((text) => ({ status: response.status, text }))
        }
    })
}

// Group Config API's
export function getAllLdap(ldap) {
    return get({
        url: `${SERVER_URL}/care/v1/groups/get/all/`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

export async function AddAgentGroupConfigRec(data, ldap) {
    const auth = btoa(`${CARE_USERNAME}:${CARE_PASSWORD}`)
    return fetch(
        `${SERVER_URL}/care/v1/groups/add?` +
            new URLSearchParams({
                ldap,
            }).toString(),
        {
            method: "POST",
            mode: "cors",
            // credentials:'include',
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${auth}`,
            },
            body: JSON.stringify(data),
        }
    ).then((response) => {
        const responseStatus = response.status
        if (response.headers.get("content-type") == "application/json")
            return response.json()
        return response
            .text()
            .then((text) => ({
                responseText: text,
                responseStatus: responseStatus,
            }))
    })
}

export async function EditAgentGroupConfigRec(data, ldap) {
    return post({
        url: `${SERVER_URL}/care/v1/groups/edit`,
        queryParams: {
            ldap,
        },
        json: JSON.stringify(data),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}

export const getQueueList = (group = null) => {
    return get({
        url: `${SERVER_URL}/care/v1/groups/queues/list`,
        queryParams: {
            group,
        },
        // body:data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

export const getStatusList = () => {
    return get({
        url: `${SERVER_URL}/care/v1/groups/status/list`,
        // queryParams: {
        //     ldap
        // },
        // body:data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

export const getProficiencyList = (group = null) => {
    return get({
        url: `${SERVER_URL}/care/v1/groups/proficiency/list`,
        queryParams: {
            group,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

export const getLoggedInUserIsAdmin = (ldap) => {
    return get({
        url: `${SERVER_URL}/api/v1/user/isAdmin`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

/**
 * @export Delete
 * @param {*} data
 * @param {*} ldap
 * @return {*}
 */
export async function deleteAgentGroupConfig(data, ldap) {
    const response = await post({
        url: `${SERVER_URL}/care/v1/groups/delete`,
        json: data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        queryParams: {
            ldap,
        },
        isJsonResponse: false,
        isTextResponse: true,
    })
    return response
}

export function bulkEditAgentGroup(ldap, data) {
    const auth = btoa(`${CARE_USERNAME}:${CARE_PASSWORD}`)
    return fetch(
        `${SERVER_URL}/care/v1/agentGroups/bulk/edit?` +
            new URLSearchParams({
                ldap,
            }).toString(),
        {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${auth}`,
            },
            body: JSON.stringify(data),
        }
    ).then((response) => {
        const contentType = response.headers.get("content-type")
        if (contentType && contentType.includes("application/json")) {
            return response
                .json()
                .then((data) => ({ status: response.status, data }))
        } else {
            return response
                .text()
                .then((text) => ({ status: response.status, text }))
        }
    })
}

// Agent Group Bulk Upload
export function uploadAgentGroupBulk(ldap, data) {
    return fileUploadMultipart({
        url: `${SERVER_URL}/care/v1/agentGroups/bulk/add`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        jsonData: data,
        isTextResponse: true,
    })
}

// Default Config API

// /default/get
export const getDefaultConfig = (ldap) => {
    const data = ["CRM_NEW_TXN", "Softphone", "CRMQUEUE"]
    return get({
        url: `${SERVER_URL}/default/get/all`,
        queryParams: {
            ldap,
        },
        body: data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

export const deleteDefaultConfig = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/default/delete`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
    })
}

/**
 * editDefaultConfig
 * @param {*} ldap
 * @param {*} body
 * @returns
 */
export const editDefaultConfig = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/default/edit`,
        queryParams: {
            ldap,
        },
        json: JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}

export const addDefaultConfig = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/default/add`,
        queryParams: {
            ldap,
        },
        json: JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}

// Clone Group Config
/**
 *
 * @param {*} ldap
 * @param {*} body
 * @returns
 */
export const cloneGroupConfig = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/care/v1/groups/clone`,
        queryParams: {
            ldap,
        },
        json: JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}

/**
 *
 * @param {*} ldap
 * @param {*} agentGroupName
 * @returns
 */
export const getSelectedGroupConfig = (ldap, groupName) => {
    return get({
        url: `${SERVER_URL}/care/v1/groups/configs`,
        queryParams: {
            ldap,
            groupName,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

/**
 * get Voice Complaine List
 * @param {*} ldap
 * @returns
 */
export function getAllVoiceCompliance() {
    return get({
        url: `${SERVER_URL}/voiceCompliance/get/all`,
        // queryParams: {
        //     ldap,
        // },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

/**
 * editDefaultConfig
 * @param {*} ldap
 * @param {*} body
 * @returns
 */
export const addVoiceCompliance = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/voiceCompliance/add`,
        queryParams: {
            ldap,
        },
        json: JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}

export const deleteVoiceCompliance = (ldap, policy) => {
    // if(body){
    //     return post({
    //         url:`${SERVER_URL}/voiceCompliance/delete`,
    //         queryParams:{ldap,keyName},
    //         // json:JSON.stringify(body),
    //         username: CARE_USERNAME,
    //         password: CARE_PASSWORD,
    //         isJsonResponse: false,
    //         isTextResponse: true,
    //         reqContentType: 'application/json'
    //     });
    // }else{
    return post({
        url: `${SERVER_URL}/voiceCompliance/delete`,
        queryParams: { ldap, policy },
        // json:data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
    // }
}

export const bulkDeleteVoiceCompliance = (AgentLdap, selectedAccordian) => {
    const body = selectedAccordian.join(",")
    const urlencoded = new URLSearchParams({ level1key: body })
    return post({
        url: `${SERVER_URL}/voiceCompliance/delete/multi`,
        queryParams: { ldap: AgentLdap },
        json: urlencoded,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        // reqContentType: 'application/json'
    })
}

// CommonConfig API

// ANI API
export const getAllAniByPassAPI = () => {
    return get({
        url: `${SERVER_URL}/aniBypass/get/all`,
        // queryParams: {
        //     ldap,
        // },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

/**
 * addAniByPass
 * @param {*} ldap
 * @param {*} body
 * @returns
 */
export const addAniBypassAPI = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/aniBypass/add`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/**
 * deleteAniBypass
 * @param {*} ldap
 * @param {*} body
 * @returns
 */
export const deleteAniBypassAPI = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/aniBypass/delete`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

// HOLIDAY SCHEDULE API
export const getAllHolidaySchedulesAPI = () => {
    return get({
        url: `${SERVER_URL}/holidaySchedules/get/all`,
        // queryParams: {
        //     ldap,
        // },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

/**
 * addHolidaySchedule
 * @param {*} ldap
 * @param {*} body
 * @returns
 */
export const addHolidayScheduleAPI = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/holidaySchedules/add`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/**
 * deleteHolidaySchedule
 * @param {*} ldap
 * @param {*} body
 * @returns
 */
export const deleteHolidayScheduleAPI = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/holidaySchedules/delete`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

//TIMEOUT API
export const getAllTimeoutAPI = () => {
    return get({
        url: `${SERVER_URL}/timeouts/get/all`,
        // queryParams: {
        //     ldap,
        // },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

/**
 * addTimeout
 * @param {*} ldap
 * @param {*} body
 * @returns
 */
export const addTimeoutAPI = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/timeouts/add`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/**
 * deleteTimeout
 * @param {*} ldap
 * @param {*} body
 * @returns
 */
export const deleteTimeoutAPI = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/timeouts/delete`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/**
 * Below Are API'S For Attribute List Management
 */

//Get All Attribute List and Values
export const getAllAttributeListAndValues = (ldap) => {
    return get({
        url: `${SERVER_URL}/proficiency/get/all`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

// Get All Attribute Names
export const getAllAttributeNames = (ldap, attributeName = "") => {
    return get({
        url: `${SERVER_URL}/proficiency/attributeNames`,
        queryParams: {
            ldap,
            attributeName,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

export const refreshAttributeListAPI = () => {
    return get({
        url: `${SERVER_URL}/proficiency/refresh`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

// Add Attributes
export const addAttributeList = (ldap, data) => {
    const urlencoded = new URLSearchParams(data)
    return post({
        url: `${SERVER_URL}/proficiency/add`,
        queryParams: { ldap },
        json: urlencoded,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
    })
}

export const updateAttributeList = (ldap, data) => {
    const urlencoded = new URLSearchParams(data)
    return post({
        url: `${SERVER_URL}/proficiency/update`,
        queryParams: { ldap },
        json: urlencoded,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
    })
}

export const deleteAttributeList = (ldap, data) => {
    const urlencoded = new URLSearchParams(data)
    return post({
        url: `${SERVER_URL}/proficiency/delete`,
        queryParams: { ldap },
        json: urlencoded,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
    })
}

export const getAgentGroupProficiency = (data) => {
    const { agentLdap, group } = data
    return get({
        url: `${SERVER_URL}/care/v1/agentGroups/users/${agentLdap}/proficiencies`,
        queryParams: {
            group,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

export const getQueuesDataApi = (agentLdap, group) => {
    return get({
        url: `${SERVER_URL}/care/v1/agentGroups/users/${agentLdap}/queues`,
        queryParams: {
            group,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

// export const getQueuesDataApi = (agentLdap) => {
//     return get({
//         url: `${SERVER_URL}/care/v1/agentGroups/users/${agentLdap}/queues`,
//         // queryParams: {
//         //     ...data
//         // },
//         username: CARE_USERNAME,
//         password: CARE_PASSWORD,
//         isJsonResponse: true,
//         isTextResponse: false,
//     });
// };

/* DXOC */

/* EMERGENCY */
// GET all the emergency
export const getEmergencyApi = (ldap) => {
    return get({
        url: `${SERVER_URL}/emergency/get/all`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

/* ADD/POST the emergency */
export const addEmergencyApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/emergency/add`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/* DELETE the emergency */
export const deleteEmergencyApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/emergency/delete`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/*  ************************************* DX_PROMPTS ********************************************** */
// GET all the DX_PROMPTS
export const getDxPromptsApi = (ldap) => {
    return get({
        url: `${SERVER_URL}/dxPrompts/get/all`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

/* POST/Add the DxPrompts */
export const addDxPromptsApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/dxPrompts/add`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/* DELETE the DxPrompts */
export const deleteDxPromptsApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/dxPrompts/delete`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/* **************************************************** DID_MAGENTO ******************************** */
// GET all the DID_MAGENTO
export const getDidMagentoApi = (ldap) => {
    return get({
        url: `${SERVER_URL}/didMagento/get/all`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

/* POST/ADD the DID_MAGENTO */
export const addDidMagentoApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/didMagento/add`,
        queryParams: {
            ldap,
        },
        json: JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}

/* DELETE the DidMagento */
export const deleteDidMagentoApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/didMagento/delete`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/* ******************************************************* DNIS_MAPPING *************************************** */
// GET all the DNIS_MAPPING
export const getDnisMappinApi = (ldap) => {
    return get({
        url: `${SERVER_URL}/dnisMapping/get/all`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

export const addDnisMapingApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/dnisMapping/add`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/* DELETE the DnisMapping */
export const deleteDnisApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/dnisMapping/delete`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

/************************************  SRS MOCK DATA API ********************************/

// GET all the DNIS_MAPPING
export const getSrsMockDataApi = (ldap) => {
    return get({
        url: `${SERVER_URL}/mockData/get/all`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

//ADD srs mock data
export const addSrsMockDataApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/mockData/add`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

//Delete SrsMockData
export const deleteSrsMockDataApi = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/mockData/delete`,
        queryParams: {
            ldap,
        },
        json: body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

// GET all permissions
export const getPermissionsApi = (ldap) => {
    return get({
        url: `${SERVER_URL}/care/v1/permissions/user/${ldap}`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

export function getAllOperatingHours(ldap=null) {
    return get({
        url: `${SERVER_URL}/operating-hours`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

/**
 * get All Supervisior Group List
 * @param {*} ldap
 * @returns
 */
export function getAllSupervisiorGroupList(ldap) {
    return get({
        url: `${SERVER_URL}/care/v1/supervisors/get/all`,
        username: CARE_USERNAME,
        queryParams: {
            ldap,
        },
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    })
}

/**
 * groups/names
 * @param {*} ldap
 */
export function getAllSupervisiorGroupNames(ldap) {
    return get({
        url: `${SERVER_URL}/care/v1/supervisors/${ldap}/groups`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
    })
}

export const addSupervisiorData = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/care/v1/supervisors/add`,
        queryParams: {
            ldap,
        },
        json: JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}

export const deleteSupevisior = (ldap, supervisorLdap, groupName) => {
    return post({
        url: `${SERVER_URL}/care/v1/supervisors/delete`,
        queryParams: {
            ldap,
            supervisorLdap, 
            groupName
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/x-www-form-urlencoded",
    })
}

export const fetchInstance = () => {  
    return get({
        url: `${SERVER_URL}/care/v1/groups/instances`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
    })
}


export function getDefaultInstanceConfig(ldap=null) {
    return get({
        url: `${SERVER_URL}/care/v1/groups/default-instance`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}


export function getDefaultGroupsConfig(ldap=null) {
    return get({
        url: `${SERVER_URL}/care/v1/groups/all/queues`,
        queryParams: {
            ldap,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}