// TWO BUTTON
import React, { useState, useEffect } from "react"
import {
    ActionButton,
    Heading,
    Item,
    MenuTrigger,
    Menu,
    Text,
    Button,
} from "@adobe/react-spectrum"
import Logo from "../images/adobe.svg"
import User from "@spectrum-icons/workflow/User"
import LogOut from "@spectrum-icons/workflow/LogOut"
import Background from "../images/background5.jpg"
import {
    logoutApi,
    alertTokenInvalidateApi,
    getUserInfo,
} from "../utilities/api/userInfo"
import { ALERT_SERVER_URL } from "../constants/constants"
import { AmazonConnectFilled, Adobe } from "../utilities/components/Icons"
import "./HeaderComponent.css"

const HeaderComponent = (props) => {
    const [isLogin, setIsLogin] = useState(false)
    const ldap = props?.userInfo?.userid

    const alertTokenInvalidate = async () => {
        await alertTokenInvalidateApi(ldap)
    }

    useEffect(() => {
        getUserInfo(true)
            .then(() => {
                setIsLogin(true)
            })
            .catch((err) => {
                setIsLogin(false)
                console.log("Error in login ", err)
            })
    }, [])

    const logout = () => {
        alertTokenInvalidate()
        logoutApi()
            .then((res) => {
                if (res.status) {
                    location.replace("/")
                }
            })
            .catch((err) => {
                console.log("Error in logout", err)
            })
    }

    const handleAmazonConnectUsLogin = () => {
        const { amazonConnectUsURL } = ALERT_SERVER_URL
        window.open(amazonConnectUsURL, "_blank")
    }

    const handleAmazonConnectSgLogin = () => {
        const { amazonConnectSgURL } = ALERT_SERVER_URL
        window.open(amazonConnectSgURL, "_blank")
    }
    return (
        <div style={{ overflow: "hidden" }}>
            <div
                style={{
                    backgroundImage: "url(" + Background + ")",
                    backgroundSize: "100% 100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <img
                    src={Logo}
                    alt="logo"
                    style={{
                        bottom: "0",
                        width: "9.5em",
                        height: "3.5em",
                    }}
                />

                <Heading
                    alignSelf="center"
                    UNSAFE_style={{
                        fontWeight: "bold",
                        color: "white",
                        display: "flex",
                        paddingBottom: "0.6%",
                        marginLeft: "-1%",
                    }}
                >
                    Care for Customer Interactions
                </Heading>
                <div style={{ marginLeft: "auto", marginRight: "1%" }}>
                    {isLogin && props?.userInfo?.fName && (
                        <>
                            <Button
                                UNSAFE_className="amazon-connect-button"
                                onPress={handleAmazonConnectUsLogin}
                                UNSAFE_style={{
                                    cursor: "pointer",
                                    padding: "0px 5px",
                                    marginRight: "10px",
                                    backgroundColor: "#ecf5ff",
                                    color: "#000",
                                    border: "2px solid #00acba",
                                    borderRadius: "5px",
                                }}
                            >
                                <AmazonConnectFilled
                                    style={{ height: "30px", width: "30px" }}
                                />
                                <h6>Amazon Connect US Login</h6>
                            </Button>
                            <Button
                                onPress={handleAmazonConnectSgLogin}
                                UNSAFE_className="amazon-connect-button"
                                UNSAFE_style={{
                                    cursor: "pointer",
                                    padding: "0px 5px",
                                    backgroundColor: "#ecf5ff",
                                    color: "#000",
                                    border: "2px solid #00acba",
                                    borderRadius: "5px",
                                }}
                            >
                                <AmazonConnectFilled
                                    style={{ height: "30px", width: "30px" }}
                                />
                                <h6>Amazon Connect SG Login</h6>
                            </Button>
                        </>
                    )}
                </div>
                {props?.userInfo && props?.userInfo?.fName && (
                    <div style={{ marginRight: "5%" }}>
                        {isLogin && (
                            <MenuTrigger>
                                <ActionButton
                                    staticColor="white"
                                    isQuiet
                                    UNSAFE_style={{ cursor: "pointer" }}
                                >
                                    <User />
                                    {props?.userInfo?.fName &&
                                        `${props?.userInfo?.fName} ${props?.userInfo?.lName}`}
                                </ActionButton>

                                <Menu onAction={logout}>
                                    <Item key="logout">
                                        <LogOut
                                            size="XXL"
                                            aria-label="Logout"
                                        />
                                        <Text>Logout</Text>
                                    </Item>
                                </Menu>
                            </MenuTrigger>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default HeaderComponent