import {
Flex,
View,
Checkbox,
NumberField,
TimeField,
ComboBox,
Item,

CheckboxGroup,
Text,
Button,
DialogTrigger,
AlertDialog,
ActionButton,
Divider,
ActionGroup,
} from "@adobe/react-spectrum"
import React, { useEffect, useState } from "react"
import "./style.css"
import { Time } from "@internationalized/date"
import Add from "@spectrum-icons/workflow/Add"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import TreeExpandAll from "@spectrum-icons/workflow/TreeExpandAll"
import TreeCollapseAll from "@spectrum-icons/workflow/TreeCollapseAll"

// import Edit from "@spectrum-icons/workflow/Edit"
import { ChevronDown, ChevronUp } from "../Icons"
import { groupConfigSortingKeysPattern } from "../../../constants/constants"
// import UnfoldLessIcon from "@mui/icons-material/UnfoldLess"
// import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"

/**
*
* @param {*} props {AccordionItem[]}
* @returns
*/
export const Accordion = (props) => {
return <div className="spectrum-Accordion">{props.children}</div>
}
export const AccordionItem = (props) => {
const handleClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const handleIconClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const shortenDayName = (days) => {
    return days
        .split(",")
        .map((day) => day.substring(0, 3))
        .join()
}
const threshHoldText = (list) => {
    const string = []
    for (let index = 0; index < list.length; index++) {
        if (list[index].days && list[index].days != "NA") {
            if (index > 0) {
                string.push(
                    <b key={`${index}`} style={{ fontSize: "medium" }}>
                        {" "}
                        |{" "}
                    </b>
                )
            }
            string.push(
                `${
                    list[index].days != "all"
                        ? shortenDayName(list[index].days)
                        : "Everyday"
                } : (Agent Threshold : ${
                    list[index].onQueueUserThreshold
                }) `
            )
        }
    }
    return string
}
const capitalizeFirstLetter = (string) => {
    if (string?.length === 0) {
        return string
    }
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}

return (
    // className="spectrum-Accordion-item"
    <div>
        {/* <Flex direction="row" UNSAFE_className="spectrum-Accordion-itemHeading" >
                <View UNSAFE_className="spectrum-Accordion-itemHeading" minWidth={'280px'} >
                    <div className="spectrum-Accordion-itemHeader">
                        <button
                            type="button"
                            id="spectrum-accordion-item-0-header"
                            aria-controls="spectrum-accordion-item-0-content"
                            onClick={handleClick}
                        >
                            {props.title}
                        </button>
                        <button onClick={handleIconClick}><Chevron100/></button>
                    </div>
                </View>
                {(props.queue.channel?.toLowerCase() == 'call') && <View>
                    <Checkbox isSelected={props.queue.alertTypes.voiceMail}><Text className="elipseText">Voicemail</Text></Checkbox>
                </View>}
                {(props.queue.channel?.toLowerCase() != 'case') && <View>
                    <Checkbox isSelected={props.queue.alertTypes.convAbandoned}><Text UNSAFE_className="elipseText">{capitalizeFirstLetter(props.queue.channel)} Abandoned</Text></Checkbox>
                </View>}
                <View>
                    <Checkbox isSelected={props.queue.alertTypes.convWait}><Text UNSAFE_className="elipseText">{capitalizeFirstLetter(props.queue.channel)} Wait {(props.queue.alertTypes.convWaitConditions && props.queue.alertTypes.convWaitConditions!="")?props.queue.alertTypes.convWaitConditions: `00`}s</Text></Checkbox>
                </View>
                <View>
                    <Checkbox isSelected={props.queue.alertTypes.onQueueUsers}><Text UNSAFE_className="elipseText">OnQueue Agent</Text></Checkbox>
                </View>

                {props.queue.alertTypes.onQueueUsers && <View UNSAFE_style={{lineHeight:"3rem"}}>
                    <Text maxWidth={'size-5000'} UNSAFE_className="elipseText">{threshHoldText(props.queue.alertTypes.userThresholdSchedules)}</Text>
                </View>}
            </Flex>
            <div
                className="spectrum-Accordion-itemContent"
                id="spectrum-accordion-item-0-content"
                aria-labelledby="spectrum-accordion-item-0-header"
            >
                {props.children}
            </div> */}
        {props.title == "Custom CallWait Configuration" ? (
            <div className="spectrum-Accordion-item">
                <Flex
                    direction="row"
                    UNSAFE_className="spectrum-Accordion-itemHeading"
                >
                    <View
                        UNSAFE_className="spectrum-Accordion-itemHeading"
                        minWidth={"280px"}
                    >
                        <div className="spectrum-Accordion-itemHeader">
                            <button
                                type="button"
                                id="spectrum-accordion-item-0-header"
                                aria-controls="spectrum-accordion-item-0-content"
                                onClick={handleClick}
                            >
                                {props.title}
                            </button>
                            <button
                                className="arrowIconButton"
                                onClick={handleIconClick}
                            >
                                <Chevron100 />
                            </button>
                        </div>
                    </View>
                </Flex>
                <div
                    className="spectrum-Accordion-itemContent"
                    id="spectrum-accordion-item-0-content"
                    aria-labelledby="spectrum-accordion-item-0-header"
                >
                    <Flex direction={"row"} justifyContent="space-between">
                        <h5>Schedules</h5>
                        <Button
                            aria-label="addKeyValue"
                            variant="secondary"
                            staticColor="black"
                            style="fill"
                            UNSAFE_className="noBorder cursor"
                            onPress={() => props.addNewCnvThreshold()}
                        >
                            {" "}
                            <Add />
                        </Button>
                    </Flex>
                    <View>
                        {props.queue.alertTypes.convWaitAlerts[props.index]
                            .convWaitCustom &&
                            // props.queue.alertTypesconvWaitAlerts[props.index].convWaitSchedules?.length &&
                            props.queue.alertTypes.convWaitAlerts[
                                props.index
                            ].convWaitSchedules?.map((schedule, index) => (
                                <Flex
                                    UNSAFE_style={{
                                        border: "1px solid #c3c3c3",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        marginBottom: "5px",
                                    }}
                                    key={`main-${index}`}
                                    direction={"column"}
                                    // marginStart="size-600"
                                    gap="size-100"
                                >
                                    <Flex
                                        direction={"row"}
                                        justifyContent={"end"}
                                        marginTop={"5px"}
                                    >
                                        {props.queue.alertTypes
                                            .convWaitAlerts[props.index]
                                            .convWaitSchedules.length &&
                                            !props.isUpdate && (
                                                <ActionButton
                                                    onPress={() =>
                                                        props.removeCnvThresholdIndex(
                                                            props.index,
                                                            index,
                                                            props.queue
                                                                .alertTypes
                                                                .convWaitSchedules
                                                        )
                                                    }
                                                >
                                                    <Delete />
                                                </ActionButton>
                                            )}
                                        {props.queue.alertTypes
                                            .convWaitAlerts[props.index]
                                            .convWaitSchedules.length &&
                                            props.isUpdate && (
                                                <DialogTrigger>
                                                    <ActionButton aria-label="Delete">
                                                        <Delete />
                                                    </ActionButton>
                                                    <AlertDialog
                                                        variant="confirmation"
                                                        title="Delete record?"
                                                        primaryActionLabel="Confirm"
                                                        cancelLabel="Cancel"
                                                        onPrimaryAction={() =>
                                                            props.removeCnvThresholdIndex(
                                                                index,
                                                                props.queue
                                                                    .alertTypes
                                                                    .convWaitSchedules
                                                            )
                                                        }
                                                    >
                                                        Are you sure you
                                                        want to delete this
                                                        record?
                                                    </AlertDialog>
                                                </DialogTrigger>
                                            )}
                                    </Flex>
                                    <Flex
                                        direction={"row"}
                                        rowGap="size-800"
                                        marginTop="size-100"
                                        gap={"size-900"}
                                    >
                                        <Text width={"size-1250"}>
                                            <h5 className="schedule-label">
                                                Days
                                            </h5>
                                        </Text>
                                        <Flex direction={"column"}>
                                            <Flex
                                                direction={"row"}
                                                justifyContent="end"
                                            >
                                                {/* <Checkbox isSelected={schedule.days != undefined && schedule.days.toLowerCase() === 'all'} onChange={(e) => props.updateThresholdData('days', index, e, true)} id={index}>Everyday</Checkbox> */}
                                            </Flex>
                                            <CheckboxGroup
                                                defaultValue={[]}
                                                value={
                                                    schedule?.days &&
                                                    schedule?.days?.split(
                                                        ","
                                                    )
                                                }
                                                isDisabled={
                                                    schedule.days &&
                                                    schedule.days.toLowerCase() ===
                                                        "all"
                                                }
                                                onChange={(e) =>
                                                    props.updateThresholdData(
                                                        "days",
                                                        props.index,
                                                        e,
                                                        true,
                                                        index
                                                    )
                                                }
                                                orientation="horizontal"
                                                id={`day-${index}`}
                                            >
                                                <Checkbox
                                                    id={`monday-${index}`}
                                                    value="MONDAY"
                                                >
                                                    Mon
                                                </Checkbox>
                                                <Checkbox
                                                    id={`tuesday-${index}`}
                                                    value="TUESDAY"
                                                >
                                                    Tue
                                                </Checkbox>
                                                <Checkbox
                                                    id={`wensday-${index}`}
                                                    value="WEDNESDAY"
                                                >
                                                    Wed
                                                </Checkbox>
                                                <Checkbox
                                                    id={`thursday-${index}`}
                                                    value="THURSDAY"
                                                >
                                                    Thu
                                                </Checkbox>
                                                <Checkbox
                                                    id={`friday-${index}`}
                                                    value="FRIDAY"
                                                >
                                                    Fri
                                                </Checkbox>
                                                <Checkbox
                                                    id={`sat-${index}`}
                                                    value="SATURDAY"
                                                >
                                                    Sat
                                                </Checkbox>
                                                <Checkbox
                                                    id={`sunday-${index}`}
                                                    value="SUNDAY"
                                                >
                                                    Sun
                                                </Checkbox>
                                            </CheckboxGroup>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        direction={"row"}
                                        rowGap="size-800"
                                        gap={"size-400"}
                                    >
                                        <Text width={"size-1250"}>
                                            <h5 className="schedule-label">
                                                Start Time
                                            </h5>
                                        </Text>
                                        <Flex direction={"column"}>
                                            <Checkbox
                                                isSelected={
                                                    schedule.startTime.toLowerCase() ===
                                                    "all"
                                                }
                                                onChange={(e) =>
                                                    props.updateThresholdData(
                                                        "startTime",
                                                        props.index,
                                                        e,
                                                        true,
                                                        index
                                                    )
                                                }
                                                aria-label="allday"
                                            >
                                                All Day
                                            </Checkbox>
                                            <TimeField
                                                value={
                                                    new Time(
                                                        schedule.startTime.split(
                                                            ":"
                                                        )[0],
                                                        schedule.startTime.split(
                                                            ":"
                                                        )[1],
                                                        schedule.startTime.split(
                                                            ":"
                                                        )[2]
                                                    )
                                                }
                                                isDisabled={
                                                    schedule.startTime.toLowerCase() ===
                                                    "all"
                                                }
                                                onChange={(e) =>
                                                    props.updateThresholdData(
                                                        "startTime",
                                                        props.index,
                                                        e,
                                                        true,
                                                        index
                                                    )
                                                }
                                                granularity="second"
                                                hourCycle={24}
                                                aria-label="time"
                                                isQuiet
                                            />
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        direction={"row"}
                                        gap={"size-400"}
                                    >
                                        <Text width={"size-1250"}>
                                            <h5 className="schedule-label">
                                                End Time
                                            </h5>
                                        </Text>
                                        <TimeField
                                            value={
                                                new Time(
                                                    schedule.endTime.split(
                                                        ":"
                                                    )[0],
                                                    schedule.endTime.split(
                                                        ":"
                                                    )[1],
                                                    schedule.endTime.split(
                                                        ":"
                                                    )[2]
                                                )
                                            }
                                            isDisabled={
                                                schedule.startTime.toLowerCase() ===
                                                "all"
                                            }
                                            onChange={(e) =>
                                                props.updateThresholdData(
                                                    "endTime",
                                                    props.index,
                                                    e,
                                                    true,
                                                    index
                                                )
                                            }
                                            hourCycle={24}
                                            aria-label="endtime"
                                            granularity="second"
                                            isQuiet
                                        />
                                    </Flex>
                                    <Flex
                                        direction={"row"}
                                        gap={"size-400"}
                                    >
                                        <Text width={"size-1250"}>
                                            <h5 className="schedule-label">
                                                Timezone
                                                <Text UNSAFE_className="errorMessage">
                                                    *
                                                </Text>
                                            </h5>
                                        </Text>
                                        {!props.isUpdate && (
                                            <ComboBox
                                                items={props.timezonesValue}
                                                selectedKey={
                                                    schedule.timezone
                                                }
                                                onSelectionChange={(e) =>
                                                    props.updateThresholdData(
                                                        "timezone",
                                                        props.index,
                                                        e,
                                                        true,
                                                        index
                                                    )
                                                }
                                                aria-label="timezone"
                                                width="size-3000"
                                                isQuiet
                                            >
                                                {(timezone) => (
                                                    <Item key={timezone.id}>
                                                        {timezone.name}
                                                    </Item>
                                                )}
                                            </ComboBox>
                                        )}
                                        {props.isUpdate && (
                                            <ComboBox
                                                items={props.timezonesValue}
                                                selectedKey={
                                                    schedule.timezone
                                                }
                                                onSelectionChange={(e) =>
                                                    props.updateThresholdData(
                                                        "timezone",
                                                        props.index,
                                                        e,
                                                        true,
                                                        index
                                                    )
                                                }
                                                aria-label="timezone"
                                                width="size-3000"
                                                isQuiet
                                            >
                                                {(timezone) => (
                                                    <Item key={timezone.id}>
                                                        {timezone.name}
                                                    </Item>
                                                )}
                                            </ComboBox>
                                        )}
                                    </Flex>
                                    <Flex
                                        direction={"row"}
                                        gap={"size-100"}
                                    >
                                        <Text>
                                            <h5 className="schedule-label">
                                                Schedule condition
                                                <Text UNSAFE_className="errorMessage">
                                                    *
                                                </Text>
                                            </h5>
                                        </Text>
                                        <NumberField
                                            minValue={0}
                                            maxValue={600}
                                            value={
                                                schedule.convWaitConditions
                                            }
                                            onChange={(e) =>
                                                props.updateThresholdData(
                                                    "convWaitConditions",
                                                    props.index,
                                                    e,
                                                    true,
                                                    index
                                                )
                                            }
                                            aria-label="threshold"
                                            height="0px"
                                            width="size-3000"
                                            formatOptions={{
                                                style: "unit",
                                                unit: "second",
                                            }}
                                            isQuiet
                                        />
                                    </Flex>

                                    {/* {props.queue.alertTypes
                                            .convWaitSchedules.length > 1 &&
                                            props.queue.alertTypes
                                                .convWaitSchedules.length !=
                                                index + 1 && (
                                                <Divider size="M" />
                                            )} */}
                                </Flex>
                            ))}
                    </View>
                </div>
            </div>
        ) : (
            <div className="spectrum-Accordion-item">
                <Flex
                    direction="row"
                    UNSAFE_className="spectrum-Accordion-itemHeading"
                >
                    <View
                        UNSAFE_className="spectrum-Accordion-itemHeading"
                        minWidth={"280px"}
                    >
                        <div className="spectrum-Accordion-itemHeader">
                            <button
                                type="button"
                                id="spectrum-accordion-item-0-header"
                                aria-controls="spectrum-accordion-item-0-content"
                                onClick={handleClick}
                            >
                                {props.title}
                            </button>
                            <button
                                className="arrowIconButton"
                                onClick={handleIconClick}
                            >
                                <Chevron100 />
                            </button>
                        </div>
                    </View>
                    {props.queue.channel?.toLowerCase() == "call" && (
                        <View UNSAFE_style={{ minWidth: "150px" }}>
                            <Checkbox
                                isSelected={
                                    props.queue.alertTypes.voiceMail
                                }
                            >
                                <Text className="elipseText">
                                    Voicemail
                                </Text>
                            </Checkbox>
                        </View>
                    )}
                    {props.queue.channel?.toLowerCase() != "case" && (
                        <View UNSAFE_style={{ minWidth: "150px" }}>
                            <Checkbox
                                isSelected={
                                    props.queue.alertTypes.convAbandoned
                                }
                            >
                                <Text UNSAFE_className="elipseText">
                                    {capitalizeFirstLetter(
                                        props?.queue?.channel
                                    )}{" "}
                                    Abandoned
                                </Text>
                            </Checkbox>
                        </View>
                    )}
                    <View UNSAFE_style={{ minWidth: "150px" }}>
                        <Checkbox
                            isSelected={props.queue.alertTypes.convWait}
                        >
                            <Text UNSAFE_className="elipseText">
                                {capitalizeFirstLetter(
                                    props?.queue?.channel
                                )}{" "}
                                Wait{" "}
                                {props.queue.alertTypes
                                    .convWaitConditions &&
                                props.queue.alertTypes.convWaitConditions !=
                                    ""
                                    ? props.queue.alertTypes
                                          .convWaitConditions
                                    : `00`}
                                s
                            </Text>
                        </Checkbox>
                    </View>
                    <View UNSAFE_style={{ minWidth: "150px" }}>
                        <Checkbox
                            isSelected={props.queue.alertTypes.onQueueUsers}
                        >
                            <Text UNSAFE_className="elipseText">
                                OnQueue Agent
                            </Text>
                        </Checkbox>
                    </View>

                    {props.queue.alertTypes.onQueueUsers && (
                        <View UNSAFE_style={{ lineHeight: "3rem" }}>
                            <Text
                                maxWidth={"size-5000"}
                                UNSAFE_className="elipseText"
                            >
                                {threshHoldText(
                                    props.queue.alertTypes
                                        .userThresholdSchedules
                                )}
                            </Text>
                        </View>
                    )}
                </Flex>
                <div
                    style={{
                        border: "1px solid #c3c3c3",
                        padding: "10px",
                        borderRadius: "5px",
                        marginBottom: "5px",
                        width: "70%",
                        marginLeft: "28rem",
                    }}
                    className="spectrum-Accordion-itemContent"
                    id="spectrum-accordion-item-0-content"
                    aria-labelledby="spectrum-accordion-item-0-header"
                >
                    {props.children}
                </div>
            </div>
        )}
    </div>
)
}

export const AccordionItemAgentGroup = (props) => {
const fullName = `${props.groupList[0].firstName} ${props.groupList[0].lastName}`
const handleClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const handleIconClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const checkIfKeysDisabled = (groupActiveStatus) => {
    return groupActiveStatus ? ["delete"] : []
}

const deleteAgentGroupList = (
    agentLdap,
    groupInfo,
    showRoutingDelete = false
) => {
    const allGroupsList = groupInfo.map((group) => group.groupName).join()
    props.deleteAgentGroup(
        { [agentLdap]: allGroupsList },
        showRoutingDelete
    )
}

const changesCheckboxAcc = (value, ldap) => {
    const operation = value ? "Add" : "Remove"
    const allGroupsList = props.groupList
        .map((group) => group.groupName)
        .join()
    props.setSelectedAcc({ [ldap]: allGroupsList }, operation)
}

return (
    <div className="spectrum-Accordion-item">
        <Flex
            direction="row"
            UNSAFE_className="spectrum-Accordion-itemHeading"
            aria-label="Accordian"
        >
            <View key={props.title} aria-label="Accordian">
                <Checkbox
                    aria-label="Accordian"
                    UNSAFE_style={{ padding: "0px" }}
                    isSelected={Object.keys(
                        props.selectedAccordians
                    ).includes(props.title)}
                    value={props.title}
                    onChange={(value) =>
                        changesCheckboxAcc(value, props.title)
                    }
                />
            </View>
            <View
                UNSAFE_className="spectrum-Accordion-itemHeading"
                minWidth={"350px"}
                width={"350px"}
            >
                <div
                    className="spectrum-Accordion-itemHeader"
                    style={{ cursor: "default" }}
                >
                    <button
                        type="button"
                        id="spectrum-accordion-item-0-header"
                        aria-controls="spectrum-accordion-item-0-content"
                        onClick={handleClick}
                        style={{ cursor: "text", textAlign: "start" }}
                    >
                        {fullName}
                        {` (${props.title})`}
                    </button>
                    <button
                        className="arrowIconButton"
                        onClick={handleIconClick}
                    >
                        {/* <Chevron100 /> */}
                    </button>
                </div>
            </View>
            <Flex wrap width={"25%"} gap={"size-100"}>
                {props.groupList &&
                    props.groupList.map((groupInfo, index) => (
                        <View key={index}>
                            {/* <Checkbox
                                aria-label="Accordian"
                                isSelected={groupInfo.groupActiveStatus}
                            >
                               
                            </Checkbox> */}
                            <Text className="elipseText">
                                {groupInfo.groupName}
                            </Text>
                            {groupInfo.isGroupSupervisor && (
                                <i>{` (supervisor)`}</i>
                            )}
                        </View>
                    ))}
            </Flex>
            {/* {props.isAdmin && (<View flex UNSAFE_style={{ right: '0px', display: 'flex', gap: '10px' }}>
                <Button aria-label="Accordian" onPress={() => deleteAgentGroupList(props.title, props.groupList, true)}><Delete /></Button>
                <Button aria-label="Accordian" onPress={() => props.cloneAgentGroup({ agentLdap: props.title })}><Add /></Button>
            </View>)} */}
            {props.isAdmin && (
                <View>
                    <ActionGroup
                        // disabledKeys={checkIfKeysDisabled(
                        //     groupData.groupActiveStatus
                        // )}
                        onAction={(value) =>
                            value === "delete"
                                ? deleteAgentGroupList(
                                      props.title,
                                      props.groupList,
                                      true
                                  )
                                : props.ItemAction(
                                      value,
                                      props.title,
                                      props.groupList
                                  )
                        }
                    >
                        <Item key="edit">
                            <Draw />
                        </Item>
                        <Item key="delete">
                            <Delete />
                        </Item>
                    </ActionGroup>
                </View>
            )}
        </Flex>
        {/* <div
            className="spectrum-Accordion-itemContent"
            id="spectrum-accordion-item-0-content"
            aria-labelledby="spectrum-accordion-item-0-header"
        >
            <Flex direction={"column"} marginStart="size-250">
                <View marginStart="size-250">
                    {props.groupList.map((groupData, index) => (
                        <Flex
                            direction={"row"}
                            marginStart="size-250"
                            gap={"size-100"}
                            key={index}
                        >
                            <View minWidth={'25%'} marginEnd={'size-400'}>
                                <h5 className="schedule-label">
                                    {groupData.groupName}{groupData.isGroupSupervisor && <i >{(` (supervisor)`)}</i>}
                                </h5>
                            </View>
                            <View minWidth={'15%'} marginEnd={'size-100'}>
                                <h5 className={"schedule-label " + (groupData.groupActiveStatus ? 'enabled' : 'disabled')}>
                                    {groupData.groupActiveStatus ? 'Routable' : 'Unroutable'}
                                </h5>
                            </View>
                            {props.isAdmin && (
                                <View>
                                    <ActionGroup disabledKeys={checkIfKeysDisabled(groupData.groupActiveStatus)} onAction={(value) => props.ItemAction(value, props.title, groupData)}>
                                        <Item key="edit">
                                            <Draw />
                                        </Item>
                                        <Item key="delete">
                                            <Delete />
                                        </Item>
                                    </ActionGroup>
                                </View>
                            )}
                        </Flex>
                    ))}
                </View>
            </Flex>
        </div> */}
    </div>
)
}

export const AccordionItemGroupAgent = (props) => {
const supervisorCount = props?.groupList?.filter(ele => ele?.isGroupSupervisor)?.length
const handleClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const handleIconClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const checkIfKeysDisabled = (groupActiveStatus) => {
    return groupActiveStatus ? ["delete"] : []
}

const deleteAgentGroupList = (groupName, agentList) => {
    const formattedData = agentList
        .map((agent) => {
            const { agentName } = agent
            return { [agentName]: groupName }
        })
        .reduce((acc, curr) => Object.assign(acc, curr), {})
    props.deleteGroupAgents(formattedData)
}

const capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase())
}

return (
    <div className="spectrum-Accordion-item" style={{ margin: "5px 0px" }}>
        <Flex
            direction="row"
            UNSAFE_className="spectrum-Accordion-itemHeading"
        >
            <View
                UNSAFE_className="spectrum-Accordion-itemHeading"
                minWidth={"280px"}
            >
                <div
                    className="spectrum-Accordion-itemHeader"
                    style={{ height: "35px" }}
                >
                    <div
                        // type="button"
                        id="spectrum-accordion-item-0-header"
                        aria-controls="spectrum-accordion-item-0-content"
                        onClick={handleClick}
                        style={{ marginTop: "3px" }}
                    >
                        {props.title}

                        {props.hasOwnProperty('isSupervisior') && (<Text
                            UNSAFE_style={{ fontWeight: 400 }}
                        >{` (supervisors : ${props?.groupList.length} )`}</Text>)}
                        {!props.hasOwnProperty('isSupervisior') && (
                            <>
                            <Text
                            UNSAFE_style={{ fontWeight: 400 }}
                        >{` (supervisors : ${supervisorCount} )`}</Text>
                                                <Text
                            UNSAFE_style={{ fontWeight: 400 }}
                        >{` (users : ${props?.groupList.length - supervisorCount} )`}</Text>
                            </>)}

                    </div>
                    <button
                        className="arrowIconButton"
                        onClick={handleIconClick}
                    >
                        <Chevron100 />
                    </button>
                </div>
            </View>
            {/* {props.isAdmin && (
            <View
                UNSAFE_style={{
                    right: "0px",
                    alignItems: "normal",
                    display: "flex",
                }}
            >
                <Button
                    UNSAFE_className="cursor"
                    onPress={() =>
                        deleteAgentGroupList(props.title, props.groupList)
                    }
                >
                    <Delete />
                </Button>
            </View>
        )} */}
        </Flex>
        <div
            className="spectrum-Accordion-itemContent"
            id="spectrum-accordion-item-0-content"
            aria-labelledby="spectrum-accordion-item-0-header"
            style={{ width: "95%", marginLeft: "4%" }}
        >
            <Flex
                // gap={"size-100"}
                columnGap={"90px"}
                rowGap={"5px"}
                UNSAFE_style={{
                    flexWrap: "wrap",
                    marginTop: "12px",
                    // columnGap:"40px"
                }}
            >
                {props.groupList &&
                    props.groupList.map((groupInfo, index) => (
                        <View
                            key={index}
                            UNSAFE_style={{
                                display: "flex",
                            }}
                        >
                            {/* <Checkbox
                            isSelected={groupInfo.groupActiveStatus}
                        >
                        </Checkbox> */}
                            <Text
                                className="elipseText"
                                UNSAFE_style={{
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    wordBreak: "break-word",
                                }}
                            >
                                {`${capitalizeFirstLetter(
                                    `${groupInfo?.firstName} ${groupInfo?.lastName}`
                                )} `}
                                {`( ${groupInfo.agentName} )`}
                                {groupInfo.isGroupSupervisor && (
                                    <i>
                                        <b>{` (supervisor)`}</b>
                                    </i>
                                )}
                            </Text>
                            { (
                                <ActionGroup
                                    UNSAFE_style={{
                                        display: "inline",
                                        transform: "scale(0.6)",
                                        marginTop: "-5px",
                                    }}
                                    onAction={() =>
                                        props.ItemAction(
                                            "edit",
                                            props.title,
                                            groupInfo
                                        )
                                    }
                                >
                                    <Item
                                        key="edit"
                                        UNSAFE_style={{ cursor: "pointer" }}
                                    >
                                        <Draw />
                                    </Item>
                                </ActionGroup>
                            )}
                        </View>
                    ))}
            </Flex>
        </div>
    </div>
)
}

export const AccordionItemGroupConfig = (props) => {
const handleClick = (e) => {
    props.click(props.title)
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const handleIconClick = (e) => {
    props.click(props.title)
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

// const checkIfKeysDisabled = (groupActiveStatus) => {
//     return groupActiveStatus?['delete']:[];
// }

// const deleteAgentGroupList = (groupName ,agentList) => {
//     const formattedData = agentList.map((agent)=>{
//         const {agentName} = agent;
//         return {[agentName]:groupName}
//     }).reduce((acc,curr)=>Object.assign(acc, curr),{});
//     props.deleteGroupAgents(formattedData);
// }

const deleteAgentGroupConfig = (configData) => {
    const deleteObj = configData.map((config) => config.config_key).join()
    props.ItemAction("delete", {
        key: props.title.trim(),
        value: deleteObj,
    })
}

const editAgentGroupConfig = () => {
    props.ItemAction("edit1", { agent_group_name: props.title })
}

return (
    <div style={{ margin: "15px 0px" }} className={props?.openedAccList?.includes(props.title)?'spectrum-Accordion-item is-open':'spectrum-Accordion-item'}>
        <Flex
            direction="row"
            // justifyContent="space-between"
            UNSAFE_className="spectrum-Accordion-itemHeading"
        >
            <View
                UNSAFE_className="spectrum-Accordion-itemHeading"
                minWidth={"280px"}
                width={"280px"}
            >
                <div className="spectrum-Accordion-itemHeader">
                    <span
                        // type="button"
                        id="spectrum-accordion-item-0-header"
                        aria-controls="spectrum-accordion-item-0-content"
                        onClick={handleClick}
                        style={{ textAlign: "start", minWidth: "280px" ,maxWidth: "280px", overflowWrap:"break-word" }}
                    >
                        {props.title}
                    </span>
                    <button
                        className="arrowIconButton"
                        onClick={handleIconClick}
                    >
                        <Chevron100 />
                    </button>
                </div>
            </View>
            {/* <Flex width={"90%"} gap={"size-100"}>
                &nbsp;
            </Flex> */}

            {
                <View UNSAFE_style={{ right: "0px", display: "flex" }}>
                    <Button
                        onPress={() => editAgentGroupConfig()}
                        UNSAFE_style={{ margin: "0px 5px" }}
                        // isDisabled={!(props?.isAdminInEdit)}
                        // isDisabled={
                        //     !props?.isAdminInEdit &&
                        //     !props?.userPermissions
                        //         ?.find((ele) => ele?.group === props?.title)
                        //         ?.permissions?.includes("edit_group")
                        //         ? true
                        //         : false
                        // }
                        isDisabled={!props?.isHavingEditPermission}
                    >
                        <Add />
                    </Button>

                    <Button
                        onPress={() =>
                            deleteAgentGroupConfig(props.configs)
                        }
                        // isDisabled={!(props?.isAdminInEdit)}
                        // isDisabled={
                        //     !props?.isAdminInEdit &&
                        //     !props?.userPermissions
                        //         ?.find((ele) => ele?.group === props?.title)
                        //         ?.permissions?.includes("delete_group")
                        //         ? true
                        //         : false
                        // }
                        isDisabled={!props?.isHavingDeletePermisssion}
                    >
                        <Delete />
                    </Button>
                </View>
            }
        </Flex>
        <div
            className="spectrum-Accordion-itemContent"
            id="spectrum-accordion-item-0-content"
            aria-labelledby="spectrum-accordion-item-0-header"
        >
            <Flex direction={"column"} marginStart="size-250">
                {!props.selectView
                ? (
                    <View marginStart="size-250">
                        {props.configs &&
                            props.configs.map((configData, index) => (
                                <Flex
                                    direction={"row"}
                                    marginStart="size-250"
                                    gap={"size-100"}
                                    key={index}
                                    marginTop={"size-100"}
                                >
                                    <View
                                        width={"20%"}
                                        minWidth={"20%"}
                                        marginEnd={"size-400"}
                                    >
                                        <h5
                                            style={{
                                                overflowWrap: "break-word",
                                            }}
                                        >
                                            {configData.config_key
                                                .replace(/_/g, " ")
                                                .replace(/\b\w/g, (match) =>
                                                    match.toUpperCase()
                                                )}
                                        </h5>
                                    </View>
                                    <View
                                        minWidth={"60%"}
                                        width={"60%"}
                                        marginEnd={"size-100"}
                                    >
                                        {/* TABLE VIEW */}
                                        {/* {configData.config_values.map(
                                            (obj, index) =>
                                                (
                                                <div key={index} style={{display:'flex',flexDirection:"row",justifyContent:"start",
                                                //  borderBottom:"1px solid grey"
                                                 }}>
                                                    {Object.entries(
                                                        obj
                                                    ).map(
                                                        (
                                                            [key, value],
                                                            i
                                                        ) => (
                                                            <div key={i}
                                                            style={{marginRight:"10px", width: key=== "queue_name" ? "35%" : "auto",wordBreak:"break-all" }}
                                                         
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight:"400",
                                                                        color: "#000000",
                                                                       
                                                                       
                                                                    }}
                                                                >
                                                                    {key}{" "}
                                                                </span>
                                                                :
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            "700",
                                                                        color:
                                                                            "#464646",                                                                                
                                                                    }}
                                                                >
                                                                    {" "}{value}
                                                                </span>
                                                                {i <
                                                                    Object.entries(
                                                                        obj
                                                                    )
                                                                        .length -
                                                                        1 &&
                                                                    "\t"}
                                                            </div>
                                                        )
                                                    )}
                                                    {index <
                                                        configData
                                                            .config_values
                                                            .length -
                                                            1 && "\n"}
                                                </div>
                                            )
                                        )} */}

                                        <h5
                                            style={{
                                                overflowWrap: "break-word",
                                            }}
                                        >
                                            {JSON.stringify(
                                                configData.config_values
                                            )}
                                        </h5>

                                        {/* SPACIOUS VIEW */}
                                        {/*
                                    {configData.config_values.map((config)=>(
                                    <Flex>
                                        <View UNSAFE_className="schedule-label">
                                        <h5 className="schedule-label">
                                        {JSON.stringify(config)}
                                        </h5>                                                
                                        </View>
                                    </Flex>
                                ))} */}
                                    </View>
                                    {
                                        <View
                                            minWidth={"5%"}
                                            width={"10%"}
                                            marginEnd={"size-100"}
                                        >
                                            <ActionGroup
                                                onAction={(value) =>
                                                    props.ItemAction(
                                                        value,
                                                        configData
                                                    )
                                                }
                                                // disabledKeys={props?.isAdminInEdit ? [] : ["edit", "delete"]}
                                                // disabledKeys={[
                                                //     ...(props?.isAdminInEdit ||
                                                //     props?.userPermissions
                                                //         ?.find(
                                                //             (ele) =>
                                                //                 ele?.group ===
                                                //                 props?.title
                                                //         )
                                                //         ?.permissions?.includes(
                                                //             "edit_group"
                                                //         )
                                                //         ? []
                                                //         : ["edit"]),
                                                //     ...(props?.isAdminInEdit ||
                                                //     props?.userPermissions
                                                //         ?.find(
                                                //             (ele) =>
                                                //                 ele?.group ===
                                                //                 props?.title
                                                //         )
                                                //         ?.permissions?.includes(
                                                //             "delete_group"
                                                //         )
                                                //         ? []
                                                //         : ["delete"]),
                                                //     ...( configData.config_key=="instance_config" && props.userInGroup.length?['delete', 'edit']:[]),
                                                //     ...(configData.config_key=="instance_config" && props.supervisiorInGroup.length?['delete', 'edit']:[])
                                                // ]}
                                                disabledKeys={!props?.isHavingEditPermission ? ["edit","delete"] : [] }

                                            >
                                                <Item key="edit">
                                                    <Draw />
                                                </Item>
                                                <Item key="delete">
                                                    <Delete />
                                                </Item>
                                            </ActionGroup>
                                        </View>
                                    }
                                </Flex>
                            ))}
                    </View>
                ) : (
                    <ConfigTable data={props?.configs} props={props} 
                    // disabledKeys={[
                    //     ...(props?.isAdminInEdit ||
                    //     props?.userPermissions
                    //         ?.find(
                    //             (ele) =>
                    //                 ele?.group ===
                    //                 props?.title
                    //         )
                    //         ?.permissions?.includes(
                    //             "edit_group"
                    //         )
                    //         ? []
                    //         : ["edit"]),
                    //     ...(props?.isAdminInEdit ||
                    //     props?.userPermissions
                    //         ?.find(
                    //             (ele) =>
                    //                 ele?.group ===
                    //                 props?.title
                    //         )
                    //         ?.permissions?.includes(
                    //             "delete_group"
                    //         )
                    //         ? []
                    //         : ["delete"]),
                    // ]}
                    disabledKeys={!props?.isHavingEditPermission ? ["edit","delete"] : [] }
                    userIngroupCount = {props.userInGroup.length}
                    supervisorInGroupCount = {props.supervisiorInGroup?.length}
                    />
                )}
            </Flex>

            {/* </Flex> */}
        </div>
    </div>
)
}

const ConfigTable = ({ data, props, disabledKeys, userIngroupCount, supervisorInGroupCount }) => {
const [expandedTable, setExpandedTable] = useState({})
const [editingTable, setEditingTable] = useState({});
const [editedData, setEditedData] = useState(data);
useEffect(()=>{
    setEditedData(data.map(dataSection => formatDataorder(dataSection)));
},[data]);

const sortObjectKeys = (obj) => {
    const sortedKeys = Object.keys(obj).sort();
    const sortedObj = {};
  
    sortedKeys.forEach(key => {
      sortedObj[key] = obj[key];
    });
  
    return sortedObj;
  };

  const patternBasedSort = (obj, pattern) =>{
    let matchingKeysObj={}, unmatchingKeysObj={}, sortedMatchedObj={};
    // Split Object Based on patternArray
    Object.keys(obj).forEach(key=>{
        if(pattern.includes(key)){
            matchingKeysObj = Object.assign(matchingKeysObj, {[key]:obj[key]});
        }else{
            unmatchingKeysObj = Object.assign(unmatchingKeysObj, {[key]:obj[key]});
        }
    });

    // Sort Matched Object
    pattern.forEach(key => {
        if (obj.hasOwnProperty(key)) {
            sortedMatchedObj[key] = obj[key];
        }
      });
    return Object.assign(sortedMatchedObj, unmatchingKeysObj);
  }

  const sortArrayOfObjects = (arr, pattern=null) =>{
    return arr.map(obj => ((!pattern)?sortObjectKeys(obj):patternBasedSort(obj,pattern)));
  }

const formatDataorder = (unformattedData) =>{
    const {config_key, config_values} = unformattedData;
    switch(config_key){
        case 'entitlement_roles':
            let [mapped_data] = config_values.map(obj => Object.keys(obj).map(keys => ({key:keys, value:obj[keys]})));
            return {...unformattedData, ...{config_values: mapped_data}}
        case 'aic_config':
            return {...unformattedData, ...{config_values: config_values.map(obj=>({key:Object.keys(obj)[0], value:obj[Object.keys(obj)[0]]}))}}
            case 'routing_profile_concurrency':
                case 'agent_states':
                    case 'queues_assigned':
                        const formattedDataQueueAssigned = Object.assign(unformattedData, {config_values:sortArrayOfObjects(config_values, groupConfigSortingKeysPattern[config_key])});
                        return formattedDataQueueAssigned;
        case 'default_attributes':
            case 'proficiency':
                case 'business_attributes':
                    const formattedData = Object.assign(unformattedData, {config_values:sortArrayOfObjects(config_values)});
                    return formattedData;                
        default:
            return unformattedData;
    }
}

useEffect(()=>{
    setEditedData(data.map(dataSection => formatDataorder(dataSection)));
},[data])

const toggleTable = (configKey) => {
    setExpandedTable((prevState) => ({
        ...prevState,
        [configKey]: !prevState[configKey],
    }))
}

    const expandAll = () => {
        const allOpen = {}
        editedData.forEach((config) => {
            allOpen[config?.config_key] = true
        })
        setExpandedTable(allOpen)
    }

const collapseAll = () => {
    setExpandedTable({})
}

const handleInputChange = (configKey, rowIndex, key, value) => {
    const updatedData = editedData.map((config) => {
        if (config.config_key === configKey) {
            const updatedConfigValues = config.config_values.map(
                (row, index) => {
                    if (index === rowIndex) {
                        return { ...row, [key]: value }
                    }
                    return row
                }
            )
            return { ...config, config_values: updatedConfigValues }
        }
        return config
    });
    setEditedData(updatedData)
}

useEffect(()=>{
    const allOpen = {}
    editedData.forEach((config) => {
      allOpen[config.config_key] = true
    })
    setExpandedTable(allOpen)
  },[])

    return (
        <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
            <div style={{ marginBottom: "10px", textAlign: "end", width: '100%'}}>
                {(!Object.keys(expandedTable).length || !Object.values(expandedTable)?.some(value => value === true))?(<Button
                    onClick={expandAll}
                    variant="secondary"
                    sx={{ marginRight: "5px" }}
                    size="small"
                >
                    <TreeExpandAll />
                    <Text>Expand All</Text>
                </Button>):(                
                    <Button onClick={collapseAll} variant="secondary" size="small">
                    <TreeCollapseAll />
                    <Text>Collapse All</Text>
                </Button>)}
            </div>
            {editedData.map((config, index) => (
                <div
                    key={index}
                    style={{
                        alignItems: "center",
                        marginBottom: "20px",
                        position: "relative",
                        marginLeft: "20px",
                    }}
                >
                    <div style={{ display: "flex", marginLeft:"20px",justifyContent:'space-between'}}>
                        <div style={{justifyContent:'space-between'}}><h5>{config.config_key
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (match) =>
                            match.toUpperCase()
                        )}</h5></div>
                        <div style={{display:'flex', alignItems:'center', gap: '1em'}}>
                            <button
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#ededed',
                                    border: '1px solid transparent',
                                    height: '25px',
                                    padding: '3px',
                                    cursor: "pointer",
                                }}
                                onClick={() => toggleTable(config.config_key)}
                            >
                                {expandedTable[config.config_key] ? (
                                    <ChevronUp />
                                ) : (
                                    <ChevronDown />
                                )}
                            </button>
                            {(
                                <View>
                                    <ActionGroup
                                    // isDisabled={isDisabled}
                                    // disabledKeys={[...disabledKeys, 
                                    //     ...(config.config_key == "instance_config" && userIngroupCount?['edit','delete']:[]),
                                    //     ...(config.config_key == "instance_config" && supervisorInGroupCount?['edit','delete']:[])
                                    // ]}
                                    disabledKeys={disabledKeys}
                                    orientation="horizontal"
                                        onAction={(value) =>
                                            props.ItemAction(value, config)
                                        }
                                    >
                                        <Item key="edit">
                                            <Draw /> 
                                        </Item>
                                        <Item key="delete">
                                            <Delete />
                                        </Item>
                                    </ActionGroup>
                                </View>
                            )}
                        </div>
                    </div>

                    {expandedTable[config.config_key] && (
                        <div
                            style={{
                                marginLeft: "auto",
                                marginRight: "130px",
                                width: "75%",
                                maxWidth: "75%",
                                overflow: "auto",
                            }}
                        >
                            <table
                                border="1"
                                cellPadding="2"
                                cellSpacing="0"
                                style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                    backgroundColor: "#fff",
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                    border: "1px solid #ddd",
                                }}
                            >
                                <thead>
                                    <tr>
                                        {(config.config_key !== 'aic_config' && config.config_key !== 'entitlement_roles') && config.config_values[0] && Object.keys(
                                            config.config_values[0]
                                        ).map((key) => (
                                            <th
                                                key={key}
                                                style={{
                                                    padding: "5px",
                                                    borderRight:
                                                        "1px solid #ddd",
                                                }}
                                            >
                                                {key.toLowerCase()
                                                    .split(/[\s_]+/)
                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                    .join('')}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {config.config_values.length == 0 && (<tr style={{
                                                    borderBottom:
                                                        "1px solid #ddd",
                                                }}> <td style={{
                                                    padding: "5px",
                                                    textAlign:
                                                        "start",
                                                    borderRight:
                                                        "1px solid #ddd",
                                                }}> N/A </td></tr>)}
                                    {config.config_values.length > 0 && config.config_values.map(
                                        (value, rowIndex) => (
                                            <tr
                                                key={rowIndex}
                                                style={{
                                                    borderBottom:
                                                        "1px solid #ddd",
                                                }}
                                            >
                                                {Object.entries(value)?.map(
                                                    ([key, val], valIndex) => (
                                                        <td
                                                            key={valIndex}
                                                            style={{
                                                                padding: "5px",
                                                                textAlign:
                                                                    "start",
                                                                borderRight:
                                                                    "1px solid #ddd",
                                                            }}
                                                        >
                                                            {editingTable[
                                                                config
                                                                    .config_key
                                                            ] ? (
                                                                <input
                                                                    type="text"
                                                                    value={val}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleInputChange(
                                                                            config.config_key,
                                                                            rowIndex,
                                                                            key,
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                ((config?.config_key == 'aic_config' || config?.config_key == 'entitlement_roles') && valIndex == 0)?
                                                                <strong>{val?.toLowerCase()
                                                                    ?.split(/[\s_]+/)
                                                                    ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
                                                                    ?.join('')}</strong>: val
                                                            )}
                                                        </td>
                                                    )
                                                )}
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export const AccordionItemVoiceCompliance = (props) => {
const handleClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const handleIconClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const deleteVoiceComplianceFirstLevel = (agentLdap, firstKey) => {
    props.deleteApi(agentLdap, firstKey)
    // props.getVoiceComplianceList();
}

const changesCheckboxAcc = (value, firstkey) => {
    const operation = value ? "Add" : "Remove"
    props.setSelectedAcc(firstkey, operation)
}

return (
    <div className="spectrum-Accordion-item">
        <Flex
            direction="row"
            UNSAFE_className={"spectrum-Accordion-itemHeading"}
            aria-label="Accordian"
            justifyContent={props.isAdmin && "space-between"}
        >
            <View key={props.key} aria-label="Accordian">
                <Checkbox
                    aria-label="Accordian"
                    UNSAFE_style={{ padding: "0px" }}
                    value={props?.keyName}
                    onChange={(value) =>
                        changesCheckboxAcc(value, props.keyName)
                    }
                />
            </View>
            <View
                UNSAFE_className="spectrum-Accordion-itemHeading"
                minWidth={"280px"}
                width={"90%"}
            >
                <div className="spectrum-Accordion-itemHeader">
                    <button
                        type="button"
                        id="spectrum-accordion-item-0-header"
                        aria-controls="spectrum-accordion-item-0-content"
                        onClick={handleClick}
                    >
                        {props.keyName}
                    </button>
                    <button
                        className="arrowIconButton"
                        onClick={handleIconClick}
                    >
                        <Chevron100 />
                    </button>
                </div>
            </View>
            { (
                <View
                    flex
                    UNSAFE_style={{
                        right: "0px",
                        display: "flex",
                        gap: "10px",
                    }}
                >
                    <Button
                        aria-label="Accordian"
                        UNSAFE_className="pointer"
                        onPress={() =>
                            props.ActionProcess("edit1", props.ldap, {
                                firstKey: props.keyName,
                            })
                        }
                    >
                        <Add />
                    </Button>
                    <Button
                        aria-label="Accordian"
                        UNSAFE_className="pointer"
                        onPress={() =>
                            deleteVoiceComplianceFirstLevel(
                                props.ldap,
                                props.keyName
                            )
                        }
                    >
                        <Delete />
                    </Button>
                </View>
            )}
        </Flex>
        <div
            className="spectrum-Accordion-itemContent"
            id="spectrum-accordion-item-0-content"
            aria-labelledby="spectrum-accordion-item-0-header"
        >
            <Flex direction={"column"} marginStart="size-250">
                <View marginStart="size-250">
                    <Accordion>
                        {props.data.map((item, index) => (
                            <div
                                key={index}
                                className={
                                    props.keyName === "restricted_countries"
                                        ? `box-shadow`
                                        : null
                                }
                            >
                                {Object.keys(item).map((subKey) => (
                                    <SecondLevelAccordian
                                        key={`${subKey}-${index}`}
                                        secondKeyName={subKey}
                                        data={item[subKey]}
                                        firstKeyName={props.keyName}
                                        ldap={props.ldap}
                                        isAdmin={props.isAdmin}
                                        deleteApi={props.deleteApi}
                                        ActionProcess={props.ActionProcess}
                                    />
                                ))}
                            </div>
                        ))}
                    </Accordion>
                </View>
            </Flex>
        </div>
    </div>
)
}

export const SecondLevelAccordian = (props) => {
useEffect(() => {
    if (!Object.values(props.item)[0].length) {
        props.deleteApi(props?.ldap?.userid, Object.keys(props.item)[0])
    }
}, [props?.item])

const handleClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const handleIconClick = (e) => {
    const parentEle =
        e.target.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement
    parentEle.classList.toggle("is-open")
}

const deleteVoiceComplianceSecondLevel = (agentLdap, keyName) => {
    props.deleteApi(agentLdap, keyName)
}

const deleteVoiceComplianceThirdLevel = (agentLdap, index) => {
    const filteredObj = Object.values(props?.item)[0].filter(
        (obj, objIndex) => index !== objIndex
    )
    const filterdKey = Object.keys(props.item)[0]
    props.handleSubmit({ [filterdKey]: filteredObj })
}

return (
    <div className="spectrum-Accordion-item">
        <Flex
            direction="row"
            UNSAFE_className={"spectrum-Accordion-itemHeading"}
            aria-label="Accordian"
        >
            <View
                UNSAFE_className="spectrum-Accordion-itemHeading"
                minWidth={"280px"}
                // width={"92.3%"}
            >
                <div className="spectrum-Accordion-itemHeader">
                    <button
                        type="button"
                        id="spectrum-accordion-item-0-header"
                        aria-controls="spectrum-accordion-item-0-content"
                        onClick={handleClick}
                    >
                        {Object.keys(props.item)[0]
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (match) => match)}
                    </button>
                    <button
                        className="arrowIconButton"
                        onClick={handleIconClick}
                    >
                        <Chevron100 />
                    </button>
                </div>
            </View>
            {(
                <View
                    flex
                    UNSAFE_style={{
                        right: "0px",
                        display: "flex",
                        gap: "10px",
                        flexDirection: "row-reverse",
                    }}
                >
                    <Button
                        aria-label="Accordian"
                        UNSAFE_className="pointer"
                        onPress={() =>
                            props.ActionProcess(
                                "edit",
                                props.ldap,
                                props?.item
                            )
                        }
                        isDisabled={!props.editPermission}
                    >
                        <Draw />
                    </Button>
                    <Button
                        aria-label="Accordian"
                        UNSAFE_className="pointer"
                        onPress={() =>
                            deleteVoiceComplianceSecondLevel(
                                props.ldap,
                                Object.keys(props.item)
                            )
                        }
                        isDisabled={!props.deletePermission}

                    >
                        <Delete />
                    </Button>
                </View>
            )}
        </Flex>
        <div
            className="spectrum-Accordion-itemContent"
            id="spectrum-accordion-item-0-content"
            aria-labelledby="spectrum-accordion-item-0-header"
        >
            <Flex direction={"column"} marginStart="size-250">
                {props?.item &&
                    Object.values(props?.item)?.map((ele, firstindex) => {
                        return ele?.map((obj, secondindex) => (
                            <div
                                style={{ display: "flex" }}
                                key={secondindex}
                            >
                                <View
                                    key={secondindex}
                                    UNSAFE_style={{
                                        borderRadius: "8px",
                                        boxShadow:
                                            "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        marginBottom: "15px",
                                        padding: "10px",
                                        width: "50%",
                                        marginStart: "25px",
                                    }}
                                >
                                    {Object.entries(obj).map(
                                        ([key, value], thirdindex) => (
                                            <View
                                                key={`${thirdindex}-${key}`}
                                                marginStart="size-250"
                                                UNSAFE_style={{
                                                    display: "flex",
                                                }}
                                            >
                                                <h5 className="key">
                                                    {key}
                                                </h5>
                                                <h5>:</h5>
                                                <h5 className="value">
                                                    {value}
                                                </h5>
                                            </View>
                                        )
                                    )}
                                </View>
                                {
                                // props.isAdmin ?
                                 (
                                    <View
                                        flex
                                        UNSAFE_style={{
                                            right: "10px",
                                            display: "flex",
                                            gap: "10px",
                                            position: "relative",
                                            left: "12px",
                                        }}
                                    >
                                        <Button
                                            aria-label="Accordian"
                                            UNSAFE_className="pointer"
                                            onPress={() =>
                                                deleteVoiceComplianceThirdLevel(
                                                    props.ldap,

                                                    secondindex
                                                )
                                            }
                                        >
                                            <Delete />
                                        </Button>
                                    </View>
                                )
                                //  : null
                                 }
                            </div>
                        ))
                    })}
            </Flex>
        </div>
    </div>
)
}

const Chevron100 = () => {
return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        className="spectrum-Icon spectrum-UIIcon-ChevronRight100 spectrum-Accordion-itemIndicator"
    >
        <g id="ChevronSize100">
            <rect
                id="Frame"
                width="10"
                height="10"
                fill="red"
                opacity="0"
            />
            <path d="M7.96423,5.18384A.87628.87628,0,0,0,7.999,5a.88.88,0,0,0-.03467-.18384.85473.85473,0,0,0-.02887-.14386.88334.88334,0,0,0-.12432-.18951.82653.82653,0,0,0-.064-.09754L7.7442,4.3833l-.004-.00586L3.61475.30225a.87492.87492,0,1,0-1.2295,1.24511L5.88007,5,2.38525,8.45264a.87492.87492,0,1,0,1.2295,1.24511L7.74023,5.62256l.004-.00586.00287-.00195a.81976.81976,0,0,0,.064-.0976.88482.88482,0,0,0,.12432-.18951A.85511.85511,0,0,0,7.96423,5.18384Z" />
        </g>
    </svg>
)
}