import React, { useEffect } from "react"
import {
    Flex,
    View,
    Checkbox,
    CheckboxGroup,
    Text as SpectrumText,
    TextField,
    TagGroup,
    Item,
    ComboBox,
} from "@adobe/react-spectrum"
import styles from "./AddAlert.module.css"
import DeleteButton from "./DeleteButton"
import AddButton from "./AddButton"
import { notify } from "../constants/constants"

const CustomAttribute = ({
    index,
    state,
    updateAbandonedState,
    addNewCustomAttribute,
    deleteCustomAttribute,
    handleCustomAttributeCheckboxChange,
    isEditable
}) => {
    const attributeDropdown = [
        {
            id: "ORGID",
            name: "ORGID",
        },
        {
            id: "LEVEL",
            name: "LEVEL",
        },
        {
            id: "PRODUCTNAME",
            name: "PRODUCTNAME",
        },
        {
            id: "PRIORITYTEXT",
            name: "PRIORITY",
        },
    ]

    const handleAttributeNameChange = (value, attrIndex) => {
        const newState = { ...state }
        const isDuplicate = newState.customAttributes.some(
            (attr, index) => attr.attributeName === value
        )
        if (isDuplicate) {
            notify("Attribute name already exists", "warn", {
                containerId: "modalToast",
            })
            return
        }
        newState.customAttributes[attrIndex].attributeName = value
        updateAbandonedState(newState)
    }
    const handleAttributeValueChange = (value, attrIndex) => {
        const newState = { ...state }
        newState.customAttributes[attrIndex].attributeValue = value
        updateAbandonedState(newState)
    }

    const handleAttributeValueKeyDown = (event, attrIndex) => {
        if (event.key === "Enter" && event.target.value.trim() !== "") {
            const newState = { ...state }
            const newValue = event.target.value.trim()
            const newItem = {
                // id: newValue,
                name: newValue,
            }
            newState.customAttributes[attrIndex].items.push(newItem)
            newState.customAttributes[attrIndex].attributeValues.push(newValue)
            newState.customAttributes[attrIndex].attributeValue = ""
            updateAbandonedState(newState)
        }
    }

    const handleRemoveTag = (keys, attrIndex) => {
        const newState = { ...state }
        const removedIndices = []
        newState.customAttributes[attrIndex].items = newState.customAttributes[
            attrIndex
        ].items.filter((item, index) => {
            if (keys.has(item.name.toString())) {
                removedIndices.push(index)
                return false
            }
            return true
        })
        newState.customAttributes[
            attrIndex
        ].attributeValues = newState.customAttributes[
            attrIndex
        ].attributeValues.filter((_, index) => !removedIndices.includes(index))
        updateAbandonedState(newState)
    }

    return (
        <View padding={"0 0 0 3rem"}>
            <View>
                {/* <CheckboxGroup orientation="horizontal"> */}
                    <Checkbox
                        isSelected={state.customAttribute}
                        onChange={handleCustomAttributeCheckboxChange}
                        isDisabled={!isEditable}
                    >
                        <SpectrumText UNSAFE_className={styles.checkbox}>
                            Attributes
                        </SpectrumText>
                    </Checkbox>
                {/* </CheckboxGroup> */}
                {state?.customAttributes?.length &&
                    state?.customAttributes?.map((attr, attrIndex) => (
                        <View
                            marginStart={"2rem"}
                            key={`abc-${attrIndex}`}
                            marginY={"4px"}
                        >
                            <Flex
                                gap={"1rem"}
                                UNSAFE_className={styles.placeholder}
                            >
                                <ComboBox
                                    defaultItems={attributeDropdown}
                                    placeholder="Attribute name"
                                    width={"30rem"}
                                    onSelectionChange={(value) =>
                                        handleAttributeNameChange(
                                            value,
                                            attrIndex
                                        )
                                    }
                                    selectedKey={attr.attributeName}
                                    isDisabled={!state.customAttribute || !isEditable}
                                >
                                    {(item) => (
                                        <Item key={item.id}>{item.name}</Item>
                                    )}
                                </ComboBox>
                                <TextField
                                    value={attr.attributeValue}
                                    placeholder="Enter Attribute value and press Enter"
                                    width={"30rem"}
                                    onChange={(value) =>
                                        handleAttributeValueChange(
                                            value,
                                            attrIndex
                                        )
                                    }
                                    onKeyDown={(event) =>
                                        handleAttributeValueKeyDown(
                                            event,
                                            attrIndex
                                        )
                                    }
                                    isDisabled={!state.customAttribute || !isEditable}
                                />
                                <DeleteButton
                                    onClick={() =>
                                        deleteCustomAttribute(attrIndex)
                                    }
                                    isDisabled={
                                        !isEditable ||
                                        state.customAttributes.length == 1
                                    }
                                />
                                {attrIndex === 0 && (
                                    <AddButton
                                        isEditable={isEditable}
                                        onClick={addNewCustomAttribute}
                                    />
                                )}
                            </Flex>
                            {attr?.items?.length > 0 && (
                                <TagGroup
                                    UNSAFE_style={{ overflow: "auto" }}
                                    width={"61rem"}
                                    maxHeight={"10rem"}
                                    items={attr.items}
                                    onRemove={(keys) =>
                                        handleRemoveTag(keys, attrIndex)
                                    }
                                    aria-label="Removable TagGroup example"
                                >
                                    {(item) => (
                                        <Item key={item.name}>{item.name}</Item>
                                    )}
                                </TagGroup>
                            )}
                        </View>
                    ))}
            </View>
        </View>
    )
}

export default CustomAttribute
