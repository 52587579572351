import React, { useEffect, useRef, useState } from "react"
import {
    Flex,
    SearchField,
    ActionGroup,
    Item,
    Content,
    Badge,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    View,
    ProgressCircle,
    DialogTrigger,
    ActionButton,
    Dialog,
    Divider,
} from "@adobe/react-spectrum"
import {
    getAllPolicies,
    addPolicy,
    deletePolicy,
    getRolesList
} from "../utilities/api/admin-api"
import { getUserInfo, userPermissionsAPI } from "../utilities/api/userInfo"

import {
    updateUserInfo,
    updateUserPermissions,
    userRolesAndPermissions,
} from "../actions/login"
import { connect } from "react-redux"
import { renderEmptyState } from "../utilities/components/CommonUseComponents"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import DeleteDialog from "./deleteDialog"
import { notify } from "../constants/constants"
import PermissionDetails from "./PermissionDetails"
import store from "../store"



/**
 * TODOS:
 * Create Table Layout
 * Fetch All POlicies
 * Setup For creating new policies
 * Delete Policy From list
 */

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        rolesAndPermissions: state.login.rolesAndPermissions,
    }
}

const Policies = ({ user,rolesAndPermissions }) => {
    const havingDeletePermission = rolesAndPermissions["ADMIN"]?.delete?.filter((ele) => ele.subService === "POLICIES")[0]?.resources.type
    const havingEditPermission = rolesAndPermissions["ADMIN"]?.edit?.filter((ele) => ele.subService === "POLICIES")[0]?.resources.type
    // const havingViewPermission = rolesAndPermissions["ADMIN"]?.view?.filter((ele) => ele.subService === "POLICIES")[0]?.resources.type
    const havingAddPermission = rolesAndPermissions["ADMIN"]?.add?.filter((ele) => ele.subService === "POLICIES")[0]?.resources.type


    const [policiesList, setPoliciesList] = useState([])
    const [filteredPoliciesList, setFilteredPoliciesList] = useState([])
    const [selectedPolicies, setSelectedPolicies] = useState(new Set())
    const [isLoading, setIsLoading] = useState(false)
    const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    const [currentText, setCurrentText] = useState("")
    const [alreadyExistingPolicies , setAlreadyExistingPolicies] = useState([])
    const [rolesList, setRolesList] = useState([])
    const selectedData = useRef(null)

    const columns = [
        // { name: "ID", key: "id" ,minWidth: "20%"},
        { name: "Name", key: "name", minWidth: "20%" },
        { name: "Services", key: "services", minWidth: "30%" },
        { name: "Actions", key: "actions", minWidth: "20%" },
    ]

    const performAction = (value, selectedPoliciesSet) => {
        switch (value) {
            case "edit":
                selectedData.current = selectedPoliciesSet
                setIsAddDialogOpen(true)
                break
            case "add":
                selectedData.current = null
                setIsAddDialogOpen(true)
                break
            case "delete":
                setIsDeleteDialogOpen(true)
                selectedData.current = selectedPoliciesSet.id
                break
            default:
                // console.log({ selectedPoliciesSet })
                break
        }
    }

    // const onCloseHandlerNewPolicy = (value) => {
    //     console.log({ value })
    //     if (value) {
    //         value.policies.forEach((policy) => {
    //             Object.keys(policy.permissions).forEach((permissionType) => {
    //                 policy.permissions[permissionType] = policy?.permissions[
    //                     permissionType
    //                 ].filter((permission) => {
    //                     return !(
    //                         permission.tab === "" &&
    //                         Object.keys(permission.resources).length === 0
    //                     )
    //                 })
    //             })
    //         })
    //         const upatedValue = JSON.stringify(value)
    //             .replaceAll("policies", "services")
    //             .replaceAll("tab", "subService")
    //         addPolicy(user.userid, JSON.parse(upatedValue))
    //             .then((resp) => {
    //                 console.log({ resp })
    //                 getPolicies()
    //             })
    //             .catch((err) => {
    //                 console.log({ err })
    //             })
    //             .finally(() => {
    //                 console.log("finally")
    //             })
    //     }
    //     selectedData.current = null
    //     setIsAddDialogOpen(false)
    // }
    const onCloseHandlerNewPolicy = (value) => {
        if (value) {
          value.policies.forEach((policy) => {
            Object.keys(policy.permissions).forEach((permissionType) => {
              policy.permissions[permissionType] = policy?.permissions[
                permissionType
              ].filter((permission) => {
                return !(
                  permission.tab === "" &&
                  Object.keys(permission.resources).length === 0
                );
              });
            });
          });
          const updatedValue = JSON.stringify(value)
            .replaceAll("policies", "services")
            .replaceAll("tab", "subService");
          addPolicy(user?.userid, JSON.parse(updatedValue))
            .then((resp) => {
              getPolicies();
              // Update the user permissions and roles in the store
             
              userPermissionsAPI(user?.userid).then((res) => {
                store.dispatch(userRolesAndPermissions(res));
              });
            })
            .catch((err) => {
              console.log({ err });
            })
            .finally(() => {
              console.log("finally");
            });
        }
        selectedData.current = null;
        setIsAddDialogOpen(false);
      };

      const closeDeleteDialog = (isDeletedConfirmed) => {
        if (isDeletedConfirmed) {
            setIsLoading(true);    
            const isPolicyAssociated = rolesList?.some((role) =>
                role.policyIds.includes(selectedData.current)
            );

            console.log("selectedData.current ",selectedData.current);
            
    
            if (isPolicyAssociated) {
                notify("Policy is associated with some roles, please remove the policy from roles and try again", "warn");
                setIsDeleteDialogOpen(false);
                setIsLoading(false);
                return;
            }
    
            deletePolicy(user?.userid, selectedData.current)
                .then((response) => {
                    notify("Policy successfully deleted", "success");
                    userPermissionsAPI(user?.userid).then((res) => {
                        store.dispatch(userRolesAndPermissions(res));
                    });
                })
                .catch((err) => {
                    notify(err, "error");
                })
                .finally(() => {
                    setIsLoading(false);
                    getPolicies();
                });
        }
        setIsDeleteDialogOpen(false);
    };
    

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) => performAction(value, item)}
                            disabledKeys={[
                                !havingEditPermission && "edit",
                                !havingDeletePermission && "delete",
                            ]}
                        >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            {
                                <Item key="delete">
                                    <Delete />
                                </Item>
                            }
                        </ActionGroup>
                    </Cell>
                )
            case "services":
                return (
                    <Cell>
                        <Flex gap={"size-100"}>
                            {item[columnKey].length ? (
                                item[columnKey].map((service) => (
                                    <Badge
                                        variant="neutral"
                                        key={service.service}
                                    >
                                        {service.service}
                                    </Badge>
                                ))
                            ) : (
                                <b>-</b>
                            )}
                        </Flex>
                    </Cell>
                )
            case "name":
                return <Cell>{item[columnKey]}</Cell>
            default:
                return <Cell>{JSON.stringify(item[columnKey])}</Cell>
        }
    }
    
    const getPolicies = () => {
        setIsLoading(true)
        getAllPolicies(user?.userid)
            .then((response) => {
                setAlreadyExistingPolicies(response?.map((ele) => ele.name.toLowerCase().trim()))                
                setPoliciesList(response)
                setFilteredPoliciesList(response) 
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setFilteredPoliciesList([]) 
            })
            .finally(() => {
                console.log("completed")
                setIsLoading(false)
            })
    }

    console.log({rolesList});
    

    const getAllRolesAPI = () => {
        getRolesList()
            .then((response) => {
                setRolesList(response)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getPolicies()
        getAllRolesAPI()
    }, [])

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (!policiesList) return; 
            
            if (currentText) {
                filterFunction(currentText)
            } else {
                setFilteredPoliciesList(policiesList) 
            }
        }, 500)
        return () => clearTimeout(timeOut)
    }, [currentText, policiesList]) 

    function filterFunction(text) {
        if (!policiesList) return; 
        
        const searchTerm = text.toLowerCase().trim()
        const filteredList = policiesList.filter((item) => {
            const nameMatch = item.name.toLowerCase().includes(searchTerm)
            const servicesMatch = item.services.some((service) =>
                service.service.toLowerCase().includes(searchTerm)
            )
            return nameMatch || servicesMatch
        })

        setFilteredPoliciesList(filteredList)
    }

    
    return (
        <>
            {isLoading ? (
                <View
                    UNSAFE_style={{
                        paddingTop: "10%",
                        paddingLeft: "45%",
                        position: "absolute",
                        zIndex: "10",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
                </View>
            ) : null}

            <div align="center">
                <Heading variant="pageTitle">Policies</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                }}
            >
                <Flex
                    direction="row"
                    gap="size-100"
                    UNSAFE_className="searchMobile"
                >
                    <SearchField
                        isQuiet
                        onClear={() => setCurrentText("")}
                        onChange={setCurrentText}
                        aria-label="Search"
                        value={currentText}
                        width={"20%"}
                    />
                    <ActionGroup
                        width={"80%"}
                        alignItems="end"
                        onAction={(value) =>
                            performAction(value, selectedPolicies)
                        }
                        disabledKeys={[!havingAddPermission && "add", !havingDeletePermission && "bulkDelete",]}
                    >
                        <Item key="add">+ Add</Item>
                        {/* <Item key="bulkDelete">
                            <Delete />
                            <Text>Delete</Text>
                        </Item> */}
                    </ActionGroup>
                </Flex>

               <TableView
                    aria-label="pernissions"
                    selectionMode={!havingDeletePermission ? "none" : "multiple"}
                    selectedKeys={selectedPolicies}
                    onSelectionChange={setSelectedPolicies}
                    height={"45vh"}
                    renderEmptyState={renderEmptyState}
                    // density="spacious"
                    overflowMode="wrap"
                    // disabledKeys={['1']}
                >
                    <TableHeader columns={columns}>
                        {(column) => (
                            <Column
                                allowsResizing
                                key={column.key}
                                align={
                                    column.key === "actions" ? "end" : "start"
                                }
                                minWidth={column.minWidth}
                            >
                                {column.name}
                            </Column>
                        )}
                    </TableHeader>
                    <TableBody items={filteredPoliciesList}>
                        {(item) => (                            
                            <Row key={`${item.id}`}>
                                {(columnKey) => getCell(columnKey, item)}
                            </Row>
                        )}
                    </TableBody>
                </TableView>

                <DialogTrigger
                    type="fullscreenTakeover"
                    isDismissable={true}
                    defaultOpen={false}
                    isOpen={isAddDialogOpen}
                >
                    <ActionButton ishidden isQuiet></ActionButton>
                    {(close) => (
                        <Dialog>
                            <Heading justifySelf={"center"} level={"6"}>
                                Add Policy
                            </Heading>
                            <Divider />
                            <Content>
                                <PermissionDetails
                                    selectedItem={selectedData.current}
                                    closeModal={onCloseHandlerNewPolicy}
                                    alreadyExistingPolicies={alreadyExistingPolicies}
                                />
                            </Content>
                        </Dialog>
                    )}
                </DialogTrigger>

                {isdeleteDialogOpen && (
                    <DeleteDialog
                        isDialogOpened={isdeleteDialogOpen}
                        handleCloseDialog={(isDeletedConfirmed) =>
                            closeDeleteDialog(isDeletedConfirmed)
                        }
                        entity="Policy"
                    />
                )}
            </div>
        </>
    )
}

export default connect(mapStateToProps)(Policies)
