import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, View, NumberField, Switch
} from '@adobe/react-spectrum';

const AddAniDialog = (props) => {
    const [ani, setAni] = useState('');
    const [bypass, setBypass] = useState('');

    useEffect(() => {
        if (props.editData) {
            setAni(props.editData.ani);
            setBypass(props.editData.bypass === "no" ? false : true)
        }
    }, []);

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    }


    const onSaveHandler = () => {
        props.handleCloseDialog(true, ani,bypass);
    }



    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="S">
                        <Heading>{`${props.operation} ANI ByPass`}</Heading>
                        <Divider />
                        <Content>

                            <Form isRequired>
                               <View UNSAFE_style={{display:"flex"}}>
                                <View>ANI</View>
                                    <NumberField
                                        value={ani.toString()}
                                        onChange={setAni}
                                        minValue={0}
                                        isDisabled={props?.editData?.ani}
                                        marginStart={"28px"}
                                        marginTop={"-4px"}
                                        width={"60%"}
                                    />

                               </View>
                                <div style={{ marginTop: '30px' }}></div>
                               <View UNSAFE_style={{display:"flex"}}>
                                <View>ByPass</View>
                                <Switch
                                    isSelected={bypass}
                                    onChange={setBypass}
                                    marginStart={"20px"}
                                    marginTop={"-4px"}
                                    height={"10px"}
                                    width={"20px"}
                                    UNSAFE_style={{transform:"scale(1.2)"}}
                                    >
                                    
                                    </Switch>
                               </View>
                               
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>Cancel</Button>
                            <Button variant="primary" onPress={onSaveHandler}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddAniDialog;