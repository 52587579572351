import React from 'react';
import { Accordion, AccordionItemGroupAgent } from '../../../utilities/components/Accordion/Accordion';
import { renderEmptyState } from '../../../utilities/components/CommonUseComponents';
import { Flex } from '@adobe/react-spectrum';
const GroupPrimaryList = ({AgentList, ActionProcess, deleteAgent, isAdmin}) =>{ 
    return (
    (AgentList.length)?(<Accordion>
        {AgentList?.sort((a, b) => a?.agentGroupName?.toLowerCase()?.localeCompare(b?.agentGroupName?.toLowerCase())).map((item) => (<AccordionItemGroupAgent isAdmin={isAdmin} key={item.agentGroupName} title={item.agentGroupName} groupList={item.agentGroupList} ItemAction={(value, groupName, groupData) => ActionProcess(value, groupName, groupData)} deleteGroupAgents={(deleteAgentValue) => deleteAgent(deleteAgentValue)}/>))}
    </Accordion>):(<><Flex width={'100%'} justifyContent={'center'}>{renderEmptyState()}</Flex></>)
    );
}

export default GroupPrimaryList;