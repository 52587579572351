import React, { useEffect, useState } from "react"
import { Item, TabList, TabPanels, Tabs, Well } from "@adobe/react-spectrum"
import { connect } from "react-redux"
import UserProfile from "./UserProfile/UserProfile"
import { TabsDisplay } from "../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.authorizedAccess.username,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
        rolesAndPermissions : state.login.rolesAndPermissions
    }
}

const Reports = ({ user, rolesAndPermissions , directList }) => {
    const rawdata = rolesAndPermissions
    const permissionsArrayForRouting = React.useMemo(() => {
        const result = new Set();
        if (rawdata) {
            Object.keys(rawdata).forEach((service) => {
                result.add(service);
                Object.keys(rawdata[service]).forEach((permissionType) => {
                    rawdata[service][permissionType].forEach((entry) => {
                        result.add(entry.subService);
                    });
                });
            });
        }
        return Array.from(result);
    }, [rawdata]);

    const modifiedData = permissionsArrayForRouting.flatMap((item) => {
        return item.startsWith("ROUTING") ? ["ROUTING FLOW CONFIG",item] : item
    })
    
    const uniqueData = [...new Set(modifiedData)]
    const arrayOfUniqueData = Array.from(uniqueData)

    return (
        <Well
            UNSAFE_style={{
                minHeight: "75vh",
                marginRight: "1%",
                marginLeft: "1%",
            }}
        >
             {arrayOfUniqueData.length > 0  && (
                <TabsDisplay
                    permissions={arrayOfUniqueData}
                    directList={directList}
                    user={user}
                />
            )}
            {/* {permissions?.length && (
                <Tabs aria-label="Audit Reports">
                    <TabList>
                        { <Item key="user_profile">USER PROFILE</Item>}
                      
                      
                    </TabList>
                    <TabPanels>
                        <Item key="user_profile">
                            <UserProfile />
                        </Item>
                       
                    </TabPanels>
                </Tabs>
            )} */}
        </Well>
    )
}

export default connect(mapStateToProps)(Reports)
