import React from "react"
import {
    Flex,
    View,
    Checkbox,
    CheckboxGroup,
    Text as SpectrumText,
    TextField,
    Button,
    Divider,
    Heading,
} from "@adobe/react-spectrum"
import styles from "./AddAlert.module.css"
import DeleteButton from "./DeleteButton"
import AddButton from "./AddButton"

const AgentSlackAndEmail = ({
    index,
    state,
    alertOption,
    updateAgentOption,
    addSlackChannel,
    updateSlackChannel,
    deleteSlackChannel,
    addEmail,
    updateEmail,
    deleteEmail,
    handleDelete,
    abandonedState,
    isEditable,
}) => {
    return (
        <View
            UNSAFE_style={{
                maxWidth: "100vw",
            }}
        >
            <View padding={"0 0 0 3rem"}>
                <Flex justifyContent={"space-between"}>
                    <Flex>
                        <View UNSAFE_className={styles.checkbox} width={"120px"}>
                            <Heading level={6}>Individual :</Heading>
                        </View>
                        <Checkbox
                            isSelected={state?.slack}
                            onChange={(isSelected) =>
                                updateAgentOption("slack", isSelected)
                            }
                            isDisabled={!isEditable}
                        >
                            <SpectrumText UNSAFE_className={styles.checkbox}>
                                Slack
                            </SpectrumText>
                        </Checkbox>
                        <Checkbox
                            isSelected={state?.email}
                            onChange={(isSelected) =>
                                updateAgentOption("email", isSelected)
                            }
                            isDisabled={!isEditable}
                        >
                            <SpectrumText UNSAFE_className={styles.checkbox}>
                                Email
                            </SpectrumText>
                        </Checkbox>
                    </Flex>
                    <Button
                        variant="negative"
                        style="outline"
                        UNSAFE_className="pointer"
                        UNSAFE_style={{ borderRadius: "4px" }}
                        onPress={handleDelete}
                        isDisabled={
                            !isEditable || abandonedState?.length == 1
                        }
                    >
                        Delete
                    </Button>
                </Flex>
            </View>
        </View>
    )
}

export default AgentSlackAndEmail
