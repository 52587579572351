import './style.css';
import React, { useEffect, useState } from 'react';
import { Flex, Heading, ProgressCircle, View, SearchField, TableView, TableHeader, Row, Column, TableBody, Cell, Content, Text, ContextualHelp, StatusLight} from '@adobe/react-spectrum';
import { connect } from 'react-redux';
import TableViewContainer from '../../../../utilities/components/TableViewContanire';
import { getAllOperatingHours } from '../../../../utilities/api/phoenix-api';
import { renderEmptyState } from '../../../../utilities/components/CommonUseComponents';
import { PheonixRoles } from '../../../../constants/constants';

function mapStateToProps(state) {
    return {
      user: state.login.userInfo,
      permissions: (state.login.authorizedAccess != null) ? state.login.authorizedAccess.permissions : []
    };
}

const GroupCallDuration = (props) => {
    const isAdmin = props?.permissions?.includes(
        PheonixRoles.operationalHoursAdmin
    )
    const [isLoading, setIsLoading] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const [originalList, setOriginalList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);

    const columns = [
        {name: "Name", key:"name", minWidth:'20%'},
        {name: "Time Zone", key:"timezone", minWidth:'15%'},
        {name: "Monday", key:"monday"},
        {name: "Tuesday", key:"tuesday"},
        {name: "Wednesday", key:"wednesday"},
        {name: "Thursday", key:"thursday"},
        {name: "Friday", key:"friday"},
        {name: "Saturday", key:"saturday"},
        {name: "Sunday", key:"sunday"}
      ];
    
    const hourMinuteFormat = (time) => {
        let [hours,minutes] = time.split(':');
        hours = (hours.length == 1)?'0'+hours:hours;
        minutes = (minutes.length == 1)?'0' + minutes : minutes;
        return hours +':'+ minutes;
    }
    
    const formatTimeValues = (timeValues) => {
        if(timeValues){
            let [startTime, endTime] = timeValues.split('-');
            startTime = hourMinuteFormat(startTime);
            endTime = hourMinuteFormat(endTime);
            return (<Text UNSAFE_className={`timeRange--${(startTime+'-'+endTime).split(':').join('')}`}><strong>{startTime+' - '+endTime}</strong></Text>);
        }else{
            return '-';
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case 'name':
                if(item['description']){
                    return (
                        <Cell>
                            <Flex direction={'row'} gap={'size-0'} columnGap={'size-0'}>
                                <View>
                                    <Text>
                                        {item[columnKey] || '-'}
                                    </Text>
                                </View>
                                <View>
                                    <ContextualHelp variant="info" UNSAFE_className='cursor--pointer'>
                                        <Heading>{item[columnKey] || '-'}</Heading>
                                        <Content>
                                            <Text>
                                                {item['description'] || '-'}
                                            </Text>
                                        </Content>
                                    </ContextualHelp>
                                </View>
                            </Flex>
                        </Cell>
                    )
                }else{
                    return (
                        <Cell>{item[columnKey] || '-'}</Cell>
                    )
                }

            case 'timezone':
                return(
                    <Cell>{item[columnKey] || '-'}</Cell>
                )

            default: 
            return (
                <Cell>{(item[columnKey])?formatTimeValues(item[columnKey]):(<Text UNSAFE_className='timeRange--closed'><strong>Closed</strong></Text>)}</Cell>
            )
        }
    }

    const getAllGroupHours = () =>{
        setIsLoading(true);
        getAllOperatingHours().then(resp=>{
            setOriginalList(resp.sort((a, b) => a.name.localeCompare(b.name)));
            setFilteredList(resp.sort((a, b) => a.name.localeCompare(b.name)));
        }).catch(err=>{
            console.log({err});
        }).finally(()=>{
            setIsLoading(false);
        });
    }

    const filterOperationalHours = (searchText) => {
        const lowercaseValue = searchText?.trim()?.replace(/\s+/g, " ")?.toLowerCase()
        if(searchText){
            setFilteredList(originalList.filter(group => group?.name?.toLowerCase()?.includes(lowercaseValue) || group?.timezone?.toLowerCase()?.includes(lowercaseValue) || group?.description?.toLowerCase()?.includes(lowercaseValue)))
        }else{
            setFilteredList(originalList);
        }        
    }
    // Component INIT
    useEffect(()=>{
        getAllGroupHours();
    },[]);

    // Debounce
    useEffect(()=>{
        const timer = setTimeout(() => {
            // call to a function 
            if(originalList.length){
                filterOperationalHours(currentText);
            }
        }, 500);
        return () => {
            clearTimeout(timer);
        }
    },[originalList, currentText])
    
    return (
        <>
            <div className={isLoading ? 'display-block' : 'display-none'} style={{ paddingTop: '10%', paddingLeft: '45%', position: 'absolute', zIndex: '10' }}>
                <ProgressCircle aria-label="Loading…" isIndeterminate size='L' />
            </div>
            <div align="center">
                <Heading variant="pageTitle">Operational Hours</Heading>
            </div>
            <TableViewContainer>
                <Flex direction={'row'} justifyContent={'space-between'} gap="size-100" UNSAFE_className="searchMobile">
                <View>
                    <Flex direction={'row'}>
                        <SearchField isQuiet width={'80%'} onClear={setCurrentText} aria-label='Search' onChange={(value)=>setCurrentText(value)} value={currentText} />
                    </Flex>
                </View>
                <View>
                    <Flex direction={'row'}>
                        <StatusLight UNSAFE_className='statusTimeRange--closed'>Closed</StatusLight>
                        <StatusLight UNSAFE_className='statusTimeRange--0900-1700'>09:00-17:00</StatusLight>
                        <StatusLight UNSAFE_className='statusTimeRange--0900-1800'>09:00-18:00</StatusLight>
                        <StatusLight UNSAFE_className='statusTimeRange--0600-1800'>06:00-18:00</StatusLight>
                        <StatusLight UNSAFE_className='statusTimeRange--0800-1700'>08:00-17:00</StatusLight>
                        <StatusLight UNSAFE_className='statusTimeRange--0800-1800'>08:00-18:00</StatusLight>
                        <StatusLight UNSAFE_className='statusTimeRange--0000-2359'>00:00-23:59</StatusLight>
                        <StatusLight UNSAFE_className='statusTimeRange--other'>Other</StatusLight>
                    </Flex>
                </View>
                </Flex>
                <Flex width="100%" height="100%" direction="column">
                    <TableView
                    aria-label="Case History"
                    selectionMode="none"
                    renderEmptyState={renderEmptyState}
                    height="70vh"
                    overflowMode="wrap"
                    >
                    <TableHeader columns={columns}>
                        {(column) => (
                            <Column key={column.key} align={column.key === 'actions' ? 'end' : 'start'} minWidth={column?.minWidth || '0%'}>
                                {column.name}
                            </Column>
                        )}
                    </TableHeader>
                    <TableBody>
                        {filteredList.map((item,index)=> (
                            <Row key={index}>
                            {(columnKey) => getCell(columnKey, item)}
                        </Row>
                        ))}
                    </TableBody>
                    </TableView>
                </Flex>
            </TableViewContainer>
        </>
    )
};

export default connect(mapStateToProps)(GroupCallDuration);