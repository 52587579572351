import React, { useEffect, useRef, useState } from "react"
import {
    Item,
    Content,
    Heading,
    IllustratedMessage,
    Flex,
    View,
    SearchField,
    ActionGroup,
    Button,
    ProgressCircle,
    Text,
    MenuTrigger,
    Menu,
    ActionButton,
    TooltipTrigger,
    Tooltip,
    TableView,
    TableHeader,
    TableBody,
    Row,
    Column,
    Cell,
} from "@adobe/react-spectrum"
import DeleteAgentGroup from "./AgentGroupDelete"
import {
    deleteAgentGroupList,
    getAllGroupNames,
    editAgentGroupList,
    getAllAgentGroupList,
    uploadAgentGroupBulk,
    addAgentGroupList,
    getAllAttributeListAndValues,
    bulkEditAgentGroup,
    getQueueList,
    getLoggedInUserIsAdmin,
    addSupervisiorData,
} from "../../../utilities/api/phoenix-api"
import AddAgentGroup from "./AddAgentGroup"
import "./AgentGroupStyle.css"
import AgentPrimaryList from "./AgentPrimaryList"
import GroupPrimaryList from "./GroupPrimaryList"
import UploadFilesXlsx from "../../../utilities/components/UploadFiles"
import FileAdd from "@spectrum-icons/workflow/FileAdd"
import Draw from "@spectrum-icons/workflow/Draw"
import { notify, SERVER_URL } from "../../../constants/constants"
import BulkEditQueue from "./BulkEditQueue"
import BulkEditProficiency from "./BulkEditProficiency"
import Delete from "@spectrum-icons/workflow/Delete"
import BulkEditGroup from "./BulkEditGroup"
import { renderEmptyState } from "../../../utilities/components/CommonUseComponents"
import { instances } from "chart.js"
// import { toast } from "react-toastify"
// import notify from "../../../constants/constants"
import { connect } from "react-redux"

function mapStateToProps(state) {
    return {
        user: state.login.authorizedAccess?.username,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
        rolesAndPermissions: state.login.rolesAndPermissions,
    }
}

const AgentGroups = ({
    user,
    isAdmin,
    queueAccess,
    isAdminInEdit,
    groupPermission,
    additionalPermission,
    commonPermissions,
    userPermissions,
    rolesAndPermissions

}) => {
    // console.log("rolesAndPermissions from USERS",rolesAndPermissions);

    const groupsHavingDeletePermissions = rolesAndPermissions["AMAZON CONNECT"]?.delete?.filter(ele => ele.subService === "USERS")[0]?.resources.groups
    const groupsHavingEditPermissions = rolesAndPermissions["AMAZON CONNECT"]?.edit?.filter(ele => ele.subService === "USERS")[0]?.resources.groups
    const groupsHavingViewPermissions = rolesAndPermissions["AMAZON CONNECT"]?.view?.filter(ele => ele.subService === "USERS")[0]?.resources.groups
    const groupsHavingAddPermissions = rolesAndPermissions["AMAZON CONNECT"]?.add?.filter(ele => ele.subService === "USERS")[0]?.resources.groups
    // console.log("groupsHavingViewPermissions ",groupsHavingViewPermissions)
    // console.log("groupsHavingEditPermissions ",groupsHavingEditPermissions)

    
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    const [groupsList, setGroupList] = useState([])
    const [operation, setOperation] = useState("")
    const [currentText, setCurrentText] = useState("")
    const [allAgents, setAllAgents] = useState([])
    const [layout, setLayout] = useState("Agent")
    const [AgentList, setAgentList] = useState([])
    const [groupAgentList, setGroupAgentList] = useState([])
    const [isUploadDialogueOpened, setIsUploadDialogueOpened] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [cloneInfo, setCloneInfo] = useState()
    const [showRoutingDeleteOption, setShowRoutingDeleteOption] = useState(
        false
    )
    const [selectedAccordians, setSelectedAccordians] = useState({})
    const [attributeList, setAttributeList] = useState([])
    const [isBulkEditDialogueOpen, setIsBulkEditDialogueOpen] = useState(false)
    const [queueList, setQueueList] = useState()

    const currentContactSelected = useRef("")
    const editData = useRef(false)
    const [selectedAgent, setSelectedAgent] = useState(new Set())
    const [userToBeDeleted, setUserToBeDeleted] = useState("")

    const columns = [
        { name: "User", key: "user", minWidth: 120 },
        { name: "Group", key: "group", minWidth: 120 },
        { name: "Instance", key: "instance", minWidth: 120 },
    ]

    const columnsAdmin = [
        { name: "User", key: "user", minWidth: 120 },
        { name: "Group", key: "group", minWidth: 120 },
        { name: "Instance", key: "instance", minWidth: 120 },
        { name: "Actions", key: "actions" },
    ]

    const formatGroupAgentList = (unformattedData) => {
        const updatedGroupStructure = unformattedData.map((agent) => {
            const { agentGroupName } = agent
            const agentGroupList = new Set(
                unformattedData
                    .filter(
                        (subagent) => subagent.agentGroupName == agentGroupName
                    )
                    .map((subagent) => {
                        return {
                            agentName: subagent.agentLdap,
                            groupActiveStatus: subagent.isAgentProfile,
                            firstName: subagent.firstName,
                            lastName: subagent.lastName,
                            isGroupSupervisor: subagent.isGroupSupervisor,
                            instance: subagent.instance,
                        }
                    })
            )
            return {
                agentGroupName,
                agentGroupList: Array.from(agentGroupList),
            }
        })
        const uniqueGroupList = [
            ...new Map(
                updatedGroupStructure.map((item) => [item.agentGroupName, item])
            ).values(),
        ]
        setGroupAgentList(uniqueGroupList)
    }

    const formatAgentGroupList = (unformattedData) => {
        const updatedAgentGroupStructure = unformattedData.map((agent) => {
            const { agentLdap } = agent
            const agentGroupList = new Set(
                unformattedData
                    .filter((subagent) => subagent.agentLdap == agentLdap)
                    .map((subagent) => {
                        return {
                            groupName: subagent.agentGroupName,
                            groupActiveStatus: subagent.isAgentProfile,
                            firstName: subagent.firstName,
                            lastName: subagent.lastName,
                            isGroupSupervisor: subagent.isGroupSupervisor,
                            instance: subagent.instance,
                        }
                    })
            )
            return { agentLdap, agentGroupList: Array.from(agentGroupList) }
        })
        const uniqueAgentList = [
            ...new Map(
                updatedAgentGroupStructure.map((item) => [item.agentLdap, item])
            ).values(),
        ]
        const sortedData = [...uniqueAgentList]?.sort((a, b) =>
            a.firstname?.localeCompare(b.firstname)
        )
        setAgentList(sortedData)
    }

    const getAgentGroupList = async (ldap) => {
        setIsLoading(true)
        const response = await getAllAgentGroupList(ldap)
        setAllAgents(response)
        formatAgentGroupList(response)
        formatGroupAgentList(response)
        setIsLoading(false)
        setCurrentText("")
    }

    const handleUpload = async (isClosed, File) => {
        setIsLoading(true)
        uploadAgentGroupBulk(user, File)
            .then(() => {
                notify("Bulk Add Successful", "success")
            })
            .catch(() => {
                notify("Something went wrong", "error")
            })
            .finally(() => {
                getAgentGroupList(user)
                setIsUploadDialogueOpened(false)
                setIsLoading(false)
            })
    }

    const deleteAgentGroupAccordian = (deleteValue, showRoutingDelete) => {
        const userName = Object.keys(deleteValue)[0]
        setShowRoutingDeleteOption(showRoutingDelete)
        setShowRoutingDeleteOption(true)
        setIsLoading(true)
        setIsDeleteDialogOpen(true)
        setUserToBeDeleted(userName)
        currentContactSelected.current = deleteValue
    }

    const ActionProcess = (value, AgentLdap, groupData) => {
        let first_Name = ""
        let last_Name = ""
        let agent = ""
        let groupname = ""
        let activeStatus = false
        let is_group_supervisor_value = false
        let agent_instance
        if (layout == "Group") {
            if (groupData) {
                const {
                    agentName,
                    groupActiveStatus,
                    firstName,
                    lastName,
                    isGroupSupervisor,
                    instance,
                } = groupData
                first_Name = firstName
                last_Name = lastName
                agent = agentName
                groupname = AgentLdap
                activeStatus = groupActiveStatus
                is_group_supervisor_value = isGroupSupervisor
                agent_instance = instance
            }
        } else {
            if (groupData) {
                const {
                    groupName,
                    groupActiveStatus,
                    firstName,
                    lastName,
                    isGroupSupervisor,
                    instance,
                } = groupData[0]
                first_Name = firstName
                last_Name = lastName
                agent = AgentLdap
                groupname = groupName
                activeStatus = groupActiveStatus
                is_group_supervisor_value = isGroupSupervisor
                agent_instance = instance
            }
        }
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = {
                    firstName: first_Name,
                    lastName: last_Name,
                    agentLdap: agent,
                    agentGroupName: groupname,
                    isAgentProfile: activeStatus,
                    isGroupSupervisor: is_group_supervisor_value,
                    instance: agent_instance,
                }
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "delete":
                currentContactSelected.current = { [agent]: groupname }
                setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                deleteMultiUser(selectedAgent, true)
                setIsDeleteDialogOpen(true)
                break
            case "upload":
                setIsUploadDialogueOpened(true)
                break
            case "bulkEditQueue":
                setOperation("Edit")
                setIsBulkEditDialogueOpen("queue")
                break
            case "bulkEditProficiency":
                setOperation("Edit")
                setIsBulkEditDialogueOpen("proficiency")
                break
            case "bulkEditGroup":
                setOperation("Edit")
                setIsBulkEditDialogueOpen("bulkEditGroup")
                break
        }
    }

    const agentNameFromSet = (agents) => {
        const convertedArray = Array.from(agents).map((item) => {
            const [name, value] = item.split(":")
            return name
        })
        return convertedArray
    }

    const deleteMultiUser = (deleteValue, showRoutingDelete) => {
        const selectedUser = [...deleteValue]
        const convertedArray = selectedUser.map((item) => {
            const [name, value] = item.split(":")
            return { [name]: value }
        })
        setShowRoutingDeleteOption(showRoutingDelete)
        // setIsLoading(true)
        setIsDeleteDialogOpen(true)        
        currentContactSelected.current = convertedArray
    }

    const closeDeleteDialog = async (isClose, deleteRoute) => {
        setIsDeleteDialogOpen(false)
        setIsLoading(true)
        const users = currentContactSelected.current        
        if (isClose) {
            const urlencoded = new URLSearchParams()
            if (selectedAgent.size > 1) {
                for (const key in users) {
                    const entry = Object.entries(users[key])[0]
                    urlencoded.append(entry[0], entry[1])
                }
            } else {
                urlencoded.append(
                    Object.keys(users)[0],
                    users[Object.keys(users)[0]]
                )
            }
            deleteAgentGroupList(user, urlencoded)
                .then((response) => {
                    notify(response, "success")
                })
                .catch((err) => {
                    notify("Error deleting the Agent", "error")
                })
                .finally(() => {
                    currentContactSelected.current = null
                    getAgentGroupList(user)
                    setIsDeleteDialogOpen(false)
                    setShowRoutingDeleteOption(false)
                    setSelectedAccordians({})
                    setIsLoading(true)
                    setSelectedAgent(new Set())
                    setUserToBeDeleted("")
                })
        } else {
            setShowRoutingDeleteOption(false)
            setIsDeleteDialogOpen(false)
            setShowRoutingDeleteOption(false)
            setSelectedAccordians({})
            setIsLoading(false)
        }
        // setIsLoading(false);
    }

    const createAgentGroup = async (isOpen, data) => {
        const profile = data?.userInfo?.profile
        delete data?.userInfo?.profile
        setIsAddDialogOpen(false)
        setIsLoading(true)
        if (isOpen && !editData.current) {
            await addAgentGroupList(user, data)
                .then((response) => {
                    if (response.status === 500) {
                        notify(response.text, "error")
                        setIsLoading(false)
                    } else if (response.status === 200) {
                        notify(response.text, "success")
                        setIsLoading(false)
                    }
                })
                .catch((err) => {
                    notify(err, "error")
                    setIsLoading(false)
                })
        }
        if (isOpen && editData.current) {
            if (profile === "supervisor") {
                if (data.userInfo.isGroupSupervisor) {
                    setIsLoading(false)
                    notify("User is already a supervisor", "warn")
                    return
                } else {
                    try {
                        delete data.userInfo.isAgentProfile
                        delete data.userInfo.isGroupSupervisor
                        data.userInfo.dualAccess = false
                        const response = await addSupervisiorData(
                            user,
                            data.userInfo
                        )
                        if (response.statusCode === 200) {
                            notify(response.response, "success")
                        } else {
                            notify(
                                response.response || "An error occurred",
                                "error"
                            )
                        }
                    } catch (error) {
                        console.error("Error adding supervisor data:", error)
                        notify("An unexpected error occurred", "error")
                    } finally {
                        setIsLoading(false)
                    }
                }
            } else {
                await editAgentGroupList(user, data)
                    .then((response) => {
                        if (response.status === 500) {
                            notify(response.data.response, "error")
                            setIsLoading(false)
                        } else if (response.status === 200) {
                            notify(response.data.response, "success")
                            setIsLoading(false)
                        }
                    })
                    .catch((err) => {
                        notify(err, "error")
                        setIsLoading(false)
                    })
            }
        }

        getAgentGroupList(user)
        setIsAddDialogOpen(false)
        setIsLoading(false)
        setCloneInfo()
        editData.current = null
    }

    const fetchGroupNames = async () => {
        const response = await getAllGroupNames(user)
        setGroupList(
            response
                .slice(1, -1)
                .split(",")
                .map((group) => group.trim())
        )
    }

    const getQueues = async () => {
        const res = await getQueueList()
        setQueueList(res)
    }

    useEffect(() => {
        fetchGroupNames()
        getAgentGroupList(user)
        getQueues()
        getAllAttributeListAndValues(user)
            .then((resp) => {
                // const mapped = resp.map((value,index)=>({id:index, name:value}))
                setAttributeList(resp)
            })
            .catch((err) => {
                console.log("error ", err)
            })
            .finally(() => {})
    }, [])

    const filterAgentsList = (value) => {
        const lowercaseValue = value.trim().replace(/\s+/g, " ").toLowerCase()
        if (allAgents.length) {
            const getFilteredData = allAgents.filter((info) => {
                const fullName = `${info.firstName} ${info.lastName}`.toLowerCase()
                return (
                    fullName.includes(lowercaseValue) ||
                    info.firstName.toLowerCase().includes(lowercaseValue) ||
                    info.lastName.toLowerCase().includes(lowercaseValue) ||
                    info.agentLdap.toLowerCase().includes(lowercaseValue) ||
                    info.agentGroupName.toLowerCase().includes(lowercaseValue)
                )
            })
            formatAgentGroupList(getFilteredData)
            formatGroupAgentList(getFilteredData)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            filterAgentsList(currentText)
        }, 500)
        return () => clearTimeout(timer)
    }, [currentText])

    const filterAgents = (value) => {
        setCurrentText(value)
    }

    // const cloneAgent = (value) => {
    //     const { firstName, lastName, agentLdap } = allAgents.filter(
    //         (agent) => agent.agentLdap === value.agentLdap
    //     )[0]
    //     setCloneInfo({ firstName, lastName, agentLdap })
    //     setIsAddDialogOpen(true)
    //     setOperation("Add")
    // }

    // const selectedAccordiansValue = (value, operation) => {
    //     if (operation == "Add") {
    //         setSelectedAccordians({ ...selectedAccordians, ...value })
    //     } else {
    //         const [key] = Object.keys(value)
    //         const currentValue = JSON.parse(JSON.stringify(selectedAccordians))
    //         delete currentValue[key]
    //         setSelectedAccordians(currentValue)
    //     }
    // }

    const closeBulkEditAgentSkill = (isAddConfirmed, data, action) => {
        setIsLoading(true)
        setIsBulkEditDialogueOpen(isAddConfirmed)
        setSelectedAccordians({})
        bulkEditAgentGroup(user, data)
            .then((res) => {
                notify(res.data.response, "success")
                setIsLoading(false)
                getAgentGroupList(user)
                setSelectedAgent(new Set())
            })
            .catch((err) => {
                notify(err, "error")
                setSelectedAgent(new Set())
                //console.log({ err })
            })
    }

    const handleDownloadTemplate = async () => {
        try {
            const response = await fetch(
                `${SERVER_URL}/care/v1/agentGroups/bulk/template`
            )
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = "User-Data-Template.xlsx"
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        } catch (error) {
            console.error("Error downloading template:", error)
        }
    }

    // const filteredGroups = userPermissions
    //     ?.filter((permissionSet) => {
    //         return permissionSet?.permissions?.some((permission) =>
    //             ["add_user", "edit_userinfo"].includes(permission)
    //         )
    //     })
    //     .map((permissionSet) => ({
    //         id: permissionSet?.group,
    //         name: permissionSet?.group,
    //     }))

    const groupsForIsAdmin = groupsList.map((group) => ({
        id: group,
        name: group,
    }))

    // const filteredGroups = [];

    const getCell = (columnKey, item) => {        
        const group = item?.agentGroupList[0]?.groupName
        const deletePermissions = groupsHavingDeletePermissions  ? (groupsHavingDeletePermissions[0] === "*"  ? true : groupsHavingDeletePermissions.includes(group)) : false;
        const editPermissions = groupsHavingEditPermissions ? (groupsHavingEditPermissions[0] === "*" ? true :  groupsHavingEditPermissions?.includes(group)) : false;
        switch (columnKey) {
            case "actions":
                // const deletePermissions = groupsHavingDeletePermissions && ( groupsHavingDeletePermissions[0] !== "*" ? groupsHavingDeletePermissions?.includes(group) : true); 
               
                const { agentGroupList } = item
                const [{ isGroupSupervisor }] = agentGroupList
                
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) =>
                                value === "delete"
                                    ? deleteAgentGroupAccordian({
                                          [item?.agentLdap]: item
                                              ?.agentGroupList[0]?.groupName,
                                      })
                                    : ActionProcess(
                                          value,
                                          item?.agentLdap,
                                          item?.agentGroupList
                                      )
                            }
                            disabledKeys={[!deletePermissions && "delete" , !editPermissions && "edit", isGroupSupervisor && "delete"]}
                          
                            UNSAFE_className="action-btn"
                        >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "user":
                return (
                    <Cell>
                        <Button
                            autoFocus={false}
                            onFocusChange={false}
                            isQuiet
                            UNSAFE_style={{
                                border: "transparent",
                                width: "100%",
                            }}
                        >
                            <span
                                style={{
                                    color: "blue",
                                    fontWeight: "400",
                                    width: "100%",
                                    cursor: "pointer",
                                }}
                                onClick={ () =>
                                    ActionProcess(
                                        "edit",
                                        item?.agentLdap,
                                        item?.agentGroupList
                                    )
                                }
                            >
                                <Flex
                                    width={"100%"}
                                    UNSAFE_style={{ textAlign: "start" }}
                                    gap={"size-100"}
                                >
                                    <Text
                                        UNSAFE_style={{
                                            maxWidth: "60%",
                                            width: "fit-content",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            textAlign: "start",
                                        }}
                                    >{`${item?.agentGroupList[0]?.firstName} ${item?.agentGroupList[0]?.lastName}`}</Text>
                                    <Text
                                        UNSAFE_style={{
                                            width: "30%",
                                            textAlign: "start",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {" "}
                                        ( {item.agentLdap} )
                                    </Text>
                                </Flex>
                            </span>
                        </Button>
                    </Cell>
                )
            case "group":
                return (
                    <Cell>
                        {item?.agentGroupList[0]?.groupName}{" "}
                        <Text UNSAFE_style={{ fontStyle: "italic" }}>
                            {item?.agentGroupList[0]?.isGroupSupervisor &&
                                `(supervisor)`}
                        </Text>
                    </Cell>
                )
            case "instance":
                return (
                    <Cell>
                        {item?.agentGroupList[0]?.instance?.instance_alias ||
                            "-"}
                    </Cell>
                )
            default:
                return (
                    <Cell>
                        <TooltipTrigger delay={0}>
                            <ActionButton
                                width={"100%"}
                                UNSAFE_className="tooltipButton"
                                aria-label={columnKey}
                                isQuiet
                            >
                                {item[columnKey]}
                            </ActionButton>
                            <Tooltip>{item[columnKey]}</Tooltip>
                        </TooltipTrigger>
                    </Cell>
                )
        }
    }

    const disabledKeys = new Set(
        AgentList
            .filter(item => !groupsHavingDeletePermissions?.includes(item.agentGroupList[0]?.groupName))
            .map(item => `${item.agentLdap}:${item.agentGroupList[0]?.groupName}`)
    );

    const disabledKeysIsSupervisor = new Set(
        AgentList
            .filter(item => item.agentGroupList[0]?.isGroupSupervisor)
            .map(item => `${item.agentLdap}:${item.agentGroupList[0]?.groupName}`)
    );

    return (
        <div key={`${isAdminInEdit}-${userPermissions}`}>
            {isLoading ? (
                <View
                    // UNSAFE_className={
                    //     isLoading ? "display-block" : "display-none"
                    // }
                    UNSAFE_style={{
                        paddingTop: "10%",
                        paddingLeft: "45%",
                        position: "absolute",
                        zIndex: "10",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="M"
                    />
                </View>
            ) : null}
            <div align="center">
                <Heading variant="pageTitle">Users</Heading>
            </div>
            <View
                UNSAFE_style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "90vw",
                    margin: "0 auto",
                }}
            >
                <Content
                    aria-label="Example ListView for empty state"
                    minWidth="size-6000"
                    minHeight="size-3000"
                >
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                        <SearchField
                            isQuiet
                            // label="Search"
                            labelPosition="side"
                            labelAlign="start"
                            onClear={() => setCurrentText("")}
                            onChange={filterAgents}
                            aria-label="Search"
                            value={currentText}
                            width={"30%"}
                        />

                        <ActionGroup
                            selectionMode="single"
                            width={"25%"}
                            justifySelf={"flex-start"}
                            alignItems="start"
                            onAction={setLayout}
                            selectedKeys={[layout]}
                        >
                            <Item key="Agent">Users</Item>
                            <Item key="Group">Groups</Item>
                        </ActionGroup>
                        {layout == "Agent" && (
                            <ActionGroup
                                width={"45%"}
                                justifySelf={"flex-end"}
                                alignItems="end"
                                onAction={(value) =>
                                    ActionProcess(value, null, null)
                                }
                                disabledKeys={[!groupsHavingAddPermissions?.length &&  "add", !(selectedAgent.size > 1) && "bulkDelete"]}
                            >
                                {/* <TooltipTrigger>
                                    <Item key={"upload"}>
                                        <FileAdd />
                                    </Item>
                                    <Tooltip>Bulk Upload Users</Tooltip>
                                </TooltipTrigger> */}
                                {/* {(
                                    <Item key="abc">
                                        <MenuTrigger>
                                            <TooltipTrigger>
                                                <ActionButton
                                                    UNSAFE_style={{
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    <Draw />
                                                    <Text
                                                        UNSAFE_style={{
                                                            opacity:
                                                                selectedAgent.size <
                                                                    2 ||
                                                                selectedAgent ===
                                                                    "all"
                                                                    ? "0.5"
                                                                    : null,
                                                        }}
                                                    >
                                                        Change Groups
                                                    </Text>
                                                </ActionButton>
                                                <Tooltip>
                                                    Change Group
                                                </Tooltip>
                                            </TooltipTrigger>
                                            <Menu
                                                onAction={(value) =>
                                                    ActionProcess(
                                                        value,
                                                        null,
                                                        null
                                                    )
                                                }
                                              
                                            >
                                                <Item key="bulkEditGroup">
                                                    Change Group
                                                </Item>
                                                <Item key="bulkEditQueue">
                                                    Queues
                                                </Item>
                                                <Item key="bulkEditProficiency">
                                                    Proficiency
                                                </Item>
                                            </Menu>
                                        </MenuTrigger>
                                    </Item>
                                )} */}
                                <TooltipTrigger>
                                    <Item key={"add"}>+ Add</Item>
                                    <Tooltip>Add Users</Tooltip>
                                </TooltipTrigger>
                                <TooltipTrigger>
                                    <Item key="bulkDelete">Delete</Item>
                                    <Tooltip>Multi Delete Users</Tooltip>
                                </TooltipTrigger>
                            </ActionGroup>
                        )}
                    </Flex>
                    <Flex marginTop={"size-300"} UNSAFE_className="listItems">
                        {layout === "Agent" && (
                            <TableView
                                aria-label="users"
                                selectionMode={!groupsHavingDeletePermissions ? "none" : "multiple"}
                                selectedKeys={selectedAgent}
                                onSelectionChange={setSelectedAgent}
                                renderEmptyState={renderEmptyState}
                                // sortDescriptor={list.sortDescriptor}
                                // onSortChange={list.sort}
                                //   height="70vh"
                                width={"90vw"}
                                overflowMode="wrap"
                                height={"45vh"}
                                disabledKeys={((groupsHavingDeletePermissions && groupsHavingDeletePermissions[0] !== "*") && disabledKeys) || disabledKeysIsSupervisor}
                            >
                                <TableHeader
                                    // columns={props.permissions.includes(PheonixRoles.defaultConfigAdmin)?columnsAdmin:columns}
                                    columns={isAdmin ? columnsAdmin : columnsAdmin}
                                >
                                    {(column) => (
                                        <Column
                                            allowsResizing
                                            minWidth={column.minWidth}
                                            key={column.key}
                                            // allowsSorting={
                                            //     column.key === "agent_group_name" || column.key === "config_key"
                                            // }
                                            align={
                                                column.key !== "actions"
                                                    ? "start"
                                                    : "end"
                                            }
                                        >
                                            {column.name}
                                        </Column>
                                    )}
                                </TableHeader>
                                <TableBody items={AgentList}>
                                    {(item) => (
                                        <Row
                                            key={`${item?.agentLdap}:${item?.agentGroupList[0]?.groupName}`}
                                            
                                        >
                                            {(columnKey) =>
                                                getCell(columnKey, item)
                                            }
                                        </Row>
                                    )}
                                </TableBody>
                            </TableView>
                        )}
                        {layout == "Group" && (
                            <GroupPrimaryList
                                isAdmin={isAdmin}
                                AgentList={groupAgentList}
                                ActionProcess={(value, groupName, groupData) =>
                                    ActionProcess(value, groupName, groupData)
                                }
                                deleteAgent={(deleteValue) =>
                                    deleteAgentGroupAccordian(deleteValue)
                                }
                            ></GroupPrimaryList>
                        )}
                    </Flex>
                    {/* {!AgentList.length && returnEmptyState()} */}
                </Content>
            </View>

            {isDeleteDialogOpen && (
                <DeleteAgentGroup
                    isDialogOpened={isDeleteDialogOpen}
                    showRoutingDelete={showRoutingDeleteOption}
                    selectedUsers={Array.from(selectedAgent)}
                    handleCloseDialog={(isDeletedConfirmed, deleteRoute) =>
                        closeDeleteDialog(isDeletedConfirmed, deleteRoute)
                    }
                    userToDelete={userToBeDeleted}
                />
            )}

            {isAddDialogOpen && (
                <AddAgentGroup
                    isDialogOpened={isAddDialogOpen}
                    editData={editData.current}
                    operation={operation}
                    allAgentsList={AgentList}
                    groups={groupsForIsAdmin}
                    cloneInfo={cloneInfo}
                    attributeList={attributeList}
                    handleCloseDialog={(isOpen, data) =>
                        createAgentGroup(isOpen, data)
                    }
                    queueList={queueList}
                    queueAccess={queueAccess}
                    isAdminInEdit={isAdminInEdit}
                    groupPermission={groupPermission}
                    additionalPermission={additionalPermission}
                    userPermissions={userPermissions}
                    // commonPermissions={commonPermissions}
                    // filteredGroups={filteredGroups}
                    groupsHavingAddPermissions={groupsHavingAddPermissions}
                    groupsHavingEditPermissions={groupsHavingEditPermissions}
                    canViewInformation = { !(groupsHavingEditPermissions?.includes("*") ? true : groupsHavingEditPermissions?.includes(editData.current?.agentGroupName))}
                />
            )}

            {isUploadDialogueOpened && (
                <UploadFilesXlsx
                    isDialogOpened={isUploadDialogueOpened}
                    handleFileUpload={(isClosed, File) =>
                        handleUpload(isClosed, File)
                    }
                    handleCloseDialog={(isClose) =>
                        setIsUploadDialogueOpened(!isClose)
                    }
                    handleDownloadTemplate={handleDownloadTemplate}
                />
            )}

            {isBulkEditDialogueOpen === "proficiency" ? (
                <BulkEditProficiency
                    attributeList={attributeList}
                    isDialogOpened={isBulkEditDialogueOpen}
                    selectedAgents={agentNameFromSet(selectedAgent)}
                    // editData={{}}
                    operation={operation}
                    handleCancel={() => setIsBulkEditDialogueOpen(false)}
                    handleCloseDialog={(isAddConfirmed, data) =>
                        closeBulkEditAgentSkill(isAddConfirmed, data)
                    }
                />
            ) : isBulkEditDialogueOpen === "queue" ? (
                <BulkEditQueue
                    queueList={queueList?.allQueues}
                    isDialogOpened={isBulkEditDialogueOpen}
                    selectedAgents={agentNameFromSet(selectedAgent)}
                    // editData={{}}
                    operation={operation}
                    handleCancel={() => setIsBulkEditDialogueOpen(false)}
                    handleCloseDialog={(isAddConfirmed, data) =>
                        closeBulkEditAgentSkill(isAddConfirmed, data)
                    }
                />
            ) : isBulkEditDialogueOpen === "bulkEditGroup" ? (
                <BulkEditGroup
                    groupList={groupsList}
                    isDialogOpened={isBulkEditDialogueOpen}
                    selectedAgents={agentNameFromSet(selectedAgent)}
                    operation={operation}
                    handleCancel={() => setIsBulkEditDialogueOpen(false)}
                    handleCloseDialog={(isAddConfirmed, data) =>
                        closeBulkEditAgentSkill(isAddConfirmed, data)
                    }
                />
            ) : null}
        </div>
    )
}

// export default AgentGroups
export default connect(mapStateToProps)(AgentGroups)
