import React, { useState, useEffect } from "react"
import {
    Provider,
    lightTheme,
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    Heading,
    Content,
    ButtonGroup,
    Form,
    TextField,
    View,
    NumberField,
    Flex,
    ComboBox,
    Item
} from "@adobe/react-spectrum"

const AddDnis = (props) => {
    const [did, setDid] = useState("")
    const [tfn, setTfn] = useState("")
    const [region, setRegion] = useState("NA")
    const [country, setCountry] = useState("US")
    const [afterHoursSchedule, setAfterHoursSchedule] = useState("C")
    const [operationalHours, setoperationalHours] = useState("")
    const [hub, setHub] = useState("Adobe")
    const [language, setLanguage] = useState("en-US")
    const [bu, setBu] = useState("DX")
    const [timezone, setTimezone] = useState("America/Los_Angles")
    const [env, setEnv] = useState("")
    const [language2, setLanguage2] = useState("")
    const [cfn, setCfn] = useState("")
    const [language3, setLanguage3] = useState("")
    const [holidaySchedule, setHolidaySchedule] = useState("C")
    const [tier, setTier] = useState(2);

    const envOptions = [
        {id:'DEV', name:'DEV'},
        {id:'QE', name:'QE'},
        {id:'PROD', name:'PROD'},
        {id:'STAGE', name:'STAGE'},
    ]

    const timezoneOptions = [
        {id:'Asia/Shanghai',name:'Asia/Shanghai'},
        {id:'Asia/Tokyo',name:'Asia/Tokyo'},
        {id:'Asia/Seoul',name:'Asia/Seoul'},
        {id:'Europe/Lisbon',name:'Europe/Lisbon'},
        {id:'Europe/Madrid',name:'Europe/Madrid'},
        {id:'America/Los_Angles',name:'America/Los_Angles'},

    ]

    const getEnv = () => {
        const { hostname } = window.location;
        let env = '';
        switch (hostname) {
          case 'localhost':
            env = 'DEV';
            break;
          default:
            if (hostname.indexOf('dev-') >= 0) {
              env = 'DEV';
              break;
            } else if (hostname.indexOf('stage-') >= 0) {
              env = 'STAGE';
              break;
            } else if (hostname.indexOf('qe-') >= 0){
              env = 'QE';
              break;
            } else {
              env = 'PROD';
              break;
            }
        }
        return env;
    }

    useEffect(() => {
       const environment = getEnv();
       setEnv(environment);
    },[])

    useEffect(() => {
        if (props?.editData) {
            setDid(props?.editData?.did)
            setTfn(props?.editData?.tfn)
            setRegion(props?.editData?.region)
            setCountry(props?.editData?.country)
            setoperationalHours(props?.editData?.operationalHours)
            setHub(props?.editData?.hub)
            setLanguage(props?.editData?.language)
            setBu(props?.editData?.bu)
            setTimezone(props?.editData?.timezone)
            setEnv(props?.editData?.env)
            setLanguage2(props?.editData?.language2)
            setCfn(props?.editData?.cfn)
            setLanguage3(props?.editData?.language3)
            setHolidaySchedule(props?.editData?.holidaySchedule)
            setTier(props?.editData?.tier)
        }
    }, [])

    const onCloseHandler = () => {
        props.handleCloseDialog(false)
    }

    const onSaveHandler = () => {
        const body = {
            did: did,
            tfn: tfn,
            region: region.trim(),
            country: country.trim(),
            operationalHours: operationalHours.trim(),
            hub: hub.trim(),
            language: language.trim(),
            bu: bu.trim(),
            timezone: timezone.trim(),
            env: env,
            language2: language2.trim(),
            cfn: cfn,
            language3: language3.trim(),
            holidaySchedule: holidaySchedule.trim(),
            tier: tier,
        }
        //console.log(body);
        props.handleCloseDialog(true, body)
    }

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="L">
                        <Heading>{`${props.operation} DNIS`}</Heading>
                        <Divider />
                        <Content>
                            <Form isRequired>
                                {/* <Heading level={5}>Numbers</Heading> */}
                                <Flex gap={100}>
                                    <View>
                                        {/* <View>DID</View> */}
                                        <NumberField
                                            value={did}
                                            isRequired
                                            onChange={setDid}
                                            label="DID"
                                            width={"100%"}
                                            minValue={0}
                                            isDisabled={props.editData}
                                        />
                                    </View>
                                    <View>
                                        {/* <View>CFN</View> */}
                                        <NumberField
                                            value={cfn}
                                            onChange={setCfn}
                                            isRequired
                                            label="CFN"
                                            minValue={0}
                                            width={"100%"}
                                        />
                                    </View>
                                </Flex>
                                <Flex gap={100}>
                                    <View>
                                        <NumberField
                                            value={tfn}
                                            onChange={setTfn}
                                            label="TFN"
                                            isRequired
                                            width={"100%"}
                                            minValue={0}
                                            
                                        />
                                    </View>
                                    <View>
                                        <NumberField
                                            value={tier}
                                            onChange={setTier}
                                            label="Tier"
                                            minValue={0}
                                            width={"100%"}
                                        />
                                    </View>
                                </Flex>
                                <Divider size="S" marginY={10} />
                                {/* <Heading level={5}>Language</Heading> */}
                                <Flex gap={124}>
                                    <View>
                                        <View>Language</View>
                                        <TextField
                                            value={language}
                                            onChange={setLanguage}
                                            // isQuiet
                                            isDisabled={false}
                                            width={"113%"}
                                        />
                                    </View>
                                    <View>
                                        <View>Language 2</View>
                                        <TextField
                                            value={language2}
                                            onChange={setLanguage2}
                                            // isQuiet
                                            isDisabled={false}
                                            width={"113%"}
                                        />
                                    </View>
                                </Flex>
                                <Flex gap={124}>
                                    <View>
                                        <View>Language 3</View>
                                        <TextField
                                            value={language3}
                                            onChange={setLanguage3}
                                            // isQuiet
                                            isDisabled={false}
                                            width={"113%"}
                                        />
                                    </View>
                                    <View>
                                        <View>BU</View>
                                        <TextField
                                            value={bu}
                                            onChange={setBu}
                                            // isQuiet
                                            isDisabled={false}
                                            width={"113%"}
                                        />
                                    </View>
                                </Flex>
                                <Divider size="S" marginY={10} />
                                {/* <Heading level={5}>Others</Heading> */}

                                <Flex gap={124}>
                                    <View>
                                        <View>Operational Hours</View>
                                        <TextField
                                            value={operationalHours}
                                            onChange={setoperationalHours}
                                            // isQuiet
                                            isDisabled={false}
                                            width={"113%"}
                                        />
                                    </View>
                                    <View>
                                        <View>Hub</View>
                                        <TextField
                                            value={hub}
                                            onChange={setHub}
                                            // isQuiet
                                            isDisabled={false}
                                            width={"113%"}
                                        />
                                    </View>
                                </Flex>
                                <Flex gap={88}>
                                    <View>
                                        <View>Timezone</View>
                                        <ComboBox
                                         defaultItems={timezoneOptions}
                                         selectedKey={timezone}
                                         width={'94%'}
                                         onSelectionChange={selected => setTimezone(selected)}>
                                         {item => <Item>{item.name}</Item>}
                                         </ComboBox>
                                    </View>
                                    <View>
                                    <View>Env</View>
                                        <ComboBox
                                         defaultItems={envOptions}
                                         selectedKey={env}
                                         width={'94%'}
                                         onSelectionChange={selected => setEnv(selected)}>
                                         {item => <Item>{item.name}</Item>}
                                         </ComboBox>
                                            
                                    </View>
                                </Flex>
                                <Flex gap={124}>
                                    <View>
                                        <View>Holiday Schedule</View>
                                        <TextField
                                            value={holidaySchedule}
                                            onChange={setHolidaySchedule}
                                            // isQuiet
                                            isDisabled={false}
                                            width={"113%"}
                                        />
                                    </View>
                                </Flex>
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onPress={onSaveHandler}
                                isDisabled={did === "" || did === 0 || tfn ==="" || tfn === 0 }
                            >
                                Save
                            </Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default AddDnis
