import React from "react"
import {
    Flex,
    View,
    Checkbox,
    CheckboxGroup,
    Heading,
    ComboBox,
    Text as SpectrumText,
    Item,
    TextField,
} from "@adobe/react-spectrum"
import styles from "./AddAlert.module.css"
import { notify } from "../constants/constants"

const AddQueue = ({
    alertService,
    setAlertService,
    allQueues,
    selectedAlerts,
    setSelectedAlerts,
    selectedChannel,
    setSelectedChannel,
    operation,
    existingQueues,
    isEditable,
}) => {
    const handleQueueSelection = (value) => {
        if (value) {
            const queue = existingQueues.find((ele) => ele.buId === value)

            if (queue) {
                notify("Alert for this BU already exist", "warn", {
                    containerId: "modalToast",
                })
                return
            }
            // const queueName = allQueues?.find((ele) => ele?.id === value)?.name
            // getChannel(queueName)
            // setAlertService((prevState) => [
            //     {
            //         ...prevState[0],
            //         buName: queueName,
            //         buId: value,
            //     },
            // ])
        }
        const buName = allQueues?.find((ele) => ele?.id == value)?.name

        setAlertService((prevState) => [
            {
                ...prevState[0],
                buName: buName,
                buId: value,
            },
        ])
    }

    // const handleChannelSelection = (value) => {
    //     setSelectedChannel(value)
    //     setSelectedAlerts([])
    //     setAlertService((prevState) => [
    //         {
    //             ...prevState[0],
    //             channel: value,
    //         },
    //     ])
    // }

    const getChannel = (queueName) => {
        const lowercaseQueueName = queueName.toLowerCase()
        if (lowercaseQueueName.endsWith("msg")) {
            setSelectedChannel("Chat")
        } else if (lowercaseQueueName.endsWith("ph")) {
            setSelectedChannel("Call")
        } else if (lowercaseQueueName.endsWith("case")) {
            setSelectedChannel("Case")
        } else {
            setSelectedChannel("Call")
        }
    }
    return (
        <View>
            <Heading
                UNSAFE_style={{
                    textAlign: "center",
                    color: "#707070",
                    fontWeight: 700,
                }}
                level={3}
            >
                Add BU
            </Heading>
            <View
                padding="size-300"
                marginBottom="size-300"
                marginStart={"size-500"}
                UNSAFE_style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "15px",
                }}
                maxWidth={"fit-content"}
                width={"60%"}
                margin={"5px auto"}
                height={"auto"}
                maxHeight={"auto"}
            >
                <View UNSAFE_style={{ height: "100%" }}>
                    <Flex gap={"1rem"}>
                        <ComboBox
                            label={
                                <SpectrumText UNSAFE_className={styles.label}>
                                    BU
                                </SpectrumText>
                            }
                            defaultItems={allQueues}
                            width={"40rem"}
                            aria-label="SelectQueue"
                            placeholder="Select a BU..."
                            selectedKey={alertService[0].buId}
                            onSelectionChange={handleQueueSelection}
                            isDisabled={operation === "edit"}
                        >
                            {(item) => <Item key={item.id}>{item.name}</Item>}
                        </ComboBox>
                        <TextField
                            label={
                                <SpectrumText UNSAFE_className={styles.label}>
                                    Channel
                                </SpectrumText>
                            }
                            width={"40rem"}
                            aria-label="QueueName"
                            value={selectedChannel || alertService[0].channel}
                            isDisabled
                            // isReadOnly
                            // UNSAFE_style={{ cursor: "not-allowed" }}
                        />

                        {/* <ComboBox
                            label={
                                <SpectrumText UNSAFE_className={styles.label}>
                                    Channels
                                </SpectrumText>
                            }
                            // defaultItems={[
                            //     { name: "call" },
                            //     { name: "chat" },
                            //     { name: "case" },
                            // ]}
                            width={"40rem"}
                            placeholder="Select Channel..."
                            aria-label="SelectChannel"
                            // selectedKey={selectedChannel || alertService[0].channel}
                            onSelectionChange={handleChannelSelection}
                            isDisabled={operation === "edit"}
                        >
                            {(item) => <Item key={item.name}>{item.name}</Item>}
                        </ComboBox> */}
                    </Flex>
                    <View marginTop={"size-200"}>
                        <CheckboxGroup
                            label={
                                <SpectrumText
                                    UNSAFE_className={styles.label}
                                    UNSAFE_style={{ fontSize: "14px" }}
                                >
                                    Alerts
                                </SpectrumText>
                            }
                            aria-label="Alerts"
                            isEmphasized
                            value={selectedAlerts}
                            onChange={setSelectedAlerts}
                            orientation="horizontal"
                            width={"81rem"}
                            // isDisabled={
                            //     !adminPermission ||
                            //     (operation !== "edit" &&
                            //         !alertService[0].queueName)
                            // }
                        >
                            <Flex
                                direction={"column"}
                                justifyContent={"center"}
                                width={"100%"}
                                marginStart={"size-200"}
                            >
                                {/* {selectedChannel !== "Case" && (
                                    <Checkbox
                                        value="abandoned"
                                        UNSAFE_className={`${styles.box}`}
                                    >
                                        <SpectrumText
                                            UNSAFE_className={
                                                styles.checkboxText
                                            }
                                        >
                                            Abandoned
                                        </SpectrumText>
                                    </Checkbox>
                                )} */}
                                {/* <Checkbox
                                    value="wait"
                                    UNSAFE_className={styles.box}
                                >
                                    <SpectrumText
                                        UNSAFE_className={styles.checkboxText}
                                    >
                                        Wait
                                    </SpectrumText>
                                </Checkbox>
                                <Checkbox
                                    value="onQueueUser"
                                    UNSAFE_className={styles.box}
                                >
                                    <SpectrumText
                                        UNSAFE_className={styles.checkboxText}
                                    >
                                        OnQueueUsers
                                    </SpectrumText>
                                </Checkbox> */}
                                {
                                    <Checkbox
                                        value="voicemail"
                                        UNSAFE_className={styles.box}
                                    >
                                        <SpectrumText
                                            UNSAFE_className={
                                                styles.checkboxText
                                            }
                                        >
                                            Voicemail
                                        </SpectrumText>
                                    </Checkbox>
                                }
                            </Flex>
                        </CheckboxGroup>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default AddQueue
