import React, { useState, useEffect, useMemo } from "react"
import {
    Provider,
    lightTheme,
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    Heading,
    Content,
    ButtonGroup,
    TextField,
    Picker,
    Item,
    Footer,
    Flex,
    View,
    ContextualHelp,
    RadioGroup,
    Radio,
    CheckboxGroup,
    Checkbox,
    NumberField,
    TextArea
} from "@adobe/react-spectrum"
import {
    ACTION_TYPE,
    CASE_ROUTE,
    CONDITION_TYPE,
    RULE_STATUS,
    RULE_TYPE,
    SRS_NAME_regexPatternIN,
    SRS_NAME_regexPatternNOTIN,
} from "../../constants/constants"

import './style.css';

const initialData = {
    action: "",
    elseAction: "",
    condition: "",
    conditionType: "",
    ruleName: "",
    ruleStatus: false,
    ruleType: "",
    start: "",
    end: "",
    position: "before",
    subRules: null,
    elseSubRules: null,
    denyReasonCode: 0,
    entitlementService: false,
    supportLevelService: false,
    supportPlanService: false,
    caseRoute : "",
}

const AddSRSRules = (props) => {
    // States For
    const [form, setForm] = useState(initialData)
    const [step, setStep] = useState("step1")
    const [startEndList, setStartEndList] = useState([])
    const [isEdit, setIsEdit] = useState(false)

    const isFirstStepDisabled = ({ ruleType, start, end }) => {
        if (ruleType == "") {
            return true
        } else if (start && end && start > end) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        if (props.editData) {
            setIsEdit(true);
            setForm({...props.editData, ruleStatus:`${props.editData.ruleStatus}`});
        }
    }, [])


    const setFormValues = (value, key) => {
        if (key == "action" && value != "sub_rules") {
            setFormValues([], "subRules")
        } 
        if(key == "elseAction" && value != "sub_rules"){
            setFormValues([], "elseSubRules")
        }
        setForm((curr) => {
            return { ...curr, ...{ [key]: value } }
        })
    }

    useEffect(() => {
        if (form.ruleType == RULE_TYPE.ROOT) {
            if(!form.caseRoute){
                setFormValues(CASE_ROUTE.PURECLOUD,"caseRoute")
            }
            setStartEndList(
                props.existingData.filter(
                    (ele) => ele.ruleType === RULE_TYPE.ROOT
                )
            )
        } else {
            if(form.caseRoute){
                setFormValues("","caseRoute")
            }
            setStartEndList(
                props.existingData.filter(
                    (ele) => ele.ruleType !== RULE_TYPE.ROOT
                )
            )
        }
    }, [form])

    const checkConditionValid = () => {
        return (
            SRS_NAME_regexPatternIN.test(form.condition) ||
            SRS_NAME_regexPatternNOTIN.test(form.condition) || form.ruleType !== RULE_TYPE.ROOT
        )
    }

    const ruleNameValidation = useMemo(() => {
        return props.existingData.find(
            (rule) => rule.ruleName === form.ruleName
        )
    }, [form.ruleName])

    const conditionsValidation = useMemo(() => {
        return checkConditionValid()
    }, [form.condition])

    const onChangeStep = () => {
        setStep((curr) => (curr == "step2" ? "step1" : "step2"))
    }

    const onCloseHandler = () => {
        props.handleCloseDialog(false)
    }
    const onSaveHandler = () => {
        props.handleCloseDialog(true, form)
    }

    const ifActionSubrulesCheck = () => {
        if(form.action == "sub_rules" && form?.subRules?.length){
            return true;
        }else if(form.action != "sub_rules"){
            return true;
        }else{
            return false;
        }
    }

    const ifElseActionSubrulesCheck = () => {
        if(form.elseAction == "sub_rules" && form?.elseSubRules?.length){
            return true;
        }else if(form.elseAction != "sub_rules"){
            return true;
        }else{
            return false;
        }
    }

    const validateFormValues = ({
        action,
        condition,
        conditionType,
        ruleName,
        ruleStatus,
        ruleType
    }) => {
        return !(
            action &&
            condition &&
            conditionType &&
            ruleName &&
            `${ruleStatus}` &&
            ruleType &&
            conditionsValidation && ifActionSubrulesCheck()
            && ifElseActionSubrulesCheck()
        )
    }

    const setStartEndPosition = (value) => {
        const onlyValues = startEndList.map((rule) => `${rule.ruleId}`)
        if (form.position == "before") {
            setFormValues(value, "end")
            const previousItemValue = onlyValues[onlyValues.indexOf(value) - 1]
            setFormValues(previousItemValue || "", "start")
            // startEndList
        } else {
            setFormValues(value, "start")
            const nextItemValue = onlyValues[onlyValues.indexOf(value) + 1]
            setFormValues(nextItemValue || "", "end")
        }
    }

    const checkExistingIndex = (id) =>{
        if(form?.subRules && form?.subRules.indexOf(id) >=0 ){
            return (  <button className="subRuleOrderBadge">{form?.subRules.indexOf(id)+1}</button>)
        } else{
            return '';
        }
    }

    const checkExistingElseSubRulesIndex = (id) =>{
        if(form?.elseSubRules && form?.elseSubRules.indexOf(id) >=0 ){
            return (  <button className="subRuleOrderBadge">{form?.elseSubRules.indexOf(id)+1}</button>)
        } else{
            return '';
        }
    }

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="L">
                        <Heading>{props.operation} SRS Rule</Heading>
                        <Divider />
                        <Content>
                            {!isEdit && (
                                <section id="addRuleForm">
                                    {step === "step1" && (
                                        <section id="firstStep">
                                            <Flex
                                                gap={"size-100"}
                                                rowGap={"size-100"}
                                            >
                                                <View flexBasis={"50%"}>
                                                    <TextField
                                                        width={"100%"}
                                                        validationState={
                                                            ruleNameValidation
                                                                ? "invalid"
                                                                : "valid"
                                                        }
                                                        value={form.ruleName}
                                                        contextualHelp={
                                                            <ContextualHelp>
                                                                <Content>
                                                                    Rule Name
                                                                    should not
                                                                    be same as
                                                                    an existing
                                                                    Rule.
                                                                </Content>
                                                            </ContextualHelp>
                                                        }
                                                        onChange={(value) =>
                                                            setFormValues(
                                                                value,
                                                                "ruleName"
                                                            )
                                                        }
                                                        label="Rule Name"
                                                        aria-label="Rule Name"
                                                        isRequired
                                                    ></TextField>
                                                </View>
                                                <View flexBasis={"50%"}>
                                                    <Picker
                                                        width={"100%"}
                                                        label="Rule Type"
                                                        selectedKey={
                                                            form.ruleType
                                                        }
                                                        onSelectionChange={(
                                                            selected
                                                        ) =>
                                                            setFormValues(
                                                                selected,
                                                                "ruleType"
                                                            )
                                                        }
                                                        isRequired
                                                    >
                                                        {Object.keys(
                                                            RULE_TYPE
                                                        ).map((item) => (
                                                            <Item
                                                                key={
                                                                    RULE_TYPE[
                                                                        item
                                                                    ]
                                                                }
                                                            >
                                                                {item}
                                                            </Item>
                                                        ))}
                                                    </Picker>
                                                </View>
                                            </Flex>
                                            <Flex
                                                gap={"size-100"}
                                                rowGap={"size-100"}
                                                marginTop={"size-200"}
                                            >
                                                <View flexBasis={"50%"}>
                                                    <RadioGroup
                                                        width={"100%"}
                                                        value={form.position}
                                                        orientation={
                                                            "horizontal"
                                                        }
                                                        onChange={(selected) =>
                                                            setFormValues(
                                                                selected,
                                                                "position"
                                                            )
                                                        }
                                                        label="Select Insert Position"
                                                    >
                                                        <Radio value="before">
                                                            Before
                                                        </Radio>
                                                        <Radio value="after">
                                                            After
                                                        </Radio>
                                                    </RadioGroup>
                                                </View>
                                                <View flexBasis={"50%"}>
                                                    <Picker
                                                        width={"100%"}
                                                        onSelectionChange={(
                                                            selected
                                                        ) =>
                                                            setStartEndPosition(
                                                                selected
                                                            )
                                                        }
                                                        aria-label="startEndPosition"
                                                        label="Start/End Position"
                                                        isDisabled={
                                                            !form.ruleType
                                                        }
                                                        disabledKeys={[]}
                                                    >
                                                        {startEndList.map(
                                                            (item) => (
                                                                <Item
                                                                    aria-label={
                                                                        item.ruleId
                                                                    }
                                                                    key={
                                                                        item.ruleId
                                                                    }
                                                                >
                                                                    {
                                                                        item.ruleName
                                                                    }
                                                                </Item>
                                                            )
                                                        )}
                                                    </Picker>
                                                </View>
                                            </Flex>
                                        </section>
                                    )}
                                    {step === "step2" && (
                                        <section id="secondStep">
                                            <Flex
                                                gap={"size-100"}
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                            >
                                                <View flexBasis={"100%"}>
                                                    <Picker
                                                        width={"100%"}
                                                        label="Condition Type"
                                                        selectedKey={
                                                            form.conditionType
                                                        }
                                                        onSelectionChange={(
                                                            selected
                                                        ) =>
                                                            setFormValues(
                                                                selected,
                                                                "conditionType"
                                                            )
                                                        }
                                                        isRequired
                                                    >
                                                        {Object.keys(
                                                            CONDITION_TYPE
                                                        ).map((item) => (
                                                            <Item
                                                                aria-label={
                                                                    CONDITION_TYPE[
                                                                        item
                                                                    ]
                                                                }
                                                                key={
                                                                    CONDITION_TYPE[
                                                                        item
                                                                    ]
                                                                }
                                                            >
                                                                {item}
                                                            </Item>
                                                        ))}
                                                    </Picker>
                                                </View>
                                            </Flex>
                                            <Flex
                                                gap={"size-100"}
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                marginTop={"size-200"}
                                            >
                                            <View flexBasis={"100%"}>
                                                    <TextArea
                                                        width={"100%"}
                                                        validationState={
                                                            conditionsValidation
                                                                ? "valid"
                                                                : "invalid"
                                                        }
                                                        value={form.condition}
                                                        onChange={(value) =>
                                                            setFormValues(
                                                                value,
                                                                "condition"
                                                            )
                                                        }
                                                        label="Condition"
                                                        aria-label="Condition"
                                                        isRequired
                                                    ></TextArea>
                                                </View>
                                            </Flex>
                                            <Flex
                                                gap={"size-100"}
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                marginTop={"size-200"}
                                            >
                                                <View flexGrow={1}>
                                                    <Picker
                                                        width={"100%"}
                                                        label="Action"
                                                        selectedKey={
                                                            form.action
                                                        }
                                                        onSelectionChange={(
                                                            selected
                                                        ) =>
                                                            setFormValues(
                                                                selected,
                                                                "action"
                                                            )
                                                        }
                                                        isRequired
                                                    >
                                                        {Object.keys(
                                                            ACTION_TYPE
                                                        ).map((item) => (
                                                            <Item
                                                                aria-label={
                                                                    ACTION_TYPE[
                                                                        item
                                                                    ]
                                                                }
                                                                key={
                                                                    ACTION_TYPE[
                                                                        item
                                                                    ]
                                                                }
                                                            >
                                                                {item}
                                                            </Item>
                                                        ))}
                                                    </Picker>
                                                </View>
                                            </Flex>
                                            {form.action == "sub_rules" && (
                                                <Flex
                                                    gap={"size-100"}
                                                    justifyContent={'start'}
                                                    alignContent={'start'}
                                                    alignItems={'start'}
                                                    marginTop={"size-200"}
                                                    direction={'column'}
                                                >
                                                    <View 
                                                        flexBasis={"100%"}
                                                        maxHeight={'size-1500'}
                                                        width={'100%'}
                                                        id={"subRulesContainer"}
                                                    
                                                    >
                                                        <CheckboxGroup
                                                            label="Sub Rules"
                                                            orientation="vertical"
                                                            onChange={(
                                                                selected
                                                            ) =>
                                                                setFormValues(
                                                                    selected,
                                                                    "subRules"
                                                                )
                                                            }
                                                            width={'100%'}
                                                            isRequired
                                                        >
                                                            <View
                                                                maxHeight={'size-1000'}
                                                                UNSAFE_style={{display:'flex', flexDirection:'column',overflow:'auto'}}
                                                            >
                                                            {props.existingData
                                                                .filter(
                                                                    (ele) =>
                                                                        ele.ruleType !==
                                                                        RULE_TYPE.ROOT
                                                                )
                                                                .map((ele) => (
                                                                    <Checkbox
                                                                        aria-label={Math.random()}
                                                                        key={
                                                                            ele.ruleId
                                                                        }
                                                                        value={
                                                                            ele.ruleId
                                                                        }
                                                                    >
                                                                        {
                                                                            `(${ele.ruleId}) ${ele.ruleName}            `    
                                                                        }
                                                                        {checkExistingIndex(ele.ruleId)}    
                                                                    </Checkbox>
                                                                ))}
                                                            </View>
                                                        </CheckboxGroup>
                                                    </View>
                                                </Flex>
                                            )}
                                            <Flex
                                                gap={"size-100"}
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                marginTop={"size-200"}
                                            >
                                                <View flexGrow={1}>
                                                    <Picker
                                                        width={"100%"}
                                                        label="Else Action"
                                                        selectedKey={
                                                            form.elseAction
                                                        }
                                                        onSelectionChange={(
                                                            selected
                                                        ) =>
                                                            setFormValues(
                                                                selected,
                                                                "elseAction"
                                                            )
                                                        }
                                                        isRequired
                                                    >
                                                        {Object.keys(
                                                            ACTION_TYPE
                                                        ).map((item) => (
                                                            <Item
                                                                aria-label={
                                                                    ACTION_TYPE[
                                                                        item
                                                                    ]
                                                                }
                                                                key={
                                                                    ACTION_TYPE[
                                                                        item
                                                                    ]
                                                                }
                                                            >
                                                                {item}
                                                            </Item>
                                                        ))}
                                                    </Picker>
                                                </View>
                                            </Flex>
                                            {form.elseAction == "sub_rules" && (
                                                <Flex
                                                    gap={"size-100"}
                                                    justifyContent={'start'}
                                                    alignContent={'start'}
                                                    alignItems={'start'}
                                                    marginTop={"size-200"}
                                                    direction={'column'}
                                                >
                                                    <View 
                                                        flexBasis={"100%"}
                                                        maxHeight={'size-1500'}
                                                        width={'100%'}
                                                        id={"elseSubRulesContainer"}
                                                    
                                                    >
                                                        <CheckboxGroup
                                                            label="Else Sub Rules"
                                                            orientation="vertical"
                                                            onChange={(
                                                                selected
                                                            ) =>
                                                                setFormValues(
                                                                    selected,
                                                                    "elseSubRules"
                                                                )
                                                            }
                                                            width={'100%'}
                                                            isRequired
                                                        >
                                                            <View
                                                                maxHeight={'size-1000'}
                                                                UNSAFE_style={{display:'flex', flexDirection:'column',overflow:'auto'}}
                                                            >
                                                            {props.existingData
                                                                .filter(
                                                                    (ele) =>
                                                                        ele.ruleType !==
                                                                        RULE_TYPE.ROOT
                                                                )
                                                                .map((ele) => (
                                                                    <Checkbox
                                                                        aria-label={Math.random()}
                                                                        key={
                                                                            ele.ruleId
                                                                        }
                                                                        value={
                                                                            ele.ruleId
                                                                        }
                                                                    >
                                                                        {
                                                                            `(${ele.ruleId}) ${ele.ruleName}            `    
                                                                        }
                                                                        {checkExistingElseSubRulesIndex(ele.ruleId)}    
                                                                    </Checkbox>
                                                                ))}
                                                            </View>
                                                        </CheckboxGroup>
                                                    </View>
                                                </Flex>
                                            )}                                         
                                            <Flex
                                                gap={"size-100"}
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                marginTop={"size-200"}
                                            >
                                            <View flexBasis={"100%"}>
                                            <NumberField
                                               label="Deny Reason Code"
                                               defaultValue={0}
                                               minValue={0}
                                               value={form.denyReasonCode}
                                               onChange={(value) =>
                                                setFormValues(
                                                    value,
                                                    "denyReasonCode"
                                                )
                                            } />
                                            </View>
                                           </Flex>
                                            <Flex direction="column" marginTop='20px'>
                                                <Checkbox isSelected={form.entitlementService} onChange={(e) =>
                                                    setFormValues(
                                                        e,
                                                        "entitlementService"
                                                    )}>
                                                    Entitlement Service
                                                </Checkbox>
                                            </Flex>
                                            <Flex direction="column">
                                                <Checkbox isSelected={form.supportLevelService} onChange={(e) =>
                                                    setFormValues(
                                                        e,
                                                        "supportLevelService"
                                                    )}>
                                                    Support Level Service
                                                </Checkbox>
                                            </Flex>
                                            <Flex direction="column">
                                                <Checkbox isSelected={form.supportPlanService} onChange={(e) =>
                                                    setFormValues(
                                                        e,
                                                        "supportPlanService"
                                                    )}>
                                                    Support Plan Service
                                                </Checkbox>
                                            </Flex>
                                            <Flex
                                                gap={"size-100"}
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                marginTop={"size-200"}
                                            >
                                                <View flexBasis={"100%"}>
                                                    <Picker
                                                        width={"100%"}
                                                        label="Status"
                                                        selectedKey={
                                                            form.ruleStatus
                                                        }
                                                        onSelectionChange={(
                                                            selected
                                                        ) =>
                                                            setFormValues(
                                                                selected,
                                                                "ruleStatus"
                                                            )
                                                        }
                                                        isRequired
                                                    >
                                                        {Object.keys(
                                                            RULE_STATUS
                                                        ).map((item) => (
                                                            <Item
                                                                aria-label={
                                                                    RULE_STATUS[
                                                                        item
                                                                    ]
                                                                }
                                                                key={
                                                                    RULE_STATUS[
                                                                        item
                                                                    ]
                                                                }
                                                            >
                                                                {item}
                                                            </Item>
                                                        ))}
                                                    </Picker>
                                                </View>
                                            </Flex>
                                            {form.ruleType == RULE_TYPE.ROOT && (<Flex
                                                gap={"size-100"}
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                marginTop={"size-200"}
                                            >
                                                <View flexBasis={"100%"}>
                                                    <Picker
                                                        width={"100%"}
                                                        label="Case Route"
                                                        selectedKey={
                                                            form.caseRoute
                                                        }
                                                        onSelectionChange={(
                                                            selected
                                                        ) =>
                                                            setFormValues(
                                                                selected,
                                                                "caseRoute"
                                                            )
                                                        }
                                                        isRequired
                                                    >
                                                        {Object.keys(
                                                            CASE_ROUTE
                                                        ).map((item) => (
                                                            <Item
                                                                aria-label={
                                                                    CASE_ROUTE[
                                                                        item
                                                                    ]
                                                                }
                                                                key={
                                                                    CASE_ROUTE[
                                                                        item
                                                                    ]
                                                                }
                                                            >
                                                                {item}
                                                            </Item>
                                                        ))}
                                                    </Picker>
                                                </View>
                                            </Flex>)}
                                        </section>
                                        )}
                                </section>
                            )}

                            {isEdit && (
                                <section id="editRuleForm">
                                    <section id="secondStep">
                                        <TextField aria-label="Rule ID" value={form.ruleId}  isHidden></TextField>
                                        <Flex
                                            gap={"size-100"}
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            alignItems={"center"}
                                        >
                                            <View flexBasis={'50%'}>
                                                <TextField
                                                    width={'100%'}
                                                    value={form.ruleName}
                                                    onChange={(value) =>
                                                        setFormValues(
                                                            value,
                                                            "ruleName"
                                                        )
                                                    }
                                                    aria-label="Rule Name"
                                                    label="Rule Name"
                                                    isReadOnly
                                                ></TextField>
                                            </View>
                                            <View flexBasis={'50%'}>
                                                <Picker
                                                    width={'100%'}
                                                    label="Rule Type"
                                                    selectedKey={form.ruleType}
                                                    onSelectionChange={(
                                                        selected
                                                    ) =>
                                                        setFormValues(
                                                            selected,
                                                            "ruleType"
                                                        )
                                                    }
                                                    isRequired
                                                    isReadOnly
                                                    isDisabled
                                                >
                                                    {Object.keys(RULE_TYPE).map(
                                                        (item) => (
                                                            <Item
                                                                key={
                                                                    RULE_TYPE[
                                                                        item
                                                                    ]
                                                                }
                                                            >
                                                                {item}
                                                            </Item>
                                                        )
                                                    )}
                                                </Picker>
                                            </View>
                                        </Flex>
                                        
                                        <Flex
                                            gap={"size-100"}
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            alignItems={"center"}
                                            marginTop={'size-200'}
                                        >
                                            <View flexBasis={'100%'}>
                                                <Picker
                                                    width={'100%'}
                                                    label="Condition Type"
                                                    selectedKey={
                                                        form.conditionType
                                                    }
                                                    onSelectionChange={(
                                                        selected
                                                    ) =>
                                                        setFormValues(
                                                            selected,
                                                            "conditionType"
                                                        )
                                                    }
                                                    isRequired
                                                >
                                                    {Object.keys(
                                                        CONDITION_TYPE
                                                    ).map((item) => (
                                                        <Item
                                                            key={
                                                                CONDITION_TYPE[
                                                                    item
                                                                ]
                                                            }
                                                        >
                                                            {item}
                                                        </Item>
                                                    ))}
                                                </Picker>
                                            </View>
                                        </Flex>
                                        <Flex
                                            gap={"size-100"}
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            alignItems={"center"}
                                            marginTop={'size-200'}
                                        >
                                        <View flexBasis={"100%"}>
                                                <TextArea
                                                    width={'100%'}
                                                    validationState={
                                                        conditionsValidation
                                                            ? "valid"
                                                            : "invalid"
                                                    }
                                                    value={form.condition}
                                                    onChange={(value) =>
                                                        setFormValues(
                                                            value,
                                                            "condition"
                                                        )
                                                    }
                                                    label="Condition"
                                                ></TextArea>
                                            </View>
                                        </Flex>
                                        <Flex
                                            gap={"size-100"}
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            alignItems={"center"}
                                            marginTop={"size-200"}
                                        >
                                            <View flexBasis={'100%'}>
                                                <Picker
                                                    width={'100%'}
                                                    label="Action"
                                                    selectedKey={form.action}
                                                    onSelectionChange={(
                                                        selected
                                                    ) =>
                                                        setFormValues(
                                                            selected,
                                                            "action"
                                                        )
                                                    }
                                                    isRequired
                                                >
                                                    {Object.keys(
                                                        ACTION_TYPE
                                                    ).map((item) => (
                                                        <Item
                                                            key={
                                                                ACTION_TYPE[
                                                                    item
                                                                ]
                                                            }
                                                        >
                                                            {item}
                                                        </Item>
                                                    ))}
                                                </Picker>
                                            </View>
                                        </Flex>
                                        {form.action == "sub_rules" && (
                                                <Flex
                                                    gap={"size-100"}
                                                    justifyContent={'start'}
                                                    alignContent={'start'}
                                                    alignItems={'start'}
                                                    marginTop={"size-200"}
                                                    direction={'column'}
                                                >
                                                    <View 
                                                        flexBasis={"100%"}
                                                        maxHeight={'size-1500'}
                                                        width={'100%'}
                                                        id={"subRulesContainer"}
                                                    >
                                                        <CheckboxGroup
                                                            value={form.subRules || []}
                                                            label="Sub Rules"
                                                            orientation="vertical"
                                                            onChange={(
                                                                selected
                                                            ) =>
                                                                setFormValues(
                                                                    selected,
                                                                    "subRules"
                                                                )
                                                            }
                                                            width={'100%'}
                                                            isRequired
                                                        >
                                                            <View
                                                                maxHeight={'size-1000'}
                                                                UNSAFE_style={{display:'flex', flexDirection:'column',overflow:'auto'}}
                                                            >
                                                            {props.existingData
                                                                .filter(
                                                                    (ele) =>
                                                                        ele.ruleType !==
                                                                        RULE_TYPE.ROOT
                                                                )
                                                                .map((ele) => (
                                                                    <Checkbox
                                                                        aria-label={Math.random()}
                                                                        key={
                                                                            ele.ruleId
                                                                        }
                                                                        value={
                                                                            ele.ruleId
                                                                        }
                                                                    >
                                                                        {
                                                                            `(${ele.ruleId}) ${ele.ruleName}        
                                                                            `                            
                                                                        }

                                                                        { checkExistingIndex(ele.ruleId) }
                                                                    </Checkbox>
                                                                ))}
                                                            </View>
                                                        </CheckboxGroup>
                                                    </View>
                                                </Flex>
                                            )}
                                        <Flex
                                            gap={"size-100"}
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            alignItems={"center"}
                                            marginTop={"size-200"}
                                        >
                                            <View flexBasis={'100%'}>
                                                <Picker
                                                    width={'100%'}
                                                    label="Else Action"
                                                    selectedKey={form.elseAction}
                                                    onSelectionChange={(
                                                        selected
                                                    ) =>
                                                        setFormValues(
                                                            selected,
                                                            "elseAction"
                                                        )
                                                    }
                                                    isRequired
                                                >
                                                    {Object.keys(
                                                        ACTION_TYPE
                                                    ).map((item) => (
                                                        <Item
                                                            key={
                                                                ACTION_TYPE[
                                                                    item
                                                                ]
                                                            }
                                                        >
                                                            {item}
                                                        </Item>
                                                    ))}
                                                </Picker>
                                            </View>
                                        </Flex>
                                        {form.elseAction == "sub_rules" && (
                                                <Flex
                                                    gap={"size-100"}
                                                    justifyContent={'start'}
                                                    alignContent={'start'}
                                                    alignItems={'start'}
                                                    marginTop={"size-200"}
                                                    direction={'column'}
                                                >
                                                    <View 
                                                        flexBasis={"100%"}
                                                        maxHeight={'size-1500'}
                                                        width={'100%'}
                                                        id={"elseSubRulesContainer"}
                                                    >
                                                        <CheckboxGroup
                                                            value={form.elseSubRules || []}
                                                            label="Else Sub Rules"
                                                            orientation="vertical"
                                                            onChange={(
                                                                selected
                                                            ) =>
                                                                setFormValues(
                                                                    selected,
                                                                    "elseSubRules"
                                                                )
                                                            }
                                                            width={'100%'}
                                                            isRequired
                                                        >
                                                            <View
                                                                maxHeight={'size-1000'}
                                                                UNSAFE_style={{display:'flex', flexDirection:'column',overflow:'auto'}}
                                                            >
                                                            {props.existingData
                                                                .filter(
                                                                    (ele) =>
                                                                        ele.ruleType !==
                                                                        RULE_TYPE.ROOT
                                                                )
                                                                .map((ele) => (
                                                                    <Checkbox
                                                                        aria-label={Math.random()}
                                                                        key={
                                                                            ele.ruleId
                                                                        }
                                                                        value={
                                                                            ele.ruleId
                                                                        }
                                                                    >
                                                                        {
                                                                            `(${ele.ruleId}) ${ele.ruleName}        
                                                                            `                            
                                                                        }

                                                                        { checkExistingElseSubRulesIndex(ele.ruleId) }
                                                                    </Checkbox>
                                                                ))}
                                                            </View>
                                                        </CheckboxGroup>
                                                    </View>
                                                </Flex>
                                            )}

                                            <Flex
                                                gap={"size-100"}
                                                justifyContent={"center"}
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                marginTop={"size-200"}
                                            >
                                            <View flexBasis={"100%"}>
                                            <NumberField
                                               label="Deny Reason Code"
                                               defaultValue={0}
                                               minValue={0}
                                               value={form.denyReasonCode}
                                               onChange={(value) =>
                                                setFormValues(
                                                    value,
                                                    "denyReasonCode"
                                                )
                                            } />
                                            </View>
                                           </Flex>
                                            <Flex direction="column" marginTop='20px'>
                                                <Checkbox isSelected={form.entitlementService} value={form.entitlementService} onChange={(e) =>
                                                    setFormValues(
                                                        e,
                                                        "entitlementService"
                                                    )}>
                                                    Entitlement Service
                                                </Checkbox>
                                            </Flex>
                                            <Flex direction="column">
                                                <Checkbox isSelected={form.supportLevelService} onChange={(e) =>
                                                    setFormValues(
                                                        e,
                                                        "supportLevelService"
                                                    )}>
                                                    Support Level Service
                                                </Checkbox>
                                            </Flex>
                                            <Flex direction="column">
                                                <Checkbox isSelected={form.supportPlanService} onChange={(e) =>
                                                    setFormValues(
                                                        e,
                                                        "supportPlanService"
                                                    )}>
                                                    Support Plan Service
                                                </Checkbox>
                                            </Flex>
                                        <Flex
                                            gap={"size-100"}
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            alignItems={"center"}
                                            marginTop={"size-200"}
                                        >
                                            <View flexBasis={'100%'}>
                                                <Picker
                                                    width={'100%'}
                                                    label="Status"
                                                    selectedKey={`${form.ruleStatus}`}
                                                    onSelectionChange={(
                                                        selected
                                                    ) =>
                                                        setFormValues(
                                                            selected,
                                                            "ruleStatus"
                                                        )
                                                    }
                                                    isRequired
                                                >
                                                    {Object.keys(
                                                        RULE_STATUS
                                                    ).map((item) => (
                                                        <Item
                                                            key={
                                                                RULE_STATUS[
                                                                    item
                                                                ]
                                                            }
                                                        >
                                                            {item}
                                                        </Item>
                                                    ))}
                                                </Picker>
                                            </View>
                                        </Flex>
                                        {form.ruleType == RULE_TYPE.ROOT && (<Flex
                                            gap={"size-100"}
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            alignItems={"center"}
                                            marginTop={"size-200"}
                                        >
                                            <View flexBasis={'100%'}>
                                                <Picker
                                                    width={'100%'}
                                                    label="Case Route"
                                                    selectedKey={`${form.caseRoute}`}
                                                    onSelectionChange={(
                                                        selected
                                                    ) =>
                                                        setFormValues(
                                                            selected,
                                                            "caseRoute"
                                                        )
                                                    }
                                                    isRequired
                                                >
                                                    {Object.keys(
                                                        CASE_ROUTE
                                                    ).map((item) => (
                                                        <Item
                                                            key={
                                                                CASE_ROUTE[
                                                                    item
                                                                ]
                                                            }
                                                        >
                                                            {item}
                                                        </Item>
                                                    ))}
                                                </Picker>
                                            </View>
                                        </Flex>)}
                                    </section>
                                </section>
                            )}

                            {props.recordError && (
                                <div
                                    style={{
                                        fontSize: "small",
                                        color: "red",
                                        marginTop: "1%",
                                    }}
                                    align="center"
                                >
                                    {" "}
                                    <b> {props.recordError}</b>
                                </div>
                            )}
                        </Content>
                        <Footer alignSelf={"flex-end"}>
                            <Flex flexBasis={"100%"}>
                                <View
                                    width={"50%"}
                                    alignSelf={"flex-start"}
                                ></View>
                                <View width={"30%"}>
                                    {!isEdit && step === "step1" && (
                                        <ButtonGroup>
                                            <Button
                                                variant="secondary"
                                                onPress={onCloseHandler}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onPress={onChangeStep}
                                                isDisabled={isFirstStepDisabled(
                                                    form
                                                )}
                                            >
                                                Next
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                    {!isEdit && step === "step2" && (
                                        <ButtonGroup>
                                            <Button
                                                variant="secondary"
                                                onPress={onCloseHandler}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                onPress={onChangeStep}
                                            >
                                                Previous
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onPress={onSaveHandler}
                                                isDisabled={validateFormValues(
                                                    form
                                                )}
                                            >
                                                Save
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                    {isEdit && (
                                        <ButtonGroup>
                                            <Button
                                                variant="secondary"
                                                onPress={onCloseHandler}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onPress={onSaveHandler}
                                                isDisabled={validateFormValues(
                                                    form
                                                )}
                                            >
                                                Update
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </View>
                            </Flex>
                        </Footer>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default AddSRSRules