import React, { useState, useEffect } from "react"
import {
    Provider,
    lightTheme,
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    Heading,
    Content,
    ButtonGroup,
    TextField,
    Item,
    Flex,
    Text,
    View,
    ComboBox,
    MenuTrigger,
    Menu,
    Well,
    Badge,
    SearchField,
    Tooltip,
    TooltipTrigger,
} from "@adobe/react-spectrum"
import ChevronDown from "@spectrum-icons/workflow/ChevronDown"
import ChevronRight from "@spectrum-icons/workflow/ChevronRight"
import Delete from "@spectrum-icons/workflow/Delete"
import { notify } from "../constants/constants"
import CloseCircle from "@spectrum-icons/workflow/CloseCircle"

const AddPermission = ({
    isDialogOpened,
    editData,
    handleCloseDialog,
    operation,
    allGroups,
    allUsers,
    allRoles,
    supervisors,
}) => {

    const [role, setRole] = useState("")
    const [message, setMessage] = useState(false)
    const [users, setUsers] = useState(new Set())
    const [commonPermission, setCommonPermission] = useState(new Set())
    const [selectedPermissions, setSelectedPermissions] = useState(new Set())
    const initialState = { group: "", permissions: new Set() }
    const [data, setData] = useState([initialState])
    const [name, setName] = useState("")

    const commonPermissionsArray = [
        { id: "bulk_edit_proficiencies", name: "Bulk Edit Proficiencies" },
        { id: "bulk_edit_queues", name: "Bulk Edit Queues" },
        { id: "bulk_group_change", name: "Bulk Group Change" },
        { id: "bulk_delete", name: "Bulk Delete" },
        { id: "bulk_create_users", name: "Bulk Create Users" },
        { id: "add_group", name: "Add Group" },
        { id: "clone_group", name: "Clone Group" },
        { id: "view_all_users", name: "View All Users" },
        { id: "view_all_groups", name: "View All Groups" },
    ]
    const nonCommonPermissionsArray = [
        { id: "add_user", name: "Add User" },
        { id: "enforce_update", name: "Enforce Update" },
        { id: "delete_user", name: "Delete User" },
        { id: "edit_userinfo", name: "Edit User Info" },
        { id: "edit_user_queues", name: "Edit User Queues" },
        { id: "edit_user_proficiencies", name: "Edit User Proficiencies" },
        {
            id: "edit_user_additional_queues",
            name: "Edit User Additional Queues",
        },
        {
            id: "edit_user_additional_proficiencies",
            name: "Edit User Additional Proficiencies",
        },
        {
            id: "edit_user_default_outbound_queue",
            name: "Edit User Default Outbound Queue",
        },
        { id: "view_users", name: "View Users" },
        { id: "view_group", name: "View Group" },
        { id: "edit_group", name: "Edit Group" },
        { id: "delete_group", name: "Delete Group" },
    ]

    useEffect(() => {
        if (editData) {
            setRole(editData.role)
            setUsers(new Set(editData.users))
            const formattedData = editData.groupPermissions.map((obj) => ({
                group: Object.keys(obj)[0],
                permissions: Object.values(obj)[0],
            }))
            const commonGroup = formattedData.filter(
                (obj) => obj.group === "global"
            )
            const commonPermissionsArray =
                commonGroup.length > 0 ? commonGroup[0].permissions : []
            setCommonPermission(new Set(commonPermissionsArray))
            const removingCommonGroup = formattedData
                .filter((obj) => obj.group !== "global")
                .map((ele) => ({
                    ...ele,
                    permissions: new Set(ele.permissions),
                }))

            setData(removingCommonGroup)
        }
    }, [editData])

    useEffect(() => {
        setMessage(true)
        const timeout = setTimeout(() => {
            setMessage(false)
        }, 3000)
        return () => clearTimeout(timeout)
    }, [])

    const onCloseHandler = () => {
        handleCloseDialog(false, null, false)
    }

    const onSaveHandler = () => {
        const formatedData = {
            role: role,
            users: [...users],
            groupPermissions: [
                ...data.map((item) => ({
                    group: item.group,
                    permissions: Array.from(item.permissions),
                })),
                commonPermission.size
                    ? {
                          group: "global",
                          permissions: Array.from(commonPermission),
                      }
                    : null,
            ].filter(Boolean),
            //filter(Boolean) at the end removes any null values that might have been added if commonPermissions.length was falsy
        }
        handleCloseDialog(true, formatedData, operation)
    }

    const handleAddButton = () => {
        setSelectedPermissions(new Set())
        setData((prev) => [initialState, ...prev])
    }

    const handleDeleteField = (index) => {
        const updatedFields = [...data]
        updatedFields.splice(index, 1)
        setData(updatedFields)
    }

    const checkDuplicate = (value) => {
        return data.some((ele) => ele.group === value)
    }

    const handleUpdate = (index, value, key) => {
        const prevData = [...data]
        if (key === "group") {
            if (!checkDuplicate(value)) {
                prevData[index][key] = value
            } else {
                notify("Group already exist", "warn")
            }
        } else {
            prevData[index][key] = value
        }
        setData(prevData)
    }

    const checkValidation = (data) => {
        return data.every((obj) => obj.group && obj.permissions.size)
    }

    const removePermission = (key, value, indexFromData) => {
        if (key === "noncommon") {
            const permissionToRemove = value
            const prevData = [...data]
            const permissions = prevData[indexFromData].permissions
            permissions.delete(permissionToRemove)
            setData(prevData)
        } else if (key === "global") {
            const newCommonPermission = new Set(commonPermission)
            newCommonPermission.delete(value)
            setCommonPermission(newCommonPermission)
        } else if (key === "users") {
            const newUsers = new Set(users)
            newUsers.delete(value)
            setUsers(newUsers)
        }
    }

    // small
    // const generateBadgesFromList = (type, permissions, indexFromData) => {
    //     if (permissions.length <= 2) {
    //         return permissions.map((val, index) => (
    //             <Badge
    //                 key={index}
    //                 variant="neutral"
    //                 UNSAFE_style={{
    //                     margin: "0px 2px",
    //                     background: "#666666",
    //                     color: "white",
    //                     padding: "3px 4px",
    //                     // border: "1px solid transparent",
    //                     borderRadius: "4px",
    //                     fontSize: "10px",
    //                     height: "20px",
    //                     display: "block",
    //                 }}
    //             >
    //                 <Text UNSAFE_style={{ padding: "0px" }}>{val}</Text>
    //                 <Button
    //                     style="fill"
    //                     UNSAFE_style={{
    //                         marginTop: "-9px",
    //                         cursor: "pointer",
    //                         transform: "scale(0.7)",
    //                     }}
    //                     onPress={() =>
    //                         removePermission(type, val, indexFromData)
    //                     }
    //                 >
    //                     <CloseCircle aria-label="Done" />
    //                 </Button>
    //             </Badge>
    //         ))
    //     } else {
    //         return (
    //             <>
    //                 {permissions.slice(0, 2).map((val, index) => (
    //                     <Badge
    //                         key={index}
    //                         variant="neutral"
    //                         UNSAFE_style={{
    //                             margin: "0px 2px",
    //                             background: "#666666",
    //                             color: "white",
    //                             padding: "3px 4px",
    //                             // border: "1px solid transparent",
    //                             borderRadius: "4px",
    //                             fontSize: "10px",
    //                             height: "20px",
    //                             display: "block",
    //                         }}
    //                     >
    //                         <Text UNSAFE_style={{ padding: "0px" }}>{val}</Text>
    //                         <Button
    //                             style="fill"
    //                             UNSAFE_style={{
    //                                 marginTop: "-9px",
    //                                 cursor: "pointer",
    //                                 transform: "scale(0.7)",
    //                             }}
    //                             onPress={() =>
    //                                 removePermission(type, val, indexFromData)
    //                             }
    //                         >
    //                             <CloseCircle aria-label="Done" />
    //                         </Button>
    //                     </Badge>
    //                 ))}
    //                 {permissions.length > 2 && (
    //                     <DialogTrigger type="popover" placement="right top">
    //                         <ActionButton height={"20px"}>
    //                             Show All
    //                         </ActionButton>
    //                         <Dialog>
    //                             <Content
    //                                 height={"auto"}
    //                                 UNSAFE_style={{
    //                                     display: "flex",
    //                                     flexWrap: "wrap",
    //                                 }}
    //                             >
    //                                 {permissions.map((val, index) => (
    //                                     <Badge
    //                                         key={index}
    //                                         variant="neutral"
    //                                         UNSAFE_style={{
    //                                             margin: "2px 2px",
    //                                             background: "#666666",
    //                                             color: "white",
    //                                             padding: "3px 4px",
    //                                             // border: "1px solid transparent",
    //                                             borderRadius: "4px",
    //                                             fontSize: "14px",
    //                                             height: "30px",
    //                                             display: "block",
    //                                         }}
    //                                     >
    //                                         <Text
    //                                             UNSAFE_style={{
    //                                                 padding: "0px",
    //                                             }}
    //                                         >
    //                                             {val}
    //                                         </Text>
    //                                         <Button
    //                                             style="fill"
    //                                             UNSAFE_style={{
    //                                                 marginTop: "-5px",
    //                                                 cursor: "pointer",
    //                                             }}
    //                                             onPress={() =>
    //                                                 removePermission(
    //                                                     type,
    //                                                     val,
    //                                                     indexFromData
    //                                                 )
    //                                             }
    //                                         >
    //                                             <CloseCircle aria-label="Done" />
    //                                         </Button>
    //                                     </Badge>
    //                                 ))}
    //                             </Content>
    //                         </Dialog>
    //                     </DialogTrigger>
    //                 )}
    //             </>
    //         )
    //     }
    // }

    const generateBadgesFromList = (type, permissions, indexFromData) => {
        if (permissions.length <= 2) {
            return permissions.map((val, index) => (
                <Badge
                    key={index}
                    variant="neutral"
                    UNSAFE_style={{
                        margin: "2px 2px",
                        background: "rgb(102, 102, 102)",
                        color: "white",
                        padding: "3px 0px 0px 10px",
                        borderRadius: "4px",
                        height: "30px",
                        display: "block",
                    }}
                >
                    <Text UNSAFE_style={{ padding: "0px", fontSize: "14px" }}>
                        {val}
                    </Text>
                    <Button
                        style="fill"
                        UNSAFE_style={{
                            marginTop: "-4px",
                            cursor: "pointer",
                            transform: "scale(0.7)",
                        }}
                        onPress={() =>
                            removePermission(type, val, indexFromData)
                        }
                    >
                        <CloseCircle aria-label="Done" />
                    </Button>
                </Badge>
            ))
        } else {
            return (
                <>
                    {permissions.slice(0, 2).map((val, index) => (
                        <Badge
                            key={index}
                            variant="neutral"
                            UNSAFE_style={{
                                margin: "2px 2px",
                                background: "rgb(102, 102, 102)",
                                color: "white",
                                padding: "3px 0px 0px 10px",
                                borderRadius: "4px",
                                height: "30px",
                                display: "block",
                            }}
                        >
                            <Text
                                UNSAFE_style={{
                                    padding: "0px",
                                    fontSize: "14px",
                                }}
                            >
                                {val}
                            </Text>
                            <Button
                                style="fill"
                                UNSAFE_style={{
                                    marginTop: "-4px",
                                    cursor: "pointer",
                                    transform: "scale(0.7)",
                                }}
                                onPress={() =>
                                    removePermission(type, val, indexFromData)
                                }
                            >
                                <CloseCircle aria-label="Done" />
                            </Button>
                        </Badge>
                    ))}
                    {permissions.length > 2 && (
                        <DialogTrigger type="popover" placement="right top">
                            <ActionButton
                                height={"30px"}
                                marginTop={"2px"}
                                UNSAFE_style={{ cursor: "pointer" }}
                            >
                                Show All
                            </ActionButton>
                            <Dialog>
                                <Content
                                    height={"auto"}
                                    UNSAFE_style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {permissions.map((val, index) => (
                                        <Badge
                                            key={index}
                                            variant="neutral"
                                            UNSAFE_style={{
                                                margin: "2px 2px",
                                                background:
                                                    "rgb(102, 102, 102)",
                                                color: "white",
                                                padding: "3px 0px 0px 10px",
                                                borderRadius: "4px",
                                                height: "30px",
                                                display: "block",
                                            }}
                                        >
                                            <Text
                                                UNSAFE_style={{
                                                    padding: "0px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {val}
                                            </Text>
                                            <Button
                                                style="fill"
                                                UNSAFE_style={{
                                                    marginTop: "-4px",
                                                    cursor: "pointer",
                                                    transform: "scale(0.7)",
                                                }}
                                                onPress={() =>
                                                    removePermission(
                                                        type,
                                                        val,
                                                        indexFromData
                                                    )
                                                }
                                            >
                                                <CloseCircle aria-label="Done" />
                                            </Button>
                                        </Badge>
                                    ))}
                                </Content>
                            </Dialog>
                        </DialogTrigger>
                    )}
                </>
            )
        }
    }

    const handleSearchChange = (value) => {
        setName(value)
    }

    const filteredSupervisors = supervisors
        ?.filter(
            (user) =>
                user?.name?.toLowerCase()?.includes(name?.toLowerCase()) ||
                user?.id?.toLowerCase()?.includes(name?.toLowerCase())
        )
        ?.filter(
            (user, index, self) =>
                index ===
                self.findIndex(
                    (u) => u.name.toLowerCase() === user.name.toLowerCase()
                )
        )

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="full" width={"1300px"} height={"800px"}>
                        <Heading>{operation} Permissions</Heading>
                        <Divider />
                        <Content>
                            <Flex gap={"10px"}>
                                <ComboBox
                                    defaultItems={allRoles}
                                    label="Role"
                                    allowsCustomValue
                                    width={"29%"}
                                    inputValue={role}
                                    onInputChange={setRole}
                                    // onSelectionChange={setRole}
                                    isDisabled={editData}
                                >
                                    {(item) => (
                                        <Item key={item.id}>{item.name}</Item>
                                    )}
                                </ComboBox>
                                <View>
                                    <View>Users</View>
                                    <MenuTrigger
                                        closeOnSelect={false}
                                        direction="bottom"
                                    >
                                        <ActionButton
                                            // isDisabled={{}}
                                            width={"193px"}
                                            marginTop={"8px"}

                                            // marginStart={"60px"}
                                        >
                                            <div
                                                style={{
                                                    width: "150px",
                                                    textAlign: "start",
                                                    paddingLeft: "6px",
                                                    color: "#222",
                                                }}
                                            >
                                                Select Users
                                            </div>

                                            <ChevronRight />
                                            {/* )} */}
                                        </ActionButton>
                                        <div style={{ maxWidth: "193px" }}>
                                            <SearchField
                                                width={"193px"}
                                                onClear={() => setName("")}
                                                value={name}
                                                onChange={(value) =>
                                                    handleSearchChange(value)
                                                }
                                            />
                                            <Menu
                                                items={filteredSupervisors}
                                                selectionMode="multiple"
                                                selectedKeys={users}
                                                onSelectionChange={setUsers}
                                                position={"bottom"}
                                                maxHeight={"40vh"}
                                                width={"193px"}
                                            >
                                                {(item) => (
                                                    <Item key={item.id}>
                                                        {item.name}
                                                    </Item>
                                                )}
                                            </Menu>
                                        </div>
                                    </MenuTrigger>
                                </View>
                                {users.size > 0 ? (
                                    <Well
                                        UNSAFE_className="custom-scrollbar"
                                        UNSAFE_style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            height: "32px",
                                            maxHeight: "40px",
                                            // overflowY: "auto",
                                            maxWidth: "50%",
                                            minWidth: "50%",
                                            padding: "0px",
                                            marginTop: "28px",
                                            border: "none",
                                        }}
                                    >
                                        {generateBadgesFromList("users", [
                                            ...users,
                                        ])}
                                    </Well>
                                ) : null}
                            </Flex>

                            <Divider size="S" marginTop={"20px"} />
                            <Flex marginTop={"20px"} gap={"10px"}>
                                {/* <View>
                                    <TextField
                                       
                                        isReadOnly
                                        autoFocus={false}
                                        // value="super permissions"
                                        // onFocus={() => setMessage(true)}
                                    />
                                    {message && (
                                        <View
                                            UNSAFE_style={{
                                                marginTop: "5px",
                                                color: "#ff7800",
                                                fontStyle: "italic",
                                            }}
                                        >
                                            Note : This key is fixed
                                        </View>
                                    )}
                                </View> */}

                                <Flex
                                    direction={"row"}
                                    gap={"10px"}
                                    width={"100%"}
                                >
                                    <TooltipTrigger delay={0}>
                                        <TextField
                                            // isDisabled={true}
                                            isReadOnly
                                            width={"29%"}
                                            // selectedKey={"Global Permissions"}
                                            value="Global Permissions"
                                            UNSAFE_className="custom-combobox"
                                        ></TextField>
                                        <Tooltip>
                                            <Text>
                                                Fixed for global permissions
                                            </Text>
                                        </Tooltip>
                                    </TooltipTrigger>
                                    <MenuTrigger
                                        closeOnSelect={false}
                                        direction="top"
                                    >
                                        <ActionButton
                                            // isDisabled={{}}
                                            width={"193px"}

                                            // marginStart={"60px"}
                                        >
                                            <div
                                                style={{
                                                    width: "150px",
                                                    textAlign: "start",
                                                    paddingLeft: "6px",
                                                    color: "#222",
                                                }}
                                            >
                                                {!commonPermission.size
                                                    ? "Select Permissions"
                                                    : "Selected Permissions"}
                                            </div>
                                            {!commonPermission.size ? (
                                                <ChevronDown />
                                            ) : (
                                                <ChevronRight />
                                            )}
                                        </ActionButton>
                                        <div>
                                            {/* <SearchField
                                                width={"100%"}
                                                onClear={() =>{}
                                                    // setSearchTerm("")
                                                }
                                                value={{}}
                                                onChange={{}}
                                            /> */}
                                            <Menu
                                                items={commonPermissionsArray}
                                                selectionMode="multiple"
                                                selectedKeys={commonPermission}
                                                onSelectionChange={
                                                    setCommonPermission
                                                }
                                                position={"bottom"}
                                            >
                                                {(item) => (
                                                    <Item key={item.id}>
                                                        {item.name}
                                                    </Item>
                                                )}
                                            </Menu>
                                        </div>
                                    </MenuTrigger>
                                    {commonPermission.size > 0 ? (
                                        <Well
                                            UNSAFE_className="custom-scrollbar"
                                            UNSAFE_style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                height: "32px",
                                                maxHeight: "40px",
                                                // overflowY: "auto",
                                                padding: "0px",
                                                // marginTop: "28px",
                                                // marginLeft: "22.3%",
                                                border: "none",
                                                maxWidth: "50%",
                                                minWidth: "50%",
                                                marginTop: "0px",
                                            }}
                                        >
                                            {generateBadgesFromList("global", [
                                                ...commonPermission,
                                            ])}
                                        </Well>
                                    ) : null}
                                </Flex>
                            </Flex>
                            {/* {message && (
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "10px",
                                        marginTop: "5px",
                                        color: "red",
                                        fontStyle: "italic",
                                    }}
                                >
                                    This key is fixed , you can select the
                                    global permissions
                                </Text>
                            )} */}
                            <Divider size="S" marginTop={"20px"} />
                            <Button
                                UNSAFE_style={{
                                    cursor: "pointer",
                                    float: "right",
                                    marginTop: "5px",
                                }}
                                onPress={handleAddButton}
                            >
                                + Add
                            </Button>
                            {data.map((ele, index) => (
                                <Flex
                                    marginTop={index == 0 ? "40px" : "20px"}
                                    gap={"10px"}
                                    key={ele.group}
                                >
                                    <ComboBox
                                        label={index == 0 && "Groups"}
                                        defaultItems={allGroups}
                                        selectedKey={ele.group}
                                        width={"29%"}
                                        onSelectionChange={(value) =>
                                            handleUpdate(index, value, "group")
                                        }
                                    >
                                        {(item) => <Item>{item.name}</Item>}
                                    </ComboBox>

                                    <View>
                                        {index == 0 && <View>Permissions</View>}
                                        <MenuTrigger closeOnSelect={false}>
                                            <ActionButton
                                                // isDisabled={{}}
                                                width={"193px"}
                                                marginTop={
                                                    index == 0 ? "8px" : null
                                                }

                                                // marginStart={"60px"}
                                            >
                                                <div
                                                    style={{
                                                        width: "150px",
                                                        textAlign: "start",
                                                        paddingLeft: "6px",
                                                        color: "#222",
                                                    }}
                                                >
                                                    {!ele.permissions.size
                                                        ? "Select Permissions"
                                                        : "Selected Permissions"}
                                                </div>
                                                {!ele.permissions.size ? (
                                                    <ChevronDown />
                                                ) : (
                                                    <ChevronRight />
                                                )}
                                            </ActionButton>
                                            <div>
                                                {/* <SearchField
                                                width={"100%"}
                                                onClear={() =>{}
                                                    // setSearchTerm("")
                                                }
                                                value={{}}
                                                onChange={{}}
                                            /> */}
                                                <Menu
                                                    items={
                                                        nonCommonPermissionsArray
                                                    }
                                                    selectionMode="multiple"
                                                    selectedKeys={
                                                        ele.permissions
                                                    }
                                                    onSelectionChange={(
                                                        selected
                                                    ) => {
                                                        handleUpdate(
                                                            index,
                                                            selected,
                                                            "permissions"
                                                        )
                                                    }}
                                                >
                                                    {(item) => (
                                                        <Item key={item.id}>
                                                            {item.name}
                                                        </Item>
                                                    )}
                                                </Menu>
                                            </div>
                                        </MenuTrigger>
                                    </View>

                                    {ele?.permissions.size > 0 ? (
                                        <Well
                                            UNSAFE_className="custom-scrollbar"
                                            UNSAFE_style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                maxHeight: "40px",
                                                overflowY: "auto",
                                                // maxWidth: "48%",
                                                minWidth: "50%",
                                                padding: "0px",
                                                marginTop:
                                                    index === 0
                                                        ? "28px"
                                                        : "0px",
                                                border: "none",
                                                flexBasis: "550px",
                                            }}
                                        >
                                            {generateBadgesFromList(
                                                "noncommon",
                                                [...ele.permissions],
                                                index
                                            )}
                                        </Well>
                                    ) : null}

                                    <Button
                                        aria-label="addKeyValue"
                                        variant="secondary"
                                        staticColor="black"
                                        style="fill"
                                        marginTop={index == 0 ? "30px" : null}
                                        UNSAFE_className="cursor"
                                        onPress={() => handleDeleteField(index)}
                                    >
                                        <Delete />
                                    </Button>
                                </Flex>
                            ))}
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onPress={onSaveHandler}
                                isDisabled={
                                    !role ||
                                    !checkValidation(data) ||
                                    (!commonPermission.size && !data.length)
                                }
                            >
                                Save
                            </Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default AddPermission
