import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, TextField, View
} from '@adobe/react-spectrum';

const AddDidMagento = (props) => {
    const [did, setDid] = useState('');
    const [ldap, setLdap] = useState('');

    useEffect(() => {
        if (props.editData) {
            setDid(props?.editData?.did);
            setLdap(props?.editData?.ldap)
        }
    }, []);

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    }


    const onSaveHandler = () => {
        const body = {
            did:parseInt(did),
            ldap: ldap.trim(),
        }
        props.handleCloseDialog(true,body);
    }


    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="S">
                        <Heading>{`${props.operation} Emergency`}</Heading>
                        <Divider />
                        <Content>

                            <Form isRequired>
                                <View>DID</View>
                                <TextField autoFocus
                                    value={did}
                                    onChange={setDid}
                                    isQuiet
                                    isDisabled={props?.editData}
                                />

                                <div style={{ marginTop: '30px' }}></div>

                                <View>Ldap</View>
                                <TextField 
                                    value={ldap}
                                    onChange={setLdap}
                                    isQuiet
                                    isDisabled={false}
                                />
                            
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>Cancel</Button>
                            <Button variant="primary" onPress={onSaveHandler} isDisabled={false}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddDidMagento;