import React from "react";
import { IllustratedMessage, Heading, Content, Button } from "@adobe/react-spectrum";
// import Unavailable from '@spectrum-icons/illustrations/Unavailable';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.error("Error loading component:", error, info);
    }

    handleGoHome = () => {
        window.location.href = "/menu";
    };

    render() {
        if (this.state.hasError) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '60vh',
                    textAlign: 'center'
                }}>
                    <IllustratedMessage>
                        {/* <Unavailable /> */}
                        <Heading>Whoops, Something went wrong</Heading>
                        <Content marginTop="30px">
                            <Heading level={4}>Please either refresh the page or return home to try again.</Heading>
                            {/* <Heading level={6}>Soon, our engineers will roll up their sleeves to fix this issue!</Heading> */}
                        </Content>
                        <Button onPress={this.handleGoHome} variant="cta" marginTop="20px">
                            Go Home
                        </Button>
                    </IllustratedMessage>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
