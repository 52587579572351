import React, { useState, useRef, useEffect } from "react"
// import 'react-schedule-job/dist/index.css'
// import './index.css';
import { connect, useStore } from "react-redux"
import {
    Flex,
    Heading,
    TableBody,
    View,
    Item,
    TableHeader,
    Button,
    TableView,
    SearchField,
    ActionGroup,
    Text,
    Column,
    IllustratedMessage,
    Content,
    Row,
    Cell,
    Badge,
    DialogTrigger,
    Dialog,
    ActionButton,
    ProgressCircle,
    Divider,
    ComboBox,
    MenuTrigger,
    Menu,
} from "@adobe/react-spectrum"
import {
    fetchInstance,
    getDefaultGroupsConfig,
} from "../../utilities/api/phoenix-api"
import { getUserProfileReport } from "../../utilities/api/reports-api"
// import Delete from '@spectrum-icons/workflow/Delete';
// import Draw from '@spectrum-icons/workflow/Draw';
// import Refresh from "@spectrum-icons/workflow/Refresh"
// import { notify } from '../../../constants/constants';
import { renderEmptyState } from "../../utilities/components/CommonUseComponents"
import ChevronDown from "@spectrum-icons/workflow/ChevronDown"
import Filter from "@spectrum-icons/workflow/Filter"
// import NotFound from '@spectrum-icons/illustrations/NotFound';
import {NotFoundIconSvg} from "../../utilities/components/Icons"
import Search from "@spectrum-icons/workflow/Search"

const UserProfile = () => {
    const columns = [
        { name: "Name", key: "name" },
        { name: "Group", key: "group" },
        { name: "Group Supervisor", key: "groupSupervisor" },
    ]

    const columnsAdmin = [
        { name: "Name", key: "name" },
        { name: "Group", key: "group" },
        { name: "Group Supervisor", key: "groupSupervisor" },
    ]

    const [isLoading, setIsLoading] = useState(true)
    const [allInstances, setAllInstances] = useState([])
    const [selectedInstance, setSelectedInstance] = useState()
    const [selectedType, setSelectedType] = useState()
    const [allValues, setAllValues] = useState()
    const [selectedValues, setSelectedValues] = useState([])
    const [searchResults, setSearchResults] = useState([])
    // const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        getAllInstances()
        getAllQueues()
    }, [])

   

    const getAllInstances = () => {
        setIsLoading(true)
        fetchInstance()
            .then((response) => {
                setIsLoading(false)
                setAllInstances(response)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
    }

    function sortDataByName(data) {        
        return data?.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      }
    

    const getAllQueues = () => {
        // if (instance) {
        setIsLoading(true)
        getDefaultGroupsConfig()
            .then((response) => {                
                setIsLoading(false)
                setAllValues(response)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
        // }
    }

    

    // const handleSearchChange = (value) => {
    //     setSearchTerm(value)
    //     const filteredData = searchResults.filter((item) =>
    //         item.name.toLowerCase().includes(value.toLowerCase())
    //     )
    //     setFilteredStructuredData(filteredData)
    // }

    const handleFilter = () => {
        setIsLoading(true)
        // const selectedValuesArray = Array.from(selectedValues)
        const fetchInstance = allInstances.find(
            (item) => item.instance_id === selectedInstance
        )
        const obj = {
            instance: fetchInstance,
            queueIds: [selectedValues],
        }
        getUserProfileReport(obj)
            .then((response) => {
                setSearchResults(response[0].users)
            })
            .catch((error) => {
                console.log("error", error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleClear = () => {
        resetData()
    }

    const resetData = () => {
        setSelectedInstance(null)
        setSelectedType(null)
        setSelectedValues(null)
        setSearchResults([])
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "name":
                return <Cell>{`${item.firstName} ${item.lastName}`}</Cell>
            case "group":
                return <Cell>{item?.groupName || "-"}</Cell>
            case "groupSupervisor":
                return (
                    <Cell>{item?.isGroupSupervisor ? "Yes" : "No" || "-"}</Cell>
                )
        }
    }

    return (
        <>
            {isLoading ? (
                <div
                    className={isLoading ? "display-block" : "display-none"}
                    style={{
                        paddingTop: "20%",
                        paddingLeft: "55%",
                        position: "absolute",
                        zIndex: "10",
                        // width: "100%",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="M"
                    />
                </div>
            ) : null}
            <div align="center">
                <Heading variant="pageTitle">User Profile</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    height: "60vh",
                    width: "100%",
                    display: "flex",
                }}
            >
                <div
                    style={{
                        border: "1px solid #d9d9d9",
                        height: "55vh",
                        width: "20%",
                        maxWidth: "20%",
                        padding: "10px",
                    }}
                >
                    <Flex gap={5} marginY={5}>
                        <View
                            UNSAFE_style={{
                                width: "50px",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                color: "#999999",
                            }}
                        >
                            <Heading level={6}>Instance</Heading>
                        </View>
                        <ComboBox
                            defaultItems={allInstances || []}
                            selectedKey={selectedInstance}
                            onSelectionChange={setSelectedInstance}
                            width="20rem"
                            autoFocus={true}
                        >
                            {(item) => (
                                <Item key={item.instance_id}>
                                    {item.instance_alias}
                                </Item>
                            )}
                        </ComboBox>
                    </Flex>
                    <Flex gap={5} marginY={5}>
                        <View
                            UNSAFE_style={{
                                width: "50px",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                color: "#999999",
                            }}
                        >
                            <Heading level={6}>Type</Heading>
                        </View>

                        <ComboBox
                            defaultItems={[
                                { name: "Queue", key: "queue" },
                                { name: "Proficiency", key: "proficiency" },
                            ]}
                            selectedKey={selectedType}
                            onSelectionChange={setSelectedType}
                            disabledKeys={["proficiency"]}
                            width="20rem"
                            isDisabled={!selectedInstance}
                        >
                            {(item) => <Item key={item.key}>{item.name}</Item>}
                        </ComboBox>
                    </Flex>
                    {/* <Flex gap={5} marginY={5}>
                        <View
                            UNSAFE_style={{
                                // border: "1px solid red",
                                width: "50px",
                            }}
                        >
                            <Text>Value</Text>
                        </View>
                        <MenuTrigger
                            closeOnSelect={false}
                            direction="bottom"
                            width={"20rem"}
                        >
                            <ActionButton
                                isDisabled={false}
                                width={"20rem"}

                                // marginStart={"60px"}
                            >
                                <div
                                    style={{
                                        width: "162px",
                                        height: "100%",
                                        textAlign: "start",
                                        paddingLeft: "6px",
                                        color: "#222",
                                        fontStyle: "italic",
                                        borderRight: "1px solid #929292",
                                        // opacity: isCheckboxSelected
                                        //     ? "0.5"
                                        //     : null,
                                        // cursor: isCheckboxSelected
                                        //     ? "not-allowed"
                                        //     : "initial",
                                    }}
                                ></div>
                                <ChevronDown />
                            </ActionButton>
                            <div
                                style={{
                                    width: "20rem",
                                }}
                            >
                                <SearchField
                                    width={"100%"}
                                    onClear={() => setSearchTerm("")}
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <Menu
                                    items={allValues || []}
                                    selectionMode="multiple"
                                    selectedKeys={selectedValues}
                                    onSelectionChange={setSelectedValues}
                                    maxHeight={"350px"}
                                >
                                    {(item) => (
                                        <Item key={item.ldap}>{item.name}</Item>
                                    )}
                                </Menu>
                            </div>
                        </MenuTrigger>
                    </Flex> */}

                    <Flex gap={5} marginY={5}>
                        <View
                            UNSAFE_style={{
                                width: "50px",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                color: "#999999",
                            }}
                        >
                            <Heading level={6}>Queue</Heading>
                        </View>

                        <ComboBox
                            defaultItems={(allValues && sortDataByName(allValues[selectedInstance])) || []}
                            selectedKey={selectedValues}
                            onSelectionChange={setSelectedValues}
                            width="20rem"
                            isDisabled={!selectedInstance || !selectedType}

                        >
                            {(item) => <Item key={item.key}>{item.name}</Item>}
                        </ComboBox>
                    </Flex>

                    <View>
                        <Button
                            variant="cta"
                            style="fill"
                            marginTop={"5px"}
                            width={"100%"}
                            isDisabled={!selectedInstance || !selectedType || !selectedValues?.length}
                            onPress={handleFilter}
                        >
                            <Search marginEnd={"5px"} /> Search
                        </Button>
                    </View>
                    <View>
                        <Button
                            variant="negative"
                            style="outline"
                            marginTop={"5px"}
                            width={"100%"}
                            onPress={handleClear}
                        >
                            Clear
                        </Button>
                    </View>
                </div>
                <div>
                    <Divider
                        size="M"
                        height={"55vh"}
                        orientation="horizontal"
                        UNSAFE_style={{ width: "1px", marginLeft: "10px" }}
                    />
                </div>
                {searchResults.length > 0 && (
                    <TableView
                        UNSAFE_style={{ marginLeft: "10px" }}
                        aria-label="Care Roles"
                        // selectionMode="multiple"
                        // selectedKeys={selectedAttributes}
                        // onSelectionChange={setSelectedAttributes}
                        height={"55vh"}
                        width={"100%"}
                        density="compact"
                        overflowMode="wrap"
                        // disabledKeys={["1"]}
                    >
                        <TableHeader columns={columns}>
                            {(column) => (
                                <Column
                                    key={column.key}
                                    align={
                                        column.key === "actions"
                                            ? "end"
                                            : "start"
                                    }
                                    width={
                                        column.key === "attributeValue"
                                            ? "60%"
                                            : column.key === "actions"
                                            ? "16.5%"
                                            : "auto"
                                    }
                                    minWidth={
                                        ["permissions"].indexOf(column.key) > -1
                                            ? "40%"
                                            : "10%"
                                    }
                                >
                                    {column.name}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody items={searchResults}>
                            {(item) =>
                                 (
                                    <Row key={item.ldap}>
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                )
                            }
                        </TableBody>
                    </TableView>
                )}
                {!isLoading &&  searchResults.length === 0 && (
                    <View width={"100%"}>
                        <IllustratedMessage>
                            {/* <NotFound /> */}
                            <NotFoundIconSvg />
                            <Heading>No results found</Heading>
                            {/* <Content>No results found</Content> */}
                        </IllustratedMessage>
                    </View>
                )}
            </div>
            {/* </div> */}
        </>
    )
}

export default UserProfile
