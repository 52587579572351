import React, { useEffect, useState, useRef } from 'react';
import Delete from '@spectrum-icons/workflow/Delete';
import Draw from '@spectrum-icons/workflow/Draw';
import 'react-schedule-job/dist/index.css';
import { connect } from 'react-redux';
import './style.css';
import {
  Flex, lightTheme, Provider, SearchField, ActionGroup, Item, Text,
  Content, IllustratedMessage, Heading, TableView, TableHeader, Column,
  TableBody, Cell, Row, ActionButton, Dialog, DialogTrigger, ButtonGroup, Button
} from '@adobe/react-spectrum';
import { SRS_PRODUCT_FAMILY_ADMIN } from '../../constants/constants';
import { ProgressCircle } from '@adobe/react-spectrum';
// import DuplicateErrorDialog from './DuplicateErrorDialog';
// import { check } from 'prettier';
import { addproductCodeTestData, deleteproductCodeTestData, getproductCodeList } from '../../utilities/api/spamCallers-api';
import DeleteSRSProductFamily from './DeleteSRSProductFamily';
import AddSRSProductFamily from './AddSRSProductFamily';
import { renderEmptyState } from '../../utilities/components/CommonUseComponents';

function mapStateToProps(state) {
  return {
    user: state.login.userInfo,
    permissions: (state.login.authorizedAccess != null) ? state.login.authorizedAccess.permissions : [],
    rolesAndPermissions: state.login.rolesAndPermissions,
  };
}
const SRSProductFamily = ({user , rolesAndPermissions}) => {
  const havingDeletePermission = rolesAndPermissions["SRS"]?.delete?.filter((ele) => ele.subService === "SRS PRODUCT FAMILY")[0]?.resources.type
  const havingEditPermission = rolesAndPermissions["SRS"]?.edit?.filter((ele) => ele.subService === "SRS PRODUCT FAMILY")[0]?.resources.type
  // const havingViewPermission = rolesAndPermissions["SRS"]?.view?.filter((ele) => ele.subService === "SRS PRODUCT FAMILY")[0]?.resources.type
  const havingAddPermission = rolesAndPermissions["SRS"]?.add?.filter((ele) => ele.subService === "SRS PRODUCT FAMILY")[0]?.resources.type


  const [allContacts, setAllContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentText, setCurrentText] = useState('');
  const [selectedMobiles, setSelectedMobiles] = useState(new Set());
  const [operation, setOperation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // const columns = [
  //   { name: 'productCode', key: 'productCode' },
  //   // { name:'Monitored', key:'isMonitored'},
  //   { name: 'caseRouting', key: 'caseRouting' },
  //   { name: 'Product Family', key: 'productFamily' }
  // ];

  const columnsForAdmin = [
    { name: 'productCode', key: 'productCode' },
    // { name:'Monitored', key:'isMonitored'},
    { name: 'caseRouting', key: 'caseRouting' },
    { name: 'Product Family', key: 'productFamily' },
    { name: 'Actions', key: 'actions' }
  ];
  const currentContactSelected = useRef('');
  const editData = useRef(false);

  function getAllContacts() {
    setIsLoading(true);
    getproductCodeList()
      .then((contacts) => {
        //console.log({contacts});
        setIsLoading(false);
        setAllContacts(contacts);
        setFilteredContacts(contacts)
      }).catch(() => {
        setIsLoading(false);
        //console.log(error)
        setErrorMessage('Error occured!');
      })
  }

  useEffect(() => {
    getAllContacts();
    // setAllContacts(dummyData);
    // setFilteredContacts(dummyData);
    // setIsLoading(false);
  }, []);



  const filterContacts = (value) => {
    setCurrentText(value);
    const filteredContact = allContacts.filter(contact =>
      contact.productCode?.toLowerCase().includes(value.toLowerCase()) ||
      contact.caseRouting?.toLowerCase().includes(value.toLowerCase()) ||
      contact.productFamily?.toLowerCase().includes(value.toLowerCase())
    );
    //console.log({filteredContact})
    setFilteredContacts(filteredContact)
  }

  const formatDate = (milis) => {

    if (milis == null) {
      return '';
    } else {
      var d = new Date(Number(milis));
      var datestring = '' + d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
        d.getHours() + ":" + d.getMinutes();
      return datestring;
    }
  }

  // const clickOnExpectedRespCell = () => {
  //   console.log('clickOnExpectedRespCell');
    
  // }

  const performAction = (value, contactNumber, caseRouting) => {
    switch (value) {
      case 'add':
        editData.current = null;
        setIsAddDialogOpen(true);
        setOperation("Add")
        break;
      case 'edit':
        editData.current = allContacts.find((d) => (d.productCode == contactNumber && d.caseRouting == caseRouting));
        setIsAddDialogOpen(true);
        setOperation("Edit")
        break;
      case 'delete':
        currentContactSelected.current = `${contactNumber}`;
        setIsDeleteDialogOpen(true);
        break;
      case 'bulkDelete':
        setIsDeleteDialogOpen(true);
        break;
    }
  }

  function deleteContacts(usernamesToDelete) {
    setIsLoading(true);
    //console.log({usernamesToDelete});
    deleteproductCodeTestData(usernamesToDelete,user?.userid).then(()=>{
      getAllContacts();
    }).catch(()=>{
      //console.log({err});
      setIsLoading(false);
    }).finally(()=>{
      setIsLoading(false);
    });
  }

  const closeDeleteDialog = (isDeletedConfirmed) => {
    setIsDeleteDialogOpen(false);
    if (isDeletedConfirmed) {
      let usernamesToDelete = [currentContactSelected.current];
      if (selectedMobiles == 'all') {
        usernamesToDelete = filteredContacts.map((ele)=> `${ele.productCode}`);
      } else if (!currentContactSelected.current && selectedMobiles.size > 0) {
        usernamesToDelete = [...selectedMobiles];
        const formattedData = filteredContacts.map((ele,index)=> usernamesToDelete.includes(`${index}`)?`${ele.productCode}`:null).filter(item => item != null);
        usernamesToDelete = formattedData;
      }
      deleteContacts(usernamesToDelete.join());
    }
  }

  const checkDuplicateEntry = (data) => {
    if (allContacts.filter(c => (c.productCode == data.productCode && c.caseRouting == data.caseRouting)).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const closeAddContactDialog = (isAddConfirmed, contactData) => {
    setIsAddDialogOpen(false);
    // console.log({isAddConfirmed, contactData});
    if (isAddConfirmed) {
      setIsLoading(true);
      var urlencoded = new URLSearchParams();
      urlencoded.append("username", contactData.productCode);
      urlencoded.append("remark", contactData.caseRouting);
      urlencoded.append("createdBy", user?.userid);
      urlencoded.append("createdDate", new Date().getTime());

      if (editData.current !== null && operation === "Edit") {
        deleteproductCodeTestData(`${editData.current.productCode}`,user?.userid).then(
         ()=>{
          // contactData.caseRouting = JSON.parse(contactData.caseRouting,true);
          //console.log({contactData});
          addproductCodeTestData(contactData,user?.userid).then(()=>{
            //console.log({resp});
            setIsLoading(false);
            getAllContacts();
          }).catch(()=>{
            //console.log({error});
            setIsLoading(false);
          });
         } 
        ).catch(()=>{
          setIsLoading(false);
        });
      }

      if (operation === "Add") {
        if (checkDuplicateEntry(contactData)) {
          setIsLoading(false);
        }
        else {
          //console.log({contactData});
          addproductCodeTestData(contactData,user?.userid).then(()=>{
            //console.log({resp});
            setIsLoading(false);
            getAllContacts();
          }).catch(()=>{
            //console.log({error});
            setIsLoading(false);
          });
        }
      }
    }
  }


  const getCell = (columnKey, item) => {
    switch (columnKey) {
      case 'actions':
        return (
          <Cell>
            <ActionGroup onAction={(value) => performAction(value, item.productCode, item.caseRouting)} disabledKeys={[ !havingDeletePermission && "delete" , !havingEditPermission && "edit"]}>
              <Item key="edit">
                <Draw />
              </Item>
              <Item key="delete">
                <Delete />
              </Item>
            </ActionGroup>
          </Cell>
        );
      case 'createdDate':
        return (
          <Cell>{formatDate(item[columnKey])}</Cell>
        )
        case 'productFamily':
            return (
                <Cell UNSAFE_style="border:1px solid; cursor:pointer;">
                {/* <TooltipTrigger placement="end">
                <ActionButton aria-label="Save" isQuiet onPress={clickOnExpectedRespCell}>{JSON.stringify(item[columnKey], undefined, 2)}</ActionButton>
                  <Tooltip>{JSON.stringify(item[columnKey], undefined, 2)}</Tooltip>
                </TooltipTrigger> */}

                  <DialogTrigger>
                    <ActionButton isQuiet> {item[columnKey]}</ActionButton>
                    {(close) => (
                      <Dialog>
                        <Content>
                          <Text>
                          {item[columnKey]}
                          </Text>
                        </Content>
                        <ButtonGroup>
                          <Button variant="secondary" onPress={close}>Close</Button>   
                        </ButtonGroup>
                      </Dialog>
                    )}
                  </DialogTrigger>
                </Cell>
            )
        // case 'isMonitored':
        //   return (
        //     <Cell>{(item[columnKey])?(item[columnKey]):'false'}</Cell>
        //   )
      default:
        return (
          <Cell>{item[columnKey]}</Cell>
        );
    }
  }

  // const closeErrorDialog = () => {
  //   setShowDuplicateError(false);
  // }

  return (
    <>

      {isLoading ? <div className={isLoading ? 'display-block' : 'display-none'}
        style={{ paddingTop: '10%', paddingLeft: '45%', position: 'absolute', zIndex: '10' }}
      >
       {isLoading && <ProgressCircle aria-label="Loading…" isIndeterminate size='L' />}
        
      </div> : null}

      <div align="center">
        <Heading variant="pageTitle">SRS Product Family</Heading>
      </div>

      <div
        style={{
          padding: '25px',
          border: '0.8px solid',
          borderRadius: '0.7em',
          marginTop: '30px',
          width: '75vw',
          margin: '0 auto'
        }}
      >

        <Provider theme={lightTheme}>
          <Flex direction="row" gap="size-100" UNSAFE_className="searchMobile">
            <SearchField isQuiet
              onClear={() => setCurrentText('')}
              onChange={filterContacts}
              placeholder="Search"
              value={currentText}
              width={'20%'}
            />
            {(
              <ActionGroup width={'80%'} alignItems="end" onAction={(value) => performAction(value, null)} disabledKeys={[!havingAddPermission && "add", !havingDeletePermission && "bulkDelete"]}>
                <Item key="add">+ Add</Item>
                <Item key="bulkDelete" ><Delete /><Text>Delete</Text></Item>
              </ActionGroup>
            )} 

          </Flex>
          <Flex width="100%" height="100%" direction="column">
            <TableView aria-label="spam contacts"
              selectionMode={havingDeletePermission? "multiple" : "none"}
              selectedKeys={selectedMobiles}
              onSelectionChange={setSelectedMobiles}
              renderEmptyState={renderEmptyState}
              density="compact"
              height="45vh"
              // overflowMode='wrap'
              UNSAFE_className={'dualSystemTable'}
            >
              <TableHeader columns={columnsForAdmin}>
                {(column) => (
                  <Column key={column.key} align={column.key === 'actions' ? 'end' : 'start'}>
                    {column.name}
                  </Column>
                )}
              </TableHeader>
              <TableBody>
                {filteredContacts.map((item,index)=> (
                  <Row key={index}>
                    {(columnKey) => getCell(columnKey, item)}
                  </Row>
                ))}
              </TableBody>
            </TableView>
          </Flex>
        </Provider>
      </div>


      {errorMessage && (<div
        style={{
          fontSize: 'large', color: 'red', marginTop: '1%',
        }}
        align="center"
      >
        {' '}
        <b> {errorMessage}</b>
      </div>)}

      {isdeleteDialogOpen &&
        <DeleteSRSProductFamily
          isDialogOpened={isdeleteDialogOpen}
          handleCloseDialog={(isDeletedConfirmed) => closeDeleteDialog(isDeletedConfirmed)}
        />
      }

      {isAddDialogOpen &&
        <AddSRSProductFamily
          isDialogOpened={isAddDialogOpen}
          editData={editData.current}
          operation={operation}
          handleCloseDialog={(isAddConfirmed, contactData) => closeAddContactDialog(isAddConfirmed, contactData)}
          existingData={allContacts}
        />
      }

      {/* {showDuplicateError &&
        <DuplicateErrorDialog
          isDialogOpened={showDuplicateError}
          handleCloseDialog={() => closeErrorDialog()}
        />
      } */}
    </>
  );
}

export default connect(mapStateToProps)(SRSProductFamily);
