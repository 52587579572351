import React, { useEffect, useState } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Flex, View, TextField, TagGroup, Item, Text
} from '@adobe/react-spectrum';

const AddAttributeList = (props) => {
    const [attributeName, setAttributeName] = useState('');
    const [attributeValues, setAttributeValues] = useState([]);
    const [attributeValueText, setAttributeValueText] = useState('');

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    }
    const onAddConfirmHandler = () => {
        const newAttributeValues = [...attributeValues,{id:attributeValues.length , name : attributeValueText}]
        let modifiedAttributeValues = newAttributeValues.map(item=>item.name).join();
        if(modifiedAttributeValues.endsWith(',')){
            modifiedAttributeValues = modifiedAttributeValues.slice(0,-1);
        }
        const finalObject = {attributeName, attributeValue: modifiedAttributeValues}
        props.handleCloseDialog(true,finalObject);
    }

    const onRemove = (keys) => {
        setAttributeValues(prevItems => prevItems.filter((item) => !keys.has(item.id)));
      };

    const insertValue = (e) => {
        const {key} = e;
        if(key=="Enter"){
            const insertedValue = {id:attributeValues.length, name:attributeValueText}
            setAttributeValues((previous) => [...previous, insertedValue]);
            setAttributeValueText('');
        }
    }
    const renderEmptyState = () => (<Text>No Attribute Values</Text>)
    useEffect(()=>{
        const {editData} = props;
        if(editData){
            const {attributeNames, attributeValue} = editData;
            setAttributeName(attributeNames);
            setAttributeValues(attributeValue.map((item,index)=>({id:index, name:item})));
        }
    },[])

    const checkNameDuplicacy = () => {
        if(!props.editData){
            return props.attributeData.some(val => val.attributeNames === attributeName)
        }
    }

    const checkValueDuplicacy = () => {
        return props.attributeData.some(val => val.attributeValue.some(val1 => val1 === attributeValueText))
    }
return (
    <Provider theme={lightTheme}>
        <div>
            <DialogTrigger
                type="modal"
                mobileType="modal"
                isOpen = {props.isDialogOpened}
                defaultOpen= {false}>
                <ActionButton isHidden isQuiet></ActionButton>
                <Dialog size="L">
                    <Heading>Add Attribute List</Heading>
                    <Divider />
                    <Content>
                        <Flex direction={'row'} gap={'size-100'}>
                        <Flex width={'100%'}> 
                            <View width={'100%'}><TextField aria-label='Attribute Name' label='Attribute Name' value={attributeName} onChange={setAttributeName} width={'100%'} isDisabled={props.editData} isQuiet/></View>
                        </Flex>
                        <Flex direction={'column'} width={'100%'} rowGap={'size-100'}>
                            <View>
                                <TextField aria-label='Attribute Value text' label='Attribute Value' value={attributeValueText} onChange={setAttributeValueText} onKeyDown={(e)=>insertValue(e)} width={'100%'} isQuiet/>
                            </View>
                            <View>
                                <TagGroup aria-label='attributeValues' items={attributeValues} onRemove={onRemove} renderEmptyState={renderEmptyState}>
                                    {item => <Item>{item.name}</Item>}
                                </TagGroup>
                            </View>
                        </Flex>
                        </Flex>
                    </Content>
                    <ButtonGroup>
                        <Button variant="secondary" onPress={onCloseHandler}>Cancel</Button>
                        <Button variant="secondary" onPress={onAddConfirmHandler} isDisabled={attributeName === "" || (attributeValueText === "" && !attributeValues.length ) || checkNameDuplicacy() || checkValueDuplicacy()}>Confirm</Button>
                    </ButtonGroup>
                </Dialog>
            </DialogTrigger>
        </div>
    </Provider>
    );
                    
};

export default AddAttributeList;