import React, { useState, useEffect } from "react"
import Delete from "@spectrum-icons/workflow/Delete"
import {
    addDefaultConfig,
    editDefaultConfig,
} from "../../../utilities/api/phoenix-api"
import {
    Button,
    Flex,
    lightTheme,
    Provider,
    Content,
    Heading,
    ActionButton,
    DialogTrigger,
    Dialog,
    Divider,
    TextField,
    Footer,
    View,
    ButtonGroup,
    Header,
    Checkbox
} from "@adobe/react-spectrum"
import Copy from "@spectrum-icons/workflow/Copy"
import Add from "@spectrum-icons/workflow/Add"
import Undo from "@spectrum-icons/workflow/Undo"
import { notify } from "../../../constants/constants"

function DynamicFieldForm(props) {
    const sideArrayObject = { key: "", value: "" }
    const sideArray = [sideArrayObject]
    const [mainArray, setMainArray] = useState([sideArray]);
    const [objectSelected, setObjectSelected] = useState(new Set([]));

    const addNewKeyValuePair = (sideArrayIndex) => {
        mainArray[sideArrayIndex].push(sideArrayObject);
        setMainArray(() => [...mainArray]);

    }

    const addNewobject = () => {
        //console.log('Test',sideArray);
        setMainArray([...mainArray, sideArray]);
        //console.log({mainArray});
    }

    const updateValue = (mainIndex, objectIndex, type, value) => {
        const data = JSON.parse(JSON.stringify(mainArray));
        data[mainIndex][objectIndex][type] = value;
        setMainArray(()=>[...data]);
    };

    const deleteKeyValuePair = (objIndex, keyIndex) => {
        const data = JSON.parse(JSON.stringify(mainArray));
        data[objIndex].splice(keyIndex, 1);
        //console.log({data});
        setMainArray(()=>[...data]);
    }

    const cloneObject = (objIndex, obj) => {
        setMainArray([...mainArray, obj]);
    }

    const checkMainValue = (keys) => {
        const value = mainArray.map((ele)=>{
            return ele.map(objectlist => {
                if(objectlist.key)
                    return {[objectlist.key]:objectlist.value}
                else 
                    return null;
            }).filter(ele => ele !== null ).reduce(function(result, current) {
                return Object.assign(result, current);
              }, {});
        });
        return {attributeValues:value,attributeKey:keys};
    };

    const [inputFields, setInputFields] = useState([
        {
            agent_group_name: "",
            config_key: "",
            config_values: [{ key: "", value: "" }],
        },
    ])

    useEffect(() => {
        if (props.editData) {
            //console.log(props.editData, {props});
            const tempObj = props.editData.config_values
                .map((entry) => {
                    const keyValues = Object.entries(entry)
                    return keyValues.map(([key, value]) => ({ key, value }))
                })
                .flat();
                const mappedData = props.editData.config_values
                .map((entry) => {
                    const keyValues = Object.entries(entry)
                    return keyValues.map(([key, value]) => ({ key, value }))
                });
                setMainArray([...mappedData]);
                //console.log({tempObj,mappedData}, props.editData.config_key);
            setInputFields([
                {
                    agent_group_name: props.title || "",
                    config_key: props.editData.config_key || "",
                    config_values: tempObj || [{ key: "", value: "" }],
                },
            ])
        }
    }, [props.editData])

    // const addFields = () => {
    //     const updatedFields = [...inputFields]
    //     const newInnerObj = { key: "", value: "" }
    //     updatedFields[0].config_values.push(newInnerObj)
    //     setInputFields(updatedFields)
    // }

    // const deleteFields = (index) => {
    //     const updatedFields = [...inputFields]
    //     updatedFields[0].config_values = updatedFields[0].config_values.filter(
    //         (_, i) => i !== index
    //     )
    //     setInputFields(updatedFields)
    // }

    const isValid = React.useMemo(() => {
        //console.log({inputFields});
        return (
            inputFields[0]?.agent_group_name?.trim() !== "" &&
            inputFields[0]?.config_key.trim() !== "" 
            // &&
            // inputFields[0]?.config_values.every(
            //     (configValue) =>
            //         configValue.key.trim() !== "" &&
            //         configValue.value.trim() !== ""
            // )
        )
    }, [inputFields])

    const handleFormSubmit = () => {
        const data = inputFields[0];
        const mappedConfig = checkMainValue(data.config_key);
        if(props.editData){
            editDefaultConfig(props.userid, mappedConfig).then((res)=>{
                notify(res,'success')
                props.setIsAddDialogOpen(false);
                props.getAllLdapMethod();
            }).catch(async (error)=>{
                const value = await error
                notify('Error in creating','error')
                //console.log("error", value)
            });
        }else{
            addDefaultConfig(props.userid, mappedConfig).then((resp)=>{
                //console.log({resp});
                if(resp.includes('already exists')){
                    notify(resp,'warn');
                }else{
                    notify(resp,'success');
                    props.setIsAddDialogOpen(false);
                    props.getAllLdapMethod();
                }
            }).catch(()=>{
                notify('Error in creating','error')
            })
        }
    }

    const handleAboveFeilds = (name, value) => {
        const updatedFields = [...inputFields]
        updatedFields[0][name] = value
        setInputFields(updatedFields)
    }

    //const handleFormChange = (inputName, event, index) => {
    //     const value = event
    //     const updatedFields = [...inputFields]
    //     updatedFields[0].config_values[index][inputName] = value
    //     setInputFields(updatedFields)
    // }

    // const performAction = (value, index) => {
    //     switch (value) {
    //         case "add":
    //             addFields()
    //             // editData.current = null;
    //             // setIsAddDialogOpen(true);
    //             // setOperation("Add")
    //             break
    //         case "edit":
    //             // editData.current =  allContacts.find((d) => (d.ruleId == contactNumber));
    //             // setIsAddDialogOpen(true);
    //             // setOperation("Edit")
    //             break
    //         case "delete":
    //             deleteFields(index)
    //             // currentContactSelected.current = `${contactNumber}`;
    //             // setIsDeleteDialogOpen(true);
    //             break
    //         case "bulkDelete":
    //             // setIsDeleteDialogOpen(true);
    //             break
    //     }
    // }

    useEffect(()=>{
        handleAboveFeilds("agent_group_name",props.title);
        handleAboveFeilds("config_key",props?.editData?.config_key || "");
    },[]);

    const selectedObjectsForDelete = (index) => {
        setObjectSelected(prev => {
            if(prev.has(index)){
                prev.delete(index);
                return new Set([...Array.from(prev)]);    
            }
            return new Set([...Array.from(prev),index]);
        });
    }

    const deleteSelectedObjects = () => {
        const arrayofIndexes = Array.from(objectSelected);
        const data = JSON.parse(JSON.stringify(mainArray)).filter((obj,index) => !arrayofIndexes.includes(index));
        setMainArray(()=>[...data]);
        setObjectSelected(new Set([]));
    };

    const resetGroupForm = () => {
        if(props.editData){
            props.editData.config_values
            .map((entry) => {
                const keyValues = Object.entries(entry)
                return keyValues.map(([key, value]) => ({ key, value }))
            })
            .flat();
            const mappedData = props.editData.config_values
            .map((entry) => {
                const keyValues = Object.entries(entry)
                return keyValues.map(([key, value]) => ({ key, value }))
            });
            setMainArray([...mappedData]);
        }else{
            setMainArray([sideArray]);
        }
    }

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="L">
                        <Heading>Default Config</Heading>
                        <Divider />
                        <Content>
                            <Flex direction={"row"} gap={"size-1000"}>
                                {/* <TextField
                                    label="Default Config Key"
                                    name="agent_group_name"
                                    value={inputFields[0]?.agent_group_name || props.title}
                                    onChange={(value) => {
                                        handleAboveFeilds(
                                            "agent_group_name",
                                            value
                                        )
                                    }}
                                    isDisabled
                                />  */}

                                <TextField
                                    label="Attribute Key"
                                    name="config_key"
                                    value={inputFields[0]?.config_key}
                                    onChange={(value) => {
                                        handleAboveFeilds("config_key", value)
                                    }}
                                    isDisabled={props.operation == "Edit"}
                                />
                            </Flex>
                            <Divider size="S" marginY={10} />
                            {/* <div style={{ color: "#d0342c" }}>
                                {warning && `${warning}`}
                            </div> */}
                            <View>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>
                            <Header><strong>Config Value</strong></Header>
                        </View>
                        <View>
                            <ButtonGroup UNSAFE_className="noBorder">
                                <Button aria-label="AddObject"  variant="secondary" staticColor="black" style="fill" onPress={()=>addNewobject()}><Add /></Button>
                                <Button aria-label="DeleteSelectedObjects"  variant="secondary" staticColor="black" style="fill" onPress={()=>deleteSelectedObjects()} isDisabled={!objectSelected.size} >
                                    <Delete />
                                </Button>
                                <Button aria-label="ResetGroupValue"  variant="secondary" staticColor="black" style="fill" onPress={() => resetGroupForm()}><Undo /></Button>
                            </ButtonGroup>
                        </View>
                    </Flex>
                    <Flex direction={'column'} justifyContent={'center'} gap={'size-100'} marginTop={'size-100'}>
                    {mainArray.map((obj, objIndex) => {                                    
                            return (
                                <div key={objIndex} className={(objectSelected.has(objIndex))?"cardSection active":"cardSection"}>
                                    <section className="checkboxSection">
                                        <Checkbox aria-label="selectObject" isSelected={objectSelected.has(objIndex)} onChange={()=>selectedObjectsForDelete(objIndex)}></Checkbox>
                                    </section>
                                    <Divider size='S' orientation='vertical' />
                                    <section className="FormSection">
                                    {(obj.length >= 1 ) && obj.map((_keyVal, keyIndex)=>{
                                        return (
                                            <View key={keyIndex} UNSAFE_className="feild">
                                            <Flex gap={'size-100'}>
                                                <TextField
                                                width={'35%'}
                                                aria-label={"key"+keyIndex}
                                                key={"key"+keyIndex} 
                                                value={mainArray[objIndex][keyIndex].key}
                                                onChange={(value) => updateValue(objIndex, keyIndex,"key", value)}
                                                isQuiet
                                            />
                                            <strong>:</strong>
                                            <TextField
                                                width={'35%'} 
                                                aria-label={"value"+keyIndex}
                                                key={"value"+keyIndex} 
                                                value={mainArray[objIndex][keyIndex].value} 
                                                onChange={(value) => updateValue(objIndex, keyIndex,"value", value)}
                                                isQuiet
                                            />
                                            <View width={'20%'}>
                                            {(obj.length > 1) && <Button aria-label="addKeyValue"  variant="secondary" staticColor="black" style="fill" onPress={()=>deleteKeyValuePair(objIndex, keyIndex)}><Delete/></Button>}
                                            {(obj.length - 1 == keyIndex) && <Button aria-label="addKeyValue"  variant="secondary" staticColor="black" style="fill" onPress={()=>addNewKeyValuePair(objIndex)}><Add /></Button>}
                                            </View>
                                                {/* </section>  */}
                                            </Flex>
                                        </View>
                                        );
                                    })}
                                    </section>
                                    <Divider size='S' orientation='vertical' />  
                                    <section className="actionsSection">
                                                <Button aria-label="Clone Object" variant="secondary" staticColor="black" style="fill" onPress={()=>cloneObject(objIndex, obj)}><Copy /></Button>
                                                </section>
                                </div>
                            )
                        })}
                    </Flex>
                    </View>
                        </Content>
                        <Footer alignSelf={"flex-start"}>
                            <Flex flexBasis={"100%"}>
                                <View>
                                    <ButtonGroup>
                                        <Button
                                            variant="secondary"
                                            onPress={() =>
                                                props.setIsAddDialogOpen(false)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onPress={handleFormSubmit}
                                            isDisabled={isValid ? false : true}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </View>
                            </Flex>
                        </Footer>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default DynamicFieldForm
