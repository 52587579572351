import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, TextField, View,Switch
} from '@adobe/react-spectrum';

const AddDxocEmergency = (props) => {
    const [language, setLanguage] = useState('');
    const [emergency, setEmergency] = useState(false);

    useEffect(() => {
        if (props.editData) {
            setLanguage(props.editData.language);
            setEmergency(props.editData.emergency === "true" ? true : false)
        }
    }, []);

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    }


    const onSaveHandler = () => {
        props.handleCloseDialog(true,language.trim(),emergency);
    }


    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="S">
                        <Heading>{`${props.operation} Emergency`}</Heading>
                        <Divider />
                        <Content>

                            <Form isRequired>
                                <View>Language</View>
                                <TextField autoFocus
                                    value={language}
                                    onChange={setLanguage}
                                    isQuiet
                                    isDisabled={props?.editData}
                                />

                                <div style={{ marginTop: '30px' }}></div>

                                <View>Emergency</View>
                                <Switch
                                 isSelected={emergency}
                                 onChange={setEmergency}
                                />
                            
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>Cancel</Button>
                            <Button variant="primary" onPress={onSaveHandler} isDisabled={false}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddDxocEmergency;