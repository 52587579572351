import React from "react"
import { Flex, Text, ToggleButton } from "@adobe/react-spectrum"

const AlertOptions = (props) => {
    return (
        <Flex
            direction="row"
            UNSAFE_className="alertOptionWrapperMargin"
            width="size-1600"
            gap="size-50"
        >
            <ToggleButton
                defaultSelected
                isSelected={props.email}
                onChange={props.onEmailChange}
                isDisabled={props.disabled}
                height={"20px"}
                aria-label="Pin"
            >
                <Text UNSAFE_className="font11">Email</Text>
            </ToggleButton>
            <ToggleButton
                defaultSelected
                isSelected={props.slack}
                onChange={props.onSlackChange}
                isDisabled={props.disabled}
                height={"20px"}
                aria-label="Pin"
            >
                <Text UNSAFE_className="font11">Slack</Text>
            </ToggleButton>
        </Flex>
    )
}

export default AlertOptions
