// Component for handling Details View + Edit
import React, { useEffect, useState, useMemo } from "react"
import {
    Flex,
    TabList,
    TabPanels,
    Tabs,
    View,
    Item,
    Heading,
    Button,
    IllustratedMessage,
    Content,
    TextField,
    TextArea,
    ButtonGroup,
    ProgressCircle,
    Text as Write,
} from "@adobe/react-spectrum"
// import PermissionAccordion from "./PermissionAccordion"
import { notify, tabsRoutingList } from "../constants/constants"
import { getAllGroupNames } from "../utilities/api/phoenix-api"
import {
    getMonitoringQueuesNew,
    getMonitoringBUNew,
} from "../utilities/api/alertService-api"
import { PermissionAccordion2 } from "./PermissionAccordion2"
import { ToastContainer } from "react-toastify"
import Alert from "@spectrum-icons/workflow/Alert"

// Const for the form data structure
const permissionSingle = {
    tab: "",
    resources: {},
}

const instanceObject = {
    id: "",
    name: "",
}

const permissionSet = {
    add: [permissionSingle],
    view: [permissionSingle],
    edit: [permissionSingle],
    delete: [permissionSingle],
    clone: [permissionSingle],
    enforceUpdate: [permissionSingle],
}

const policyStructure = {
    service: "",
    permissions: { ...permissionSet },
    instances: [instanceObject],
}

/**
 *
 */
const formDataStructure = {
    name: "",
    description: "",
    policies: [policyStructure],
}

const NoRecords = () => {
    return (
        <IllustratedMessage
            height={"300px"}
            marginTop={"size-100"}
            UNSAFE_style={{ background: "#F8F8F8" }}
        >
            <Heading level={6}>No Policies Assigned</Heading>
            <Content>Click On Add Policies to Add new permission</Content>
        </IllustratedMessage>
    )
}

const uniqueByName = (array) => {
    const unique = array.filter(
        (obj, index, self) =>
            index === self.findIndex((t) => t.service === obj.service)
    )
    return unique
}

const PanelView = ({
    isEdit = false,
    resultantArray = [],
    formDataLiftingSet,
    formData,
    queueList,
    groupList,
    buList,
}) => {
    const addNewPolicyOnPermission = () => {
        formDataLiftingSet((previous) => {
            previous.policies = [
                ...previous.policies,
                {
                    service: "",
                    permissions: { ...permissionSet },
                },
            ]

            return JSON.parse(JSON.stringify(previous))
        })
    }
    useEffect(() => {}, [resultantArray, queueList, groupList, buList])

    return (
        <Flex
            direction={"column"}
            width={"100%"}
            UNSAFE_style={{ overflowY: "hidden" }}
        >
            <Flex
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                UNSAFE_style={{ padding: "0px 10px" }}
            >
                <Heading level={4}>Services</Heading>
                {!isEdit && (
                    <Button
                        variant="cta"
                        onPress={() => addNewPolicyOnPermission()}
                    >
                        Add Service
                    </Button>
                )}
            </Flex>
            {!resultantArray.length && NoRecords()}
            <Flex
                width={"100%"}
                marginTop={"size-100"}
                direction={"column"}
                gap={"size-200"}
                UNSAFE_style={{
                    padding: "0px 10px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                }}
            >
                {/* Accordian Contents */}
                {!!resultantArray.length &&
                    uniqueByName(resultantArray).map(
                        (values) =>
                            console.log("Values Line 128") || (
                                <PermissionAccordion2
                                    width={"100%"}
                                    policy={values}
                                    formDataLifting={formDataLiftingSet}
                                    disabledServices={resultantArray.map(
                                        (res) => res.service
                                    )}
                                    formData={formData}
                                    queueList={queueList}
                                    groupList={groupList}
                                    buList={buList}
                                    isEdit={isEdit}
                                />
                            )
                    )}
                {/* Accordian Contents End*/}
            </Flex>
        </Flex>
    )
}

const PermissionDetails = ({
    isEdit = false,
    selectedItem = {},
    closeModal,
    alreadyExistingPolicies = [],
}) => {
    // const [permission, setPermission] = useState('view');
    const [formData, setFormData] = useState()
    const [groupList, setGroupList] = useState([])
    const [queueList, setQueueList] = useState([])
    const [buList, setBuList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [alreadyExist, setAlreadyExist] = useState(false)
    const isEditing = !!selectedItem

    useEffect(() => {
        if (!isEditing && formData?.name) {
            const isExist = alreadyExistingPolicies?.includes(formData?.name?.toLowerCase().trim())
            setAlreadyExist(isExist)
        } else {
            setAlreadyExist(false)
        }
    }, [formData?.name, isEditing, alreadyExistingPolicies])    

    const isFormInvalid = () => {
        const hasValidNameAndDescription = formData?.name && formData.name.trim() !== "" && formData?.description;
        
        const hasValidPolicies = formData?.policies?.every((policy) => {
            const hasService = policy.service && policy.service.trim() !== "";
            
            const hasPermissions = Object.values(policy.permissions || {}).some((permissionArray) => 
                Array.isArray(permissionArray) && permissionArray.length > 0 && permissionArray.some((permission) => 
                    permission.tab && permission.tab.trim() !== ""
                )
            );
    
            return hasService && hasPermissions;
        });
    
        return alreadyExist || !hasValidNameAndDescription || !hasValidPolicies || !formData?.policies?.length;
    };
    

    const providerValue = () => {
        let resultantArray = []
        formData?.policies?.forEach((policy) => {
            if (policy.service === "SRS" || policy.service === "ADMIN") {
                // Keep only 'add', 'view', 'delete' and 'edit' permissions for SRS
                const filteredPermissions = {
                    add: policy.permissions.add,
                    view: policy.permissions.view,
                    edit: policy.permissions.edit,
                    delete: policy.permissions.delete,
                }
                resultantArray.push({
                    ...policy,
                    permissions: filteredPermissions,
                })
            } else {
                // If service is not SRS, add policy as it is
                resultantArray.push(policy)
            }
        })

        return resultantArray
    }

    // const disabledServices = useMemo(() => providerValue(), [
    //     "view",
    //     "edit",
    //     "delete",
    //     "additionalPermissions",
    //     "clone",
    //     "enforceUpdate",
    // ])

    const getGroupsQueuesAndBus = () => {
        setIsLoading(true)
        const dataListArray = [
            getMonitoringQueuesNew(),
            getMonitoringBUNew(),
            getAllGroupNames(),
        ]
        Promise.allSettled(dataListArray)
            .then(([queuesInstanceList, buInstanceList, groupInstanceList]) => {
                if (groupInstanceList.status === "fulfilled") {
                    setGroupList(
                        groupInstanceList.value
                            .slice(1, -1)
                            .split(",")
                            .map((group) => group.trim())
                            .map((group) => ({ id: group, name: group }))
                    )
                }
                if (queuesInstanceList.status === "fulfilled") {
                    setQueueList(queuesInstanceList.value)
                }
                if (buInstanceList.status === "fulfilled") {
                    setBuList(buInstanceList.value)
                }
            })
            .catch((err) => {
                console.log({ err })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateFormData = (key, data) => {
        const updatedValue = Object.assign(formData, { [key]: data })
        setFormData({ ...updatedValue })
    }

    useEffect(() => {
        getGroupsQueuesAndBus()
        if (selectedItem) {
            const updatedValue = JSON.stringify(selectedItem)
                .replaceAll("services", "policies")
                .replaceAll("subService", "tab")
            setFormData(JSON.parse(updatedValue))
        } else {
            setFormData(JSON.parse(JSON.stringify(formDataStructure)));
        }
        // setPermission("view");
    }, [selectedItem])

    const handleSubmit = () => {
        const newFromData = JSON.parse(JSON.stringify(formData));
        const trimmedData = {
            ...newFromData,
            name: newFromData.name.trim(),
            description: newFromData.description.trim()
        }        
        closeModal(trimmedData);
        setFormData(JSON.parse(JSON.stringify(formDataStructure)));
    };

    const handleCancel = () => {
        closeModal(false);
        setFormData(JSON.parse(JSON.stringify(formDataStructure)));
    };

    return (
        <>
            {isLoading && (
                <Flex
                    justifyContent={"center"}
                    alignContent={"center"}
                    width={"100%"}
                    height={"100%"}
                    marginTop={"15%"}
                    UNSAFE_className="dialogue-container"
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        size="L"
                        isIndeterminate
                    />
                </Flex>
            )}
            {!isLoading && (
                <Flex UNSAFE_className="mainBoxPermissionFlex dialogue-container">
                    <Flex
                        UNSAFE_className="leftsideDetails"
                        alignContent={"center"}
                        direction={"column"}
                        gap={"size-100"}
                        justifyContent={"space-between"}
                    >
                        {/* Left Details section start */}
                        <Flex direction={"column"} gap={"size-100"}>
                            {/* <View>Redirect Back</View> */}
                            <View marginBottom={"-14px"}>
                                <h6>Policy Name</h6>
                            </View>
                            <TextField
                                value={formData?.name || ""}
                                width={"100%"}
                                onChange={(value) =>
                                    updateFormData("name", value)
                                }
                                isDisabled={isEditMode}
                                placeholder="Policy"
                            />
                            <View marginBottom={"-14px"}>
                                <h6>Description</h6>
                            </View>
                            <TextArea
                                value={formData?.description || ""}
                                width={"100%"}
                                height={"300px"}
                                onChange={(value) =>
                                    updateFormData("description", value)
                                }
                                isDisabled={isEditMode}
                                placeholder="write few lines about the policy..."
                            />
                        </Flex>
                        <View>
                            <View>
                                {alreadyExist && (
                                    <Write
                                        UNSAFE_style={{
                                            color: "#8B5700", // Darker, more subdued brown for text
                                            fontWeight: 600,
                                            backgroundColor: "#FFF4D4", // Softer yellow background
                                            padding: "8px",
                                            borderRadius: "4px",
                                            display: "block",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <Alert size="XS" /> Policy name already
                                        exists
                                    </Write>
                                )}
                            </View>

                            <ButtonGroup width={"100%"}>
                                <Button
                                    variant="cta"
                                    width={"100%"}
                                    onPress={handleSubmit}
                                    isDisabled={isFormInvalid()}
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant=""
                                    width={"100%"}
                                    onPress={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </View>
                        {/* Left Details section end */}
                    </Flex>

                    {/* RIGHT SECTION */}
                    <Flex UNSAFE_className="rightsideDetails" gap={"size-100"}>
                        <PanelView
                            isEdit={isEditMode}
                            resultantArray={providerValue()}
                            formDataLiftingSet={setFormData}
                            queueList={queueList}
                            groupList={groupList}
                            buList={buList}
                        />

                        {/* Main File & View Components */}
                        {/* Selected Tabs */}
                        {/* <Tabs aria-label="Permissions Tabs for forms" marginStart={'size-100'} selectedKey={permission} onSelectionChange={setPermission} UNSAFE_style={{overflow:'hidden'}}>
                    <TabList>
                        <Item key="view">View</Item>
                        <Item key="add">Add</Item>                        
                        <Item key="edit">Edit</Item>
                        <Item key="delete">Delete</Item>
                        <Item key="additionalPermissions">Additional</Item>
                    </TabList>
                    <TabPanels>
                        <Item key="view">
                            <PanelView  permission={permission} isEdit={isEditMode} resultantArray={providerValue(permission)} formDataLiftingSet={setFormData} queueList={queueList} groupList={groupList}/>
                        </Item>
                        <Item key="add">
                            <PanelView  permission={permission} isEdit={isEditMode} resultantArray={providerValue(permission)} formDataLiftingSet={setFormData} queueList={queueList} groupList={groupList}/>
                        </Item>                        
                        <Item  aria-label="edit" key="edit">
                            <PanelView  permission={permission} isEdit={isEditMode} resultantArray={providerValue(permission)} formDataLiftingSet={setFormData} queueList={queueList} groupList={groupList}/>
                        </Item>
                        <Item aria-label="delete" key="delete">
                            <PanelView  permission={permission} isEdit={isEditMode} resultantArray={providerValue(permission)} formDataLiftingSet={setFormData}  queueList={queueList} groupList={groupList}/>
                        </Item>
                        <Item aria-label="additionalPermissions" key="additionalPermissions">
                            <PanelView  permission={permission} isEdit={isEditMode} resultantArray={providerValue(permission)} formDataLiftingSet={setFormData} formData={formData} queueList={queueList} groupList={groupList}/>
                        </Item>
                    </TabPanels>
                </Tabs> */}

                        {/* <Button
                            UNSAFE_style={{
                                position: "absolute",
                                right: "70px",
                                marginTop: "3px",
                            }}
                            onPress={() => setIsEditMode(!isEditMode)}
                        >
                            {isEditMode ? "Edit" : "View"}
                        </Button> */}

                        {/* Selected Tabs End */}
                        {/* Main File & View Components End*/}
                    </Flex>
                </Flex>
            )}
            <ToastContainer enableMultiContainer containerId="modalToast" />
        </>
    )
}

export default PermissionDetails
