import React, { useEffect, useState } from "react"
import { Item, TabList, TabPanels, Tabs, Well } from "@adobe/react-spectrum"
import { connect } from "react-redux"
import AgentGroups from "./ChildComponents/AgentGroups/AgentGroupList"
import {
    getAllAgentGroupList,
    getLoggedInUserIsAdmin,
    getPermissionsApi,
} from "../utilities/api/phoenix-api"
import AgentGroupMapping from "./ChildComponents/GroupConfig/AgentGroupMapping"
import DefaultConfigMapping from "./ChildComponents/DefaultConfig/DefaultConfigMapping"
import VoiceComplaince from "./ChildComponents/VoiceCompliance/VoiceComplianceList"
import { PheonixRoles } from "../constants/constants"
import CommonConfig from "./ChildComponents/CommonConfig/CommonConfig"
import AttributeList from "./ChildComponents/AttributeList/AttributeList"
import Supervisior from "./ChildComponents/Supervisior/AgentGroupList"
import { TabsDisplay } from "../utilities/components/CommonUseComponents"


function mapStateToProps(state) {
    return {
        user: state.login.authorizedAccess?.username,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
        rolesAndPermissions : state.login.rolesAndPermissions
    }
}

const AmazonConnect =  ({currentComponent, directList , permissions , user , rolesAndPermissions}) => {
    const rawdata = rolesAndPermissions
    const permissionsArrayForRouting = React.useMemo(() => {
        const result = new Set();
        if (rawdata) {
            Object.keys(rawdata).forEach((service) => {
                result.add(service);
                Object.keys(rawdata[service]).forEach((permissionType) => {
                    rawdata[service][permissionType].forEach((entry) => {
                        result.add(entry.subService);
                    });
                });
            });
        }
        return Array.from(result);
    }, [rawdata]);

    const modifiedData = permissionsArrayForRouting.flatMap((item) => {
        return item.startsWith("ROUTING") ? ["ROUTING FLOW CONFIG",item] : item
    })
    
    const uniqueData = [...new Set(modifiedData)]
    const arrayOfUniqueData = Array.from(uniqueData)

    const [agentList, setAgentList] = useState([])
    const [isAdminInEdit, setIsAdminInEdit] = useState(false)
    const [commonPermissions, setCommonPermissions] = useState([])
    const [userPermissions, setUserPermissions] = useState([])

    const getAgentGroupList = async (ldap) => {
        const response = await getAllAgentGroupList(ldap);
        const updatedAgentGroupStructure = response.map((agent) => {
            const { agent_ldap } = agent
            const agentGroupList = new Set(
                response
                    .filter((subagent) => subagent.agent_ldap == agent_ldap)
                    .map((subagent) => {
                        return {
                            groupName: subagent.agent_group_name,
                            groupActiveStatus: subagent.is_agent_profile,
                            first_name: subagent.first_name,
                            last_name: subagent.last_name,
                        }
                    })
            )
            return { agent_ldap, agentGroupList: Array.from(agentGroupList) }
        })
        const uniqueAgentList = [
            ...new Map(
                updatedAgentGroupStructure.map((item) => [
                    item.agent_ldap,
                    item,
                ])
            ).values(),
        ]
        setAgentList(uniqueAgentList)
    }

    useEffect(() => {
        if (user?.userid) {
            getAgentGroupList(user.userid)
        }
        getPermissions()
        getLoggedInUserIsAdmin(user)
            .then((resp) => {
                setIsAdminInEdit(resp)
            })
            .catch((err) => console.log(err))
    }, [])

    const getPermissions = () => {
        getPermissionsApi(user)
            .then((res) => {
                setCommonPermissions(
                    res?.filter((obj) => obj.group === "global")
                )
                setUserPermissions(res?.filter((obj) => obj.group !== "global"))
            })
            .catch((err) => {
                console.log("Error in getting permissions ", err)
            })
    }

    return (
        <Well
            UNSAFE_style={{
                minHeight: "75vh",
                marginRight: "1%",
                marginLeft: "1%",
            }}
        >
          {arrayOfUniqueData.length > 0  && (
                <TabsDisplay
                    permissions={arrayOfUniqueData}
                    directList={directList}
                    user={user}
                />
            )}
        </Well>
    )
}

export default connect(mapStateToProps)(AmazonConnect)