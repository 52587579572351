import React, { useEffect, useState, useRef } from 'react';
import 'react-schedule-job/dist/index.css'
import './index.css';
import { connect } from 'react-redux';
import { Flex, Heading, TableBody, TableHeader, TableView, SearchField, ActionGroup, Item, Text, Column, IllustratedMessage, Content, Row, Cell, Badge ,DialogTrigger, Dialog,ActionButton , ProgressCircle } from '@adobe/react-spectrum';
import { addAttributeList, deleteAttributeList, getAllAttributeListAndValues, updateAttributeList , refreshAttributeListAPI ,getDefaultInstanceConfig } from '../../../utilities/api/phoenix-api';
import Delete from '@spectrum-icons/workflow/Delete';
import Draw from '@spectrum-icons/workflow/Draw';
import Refresh from "@spectrum-icons/workflow/Refresh"
import DeleteAttributeList from './DeleteAttribute';
import { notify } from '../../../constants/constants';
import AddAttributeList from './AddAttribute';
import { renderEmptyState } from '../../../utilities/components/CommonUseComponents';

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        rolesAndPermissions: state.login.rolesAndPermissions,

    };
}

const AttributeList = ({ user, rolesAndPermissions }) => {

    const havingDeletePermission = rolesAndPermissions["AMAZON CONNECT"]?.delete?.filter((ele) => ele.subService === "ATTRIBUTE LIST")[0]?.resources.groups
    const havingEditPermission = rolesAndPermissions["AMAZON CONNECT"]?.edit?.filter((ele) => ele.subService === "ATTRIBUTE LIST")[0]?.resources.groups
    // const havingViewPermission = rolesAndPermissions["AMAZON CONNECT"]?.view?.filter((ele) => ele.subService === "ATTRIBUTE LIST")[0]?.resources.groups
    const havingAddPermission = rolesAndPermissions["AMAZON CONNECT"]?.add?.filter((ele) => ele.subService === "ATTRIBUTE LIST")[0]?.resources.groups


    // const columns = [
    //     { name: 'Attribute Name', key: 'attributeNames' },
    //     { name: 'Attribute Values', key: 'attributeValue' }
    // ];

    const columnsAdmin = [
        { name: 'Attribute Name', key: 'attributeNames' },
        { name: 'Attribute Values', key: 'attributeValue' },
        { name: 'Actions', key: 'actions' }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [structuredData, setStructuredData] = useState([]);
    const [filteredStructuredData, setFilteredStructuredData] = useState([]);
    const [currentText,setCurrentText] = useState(null);
    const [selectedAttributes, setSelectedAttributes] = useState(new Set());
    const [selectedForDelete, setSelectedForDelete] = useState();
    const [instance, setInstance] = useState();
    const editData = useRef(false);

    const formatAttributeListData = (unStructuredData) => {
        return Object.keys(unStructuredData).map((key)=>({attributeNames:key, attributeValue:unStructuredData[key]}));
    };
    const filterRoles = (value) => {
        setCurrentText(value);
    }

    const refreshAttributeList = () => {
        setIsLoading(true)
        refreshAttributeListAPI()
        .then(()=>{
            setIsLoading(false);
        })
        .catch(()=>{
            setIsLoading(false)
        })
    }

    const performAction = (action, selectedAttributes) => {
        const {attributeNames} = selectedAttributes;
        switch (action) {
            case 'delete':
                setSelectedForDelete([`${attributeNames}`]);
                setOpenDeleteModal(true);
                break;
            case 'edit' :
                editData.current = {
                    ...selectedAttributes
                }
                setOpenAddModal(true);
                break;

            case 'bulkDelete' :
                setSelectedForDelete(Array.from(selectedAttributes));
                setOpenDeleteModal(true);
            break;

            case 'add':
                editData.current = null;
                setOpenAddModal(true);
                break;
            case 'refresh':
                refreshAttributeList();
                break;
        
            default:
                break;
        }
    };

    const getAllAttributeListAndMapping = async (ldap) => {
           if(instance){
            try {
                setIsLoading(true);
                const resp = await getAllAttributeListAndValues(ldap);
                const formattedData = formatAttributeListData(resp[instance?.instance_id]);
                setStructuredData(formattedData);
                setFilteredStructuredData(formattedData);
            } catch (err) {
                console.log({ err });
                notify('Something Went Wrong', 'error');
            } finally {
                // For unsetting loader etc
                setIsLoading(false);
            }
           }
    };
    
    const getAllInstance = async () => {
        try {
            const result = await getDefaultInstanceConfig();
            setInstance(result);
        } catch (err) {
            console.log("Error in fetching instances", err);
        }
    };
    
    useEffect(() => {        
         getAllInstance();
      }, []);

    useEffect(() => {
        if (instance) {
            getAllAttributeListAndMapping(user?.userid);
        }
    }, [instance]);
    

    const generateBadgesFromList = (values) => {
        if(values.length <= 20)
            return values.map(value => (<Badge aria-label={value} variant='neutral' key={value}>{value}</Badge>))
        else
        return values.slice(0, 20).map((value, index) => (
            <>
            <Badge aria-label={value} variant='neutral' key={value}>
              {value}
            </Badge>
            {index === 19 && (
              <DialogTrigger type="popover" placement="right top">
              <ActionButton>Show All</ActionButton>
              <Dialog>
                <Content height={"auto"}>
                    {values.map(value => (<Badge aria-label={value} variant='neutral' key={value} margin={"2px"}>{value}</Badge>))}
                </Content>
              </Dialog>
            </DialogTrigger>
            )}
          </>          
          ));
          
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case 'attributeNames':
                return (<Cell>{item.attributeNames}</Cell>)

            case 'attributeValue':
                return (<Cell><Flex direction="row" maxWidth={700} gap={8} wrap="wrap">{generateBadgesFromList(item.attributeValue)}</Flex></Cell>)

            case 'actions':
                return (<Cell>
                    <ActionGroup onAction={(value) => performAction(value, item)}
                         disabledKeys={[
                            !havingEditPermission && "edit",
                            !havingDeletePermission && "delete",
                        ]}
                        >
                        <Item key="edit">
                            <Draw />
                        </Item>
                    
                            <Item key="delete">
                                <Delete />
                            </Item>
                        
                    </ActionGroup>
                </Cell>)

            default:
                return (<Cell>{columnKey}</Cell>)
        }
    }

    const closeDeleteDialog = (value) => {
        setOpenDeleteModal(value);
        if(value){
            setIsLoading(true);
            deleteAttributeList(user?.userid,{attributeNames:selectedForDelete.join()}).then(resp=>{
                getAllAttributeListAndMapping(user?.userid);
                setSelectedForDelete('');
                setSelectedAttributes(new Set())
                notify(resp,'success');
            }).catch(err=>{
                console.error(err);
                notify('Something Went Wrong','error');
            }).finally(()=>{
                setIsLoading(false);
                setOpenDeleteModal(false);
            })
        }
    }

    const closeAddDialog = (isAddConfirmed, data={}) => {
        setOpenAddModal(false);
        if(isAddConfirmed && !editData.current){
            setIsLoading(true);
            addAttributeList(user?.userid, data).then(resp=>{
                notify(resp, 'success');
                getAllAttributeListAndMapping(user?.userid);
            }).catch(err=>{
                console.log({err});
                notify('Something Went Wrong', 'error');
            }).finally(()=>{
                setIsLoading(false);
                setOpenAddModal(false);
            })
        }else if(isAddConfirmed && editData.current){
            setIsLoading(true);
            updateAttributeList(user?.userid, data).then(resp=>{
                notify(resp, 'success');
                getAllAttributeListAndMapping(user?.userid);
            }).catch(err=>{
                console.log({err});
                notify('Something Went Wrong', 'error');
            }).finally(()=>{
                setIsLoading(false);
                setOpenAddModal(false);
                editData.current = null;
            })
        }
        editData.current = null;
    }

    const filterValues = (values, text) => {
        return values.filter(value => value.toLowerCase().includes(text));
    }

    const filterRows = (currentText) => {
        if(currentText){
            const filteredData = structuredData.filter(item => item.attributeNames.toLowerCase().includes(currentText.toLowerCase()) || filterValues(item.attributeValue, currentText.toLowerCase()).length);
            setFilteredStructuredData(filteredData);
        }else{
            setFilteredStructuredData(structuredData);
        }
    }


    useEffect(()=>{
        if(!isLoading && currentText!==null){
            const timer = setTimeout(() => {
                filterRows(currentText)
            }, 1000);
            return (()=>clearTimeout(timer));
        }
    },[currentText]);

    return (
        <>
            {isLoading ? (
                <div
                    className={isLoading ? "display-block" : "display-none"}
                    style={{
                        paddingTop: "10%",
                        paddingLeft: "45%",
                        position: "absolute",
                        zIndex: "10",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="M"
                    />
                </div>
            ) : null}
            <div align="center">
                <Heading variant="pageTitle">Attribute List</Heading>
            </div>
            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    height: "60vh",
                }}
            >
                <Flex
                    direction="row"
                    gap="size-100"
                    UNSAFE_className="searchMobile"
                >
                    <SearchField
                        isQuiet
                        onClear={() => setCurrentText("")}
                        onChange={filterRoles}
                        aria-label="Search"
                        value={currentText}
                        width={"20%"}
                    />

                    {(
                        <ActionGroup
                            width={"80%"}
                            alignItems="end"
                            onAction={(value) =>
                                performAction(value, selectedAttributes)
                            }
                            disabledKeys={[!havingAddPermission && "add", !havingDeletePermission && "bulkDelete",]}

                            // disabledKeys={
                            //     selectedAttributes.size ||
                            //     selectedAttributes == "all"
                            //         ? []
                            //         : ["bulkDelete"]
                            // }
                        >
                            <Item key="refresh">
                                <Refresh />
                                <Text>Refresh</Text>
                            </Item>
                            <Item key="add">+ Add</Item>
                            <Item key="bulkDelete">
                                <Delete />
                                <Text>Delete</Text>
                            </Item>
                        </ActionGroup>
                    )}
                </Flex>

                <TableView
                    aria-label="Care Roles"
                    selectionMode={!havingDeletePermission ? "none" : "multiple"}
                    selectedKeys={selectedAttributes}
                    onSelectionChange={setSelectedAttributes}
                    height={"45vh"}
                    renderEmptyState={renderEmptyState}
                    density="spacious"
                    overflowMode="wrap"
                    disabledKeys={["1"]}
                >
                    <TableHeader
                        columns={columnsAdmin}
                    >
                        {(column) => (
                            <Column
                                key={column.key}
                                align={
                                    column.key === "actions" ? "end" : "start"
                                }
                                width={
                                    column.key === "attributeValue"
                                        ? "60%"
                                        : column.key === "actions"
                                        ? "16.5%"
                                        : "auto"
                                }
                                minWidth={
                                    ["permissions"].indexOf(column.key) > -1
                                        ? "40%"
                                        : "10%"
                                }
                            >
                                {column.name}
                            </Column>
                        )}
                    </TableHeader>
                    <TableBody
                        items={filteredStructuredData}
                    >
                        {(item) => (
                            <Row key={item.attributeNames}>
                                {(columnKey) => getCell(columnKey, item)}
                            </Row>
                        )}
                    </TableBody>
                </TableView>
                {openDeleteModal && (
                    <DeleteAttributeList
                        isDialogOpened={openDeleteModal}
                        handleCloseDialog={(isDeletedConfirmed) =>
                            closeDeleteDialog(isDeletedConfirmed)
                        }
                        entity="Role"
                    />
                )}
                {openAddModal && (
                    <AddAttributeList
                        isDialogOpened={openAddModal}
                        editData={editData.current}
                        handleCloseDialog={(isAddConfirmed, data) =>
                            closeAddDialog(isAddConfirmed, data)
                        }
                        attributeData = {structuredData}
                        entity="Role"
                    />
                )}
            </div>
        </>
    )
}

export default connect(mapStateToProps)(AttributeList);