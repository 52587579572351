import React from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup
} from '@adobe/react-spectrum';

const DeleteSRSProductFamily = (props) => {
    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    }
    const onDeleteConfirmHandler = () => {
        props.handleCloseDialog(true);
    }
return (
    <Provider theme={lightTheme}>
        <div>
            <DialogTrigger
                type="modal"
                mobileType="modal"
                isOpen = {props.isDialogOpened}
                defaultOpen= {false}>
                <ActionButton isHidden isQuiet></ActionButton>
                <Dialog size="S">
                    <Heading>Delete SRS Product Data</Heading>
                    <Divider />
                    <Content>Are you sure, you want to delete this SRS Product Data ?</Content>
                    <ButtonGroup>
                        <Button variant="secondary" onPress={onCloseHandler}>Cancel</Button>
                        <Button variant="secondary" onPress={onDeleteConfirmHandler}>Confirm</Button>
                    </ButtonGroup>
                </Dialog>
            </DialogTrigger>
        </div>
    </Provider>
    );
                    
};

export default DeleteSRSProductFamily;