import React from "react"
import { Item, TabList, TabPanels, Tabs, Well } from "@adobe/react-spectrum"
import { connect } from "react-redux"
import ServiceTestList from "./ServiceTestData/ServiceTestList"
import SRSProductFamily from "./SRSProductFamily/SRSProductFamily"
import SRSRules from "./SRSRules/SRSRules"
import CaseHistory from "./CaseHistory/CaseHistory"
import { SRSRoles } from "../constants/constants"
import { TabsDisplay } from "../utilities/components/CommonUseComponents"


function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
        rolesAndPermissions: state.login.rolesAndPermissions,
    }
}

const SRS = ({ user, permissions , directList , rolesAndPermissions }) => {
    const rawdata = rolesAndPermissions
    const permissionsArrayForRouting = React.useMemo(() => {
        const result = new Set()
        if (rawdata) {
            Object.keys(rawdata).forEach((service) => {
                result.add(service)
                Object.keys(rawdata[service]).forEach((permissionType) => {
                    rawdata[service][permissionType].forEach((entry) => {
                        result.add(entry.subService)
                    })
                })
            })
        }
        return Array.from(result)
    }, [rawdata])

    return (
        <Well
            UNSAFE_style={{
                minHeight: "75vh",
                marginRight: "1%",
                marginLeft: "1%",
            }}
        >
            {permissionsArrayForRouting.length > 0 && (
                <TabsDisplay
                    permissions={permissionsArrayForRouting}
                    directList={directList}
                    user={user}
                />
            )}
        </Well>
    )
}

export default connect(mapStateToProps)(SRS)
