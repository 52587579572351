import React, { useEffect, useState, useRef } from "react"
// import Delete from "@spectrum-icons/workflow/Delete"
// import Draw from "@spectrum-icons/workflow/Draw"
import "react-schedule-job/dist/index.css"
import { connect } from "react-redux"
import "./style.css"
import DynamicFieldForm from "./DynamicFieldForm"
import DeleteGroupMapping from "./DeleteGroupMapping"
import { View } from "@adobe/react-spectrum"
import {
    Flex,
    lightTheme,
    Provider,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    ProgressCircle,
    Switch,
    SearchField,
    Tooltip,
    ActionButton,
} from "@adobe/react-spectrum"

import {
    getAllLdap,
    deleteAgentGroupConfig,
    cloneGroupConfig,
    getAllGroupNames,
    getAllAgentGroupList,
    enforceUpdateApi,
    getQueueList,
    getAllAttributeListAndValues,
    getAllSupervisiorGroupList,
    getDefaultInstanceConfig
} from "../../../utilities/api/phoenix-api"
import { notify, PheonixRoles } from "../../../constants/constants"
import GroupCloneModal from "./GroupCloneModal"
import Copy from "@spectrum-icons/workflow/Copy"
import {
    Accordion,
    AccordionItemGroupConfig,
} from "../../../utilities/components/Accordion/Accordion"
import EnforceUpdate from "./EnforceUpdate"
import Table from "@spectrum-icons/workflow/Table"
import FileJson from "@spectrum-icons/workflow/FileJson"
import { renderEmptyState } from "../../../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo.userid,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
        rolesAndPermissions: state.login.rolesAndPermissions,
    }
}
const AgentGroupMapping = (props) => {
    // console.log("rolesAndPermissions PROPS FROM GROUPS ",props.rolesAndPermissions);
    const groupsHavingDeletePermissions = props?.rolesAndPermissions["AMAZON CONNECT"]?.delete?.filter(ele => ele.subService === "GROUP CONFIG")[0]?.resources.groups
    // console.log("groupsHavingDeletePermissions ",groupsHavingDeletePermissions);
    
    const groupsHavingEditPermissions = props?.rolesAndPermissions["AMAZON CONNECT"]?.edit?.filter(ele => ele.subService === "GROUP CONFIG")[0]?.resources.groups
    // console.log("groupsHavingEditPermissions ",groupsHavingEditPermissions);
    
    const groupsHavingViewPermissions = props?.rolesAndPermissions["AMAZON CONNECT"]?.view?.filter(ele => ele.subService === "GROUP CONFIG")[0]?.resources.groups
    // console.log("groupsHavingViewPermissions ",groupsHavingViewPermissions);

    const groupsHavingAddPermissions = props?.rolesAndPermissions["AMAZON CONNECT"]?.add?.filter(ele => ele.subService === "GROUP CONFIG")[0]?.resources.groups
    // console.log("groupsHavingAddPermissions ",groupsHavingAddPermissions);

    const groupsHavingClonePermissions = props?.rolesAndPermissions["AMAZON CONNECT"]?.clone?.filter(ele => ele.subService === "GROUP CONFIG")[0]?.resources.groups
    // console.log("groupsHavingClonePermissions ",groupsHavingClonePermissions);

    const groupsHavingEnforceUpdatePermissions = props?.rolesAndPermissions["AMAZON CONNECT"]?.enforceUpdate?.filter(ele => ele.subService === "GROUP CONFIG")[0]?.resources.groups || []
    // console.log("groupsHavingEnforceUpdatePermissions ",groupsHavingEnforceUpdatePermissions); 
    
    const [allUsers, setAllUsers] = useState([])
    // const [filteredContacts, setFilteredContacts] = useState([])
    // const [errorMessage, setErrorMessage] = useState("")
    const [selectedGroup, setSelectedGroup] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    // const [createRecordError, setCreateRecordError] = useState("")
    const [isCloneDialogue, setIsCloneDialogue] = useState(false)
    const [groupNameList, setGroupNameList] = useState([])
    const [groupedConfigList, setGroupedConfigList] = useState()
    const [filteredGroupedConfigList, setFilteredGroupedConfigList] = useState()
    const [enforceUpdate, setEnforceUpdate] = useState(false)
    const [usersInGroup, setUsersInGroup] = useState()
    const [queueList, setQueueList] = useState([])
    const [attributeList, setAttributeList] = useState([])
    const [selectView, setSelectView] = useState(true)
    const [currentText, setCurrentText] = useState()
    const [openedAccordianItems, setOpenedAccordianItems] = useState(new Set());
    const [supervisorsInGroup, setSupervisorsInGroup] = useState()
    const [defaultInstanceConfig, setDefaultInstanceConfig] = useState([])

    const channels = [
        {
            id: "chat",
            name: "CHAT",
        },
        {
            id: "voice",
            name: "VOICE",
        },
        {
            id: "task",
            name: "TASK",
        },
    ]

    const groupsForDropdown =
        props?.userPermissions?.length &&
        props?.userPermissions
            ?.filter((ele) => ele?.permissions?.includes("enforce_update"))
            .map((val) => ({
                id: val?.group?.trim(),
                name: val?.group?.trim(),
            }))

    const groupsForIsAdmin = groupNameList?.map((val) => ({
        id: val?.trim(),
        name: val?.trim(),
    }))

    const usersLdapWithGroup = usersInGroup?.map((val, index) => ({
        id: index + 1,
        groupName: val.agentGroupName,
        ldap: val.agentLdap,
        name: val.firstName + " " + val.lastName,
    }))

    /**
     * Create a Map of all available values based on unique agent_group_name values
     * @param {*} list
     * @param {*} keyGetter
     * @returns
     */
    function groupBy(list, keyGetter) {
        const map = new Map()
        list.forEach((item) => {
            const key = keyGetter(item)
            const collection = map.get(key)
            if (!collection) {
                map.set(key, [item])
            } else {
                collection.push(item)
            }
        })
        return map
    }

    const getDefaultInstanceCOnfig = () =>{
        getDefaultInstanceConfig(props.user).then(resp=>{
            // console.log({resp});
            setDefaultInstanceConfig([resp])
        }).catch(err => {
            console.log({err})
        })
    }

    const getAllGroup = (ldap) => {
        setIsLoading(true)
        getAllGroupNames(ldap)
            .then((groupNames) => {
                // Converting  "[dx_english]" to array
                const withoutBrackets = groupNames.slice(1, -1)
                const processedArray = withoutBrackets.split(",")
                // conversion end
                //console.log({processedArray})
                setGroupNameList(processedArray)
            })
            .catch((err) => {
                notify("Error in getting config list", "error")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function getAllLdapMethod() {
        setIsLoading(true)
        getAllLdap(props.user)
            .then((res) => {
                setGroupedConfigList(
                    groupBy(res, (res) => res?.agent_group_name)
                )
                setFilteredGroupedConfigList(
                    groupBy(res, (res) => res?.agent_group_name)
                )
                setAllUsers(res)
                setIsLoading(false)
            })
            .catch((error) => {
                if (error) {
                    setIsLoading(false)
                }
            })
            .finally(() => {
                getAllGroup(props.user)
            })
    }

    const getAllAgentGroupListAPI = async () => {
        const res = await getAllAgentGroupList(props?.user)
        const sortedData = res?.sort((a, b) => {
            return a.firstName?.localeCompare(b.firstName)
        })
        setUsersInGroup(sortedData)
    }

    const getSupervisorsInGroup = async () => {
        const res = await getAllSupervisiorGroupList(props?.user)
        const sortedData = res?.sort((a, b) => {
            return a.firstName?.localeCompare(b.firstName)
        })
        setSupervisorsInGroup(sortedData)
    }

    const getAllQueues = () => {
        getQueueList()
            .then((res) => setQueueList(res.allQueues))
            .catch((err) => console.log("Error in getting queue List", err))
    }

    const getAttributeList = () => {
        getAllAttributeListAndValues(props.userid)
            .then((resp) => {
                setAttributeList(resp)
            })
            .catch((err) => {
                console.log("error ", err)
            })
    }

    const convertArrayToInput = (values = []) => {
        return values.length
            ? values?.map((value, index) => ({ id: index, name: value }))
            : []
    }

    const helperFunction = (name) => {
        const attributeNameObject = name.find(
            (obj) => obj.key === "attributeName"
        )?.value
        const newValue = convertArrayToInput(attributeList[attributeNameObject])
        return newValue
    }

    useEffect(() => {
        getAllLdapMethod()
        getAllAgentGroupListAPI()
        getSupervisorsInGroup()
        getAllQueues()
        getAttributeList()
        getDefaultInstanceCOnfig()
        // getAllGroup(props.user.userid);
        return () => {
            setGroupedConfigList(new Map())
            setFilteredGroupedConfigList(new Map())
        }
    }, [])

    const currentUserSelected = useRef("")
    const editData = useRef(false)

    function deleteAgentGroupConfigMethod(key, value) {
        setIsLoading(true)
        const urlencoded = new URLSearchParams()
        urlencoded.append(key, value)
        deleteAgentGroupConfig(urlencoded, props.user)
            .then((response) => {
                notify(response.response, "success")
                getAllLdapMethod()
            })
            .catch((err) => {
                if (err) setIsLoading(false)
                notify("Error in deleting", "error")
            })
            .finally(() => {
                setIsLoading(false)
                getAllLdapMethod()
            })
    }

    function deleteMultipleAgentGroupConfigMethod(obj) {
        setIsLoading(true)
        const urlencoded = new URLSearchParams(obj)
        deleteAgentGroupConfig(urlencoded, props.user)
            .then((response) => {
                notify(response.response, "success")
            })
            .catch(() => {
                notify("Error in deleting", "error")
            })
            .finally(() => {
                setIsLoading(false)
                getAllLdapMethod()
            })
    }

    const closeDeleteDialog = (isDeletedConfirmed) => {
        setIsDeleteDialogOpen(false)

        if (isDeletedConfirmed) {
            const { current } = currentUserSelected
            const groupToDelete = current.key
            const users = usersLdapWithGroup?.filter(
                (ele) => ele?.groupName === groupToDelete
            )

            const supervisors = supervisorsInGroup?.filter(
                (ele) => ele?.agentGroupName === groupToDelete
            )

            if (users.length) {
                notify(
                    `Cannot delete ${groupToDelete} group as ${users.length} users are associated with this group`,
                    "warn"
                )
                return
            }

            if(supervisors.length){
                notify(
                    `Cannot delete ${groupToDelete} group as ${supervisors.length} supervisors are associated with this group`,
                    "warn"
                )  
                return
            } 

            if (selectedGroup === "all") {
                const tempObj = {}
                allUsers.forEach((item) => {
                    const agentGroupName = item.agent_group_name

                    if (!tempObj[agentGroupName]) {
                        tempObj[agentGroupName] = []
                    }

                    tempObj[agentGroupName].push(item.config_key)
                })
                deleteMultipleAgentGroupConfigMethod(tempObj)
            } else if (!currentUserSelected.current && selectedGroup.size > 0) {
                const groupToDelete = [...selectedGroup]

                const selectedData = allUsers.filter((ele, index) => {
                    return groupToDelete.includes(`${index}`)
                })

                const tempObj = {}
                selectedData.forEach((item) => {
                    const agentGroupName = item.agent_group_name

                    if (!tempObj[agentGroupName]) {
                        tempObj[agentGroupName] = []
                    }

                    tempObj[agentGroupName].push(item.config_key)
                })
                deleteMultipleAgentGroupConfigMethod(tempObj)
                setSelectedGroup(new Set())
            } else {
                if (current.config_key) {
                    deleteAgentGroupConfigMethod(
                        current.agent_group_name,
                        current.config_key
                    )
                } else {
                    deleteAgentGroupConfigMethod(current.key, current.value)
                }
            }
            currentUserSelected.current = null
        }
    }

    useEffect(() => {
        if (groupedConfigList) {
            //console.log(Array.from(groupedConfigList.keys()))
        }
    }, [groupedConfigList])

    const performAction = (value, item) => {
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = {...allUsers.find(
                    (user) =>
                        user.agent_group_name == item.agent_group_name &&
                        user.config_key == item.config_key
                ), ...{selectedData: filteredGroupedConfigList.get(item.agent_group_name).filter(groupInfo => groupInfo.config_key === "instance_config")[0]?.config_values[0]?.instance_id}}
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "edit1":
                editData.current = { group_name: item.agent_group_name, selectedData: filteredGroupedConfigList.get(item.agent_group_name).filter(groupInfo => groupInfo.config_key === "instance_config")[0]?.config_values[0]?.instance_id, defaultInstanceConfig:defaultInstanceConfig[0]?.instance_id }
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "clone":
                editData.current = null
                setIsCloneDialogue(true)
                setOperation("Clone")
                break
            case "delete":
                currentUserSelected.current = item
                setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                setIsDeleteDialogOpen(true)
                break
            case "enforce":
                setEnforceUpdate(true)
        }
    }
    // Clone Modal Handler

    const handleCloneModalClose = (isClose = false, cloneObjectData = null) => {
        setIsCloneDialogue(isClose)
        if (cloneObjectData && cloneObjectData.length) {
            setIsLoading(true)
            cloneGroupConfig(props.user, cloneObjectData)
                .then((response) => {
                    notify(response, "success")
                    getAllLdapMethod()
                })
                .catch(() => {
                    notify("Error in cloning existing config", "error")
                })
                .finally(() => {
                    setIsLoading(false)
                })
            // send API request to update list call get API to show updated List
        }
    }

    const enforceOverrideUpdate = (data) => {
        const ldap = props?.user
        setIsLoading(true)
        enforceUpdateApi(data, ldap)
            .then((res) => {
                setIsLoading(false)
                notify(res.response, "success")
            })
            .catch((err) => {
                setIsLoading(false)
                notify(err, "err")
            })
    }

    const filterGroupList = (value) => {
        const lowercaseValue =
            value?.trim()?.replace(/\s+/g, " ")?.toLowerCase() || ""

        if (
            groupedConfigList &&
            groupedConfigList instanceof Map &&
            groupedConfigList.size > 0
        ) {
            const filteredMap = new Map()
            for (const [groupName, groupData] of groupedConfigList) {
                if (groupName.toLowerCase().includes(lowercaseValue)) {
                    filteredMap.set(groupName, groupData)
                }
            }
            //console.log({groupedConfigList, filteredMap});
            setFilteredGroupedConfigList(filteredMap)
        } else {
            setFilteredGroupedConfigList(groupedConfigList)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentText) {
                filterGroupList(currentText)
            } else {
                if (
                    groupedConfigList &&
                    groupedConfigList instanceof Map &&
                    groupedConfigList.size > 0
                ) {
                    setFilteredGroupedConfigList(groupedConfigList)
                }
            }
        }, 500)
        return () => clearTimeout(timer)
    }, [currentText])

    // useEffect(() => {
    //     if (!currentText && groupedConfigList && groupedConfigList instanceof Map && groupedConfigList.size > 0) {
    //         //console.log("groupedConfigList from 5452", groupedConfigList);
    //         setFilteredGroupedConfigList(groupedConfigList);
    //     }
    // }, [currentText])

    const filterGroups = (value) => {
        setCurrentText(value)
    }

    /**
     * 
     * @param {*} value 
     */
    function manageAccordianState(value){
        if(typeof openedAccordianItems == "boolean"){
            setOpenedAccordianItems(new Set());
        }else{
            if(openedAccordianItems.has(value)){
                setOpenedAccordianItems(prev => ((prev.size > 1)?prev.delete(value):new Set()));
            }else{
                setOpenedAccordianItems(prev => prev.add(value));
            }
        }
    }


    // console.log("groupsForIsAdmin ",groupsForIsAdmin);
    
    const enforceGroupToShow = Array.isArray(groupsHavingEnforceUpdatePermissions) 
    ? (groupsHavingEnforceUpdatePermissions[0] === '*' 
        ? groupsForIsAdmin  
        : groupsForIsAdmin.filter(group => 
            groupsHavingEnforceUpdatePermissions.includes(group.name))) 
    : [];

    const cloneGroupsToShow = Array.isArray(groupsHavingClonePermissions) 
    ? (groupsHavingClonePermissions[0] === '*' 
        ? groupsForIsAdmin  
        : groupsForIsAdmin.filter(group => 
            groupsHavingClonePermissions.includes(group.name))) 
    : [];


    // console.log("enforceGroupToShow ",enforceGroupToShow);
    
    return (
        <>
            {isLoading ? (
                <div
                    className={isLoading ? "display-block" : "display-none"}
                    style={{
                        paddingTop: "10%",
                        paddingLeft: "45%",
                        position: "absolute",
                        zIndex: "10",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="M"
                    />
                </div>
            ) : null}

            <div align="center">
                <Heading variant="pageTitle">Group Config</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "90vw",
                    minHeight: "45vh",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                <Flex
                            direction="row"
                            gap="size-100"
                            UNSAFE_className="searchMobile fixed-header"
                        >
                            <SearchField
                                isQuiet
                                labelPosition="side"
                                labelAlign="start"
                                onClear={() => setCurrentText("")}
                                onChange={filterGroups}
                                aria-label="Search"
                                value={currentText}
                                width={"30%"}
                            />
                    { (
                                <ActionGroup
                                    width={"100%"}
                                    alignItems="end"
                                    onAction={(value) =>
                                        performAction(value, null)
                                    }
                                    disabledKeys={[ !(groupsHavingAddPermissions || []).length && "add", 
                                        !(groupsHavingClonePermissions || []).length && "clone", 
                                        !(groupsHavingEnforceUpdatePermissions || []).length && "enforce"]}
                                >
                                    { (
                                        <Item key="enforce">
                                            Enforce Update
                                        </Item>
                                    )}
                                    { (
                                        <Item key="add">+ Add</Item>
                                    )}
                                    {(
                                        <Item key="clone">
                                            <Copy />
                                            <Text>Clone</Text>
                                        </Item>
                                    )}
                                </ActionGroup>                    )}
                    </Flex>
                    <View UNSAFE_style={{ textAlign: "end" }}>
                        <Flex
                            direction={"row"}
                            justifyContent={"end"}
                            alignItems={"center"}
                            gap={"size-100"}
                        >
                            <View UNSAFE_style={{ textAlign: "end" }}>
                                <Flex
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignContent={"center"}
                                    alignItems={"center"}
                                >
                                    <FileJson size="S" />
                                    <Text>JSON</Text>
                                </Flex>
                            </View>
                            <View UNSAFE_style={{ textAlign: "end" }}>
                                <Switch
                                    UNSAFE_style={{ marginRight: "0px" }}
                                    aria-label="View switcher"
                                    onChange={setSelectView}
                                    isSelected={selectView}
                                />
                            </View>
                            <View UNSAFE_style={{ textAlign: "end" }}>
                                <Flex
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Table size="S" />
                                    <Text>Table</Text>
                                </Flex>
                            </View>
                        </Flex>
                    </View>
                    <Flex width="100%" direction="column">
                        {!!allUsers.length && (
                            <View width={"100%"}>
                                <div
                                    style={{
                                        fontWeight: 700,
                                        marginLeft: "20px",
                                    }}
                                >
                                    Group Name
                                </div>

                                <Accordion>
                                    {!!(
                                        filteredGroupedConfigList &&
                                        groupNameList &&
                                        groupNameList.length
                                    ) &&
                                        Array.from(
                                            filteredGroupedConfigList?.keys()
                                        )
                                            ?.sort((a, b) =>
                                                a
                                                    ?.toLowerCase()
                                                    ?.localeCompare(
                                                        b?.toLowerCase()
                                                    )
                                            )
                                            ?.map((groupname, index) => (
                                                <AccordionItemGroupConfig
                                                    key={"group" + index}
                                                    title={groupname}
                                                    configs={filteredGroupedConfigList?.get(
                                                        groupname
                                                    )}
                                                    isAdmin={props?.permissions?.includes(
                                                        PheonixRoles?.agentGroupConfigAdmin
                                                    )}
                                                    deleteConfig={
                                                        deleteAgentGroupConfigMethod
                                                    }
                                                    ItemAction={(
                                                        value,
                                                        groupData
                                                    ) =>
                                                        performAction(
                                                            value,
                                                            groupData
                                                        )
                                                    }
                                                    userPermissions={
                                                        props?.userPermissions
                                                    }
                                                    isAdminInEdit={
                                                        props?.isAdminInEdit
                                                    }
                                                    selectView={selectView}
                                                    click={(value)=>manageAccordianState(value)}
                                                    openedAccList={Array.from(openedAccordianItems)}
                                                    userInGroup={usersLdapWithGroup?.filter(
                                                        (ele) => ele?.groupName === groupname
                                                    )}
                                                    supervisiorInGroup={supervisorsInGroup?.filter(
                                                        (ele) => ele?.agentGroupName === groupname
                                                    )}
                                                    isHavingDeletePermisssion = {groupsHavingDeletePermissions?.includes("*") || groupsHavingDeletePermissions?.includes(groupname)}
                                                    isHavingEditPermission = {groupsHavingEditPermissions?.includes("*") || groupsHavingEditPermissions?.includes(groupname)}
                                                />
                                            ))}
                                </Accordion>
                            </View>
                        )}
                        {/* IN all users we need to get a no results found section */}
                        {!!allUsers.length && (
                            <Flex alignContent={'center'} justifyContent={'center'}>
                                {!!(!filteredGroupedConfigList?.size && currentText) && (
                                    <Flex alignContent={'center'} justifyContent={'center'} height={'30vh'}>
                                        {renderEmptyState()}
                                    </Flex>
                                )}
                            </Flex>
                        )}
                    </Flex>
                </Provider>
            </div>

            {isdeleteDialogOpen && (
                <DeleteGroupMapping
                    isDialogOpened={isdeleteDialogOpen}
                    groupData={currentUserSelected.current}
                    handleCloseDialog={(isDeletedConfirmed) =>
                        closeDeleteDialog(isDeletedConfirmed)
                    }
                />
            )}

            {isAddDialogOpen && (
                <DynamicFieldForm
                    isDialogOpened={isAddDialogOpen}
                    setIsAddDialogOpen={setIsAddDialogOpen}
                    userid={props.user}
                    editData={editData?.current}
                    // handleCloseDialog={(isAddConfirmed, submitData) => closeAddContactDialog(isAddConfirmed, submitData)}
                    operation={operation}
                    allUsers={allUsers}
                    getAllLdapMethod={() => getAllLdapMethod()}
                    queueList={queueList}
                    channels={channels}
                    attributeList={convertArrayToInput(
                        Object?.keys(attributeList)
                    )}
                    attributeValuesList={(name) => helperFunction(name)}
                    defaultInstanceId={defaultInstanceConfig[0]?.instance_id}
                />
            )}

            {isCloneDialogue && (
                <GroupCloneModal
                    user={props.user}
                    isClonable={groupsHavingClonePermissions["0"] === "*" ? [] : groupsHavingClonePermissions} 
                    isDialogOpened={isCloneDialogue}
                    handleCloseDialog={handleCloneModalClose}
                />
            )}

            {enforceUpdate && (
                <EnforceUpdate
                    isDialogOpened={enforceUpdate}
                    setEnforceUpdate={setEnforceUpdate}
                    groups={
                        enforceGroupToShow
                    }
                    usersLdapWithGroup={usersLdapWithGroup}
                    enforceUpdate={(data) => enforceOverrideUpdate(data)}
                    userPermissions={props?.userPermissions}
                    queueList={queueList}
                    channels={channels}
                    attributeList={convertArrayToInput(
                        Object?.keys(attributeList)
                    )}
                    attributeValuesList={(name) =>
                        convertArrayToInput(attributeList[name])
                    }
                />
            )}
        </>
    )
}

export default connect(mapStateToProps)(AgentGroupMapping)
