const TableViewContainer = (props) => {
    return (      <div
        style={{
          padding: '25px',
          border: '0.8px solid',
          borderRadius: '0.7em',
          marginTop: '30px',
          width: '90vw',
          margin: '0 auto'
        }}
      >{props.children}</div>)
}

export default TableViewContainer;