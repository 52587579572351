import React, { useEffect, useState, useRef } from "react"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import "react-schedule-job/dist/index.css"
import "../index.css"
import {
    Flex,
    SearchField,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Badge,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    View,
    ProgressCircle,
    DialogTrigger,
    ActionButton,
    Dialog
} from "@adobe/react-spectrum"
import DeleteDialog from "./deleteDialog"
import {
    getAllPermissionsAPI,
    addPermissionsAPI,
    deletePermissionsAPI,
    editPermissionsAPI,
    
} from "../utilities/api/admin-api"
import { getAllGroupNames ,getAllAgentGroupList , getAllSupervisorsAPI , getAllSupervisiorGroupList} from "../utilities/api/phoenix-api"
import { connect } from "react-redux"
import AddPermission from "./AddPermission"
import { notify } from "../constants/constants"
import { renderEmptyState } from '../utilities/components/CommonUseComponents';

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
    }
}

const Permissions = (props) => {
    const ldap = props?.user?.userid
    const [allPermissions, setAllPermissions] = useState([])
    const [filteredPermissions, setFilteredPermissions] = useState([])
    const [currentText, setCurrentText] = useState("")
    const [selectedPermissions, setSelectedPermissions] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [allGroups, setAllGroups] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    const [allRoles , setAllRoles] = useState([])
    const [supervisors, setSupervisors] = useState(new Set())

    const columns = [
        { name: "Role", key: "role" ,minWidth: "20%"},
        { name: "Users", key: "users",minWidth: "20%" },
        { name: "Permissions", key: "groupPermissions",minWidth: "70%" },
        { name: "Actions", key: "actions",minWidth: "20%"},
    ]

    const currentPermissionSelected = useRef("")
    const editData = useRef("")

    useEffect(() => {
        getAllpermissions()
        getAllGroups()
        getAllUsers()
        getAllSupervisors()
    }, [])

    const getAllSupervisors = () => {
        getAllSupervisiorGroupList()
            .then((res) => {
                const supervisor = res.map((ele) =>  {
                    const name = ele.firstName+" "+ele.lastName
                        return {
                        id: ele.agentLdap,
                        name: name,
                    }
                }
                )
                setSupervisors(supervisor)
            }) 
            .catch((err) => {
                console.log("Error in getting supervisors ", err)
            })
    }

    const getAllUsers = () => {
        getAllAgentGroupList(ldap)
            .then((res) => {
                const users = res.map((ele) =>  {
                    return {
                        id: ele.agentLdap,
                        name: ele.firstName+" "+ele.lastName,
                    }
                }
                )
                setAllUsers(users)
            })
            .catch((err) => {
                console.log("Error in getting users ", err)
            })
    }

    const modifyData = (data) => {
        return data.map((ele) => {
            const groupNames = ele.groupPermissions
                ?.filter((val) => val.group !== null)
                .map((val) => val.group)
            const mappedGroups = ele.groupPermissions
                .filter((val) => val.group !== null)
                .map((val) => {
                    return { [val?.group]: val?.permissions }
                })
            return {
                ...ele,
                role: ele.role,
                groupPermissions: mappedGroups,
                groups: groupNames,
                users: ele.users,
            }
        })
    }

    const getAllpermissions = () => {
        setIsLoading(true)
        getAllPermissionsAPI()
            .then((perm) => {
                const modifiedData = modifyData(perm)
                setAllPermissions(modifiedData)
                setFilteredPermissions(modifiedData)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("Error in getting data", error)
                setIsLoading(false)
            })
    }

    const getAllGroups = () => {
        setIsLoading(true)
        getAllGroupNames()
            .then((groups) => {
                const withoutBrackets = groups.slice(1, -1)
                const processedGroupArray = withoutBrackets
                    .split(",")
                    .map((name, id) => {
                        const trimmedName = name.trim()
                        return { id: trimmedName, name: trimmedName }
                    })

                    const processedRoleArray = withoutBrackets
                    .split(",")
                    .map((name, id) => {
                        const trimmedName = name.trim()
                        return { id: `${trimmedName}- Manager`, name: `${trimmedName}- Manager` }
                    })

                setAllGroups(processedGroupArray)
                setAllRoles(processedRoleArray)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("Error in getting groups ", error)
                setIsLoading(false)
            })
    }

    const closeAddRoleDialog = (isAddConfirmed, data, operation) => {
        setIsAddDialogOpen(false)
        if (isAddConfirmed) {
            setIsLoading(true)
            if (operation === "Add") {
                addPermissionsAPI(ldap, data)
                    .then((res) => {
                        setIsLoading(false)
                        getAllpermissions()
                        notify(res, "success")
                    })
                    .catch((err) => {
                        setIsLoading(false)
                        notify(err, "error")
                    })
            } else if (operation === "Edit") {
                addPermissionsAPI(ldap, data)
                    .then((res) => {
                        setIsLoading(false)
                        getAllpermissions()
                        notify(res, "success")
                    })
                    .catch((err) => {
                        setIsLoading(false)
                        notify(err, "error")
                    })
            }
        }
    }

    const closeDeleteDialog = (isDeletedConfirmed, key) => {
        setIsDeleteDialogOpen(false)
        if (isDeletedConfirmed) {
            setIsLoading(true)
            if (selectedPermissions.size) {
                const urlencoded = new URLSearchParams()
                urlencoded.append("roles", Array.from(selectedPermissions))
                deletePermissionsAPI(ldap, urlencoded)
                    .then((res) => {
                        setIsLoading(false)
                        getAllpermissions()
                        notify(res.response, "success")
                    })
                    .catch((err) => {
                        setIsLoading(false)
                        notify(err, "error")
                    })
            } else {
                const urlencoded = new URLSearchParams()
                urlencoded.append(
                    "roles",
                    currentPermissionSelected.current
                )
                deletePermissionsAPI(ldap, urlencoded)
                    .then((res) => {
                        getAllpermissions()
                        notify(res.response, "success")
                    })
                    .catch((err) => {
                        notify(err, "error")
                    })
            }
        }
        setSelectedPermissions(new Set())
    }

    const filterFunction = (value) => {
        if (allPermissions.length) {
            const filteredPermission = allPermissions.filter((perm) =>
                perm.role.toLowerCase().includes(value.toLowerCase())
            )
            setFilteredPermissions(filteredPermission)
        }
    }

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (currentText) {
                filterFunction(currentText)
            } else {
                setFilteredPermissions(allPermissions)
            }
        }, 500)
        return () => clearTimeout(timeOut)
    }, [currentText, allPermissions])

    const filterPermissions = (value) => {
        setCurrentText(value)
    }

    const performAction = (value, item) => {
        switch (value) {
            case "add":
                editData.current = null
                setOperation("Add")
                setIsAddDialogOpen(true)
                break
            case "edit":
                editData.current = allPermissions?.find(
                    (val) => val.role === item.role
                )
                setOperation("Edit")
                setIsAddDialogOpen(true)
                break
            case "delete":
                currentPermissionSelected.current = item.role
                setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                // currentPermissionSelected.current = item.username
                setIsDeleteDialogOpen(true)
                break
        }
    }

    const getPermissionBadge = (permissionList) => {
        // const badges = []
        // permissionList.forEach((element) =>
        //     badges.push(
        //         <Badge aria-label={element} key={element} variant="neutral">
        //             {element}
        //         </Badge>
        //     )
        // )
        // return badges

        if(permissionList.length <= 3)
            return permissionList.map(value => (<Badge aria-label={value} variant='neutral' key={value}>{value}</Badge>))
        else
        return permissionList.slice(0, 3).map((value, index) => (
            <>
            <Badge aria-label={value} variant='neutral' key={value}>
              {value}
            </Badge>
            {index === 2 && (
              <DialogTrigger type="popover" placement="right top">
              <ActionButton>Show All</ActionButton>
              <Dialog>
                <Content height={"auto"}>
                    {permissionList.map(value => (<Badge aria-label={value} variant='neutral' key={value} margin={"2px"}>{value}</Badge>))}
                </Content>
              </Dialog>
            </DialogTrigger>
            )}
          </>          
          ));
    }

    const getPermissionBadgeGoupWithPermission = (groupsArr, groupName) => {
        const badges = []
        groupsArr.forEach((element) => {
            badges.push(
                // <div key={element}>
                <Badge
                    aria-label={element}
                    // variant="neutral"
                    UNSAFE_style={{
                        paddingLeft: "4px",
                        justifyContent: "space-between",
                        border: "1px solid #dedede",
                    }}
                >
                    <View
                        UNSAFE_style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Badge
                            variant="neutral"
                            UNSAFE_style={{
                                width: "100px", 
                                textAlign: "center",
                                fontWeight:700,
                                backgroundColor: "white",
                                color: "#6e6e6e",
                                border: "1px solid #6e6e6e",
                                wordBreak: "break-all"
                            }}
                        >
                            {`${groupName}`}
                        </Badge>
                        <Text
                            marginX={"5px"}
                            UNSAFE_style={{ color: "black", fontWeight:"800px" }}
                        >
                            :
                        </Text>
                    </View>

                    <View UNSAFE_style={{ width: "calc(100% - 100px)" }}>
                        {element.map((ele, index) => (
                            <Badge key={index} variant="neutral" margin={"1px"}>
                                {ele}
                            </Badge>
                        ))}
                    </View>
                </Badge>
                // </div>
            )
        })
        return badges
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) => performAction(value, item)}
                        >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            {
                                <Item key="delete">
                                    <Delete />
                                </Item>
                            }
                        </ActionGroup>
                    </Cell>
                )
            case "groupPermissions":
                return (
                    <Cell>
                        <Flex
                            direction="column"
                            // maxWidth={500}
                            gap={8}
                            wrap="wrap"
                        >
                            {item[columnKey].map((ele) =>
                                getPermissionBadgeGoupWithPermission(
                                    Object.values(ele),
                                    Object.keys(ele)
                                )
                            )}
                        </Flex>
                    </Cell>
                )
            case "users":
                return (
                    <Cell>
                        <Flex
                            direction="row"
                            // maxWidth={500}
                            gap={8}
                            wrap="wrap"
                        >
                            {getPermissionBadge(item[columnKey])}
                        </Flex>
                    </Cell>
                )
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    return (
        <>
            {isLoading ? (
                <View
                    UNSAFE_style={{
                        paddingTop: "10%",
                        paddingLeft: "45%",
                        position: "absolute",
                        zIndex: "10",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
                </View>
            ) : null}
            <div align="center">
                <Heading variant="pageTitle">Permissions</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                }}
            >
                <Flex
                    direction="row"
                    gap="size-100"
                    UNSAFE_className="searchMobile"
                >
                    <SearchField
                        isQuiet
                        onClear={() => setCurrentText("")}
                        onChange={filterPermissions}
                        aria-label="Search"
                        value={currentText}
                        width={"20%"}
                    />
                    <ActionGroup
                        width={"80%"}
                        alignItems="end"
                        onAction={(value) =>
                            performAction(value, selectedPermissions)
                        }
                        disabledKeys={
                            selectedPermissions.size ||
                            selectedPermissions == "all"
                                ? []
                                : ["bulkDelete"]
                        }
                    >
                        <Item key="add">+ Add</Item>
                        <Item key="bulkDelete">
                            <Delete />
                            <Text>Delete</Text>
                        </Item>
                    </ActionGroup>
                </Flex>

                <TableView
                    aria-label="pernissions"
                    selectionMode="multiple"
                    selectedKeys={selectedPermissions}
                    onSelectionChange={setSelectedPermissions}
                    height={"45vh"}
                    renderEmptyState={renderEmptyState}
                    // density="spacious"
                    overflowMode="wrap"
                    // disabledKeys={['1']}
                >
                    <TableHeader columns={columns}>
                        {(column) => (
                            <Column
                            allowsResizing
                                key={column.key}
                                // minWidth={column.minWidth}
                                //             key={column.key}
                                align={
                                    column.key === "actions" ? "end" : "start"
                                }
                                minWidth={
                                    column.minWidth

                                }
                            >
                                {column.name}
                            </Column>
                        )}
                    </TableHeader>
                    <TableBody items={filteredPermissions}>
                        {(item) => (
                            <Row key={item.role}>
                                {(columnKey) => getCell(columnKey, item)}
                            </Row>
                        )}
                    </TableBody>
                </TableView>
                {isdeleteDialogOpen && (
                    <DeleteDialog
                        isDialogOpened={isdeleteDialogOpen}
                        handleCloseDialog={(isDeletedConfirmed) =>
                            closeDeleteDialog(isDeletedConfirmed)
                        }
                        entity="Role"
                    />
                )}
            </div>

            {isAddDialogOpen && (
                <AddPermission
                    isDialogOpened={isAddDialogOpen}
                    editData={editData.current}
                    handleCloseDialog={(isAddConfirmed, data, operation) =>
                        closeAddRoleDialog(isAddConfirmed, data, operation)
                    }
                    operation={operation}
                    allGroups={allGroups}
                    allRoles={allRoles}
                    allUsers={allUsers}
                    supervisors={supervisors}
                />
            )}
        </>
    )
}

export default connect(mapStateToProps)(Permissions)
