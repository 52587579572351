import React from "react"
import { Flex, View, Button } from "@adobe/react-spectrum"


const Layout = ({ children, onNext, onPrevious, canGoNext, canGoPrevious ,alertService,isEditable , submitForm , operation}) => {

  
    return (
        <Flex direction="column" height="100%" width="100%" UNSAFE_className="dialogue-container">
            <View width="100%" height={'100%'} UNSAFE_style={{overflowX:'hidden', overflowY:'auto'}}>
                {children}
            </View>
            <Flex
                height={"10%"}
                UNSAFE_style={{ borderTop: "1px solid #d1d1d1" }}
                justifyContent="end"
                alignItems={'center'}
                gap={'size-200'}
                padding="size-200"
            >
                <Button
                    variant="secondary"
                    onPress={onPrevious}
                    isDisabled={!canGoPrevious}
                >
                    Previous
                </Button>
                <Button
                    variant="cta"
                    onPress={canGoNext ? onNext : () => submitForm(alertService[0])}
                    marginEnd={'5%'}
                    // isDisabled={!canGoNext}
                    isDisabled={!canGoNext && (!isEditable && operation !== "add")}
                >
                    {canGoNext ? "Next" : "Submit"}
                </Button>
            </Flex>
        </Flex>
    )
}

export default Layout
