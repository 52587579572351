import React, { useState, useEffect } from "react"
import Delete from "@spectrum-icons/workflow/Delete"
import {
    AddAgentGroupConfigRec,
    EditAgentGroupConfigRec,
    getQueueList,
    getStatusList,
    getAllAttributeListAndValues,
    fetchInstance,
    getDefaultGroupsConfig,
} from "../../../utilities/api/phoenix-api"
import {
    Button,
    Flex,
    Content,
    Heading,
    ActionButton,
    DialogTrigger,
    Dialog,
    Divider,
    TextField,
    Footer,
    View,
    ButtonGroup,
    Header,
    Checkbox,
    NumberField,
    Item,
    Picker,
    ComboBox,
} from "@adobe/react-spectrum"
import Copy from "@spectrum-icons/workflow/Copy"
import Add from "@spectrum-icons/workflow/Add"
import Undo from "@spectrum-icons/workflow/Undo"
import "./style.css"
import { notify } from "../../../constants/constants"
import { clone } from "@okta/okta-auth-js"

function DynamicFieldForm(props) {
    const sideArrayObject = { key: "", value: "" }
    const sideArray = [sideArrayObject]
    const [mainArray, setMainArray] = useState([sideArray])
    const [objectSelected, setObjectSelected] = useState(new Set([]))
    const [inputFields, setInputFields] = useState([
        {
            agent_group_name: "",
            config_key: "",
            config_values: [{ key: "", value: "" }],
        },
    ])

    const [queueList, setQueueList] = useState([])
    const [statusList, setStatusList] = useState([])
    const [attributeList, setAttributeList] = useState([])
    const [attValues, setAttValues] = useState([])
    const [instances, setInstances] = useState()

    const [activeInstance, setActiveInstance] = useState(props?.editData?.selectedData ||  props?.defaultInstanceId)

    const channels = [
        {
            id: "chat",
            name: "chat",
        },
        {
            id: "voice",
            name: "voice",
        },
        {
            id: "task",
            name: "task",
        },
    ]

    function getAllInstance() {
        fetchInstance()
            .then((result) => {
                // console.log("result of instance ", result)
                setInstances(result)
            })
            .catch((err) => {
                console.log("Error in fetching instances ", err)
            })
    }

    useEffect(() => {
        getAllInstance()
        activeInstance &&
            getDefaultGroupsConfig()
                .then((res) => setQueueList(res[activeInstance]))
                .catch((err) => console.log("Error in getting queue List", err))
    }, [activeInstance])

    useEffect(() => {
        getStatusList()
            .then((res) => setStatusList(res))
            .catch((err) => console.log("Error in getting queue List", err))
    }, [])

    useEffect(() => {
        getAllAttributeListAndValues(props.userid)
            .then((resp) => {
                setAttributeList(resp[activeInstance])
            })
            .catch((err) => {
                console.log("error ", err)
            })
    }, [activeInstance])

    const convertArrayToInput = (values = []) => {
        return values.length
            ? values?.map((value, index) => ({ id: index, name: value }))
            : []
    }

    const defaultDataFormats = {
        after_call_work: [{ key: "duration", value: "0" }],
        agent_states: [
            { key: "routable", value: "false" },
            { key: "state", value: "" },
        ],
        // aic_config: [
        //     { key: "session_warning_duration", value: "" },
        //     { key: "session_expiry_duration", value: "" },
        //     { key: "vpn_check_url", value: "" },
        //     { key: "ping_interval", value: "" },
        //     { key: "ccp_url", value: "" },
        // ],
        allowed_conversation_media: [
            { key: "voice", value: "false" },
            { key: "task", value: "false" },
            { key: "chat", value: "false" },
        ],
        business_attributes: [
            { key: "scope", value: "" },
            { key: "attribute_name", value: "" },
        ],
        crm: [{ key: "crm_type", value: "Enterprise" }],
        default_outbound_queue: [{ key: "queue_name", value: "" }],
        queues_assigned: [
            { key: "queue_name", value: "" },
            { key: "channel", value: "chat" },
            { key: "priority", value: "1" },
            { key: "delay", value: "0" },
        ],
        routing_profile_concurrency: [
            { key: "max_concurrency", value: "1" },
            { key: "cross_channel_allow", value: "false" },
            { key: "media", value: "chat" },
        ],
        proficiency: [
            { key: "attributeName", value: "" },
            { key: "attributeValue", value: "" },
            { key: "level", value: "1" },
        ],
        instance_config: [
            { key: "instance_alias", value: "" },
            { key: "instance_id", value: "" },
            { key: "region", value: "" },
        ],
        default: [{ key: "", value: "" }],
    }

    const addNewKeyValuePair = (sideArrayIndex) => {
        mainArray[sideArrayIndex].push(sideArrayObject)
        setMainArray(() => [...mainArray])
    }

    const addNewobject = (name, value) => {
        if (name === "config_key") {
            switch (value) {
                case "after_call_work":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                case "agent_states":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                // case "aic_config":
                //     setMainArray([...mainArray, defaultDataFormats[value]])
                //     break
                case "allowed_conversation_media":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                case "business_attributes":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                case "crm":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                case "default_outbound_queue":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                case "queues_assigned":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                case "routing_profile_concurrency":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                case "proficiency":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                case "instance_config":
                    setMainArray([...mainArray, defaultDataFormats[value]])
                    break
                default:
                    const tempValue = [{ key: "", value: "" }]
                    setMainArray([...mainArray, tempValue])
            }
        }
    }

    const updateValue = (mainIndex, objectIndex, type, value, name, obj) => {
        if (name === "instance_alias") {
            setMainArray((prevMainArray) => {
                const instanceObj = instances.find(
                    (ele) => ele.instance_id === value
                )
                const newData = JSON.parse(JSON.stringify(prevMainArray))
                newData[mainIndex][objectIndex][type] =
                    instanceObj.instance_alias
                if (newData[mainIndex][objectIndex][type]) {
                    newData[mainIndex][1][type] = instanceObj.instance_id
                    newData[mainIndex][2][type] = instanceObj.region
                }
                return newData
            })
        } else if (name === "queues_assigned") {
            setMainArray((prevMainArray) => {
                const newData = JSON.parse(JSON.stringify(prevMainArray))
                newData[mainIndex][objectIndex][type] = value

                let channel = "voice" 

                if (value) {
                    const lowercaseValue = value.toLowerCase()

                    if (lowercaseValue.endsWith("msg")) {
                        channel = "chat"
                    } else if (
                        lowercaseValue.endsWith("vm") ||
                        lowercaseValue.endsWith("case")
                    ) {
                        channel = "task"
                    } else if (lowercaseValue.endsWith("ph")) {
                        channel = "voice"
                        newData[mainIndex][objectIndex][type] = value
                        newData.splice(mainIndex + 1, 0, [
                            { key: "queue_name", value: value },
                            { key: "channel", value: "task" },
                            { key: "priority", value: "1" },
                            { key: "delay", value: "0" },
                        ])
                    }
                }

                const channelIndex = newData[mainIndex].findIndex(
                    (item) => item.key === "channel"
                )
                newData[mainIndex][channelIndex].value = channel
                // console.log({ newData })
                return newData
            })
        } else {
            setMainArray((prevMainArray) => {
                const newData = JSON.parse(JSON.stringify(prevMainArray))
                newData[mainIndex][objectIndex][type] = value
                // console.log({ newData })
                return newData
            })
        }
    }

    const deleteKeyValuePair = (objIndex, keyIndex) => {
        const data = JSON.parse(JSON.stringify(mainArray))
        data[objIndex].splice(keyIndex, 1)
        setMainArray(() => [...data])
    }

    // const deleteObject = (objIndex) => {
    //     const data = JSON.parse(JSON.stringify(mainArray));
    //     data.splice(objIndex, 1);
    //     setMainArray(()=>[...data]);
    // }

    const cloneObject = (objIndex, obj) => {
        const newArray = [...mainArray]
        newArray.splice(objIndex + 1, 0, obj)
        setMainArray(newArray)
    }
    const checkMainValue = () => {
        const value = mainArray.map((ele) => {
            return ele
                .map((objectlist) => {
                    if (objectlist.key)
                        return { [objectlist.key]: objectlist.value }
                    else return null
                })
                .filter((ele) => ele !== null)
                .reduce(function (result, current) {
                    return Object.assign(result, current)
                }, {})
        })

        return value
    }

    useEffect(() => {
        if (props.editData) {
            if (!props.editData.group_name) {
                const tempObj = props.editData.config_values
                    .map((entry) => {
                        if (props.editData.config_key === "queues_assigned") {
                            if (!entry.hasOwnProperty("priority")) {
                                entry.priority = "1"
                            }
                            if (!entry.hasOwnProperty("delay")) {
                                entry.delay = "0"
                            }
                        }
                        const keyValues = Object.entries(entry)
                        return keyValues.map(([key, value]) => ({ key, value }))
                    })
                    .flat()
                const mappedData = props.editData.config_values.map((entry) => {
                    if (props.editData.config_key === "queues_assigned") {
                        return [
                            { key: "queue_name", value: entry.queue_name },
                            { key: "channel", value: entry.channel },
                            { key: "priority", value: entry.priority || "1" },
                            { key: "delay", value: entry.delay || "0" },
                        ]
                    }
                    const keyValues = Object.entries(entry).sort()
                    return keyValues.map(([key, value]) => ({ key, value }))
                })
                setMainArray([...mappedData])
                setInputFields([
                    {
                        agent_group_name: props.editData.agent_group_name || "",
                        config_key: props.editData.config_key || "",
                        config_values: tempObj || [{ key: "", value: "" }],
                    },
                ])
            } else {
                setInputFields([
                    {
                        agent_group_name: props.editData.group_name || "",
                        config_key: "",
                        config_values: [{ key: "", value: "" }],
                    },
                ])
            }

            if (props.editData.selectedData) {
                setActiveInstance(props.editData.selectedData)
            }
            console.log(props)
        }
    }, [props.editData])

    // const addFields = () => {
    //     const updatedFields = [...inputFields]
    //     const newInnerObj = { key: "", value: "" }
    //     updatedFields[0].config_values.push(newInnerObj)
    //     setInputFields(updatedFields)
    // }

    // const deleteFields = (index) => {
    //     const updatedFields = [...inputFields]
    //     updatedFields[0].config_values = updatedFields[0].config_values.filter(
    //         (_, i) => i !== index
    //     )
    //     setInputFields(updatedFields)
    // }

    const isValid = React.useMemo(() => {
        return (
            inputFields[0]?.agent_group_name?.trim() !== "" &&
            inputFields[0]?.config_key.trim() !== ""
            // &&
            // inputFields[0]?.config_values.every(
            //     (configValue) =>
            //         configValue.key.trim() !== "" &&
            //         configValue.value.trim() !== ""
            // )
        )
    }, [inputFields])

    const handleFormSubmit = () => {
        const data = inputFields[0]
        const mappedConfig = checkMainValue()
        const requestBody = {
            agent_group_name: data.agent_group_name.trim(),
            config_key: data.config_key.trim(),
            config_values: mappedConfig,
        }
        if (props.operation == "Add") {
            AddAgentGroupConfigRec(requestBody, props.userid)
                .then((res) => {
                    //console.log("responnnnse ", res)
                    notify(res.statusCode === 200 && res.response, "success")
                    notify(res.statusCode === 500 && res.response, "error")
                    props.setIsAddDialogOpen(false)
                    props.getAllLdapMethod()
                })
                .catch(async (error) => {
                    //console.log("error", error)
                    const value = await error
                    notify("Error in Adding Agent", "error")
                    //console.log("error", value)
                })
        } else if (props.operation == "Edit") {
            EditAgentGroupConfigRec(requestBody, props.userid)
                .then((res) => {
                    notify(res.statusCode === 200 && res.response, "success")
                    props.setIsAddDialogOpen(false)
                    props.getAllLdapMethod()
                })
                .catch(async (error) => {
                    const value = await error
                    notify("Error in Editing Agent", "error")
                    //console.log("error", value)
                })
        }
    }

    const handleAboveFeilds = (name, value) => {
        if (name === "config_key") {
            switch (value) {
                case "after_call_work":
                    setMainArray([defaultDataFormats[value]])
                    break
                case "agent_states":
                    setMainArray([defaultDataFormats[value]])
                    break
                // case "aic_config":
                //     setMainArray([defaultDataFormats[value]])
                //     break
                case "allowed_conversation_media":
                    setMainArray([defaultDataFormats[value]])
                    break
                case "business_attributes":
                    setMainArray([defaultDataFormats[value]])
                    break
                case "crm":
                    setMainArray([defaultDataFormats[value]])
                    break
                case "default_outbound_queue":
                    setMainArray([defaultDataFormats[value]])
                    break
                case "queues_assigned":
                    setMainArray([defaultDataFormats[value]])
                    break
                case "routing_profile_concurrency":
                    setMainArray([defaultDataFormats[value]])
                    break
                case "proficiency":
                    setMainArray([defaultDataFormats[value]])
                    break
                case "instance_config":
                    setMainArray([defaultDataFormats[value]])
                    break
                default:
                    const tempValue = [{ key: "", value: "" }]
                    setMainArray([tempValue])
            }
        }
        const updatedFields = [...inputFields]
        updatedFields[0][name] = value
        setInputFields(updatedFields)
    }

    const selectedObjectsForDelete = (index) => {
        setObjectSelected((prev) => {
            if (prev.has(index)) {
                prev.delete(index)
                return new Set([...Array.from(prev)])
            }
            return new Set([...Array.from(prev), index])
        })
    }

    const helperFunction = (name) => {
        const attributeNameObject = name.find(
            (obj) => obj.key === "attributeName"
        )?.value
        const newValue = convertArrayToInput(attributeList[attributeNameObject])
        return newValue
    }

    // console.log("queueList ==>>>>", queueList)

    const renderView = (value) => {
        let view
        switch (value) {
            case "after_call_work":
                view = mainArray?.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj?.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>

                                                    <NumberField
                                                        width={"100%"}
                                                        isQuiet
                                                        isRequired
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].value
                                                        }
                                                        formatOptions={{
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        }}
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "value",
                                                                value
                                                            )
                                                        }
                                                        necessityIndicator="label"
                                                        minValue={0}
                                                        defaultValue={0}
                                                    />
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "agent_states":
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    {mainArray[objIndex][
                                                        keyIndex
                                                    ].key === "routable" ? (
                                                        <Picker
                                                            width={"100%"}
                                                            items={[
                                                                {
                                                                    id: "true",
                                                                    name:
                                                                        "true",
                                                                },
                                                                {
                                                                    id: "false",
                                                                    name:
                                                                        "false",
                                                                },
                                                            ]}
                                                            selectedKey={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            defaultSelectedKey={
                                                                !props.editData
                                                                    ? "false"
                                                                    : mainArray[
                                                                          objIndex
                                                                      ][
                                                                          keyIndex
                                                                      ].value
                                                            }
                                                            onSelectionChange={(
                                                                id
                                                            ) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    id
                                                                )
                                                            }
                                                        >
                                                            {(item) => (
                                                                <Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </Item>
                                                            )}
                                                        </Picker>
                                                    ) : (
                                                        <Picker
                                                            width={"100%"}
                                                            items={statusList}
                                                            selectedKey={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            defaultSelectedKey={
                                                                !props.editData
                                                                    ? "Available"
                                                                    : mainArray[
                                                                          objIndex
                                                                      ][
                                                                          keyIndex
                                                                      ].value
                                                            }
                                                            onSelectionChange={(
                                                                id
                                                            ) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    id
                                                                )
                                                            }
                                                        >
                                                            {(item) => (
                                                                <Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </Item>
                                                            )}
                                                        </Picker>
                                                    )}
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            // case "aic_config":
            //     view = mainArray.map((obj, objIndex) => {
            //         return (
            //             <div
            //                 key={objIndex}
            //                 className={
            //                     objectSelected.has(objIndex)
            //                         ? "cardSection active"
            //                         : "cardSection"
            //                 }
            //             >
            //                 <section className="checkboxSection">
            //                     <Checkbox
            //                         aria-label="selectObject"
            //                         isSelected={objectSelected.has(objIndex)}
            //                         onChange={() =>
            //                             selectedObjectsForDelete(objIndex)
            //                         }
            //                     ></Checkbox>
            //                 </section>
            //                 <Divider size="S" orientation="vertical" />
            //                 <section className="FormSection">
            //                     {obj?.length >= 1 &&
            //                         obj.map((_keyVal, keyIndex) => {
            //                             return (
            //                                 <View
            //                                     key={keyIndex}
            //                                     UNSAFE_className="feild"
            //                                 >
            //                                     <Flex gap={"size-100"}>
            //                                         <TextField
            //                                             width={"100%"}
            //                                             aria-label={
            //                                                 "key" + keyIndex
            //                                             }
            //                                             key={"key" + keyIndex}
            //                                             value={
            //                                                 mainArray[objIndex][
            //                                                     keyIndex
            //                                                 ].key
            //                                             }
            //                                             onChange={(value) =>
            //                                                 updateValue(
            //                                                     objIndex,
            //                                                     keyIndex,
            //                                                     "key",
            //                                                     value
            //                                                 )
            //                                             }
            //                                             isDisabled={true}
            //                                             isQuiet
            //                                         />
            //                                         <strong>:</strong>
            //                                         <TextField
            //                                             width={"100%"}
            //                                             aria-label={
            //                                                 "value" + keyIndex
            //                                             }
            //                                             key={"value" + keyIndex}
            //                                             value={
            //                                                 mainArray[objIndex][
            //                                                     keyIndex
            //                                                 ]?.value
            //                                             }
            //                                             onChange={(value) =>
            //                                                 updateValue(
            //                                                     objIndex,
            //                                                     keyIndex,
            //                                                     "value",
            //                                                     value
            //                                                 )
            //                                             }
            //                                             isQuiet
            //                                         />
            //                                     </Flex>
            //                                 </View>
            //                             )
            //                         })}
            //                 </section>
            //                 <Divider size="S" orientation="vertical" />
            //                 <section className="actionsSection">
            //                     <Button
            //                         aria-label="Clone Object"
            //                         variant="secondary"
            //                         staticColor="black"
            //                         style="fill"
            //                         onPress={() => cloneObject(objIndex, obj)}
            //                     >
            //                         <Copy />
            //                     </Button>
            //                 </section>
            //             </div>
            //         )
            //     })
            //     break
            case "allowed_conversation_media":
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    <Picker
                                                        items={[
                                                            {
                                                                id: "true",
                                                                name: "true",
                                                            },
                                                            {
                                                                id: "false",
                                                                name: "false",
                                                            },
                                                        ]}
                                                        selectedKey={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].value
                                                        }
                                                        defaultSelectedKey={
                                                            !props.editData
                                                                ? "false"
                                                                : mainArray[
                                                                      objIndex
                                                                  ][keyIndex]
                                                                      .value
                                                        }
                                                        onSelectionChange={(
                                                            id
                                                        ) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "value",
                                                                id
                                                            )
                                                        }
                                                    >
                                                        {(item) => (
                                                            <Item
                                                                key={item.name}
                                                            >
                                                                {item.name}
                                                            </Item>
                                                        )}
                                                    </Picker>
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "business_attributes":
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    {
                                                        <TextField
                                                            width={"100%"}
                                                            aria-label={
                                                                "value" +
                                                                keyIndex
                                                            }
                                                            key={
                                                                "value" +
                                                                keyIndex
                                                            }
                                                            value={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            onChange={(value) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    value
                                                                )
                                                            }
                                                            isQuiet
                                                        />
                                                    }
                                                    {/* <View width={'20%'}>
                                        {(obj.length > 1) && <Button aria-label="addKeyValue" variant="secondary" staticColor="black" style="fill" onPress={()=>deleteKeyValuePair(objIndex, keyIndex)}><Delete/></Button>}
                                        {(obj.length - 1 == keyIndex) && <Button aria-label="addKeyValue" variant="secondary" staticColor="black" style="fill" onPress={()=>addNewKeyValuePair(objIndex)}><Add /></Button>}
                                        </View> */}
                                                    {/* </section>  */}
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "crm":
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    <Picker
                                                        width={"100%"}
                                                        items={[
                                                            {
                                                                id: "None",
                                                                name: "None",
                                                            },
                                                            {
                                                                id:
                                                                    "Enterprise",
                                                                name:
                                                                    "Enterprise",
                                                            },
                                                        ]}
                                                        selectedKey={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].value
                                                        }
                                                        defaultSelectedKey={
                                                            !props.editData
                                                                ? "Enterprise"
                                                                : mainArray[
                                                                      objIndex
                                                                  ][keyIndex]
                                                                      .value
                                                        }
                                                        onSelectionChange={(
                                                            id
                                                        ) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "value",
                                                                id
                                                            )
                                                        }
                                                    >
                                                        {(item) => (
                                                            <Item
                                                                key={item.name}
                                                            >
                                                                {item.name}
                                                            </Item>
                                                        )}
                                                    </Picker>
                                                    {/* <TextField
                                                        width={"100%"}
                                                        aria-label={
                                                            "value" + keyIndex
                                                        }
                                                        key={"value" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].value
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "value",
                                                                value
                                                            )
                                                        }
                                                        isQuiet
                                                    /> */}
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "default_outbound_queue":
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    <ComboBox
                                                        width={"100%"}
                                                        isQuiet
                                                        defaultItems={queueList}
                                                        selectedKey={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].value
                                                        }
                                                        defaultSelectedKey={
                                                            !props.editData
                                                                ? queueList[0]
                                                                      ?.name
                                                                : mainArray[
                                                                      objIndex
                                                                  ][keyIndex]
                                                                      .value
                                                        }
                                                        onSelectionChange={(
                                                            id
                                                        ) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "value",
                                                                id
                                                            )
                                                        }
                                                    >
                                                        {(item) => (
                                                            <Item
                                                                key={item.name}
                                                            >
                                                                {item.name}
                                                            </Item>
                                                        )}
                                                    </ComboBox>
                                                    {/* <TextField
                                                        width={"100%"}
                                                        aria-label={
                                                            "value" + keyIndex
                                                        }
                                                        key={"value" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].value
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "value",
                                                                value
                                                            )
                                                        }
                                                        isQuiet
                                                    /> */}
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "queues_assigned":
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    {mainArray[objIndex][
                                                        keyIndex
                                                    ].key === "queue_name" ? (
                                                        // <Picker
                                                        //     width={"100%"}
                                                        //     items={queueList}
                                                        //     selectedKey={
                                                        //         mainArray[
                                                        //             objIndex
                                                        //         ][keyIndex]
                                                        //             .value
                                                        //     }
                                                        //     defaultSelectedKey={
                                                        //         !props.editData
                                                        //             ? "chat"
                                                        //             : mainArray[
                                                        //                   objIndex
                                                        //               ][
                                                        //                   keyIndex
                                                        //               ].value
                                                        //     }
                                                        //     onSelectionChange={(
                                                        //         id
                                                        //     ) =>
                                                        //         updateValue(
                                                        //             objIndex,
                                                        //             keyIndex,
                                                        //             "value",
                                                        //             id
                                                        //         )
                                                        //     }
                                                        // >
                                                        //     {(item) => (
                                                        //         <Item
                                                        //             key={
                                                        //                 item.name
                                                        //             }
                                                        //         >
                                                        //             {item.name}
                                                        //         </Item>
                                                        //     )}
                                                        // </Picker>
                                                        <ComboBox
                                                            isQuiet
                                                            width={"100%"}
                                                            defaultItems={
                                                                queueList
                                                            }
                                                            selectedKey={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            defaultSelectedKey={
                                                                !props.editData
                                                                    ? "chat"
                                                                    : mainArray[
                                                                          objIndex
                                                                      ][
                                                                          keyIndex
                                                                      ].value
                                                            }
                                                            onSelectionChange={(
                                                                id
                                                            ) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    id,
                                                                    "queues_assigned",
                                                                    obj
                                                                )
                                                            }
                                                        >
                                                            {(item) => (
                                                                <Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </Item>
                                                            )}
                                                        </ComboBox>
                                                    ) : mainArray[objIndex][
                                                          keyIndex
                                                      ].key === "channel" ? (
                                                        <TextField
                                                            width={"100%"}
                                                            isQuiet
                                                            isReadOnly
                                                            value={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            // defaultSelectedKey={
                                                            //     mainArray[
                                                            //         objIndex
                                                            //     ][keyIndex]
                                                            //         .value
                                                            // }
                                                            // onSelectionChange={(
                                                            //     id
                                                            // ) =>
                                                            //     updateValue(
                                                            //         objIndex,
                                                            //         keyIndex,
                                                            //         "value",
                                                            //         id
                                                            //     )
                                                            // }
                                                        />
                                                    ) : (
                                                        // <ComboBox
                                                        //     width={"100%"}
                                                        //     isQuiet
                                                        //     isReadOnly
                                                        //     defaultItems={
                                                        //         channels
                                                        //     }
                                                        //     selectedKey={
                                                        //         mainArray[
                                                        //             objIndex
                                                        //         ][keyIndex]
                                                        //             .value
                                                        //     }
                                                        //     defaultSelectedKey={
                                                        //         mainArray[
                                                        //             objIndex
                                                        //         ][keyIndex]
                                                        //             .value
                                                        //     }
                                                        //     // onSelectionChange={(
                                                        //     //     id
                                                        //     // ) =>
                                                        //     //     updateValue(
                                                        //     //         objIndex,
                                                        //     //         keyIndex,
                                                        //     //         "value",
                                                        //     //         id
                                                        //     //     )
                                                        //     // }
                                                        // >
                                                        //     {(item) => (
                                                        //         <Item
                                                        //             key={
                                                        //                 item.name
                                                        //             }
                                                        //         >
                                                        //             {item.name}
                                                        //         </Item>
                                                        //     )}
                                                        // </ComboBox>
                                                        <NumberField
                                                            width={"100%"}
                                                            isQuiet
                                                            isRequired
                                                            isReadOnly
                                                            formatOptions={{
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            }}
                                                            value={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            onChange={(value) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    value
                                                                )
                                                            }
                                                            necessityIndicator="label"
                                                            minValue={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .key ===
                                                                "delay"
                                                                    ? 0
                                                                    : 1
                                                            }
                                                            defaultValue={0}
                                                        />
                                                    )}
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "routing_profile_concurrency":
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    {mainArray[objIndex][
                                                        keyIndex
                                                    ].key ===
                                                    "max_concurrency" ? (
                                                        <NumberField
                                                            width={"100%"}
                                                            isQuiet
                                                            isRequired
                                                            formatOptions={{
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            }}
                                                            value={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            onChange={(value) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    value
                                                                )
                                                            }
                                                            necessityIndicator="label"
                                                            minValue={0}
                                                            defaultValue={0}
                                                        />
                                                    ) : mainArray[objIndex][
                                                          keyIndex
                                                      ].key ===
                                                      "cross_channel_allow" ? (
                                                        <Picker
                                                            width={"100%"}
                                                            items={[
                                                                {
                                                                    id: "true",
                                                                    name:
                                                                        "true",
                                                                },
                                                                {
                                                                    id: "false",
                                                                    name:
                                                                        "false",
                                                                },
                                                            ]}
                                                            selectedKey={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            defaultSelectedKey={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            onSelectionChange={(
                                                                id
                                                            ) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    id
                                                                )
                                                            }
                                                        >
                                                            {(item) => (
                                                                <Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </Item>
                                                            )}
                                                        </Picker>
                                                    ) : (
                                                        <Picker
                                                            width={"100%"}
                                                            items={[
                                                                {
                                                                    id: "chat",
                                                                    name:
                                                                        "chat",
                                                                },
                                                                {
                                                                    id: "voice",
                                                                    name:
                                                                        "voice",
                                                                },
                                                                {
                                                                    id: "task",
                                                                    name:
                                                                        "task",
                                                                },
                                                                // {
                                                                //     id: "case",
                                                                //     name:
                                                                //         "case",
                                                                // },
                                                            ]}
                                                            selectedKey={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            defaultSelectedKey={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            onSelectionChange={(
                                                                id
                                                            ) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    id
                                                                )
                                                            }
                                                        >
                                                            {(item) => (
                                                                <Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </Item>
                                                            )}
                                                        </Picker>
                                                    )}
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "proficiency":
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    {mainArray[objIndex][
                                                        keyIndex
                                                    ].key === "level" ? (
                                                        <NumberField
                                                            width={"100%"}
                                                            isQuiet
                                                            isRequired
                                                            formatOptions={{
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            }}
                                                            value={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            onChange={(value) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    value
                                                                )
                                                            }
                                                            necessityIndicator="label"
                                                            minValue={1}
                                                            maxValue={5}
                                                            defaultValue={1}
                                                        />
                                                    ) : mainArray[objIndex][
                                                          keyIndex
                                                      ].key ===
                                                      "attributeName" ? (
                                                        <ComboBox
                                                            width={"100%"}
                                                            isQuiet
                                                            defaultItems={convertArrayToInput(
                                                                Object?.keys(
                                                                    attributeList
                                                                )
                                                            )}
                                                            selectedKey={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    .value
                                                            }
                                                            onSelectionChange={(
                                                                id
                                                            ) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    id,
                                                                    "attributeName"
                                                                )
                                                            }
                                                        >
                                                            {(item) => (
                                                                <Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </Item>
                                                            )}
                                                        </ComboBox>
                                                    ) : mainArray[objIndex][
                                                          keyIndex
                                                      ].key ===
                                                      "attributeValue" ? (
                                                        <>
                                                            {/* <span>{helperFunction(mainArray[objIndex])}</span> */}
                                                            <ComboBox
                                                                width={"100%"}
                                                                isQuiet
                                                                defaultItems={helperFunction(
                                                                    mainArray[
                                                                        objIndex
                                                                    ]
                                                                )}
                                                                selectedKey={
                                                                    mainArray[
                                                                        objIndex
                                                                    ][keyIndex]
                                                                        ?.value
                                                                }
                                                                onSelectionChange={(
                                                                    id
                                                                ) =>
                                                                    updateValue(
                                                                        objIndex,
                                                                        keyIndex,
                                                                        "value",
                                                                        id
                                                                    )
                                                                }
                                                            >
                                                                {(item) => (
                                                                    <Item
                                                                        key={
                                                                            item.name
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </Item>
                                                                )}
                                                            </ComboBox>
                                                        </>
                                                    ) : null}
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "instance_config":
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"50%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ].key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isDisabled={true}
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    {mainArray[objIndex][
                                                        keyIndex
                                                    ].key ===
                                                    "instance_alias" ? (
                                                        <ComboBox
                                                            width={"100%"}
                                                            isQuiet
                                                            defaultItems={
                                                                instances || []
                                                            }
                                                            selectedKey={
                                                                instances?.filter(
                                                                    (
                                                                        instance
                                                                    ) =>
                                                                        instance.instance_id ===
                                                                            mainArray[
                                                                                objIndex
                                                                            ][
                                                                                keyIndex
                                                                            ]
                                                                                .value ||
                                                                        instance.instance_alias ===
                                                                            mainArray[
                                                                                objIndex
                                                                            ][
                                                                                keyIndex
                                                                            ]
                                                                                .value
                                                                )[0]
                                                                    ?.instance_id ||
                                                                ""
                                                            }
                                                            onSelectionChange={(
                                                                id
                                                            ) =>
                                                                updateValue(
                                                                    objIndex,
                                                                    keyIndex,
                                                                    "value",
                                                                    id,
                                                                    "instance_alias"
                                                                )
                                                            }
                                                        >
                                                            {(item) =>
                                                                console.log(
                                                                    "item",
                                                                    item
                                                                ) || (
                                                                    <Item
                                                                        key={
                                                                            item.instance_id
                                                                        }
                                                                    >
                                                                        {
                                                                            item.instance_alias
                                                                        }
                                                                    </Item>
                                                                )
                                                            }
                                                        </ComboBox>
                                                    ) : (
                                                        <TextField
                                                            width={"100%"}
                                                            aria-label={
                                                                "value" +
                                                                keyIndex
                                                            }
                                                            key={
                                                                "value" +
                                                                keyIndex
                                                            }
                                                            value={
                                                                mainArray[
                                                                    objIndex
                                                                ][keyIndex]
                                                                    ?.value
                                                            }
                                                            isReadOnly
                                                            // onChange={(value) =>
                                                            //     updateValue(
                                                            //         objIndex,
                                                            //         keyIndex,
                                                            //         "value",
                                                            //         value
                                                            //     )
                                                            // }
                                                            isQuiet
                                                        />
                                                    )}
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            default:
                view = mainArray.map((obj, objIndex) => {
                    return (
                        <div
                            key={objIndex}
                            className={
                                objectSelected.has(objIndex)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(objIndex)}
                                    onChange={() =>
                                        selectedObjectsForDelete(objIndex)
                                    }
                                ></Checkbox>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                {obj.length >= 1 &&
                                    obj.map((_keyVal, keyIndex) => {
                                        return (
                                            <View
                                                key={keyIndex}
                                                UNSAFE_className="feild"
                                            >
                                                <Flex gap={"size-100"}>
                                                    <TextField
                                                        width={"100%"}
                                                        aria-label={
                                                            "key" + keyIndex
                                                        }
                                                        key={"key" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ]?.key
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "key",
                                                                value
                                                            )
                                                        }
                                                        isQuiet
                                                    />
                                                    <strong>:</strong>
                                                    <TextField
                                                        width={"100%"}
                                                        aria-label={
                                                            "value" + keyIndex
                                                        }
                                                        key={"value" + keyIndex}
                                                        value={
                                                            mainArray[objIndex][
                                                                keyIndex
                                                            ]?.value
                                                        }
                                                        onChange={(value) =>
                                                            updateValue(
                                                                objIndex,
                                                                keyIndex,
                                                                "value",
                                                                value
                                                            )
                                                        }
                                                        isQuiet
                                                    />
                                                    <Flex width={"50%"}>
                                                        {obj.length > 1 && (
                                                            <Button
                                                                aria-label="addKeyValue"
                                                                variant="secondary"
                                                                staticColor="black"
                                                                style="fill"
                                                                onPress={() =>
                                                                    deleteKeyValuePair(
                                                                        objIndex,
                                                                        keyIndex
                                                                    )
                                                                }
                                                            >
                                                                <Delete />
                                                            </Button>
                                                        )}
                                                        {obj.length - 1 ==
                                                            keyIndex && (
                                                            <Button
                                                                aria-label="addKeyValue"
                                                                variant="secondary"
                                                                staticColor="black"
                                                                style="fill"
                                                                onPress={() =>
                                                                    addNewKeyValuePair(
                                                                        objIndex
                                                                    )
                                                                }
                                                            >
                                                                <Add />
                                                            </Button>
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </View>
                                        )
                                    })}
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(objIndex, obj)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
        }
        return view
    }

    // const handleFormChange = (inputName, event, index) => {
    //     const value = event
    //     const updatedFields = [...inputFields]
    //     updatedFields[0].config_values[index][inputName] = value
    //     setInputFields(updatedFields)
    // }

    // const performAction = (value, index) => {
    //     switch (value) {
    //         case "add":
    //             addFields()
    //             // editData.current = null;
    //             // setIsAddDialogOpen(true);
    //             // setOperation("Add")
    //             break
    //         case "edit":
    //             // editData.current =  allContacts.find((d) => (d.ruleId == contactNumber));
    //             // setIsAddDialogOpen(true);
    //             // setOperation("Edit")
    //             break
    //         case "delete":
    //             deleteFields(index)
    //             // currentContactSelected.current = `${contactNumber}`;
    //             // setIsDeleteDialogOpen(true);
    //             break
    //         case "bulkDelete":
    //             // setIsDeleteDialogOpen(true);
    //             break
    //     }
    // }

    const deleteSelectedObjects = () => {
        const arrayofIndexes = Array.from(objectSelected)
        const data = JSON.parse(JSON.stringify(mainArray)).filter(
            (obj, index) => !arrayofIndexes.includes(index)
        )
        setMainArray(() => [...data])
        setObjectSelected(new Set([]))
    }

    const resetGroupForm = () => {
        if (props.editData) {
            props.editData.config_values
                .map((entry) => {
                    const keyValues = Object.entries(entry)
                    return keyValues.map(([key, value]) => ({ key, value }))
                })
                .flat()
            const mappedData = props.editData.config_values.map((entry) => {
                const keyValues = Object.entries(entry)
                return keyValues.map(([key, value]) => ({ key, value }))
            })
            setMainArray([...mappedData])
        } else {
            setMainArray([sideArray])
        }
    }

    // useEffect(() => {
    //     console.log({ activeInstance })
    // }, [activeInstance])

    return (
        <DialogTrigger
            type="modal"
            mobileType="modal"
            isOpen={props.isDialogOpened}
            defaultOpen={false}
        >
            <ActionButton isHidden isQuiet></ActionButton>
            <Dialog size="L" width={772}>
                <Heading>Agent Group Config</Heading>
                <Divider />
                <Content>
                    <Flex direction={"row"} gap={"size-300"}>
                        <TextField
                            label="Agent Group Name"
                            name="agent_group_name"
                            value={inputFields[0]?.agent_group_name}
                            onChange={(value) => {
                                const valueWithNoSpace = value.replace(
                                    /\s/g,
                                    ""
                                )
                                handleAboveFeilds(
                                    "agent_group_name",
                                    valueWithNoSpace
                                )
                                // handleAboveFeilds("agent_group_name", value)
                            }}
                            isDisabled={props.operation == "Edit"}
                        />

                        <TextField
                            label="Config Key"
                            name="config_key"
                            value={inputFields[0]?.config_key}
                            onChange={(value) => {
                                handleAboveFeilds("config_key", value)
                            }}
                            isDisabled={
                                props.operation == "Edit" &&
                                !props.editData?.group_name
                            }
                        />
                    </Flex>
                    <Divider size="S" marginY={10} />
                    <View>
                        <Flex
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <View>
                                <Header>
                                    <strong>Config Value</strong>
                                </Header>
                            </View>
                            <View>
                                <ButtonGroup UNSAFE_className="noBorder">
                                    <Button
                                        aria-label="AddObject"
                                        variant="secondary"
                                        staticColor="black"
                                        style="fill"
                                        onPress={() =>
                                            addNewobject(
                                                "config_key",
                                                inputFields[0]?.config_key
                                            )
                                        }
                                    >
                                        <Add />
                                    </Button>
                                    <Button
                                        aria-label="DeleteSelectedObjects"
                                        variant="secondary"
                                        staticColor="black"
                                        style="fill"
                                        onPress={() => deleteSelectedObjects()}
                                        isDisabled={!objectSelected.size}
                                    >
                                        <Delete />
                                    </Button>
                                    <Button
                                        aria-label="ResetGroupValue"
                                        variant="secondary"
                                        staticColor="black"
                                        style="fill"
                                        onPress={() => resetGroupForm()}
                                    >
                                        <Undo />
                                    </Button>
                                </ButtonGroup>
                            </View>
                        </Flex>
                        <Flex
                            direction={"column"}
                            justifyContent={"center"}
                            gap={"size-100"}
                            marginTop={"size-100"}
                        >
                            {renderView(inputFields[0].config_key)}
                        </Flex>
                    </View>
                </Content>
                <Footer alignSelf={"flex-start"}>
                    <Flex flexBasis={"100%"}>
                        <View>
                            <ButtonGroup>
                                <Button
                                    variant="secondary"
                                    onPress={() =>
                                        props.setIsAddDialogOpen(false)
                                    }
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onPress={handleFormSubmit}
                                    isDisabled={isValid ? false : true}
                                >
                                    Submit
                                </Button>
                            </ButtonGroup>
                        </View>
                    </Flex>
                </Footer>
            </Dialog>
        </DialogTrigger>
    )
}

export default DynamicFieldForm
