import React, { useEffect } from "react"
import store from "../store"
import { updatedUserPermissionsKeys, updateUserPermissions,userRolesAndPermissions } from "../actions/login"
import { getUserPermissions, getUsersDetailsPermissions } from "../utilities/api/admin-api"
import { getUserInfo, userPermissionsAPI } from "../utilities/api/userInfo"
import { ProgressCircle } from "@adobe/react-spectrum"

const LoginCallback = () => {
    useEffect(() => {
        getUserInfo()
            .then((res) => {
                if (res.userid) {
                    userPermissionsAPI(res.userid).then((res) => {
                        store.dispatch(userRolesAndPermissions(res));
                        return (location.href = "/menu")
                      });
                    // getUsersDetailsPermissions(res.userid).then((resp)=>{
                    //     console.log({resp}) 
                    //     // let firstlevetKeys= Object.keys(resp);
                    //     // let secondLevelKeys = firstlevetKeys?.map(key => resp[key]?.map(sub=> sub?.subService));
                    //     // console.log([...firstlevetKeys, ...secondLevelKeys].flat());
                    //     store.dispatch(updateUserPermissions({availableRoutes:[...firstlevetKeys, ...secondLevelKeys].flat(), permissionObject: resp}));
                    //     return (location.href = "/menu")
                    // })
                    // getUserPermissions(res).then((response) => {
                    //     store.dispatch(updateUserPermissions(response))
                    //     return (location.href = "/menu")
                    // })
                }
            })
            .catch((err) => {
                console.log("Error from call ", err)
            })
    }, [])

    return (
        <div
            style={{
                paddingTop: "15%",
                paddingLeft: "45%",
                position: "absolute",
                zIndex: "10",
            }}
        >
            <ProgressCircle aria-label="Loading…" isIndeterminate size="L" />
        </div>
    )
}

export default LoginCallback
