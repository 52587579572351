import { createStore } from 'redux';
import { rootReducer } from '../reducers/index';

const store = createStore(rootReducer);
export default store;


// import {createStore, applyMiddleware, compose} from 'redux'
// import { rootReducer } from '../reducers/index';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// // const store = createStore(rootReducer);
// const store = createStore(rootReducer, composeEnhancers({ trace: true })(applyMiddleware()))


// export default store;
