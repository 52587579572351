import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, TextField
} from '@adobe/react-spectrum';

const AddSRSProductFamily = (props) => {
    const [productCode, setproductCode] = useState('');
    const [caseRouting, setcaseRouting] = useState('');
    const [productFamily, setproductFamily] = useState('');
    const [isproductCodeValid, setIsproductCodeValid] = useState(true);
    const [iscaseRoutingValid, setIscaseRoutingValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('productCode is invalid');

    useEffect(() => {
        if (props.editData) {
            setproductCode(props.editData.productCode);
            setcaseRouting(props.editData.caseRouting);
            setproductFamily(props.editData.productFamily);
        }
    }, []);

    const checkcaseRoutingproductCodeValidation = (productCodeData,caseRoutingData) => {
        const matchFound = props.existingData.filter((data) => (data.productCode == productCodeData && data.caseRouting == caseRoutingData));
        if(matchFound.length){
            setIsproductCodeValid(false);
            setErrorMessage('record already exists');
        }
    };

    useEffect(()=>{
        if(!props.editData){
            checkcaseRoutingproductCodeValidation(productCode,caseRouting);
        }
    },[productCode,caseRouting]);

    const handlePhoneChange = ((value) => {
        setproductCode(value);
        setIsproductCodeValid(value && (/^[a-zA-Z0-9_-]{3,50}$/.test(value.trim())) ? true: false);
        if(value && !(/^[a-zA-Z0-9_-]{3,50}$/.test(value.trim()))){
            setErrorMessage('productCode is invalid');
        }
        // checkcaseRoutingproductCodeValidation();
        // console.log(isproductCodeValid);
    });

    const handelcaseRoutingChanges = (value) =>{
        setcaseRouting(value.trim());
        setIscaseRoutingValid(value && (/^[a-zA-Z0-9_-]{3,50}$/.test(value.trim())) ? true: false);
        // checkcaseRoutingproductCodeValidation();
    };

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    };
    const onSaveHandler = () => {
        const contactData = {
            productCode: productCode,
            caseRouting: caseRouting,
            productFamily:productFamily
        };
        props.handleCloseDialog(true, contactData);
    };

    // const checkJson = (value) => {
    //     try {
    //         let parsedValue = JSON.parse(value);
    //         if(!Object.keys(parsedValue).length){
    //             console.log({parsedValue});
    //             return false;
    //         }
    //     } catch (e) {
    //         return false;
    //     }
    //     return true;
    // };

    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="M">
                        <Heading>{props.operation} productCode Test Data</Heading>
                        <Divider />
                        <Content>
                            <Form isRequired>
                                {!productCode && <TextField label="productCode" autoFocus
                                    value={productCode}
                                    errorMessage={isproductCodeValid ? '' : errorMessage}
                                    onChange={handlePhoneChange}
                                    isRequired
                                    isQuiet />}
                                {productCode && <TextField label="productCode" autoFocus
                                    value={productCode}
                                    validationState={isproductCodeValid ? 'valid' : 'invalid'}
                                    errorMessage={isproductCodeValid ? '' : errorMessage}
                                    onChange={handlePhoneChange}
                                    isRequired
                                    isQuiet />}
                                <TextField label="caseRouting"
                                    value={caseRouting}
                                    validationState={iscaseRoutingValid ? 'valid' : 'invalid'}
                                    errorMessage={iscaseRoutingValid ? '' : "caseRouting is required"}
                                    onChange={handelcaseRoutingChanges}
                                    isRequired
                                    isQuiet />
                                <TextField label="Product Family"
                                    value={productFamily}
                                    validationState={(!productFamily.length ||(productFamily.length>=1)) ? 'valid' : 'invalid'}
                                    onChange={setproductFamily}
                                    isRequired
                                    isQuiet
                                    />
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="secondary" onPress={onCloseHandler}>Cancel</Button>
                            <Button variant="primary" onPress={onSaveHandler} isDisabled={ !productCode || !isproductCodeValid || !caseRouting || !productFamily}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddSRSProductFamily;