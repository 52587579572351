import React from "react"
import { Item, TabList, TabPanels, Tabs, Well } from "@adobe/react-spectrum"
import { connect } from "react-redux"
import AlertService from "../AlertService/AlertService"
// import SpamCallersList from "../Phoenix/ChildComponents/CommonConfig/spamContactsDashboard/SpamCallersList"
// import ServiceList from "../ServiceDashboard/ServiceList"
// import DualSystemUsersList from "../DualSystemUsers/DualSystemUsersList"
// import ServiceTestList from "../SRS/ServiceTestData/ServiceTestList"
// import SRSProductFamily from "../SRS/SRSProductFamily/SRSProductFamily"
// import InteractionReportAttributes from "../InteractionReportsAttributes/InteractionReportAttributes"
// import AgentSkillList from "../AgentSkills/AgentSkills"
// import SRSRules from "../SRSRules/SRSRules";
import PhoenixOperations from "../Phoenix/AmazonConnect"
import SRS from "../SRS/SRS"

function mapStateToProps(state) {
    return {
        login: state.login,
        permissions:
            null != state.login.authorizedAccess
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

class Config extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: true,
        }
    }

    render() {
        return (
            <Well
                style={{
                    minHeight: "75vh",
                    marginRight: "1%",
                    marginLeft: "1%",
                }}
            >
                <Tabs aria-label="Audit Reports">
                    <TabList>
                        {(this.props.permissions.includes(
                            "Agent Group Mapping"
                        ) ||
                            this.props.permissions.includes(
                                "Agent Group Mapping Admin"
                            ) ||
                            this.props.permissions.includes(
                                "Agent Group Config Mapping"
                            ) ||
                            this.props.permissions.includes(
                                "Agent Group Config Mapping Admin"
                            ) ||
                            this.props.permissions.includes(
                                "Default Config Mapping"
                            ) ||
                            this.props.permissions.includes(
                                "Default Config Mapping Admin"
                            ) ||
                            this.props.permissions.includes(
                                "Voice Compliance"
                            ) ||
                            this.props.permissions.includes(
                                "Voice Compliance Admin"
                            ) ||
                            this.props.permissions.includes(
                                "Routing Flow Config"
                            ) ||
                            this.props.permissions.includes(
                                "Routing Flow Config Admin"
                            ) ||
                            this.props.permissions.includes(
                                "Attribute List Mapping"
                            ) ||
                            this.props.permissions.includes(
                                "Attribute List Mapping Admin"
                            )) && (
                            <Item key="amazon_connect">AMAZON CONNECT</Item>
                        )}

                        {(this.props.permissions.includes("Alert Service") ||
                            this.props.permissions.includes(
                                "Alert Service Admin"
                            )) && <Item key="alert_service">ALERT</Item>}

                        {/* {(this.props.permissions.includes("Spam Callers") ||
                            this.props.permissions.includes(
                                "Spam Callers Admin"
                            )) && <Item key="spam_calers">SPAM CALLERS</Item>} */}

                        {(this.props.permissions.includes(
                            "System Test Data Admin"
                        ) ||
                            this.props.permissions.includes(
                                "System Test Data"
                            ) ||
                            this.props.permissions.includes(
                                "SRS Product Family Data Admin"
                            ) ||
                            this.props.permissions.includes(
                                "SRS Product Family Data"
                            ) ||
                            this.props.permissions.includes("SRS Rules") ||
                            this.props.permissions.includes(
                                "SRS Rules Admin"
                            )) && <Item key="srs">SRS</Item>}

                        {/* {(this.props.permissions.includes("Dual System") ||
                            this.props.permissions.includes(
                                "Dual System Admin"
                            )) && (
                            <Item key="dual_system_users">
                                DUAL SYSTEM USERS
                            </Item>
                        )}
                        {(this.props.permissions.includes("System Test Data") ||
                            this.props.permissions.includes(
                                "System Test Data Admin"
                            )) && (
                            <Item key="system_test_data">SYSTEM TEST DATA</Item>
                        )}
                        {(this.props.permissions.includes(
                            "SRS Product Family Data"
                        ) ||
                            this.props.permissions.includes(
                                "SRS Product Family Data Admin"
                            )) && (
                            <Item key="srs_product_family">
                                SRS PRODUCT FAMILY
                            </Item>
                        )}

                        {(this.props.permissions.includes(
                            "Interaction Report Attributes Data"
                        ) ||
                            this.props.permissions.includes(
                                "Interaction Report Attributes Data Admin"
                            )) && (
                            <Item key="interaction_report_attributes">
                                INTERACTION REPORT ATTRIBUTES
                            </Item>
                        )}

                        {(this.props.permissions.includes("SRS Rules Data") ||
                            this.props.permissions.includes(
                                "SRS Rules Data Admin"
                            )) && (<Item key="srs_rules">SRS RULES</Item>)} */}
                        {/* <Item key="agentSkill">Agentskill</Item> */}
                    </TabList>
                    <TabPanels>
                        <Item key="amazon_connect">
                            <PhoenixOperations />
                        </Item>
                        <Item key="alert_service">
                            <AlertService />
                        </Item>
                        {/* <Item key="spam_calers">
                            <SpamCallersList />
                        </Item> */}
                        <Item key="srs">
                            <SRS />
                        </Item>

                        {/* <Item key="service_test_data">
                            <ServiceList />
                        </Item> */}

                        {/* <Item key="dual_system_users">
                            <DualSystemUsersList />
                        </Item> */}
                        {/* <Item key="system_test_data">
                            <ServiceTestList />
                        </Item> */}
                        {/* <Item key="srs_product_family">
                            <SRSProductFamily />
                        </Item> */}
                        {/* <Item key="interaction_report_attributes">
                            <InteractionReportAttributes />
                        </Item> */}
                        {/* <Item key="srs_rules">
                            <SRSRules />
                        </Item> */}
                        {/* <Item key={'agentSkill'}>
                            <AgentSkillList></AgentSkillList>
                        </Item> */}
                    </TabPanels>
                </Tabs>
            </Well>
        )
    }
}

export default connect(mapStateToProps)(Config)
