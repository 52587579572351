import React, { useState, useEffect } from "react"
import Delete from "@spectrum-icons/workflow/Delete"
import {
    Button,
    Flex,
    Content,
    Heading,
    ActionButton,
    DialogTrigger,
    Dialog,
    Divider,
    TextField,
    Footer,
    View,
    ButtonGroup,
    Checkbox,
    Item,
    ComboBox,
} from "@adobe/react-spectrum"
import Copy from "@spectrum-icons/workflow/Copy"
import Add from "@spectrum-icons/workflow/Add"
import "./style.css"
import { notify } from "../../../constants/constants"

const AddVoiceCompliancee = (props) => {
    const [keyName, setKeyName] = useState("")
    const [data, setData] = useState([])
    const [objectSelected, setObjectSelected] = useState(new Set())

    const itemsArray = [
        { id: "vpn_allowed_ip", name: "vpn_allowed_ip" },
        { id: "non_vpn_blocked_users", name: "non_vpn_blocked_users" },
        { id: "bypass_users", name: "bypass_users" },
        { id: "bypass_ip", name: "bypass_ip" },
        { id: "restricted_countries", name: "restricted_countries" },
    ]

    useEffect(() => {
        if (!props?.editData && keyName) {
            setData([])
            addNewFields(keyName)
        }
    }, [keyName])

    useEffect(() => {
        if (props?.editData) {
            setKeyName(props?.editData?.key)
            setData(props?.editData?.valueArray)
        }
    }, [])

    // }

    const handleFormSubmit = () => {
        const dataToSend = {
            [keyName]: data,
        }
        // //console.log("DATA to SEND =>", dataToSend)
        props.handleSubmit(dataToSend)
    }

    const addNewFields = (keyName) => {
        let newObj
        switch (keyName) {
            case "vpn_allowed_ip":
                newObj = { ip: "", location: "" }
                setData((prevData) => [newObj, ...prevData])
                break
            case "non_vpn_blocked_users":
                newObj = { ldap: "" }
                setData((prevData) => [newObj, ...prevData])
                break
            case "bypass_users":
                newObj = { ldap: "" }
                setData((prevData) => [newObj, ...prevData])
                break
            case "bypass_ip":
                newObj = { ip: "" }
                setData((prevData) => [newObj, ...prevData])
                break
            case "restricted_countries":
                newObj = { country_code: "", country_name: "" }
                setData((prevData) => [newObj, ...prevData])
                break
            default:
                return
        }
    }

    const handleUpdateValue = (value, index, key) => {
        setData((prevData) => {
            const newData = [...prevData]
            newData[index][key] = value
            return newData
        })
    }

    const handleMultiDelete = () => {
        const selectedIndex = Array.from(objectSelected)
        const filteredData = data.filter(
            (ele, index) => !selectedIndex.includes(index)
        )
        setData(filteredData)
        setObjectSelected(new Set())
    }

    const cloneObject = (index) => {
        const newObject = { ...data[index] }
        const updatedData = [
            ...data.slice(0, index + 1),
            newObject,
            ...data.slice(index + 1),
        ]
        setData(updatedData)
    }

    const toggleSelection = (index) => {
        const newSelection = new Set(objectSelected)
        if (newSelection.has(index)) {
            newSelection.delete(index)
        } else {
            newSelection.add(index)
        }
        setObjectSelected(newSelection)
    }

    const handleKeySelection = (name) => {
        if (!props.alreadyExistingKey.includes(name)) {
            setKeyName(name)
        } else {
            notify("Voice Policy already exist", "warn")
        }
    }

    const hasEmptyValues = (data) => {
        return data.some(obj => {
            return Object.values(obj).some(value => {
                return value === "" || value === null || value === undefined;
            });
        });
    };

    const renderView = (keyName) => {
        let view
        switch (keyName) {
            case "vpn_allowed_ip":
                // addNewFields(keyName)
                view = data.map((val, index) => {
                    return (
                        <div
                            key={index}
                            style={{margin:"4px auto"}}
                            className={
                                objectSelected.has(index)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(index)}
                                    onChange={() => toggleSelection(index)}
                                />
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                <View key={index} UNSAFE_className="feild">
                                    <Flex gap={"size-100"}>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={"ip"}
                                            onChange={{}}
                                            isDisabled={true}
                                            isQuiet
                                        />
                                        <strong>:</strong>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={val.ip}
                                            onChange={(value) =>
                                                handleUpdateValue(
                                                    value,
                                                    index,
                                                    "ip"
                                                )
                                            }
                                            isQuiet
                                        />
                                    </Flex>
                                    <Flex gap={"size-100"}>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={"location"}
                                            onChange={{}}
                                            isDisabled={true}
                                            isQuiet
                                        />
                                        <strong>:</strong>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={val.location}
                                            onChange={(value) =>
                                                handleUpdateValue(
                                                    value,
                                                    index,
                                                    "location"
                                                )
                                            }
                                            isQuiet
                                        />
                                    </Flex>
                                </View>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    UNSAFE_style={{ cursor: "pointer" }}
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(index)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "non_vpn_blocked_users":
                view = data.map((val, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                objectSelected.has(index)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(index)}
                                    onChange={() => toggleSelection(index)}
                                />
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                <View key={index} UNSAFE_className="feild">
                                    <Flex gap={"size-100"}>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={"ldap"}
                                            onChange={{}}
                                            isDisabled={true}
                                            isQuiet
                                        />
                                        <strong>:</strong>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={val.ldap}
                                            onChange={(value) =>
                                                handleUpdateValue(
                                                    value,
                                                    index,
                                                    "ldap"
                                                )
                                            }
                                            isQuiet
                                        />
                                    </Flex>
                                </View>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    UNSAFE_style={{ cursor: "pointer" }}
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(index)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "bypass_users":
                view = data.map((val, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                objectSelected.has(index)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(index)}
                                    onChange={() => toggleSelection(index)}
                                />
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                <View key={index} UNSAFE_className="feild">
                                    <Flex gap={"size-100"}>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={"ldap"}
                                            onChange={{}}
                                            isDisabled={true}
                                            isQuiet
                                        />
                                        <strong>:</strong>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={val.ldap}
                                            onChange={(value) =>
                                                handleUpdateValue(
                                                    value,
                                                    index,
                                                    "ldap"
                                                )
                                            }
                                            isQuiet
                                        />
                                    </Flex>
                                </View>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    UNSAFE_style={{ cursor: "pointer" }}
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(index)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "bypass_ip":
                view = data.map((val, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                objectSelected.has(index)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(index)}
                                    onChange={() => toggleSelection(index)}
                                />
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                <View key={index} UNSAFE_className="feild">
                                    <Flex gap={"size-100"}>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={"ip"}
                                            onChange={{}}
                                            isDisabled={true}
                                            isQuiet
                                        />
                                        <strong>:</strong>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={val.ip}
                                            onChange={(value) =>
                                                handleUpdateValue(
                                                    value,
                                                    index,
                                                    "ip"
                                                )
                                            }
                                            isQuiet
                                        />
                                    </Flex>
                                </View>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    UNSAFE_style={{ cursor: "pointer" }}
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(index)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            case "restricted_countries":
                view = data.map((val, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                objectSelected.has(index)
                                    ? "cardSection active"
                                    : "cardSection"
                            }
                        >
                            <section className="checkboxSection">
                                <Checkbox
                                    aria-label="selectObject"
                                    isSelected={objectSelected.has(index)}
                                    onChange={() => toggleSelection(index)}
                                />
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="FormSection">
                                <View key={index} UNSAFE_className="feild">
                                    <Flex gap={"size-100"}>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={"country_code"}
                                            onChange={{}}
                                            isDisabled={true}
                                            isQuiet
                                        />
                                        <strong>:</strong>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={val.country_code}
                                            onChange={(value) =>
                                                handleUpdateValue(
                                                    value,
                                                    index,
                                                    "country_code"
                                                )
                                            }
                                            isQuiet
                                        />
                                    </Flex>
                                    <Flex gap={"size-100"}>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={"country_name"}
                                            onChange={{}}
                                            isDisabled={true}
                                            isQuiet
                                        />
                                        <strong>:</strong>
                                        <TextField
                                            width={"50%"}
                                            aria-label={"key" + index}
                                            key={"key" + index}
                                            value={val.country_name}
                                            onChange={(value) =>
                                                handleUpdateValue(
                                                    value,
                                                    index,
                                                    "country_name"
                                                )
                                            }
                                            isQuiet
                                        />
                                    </Flex>
                                </View>
                            </section>
                            <Divider size="S" orientation="vertical" />
                            <section className="actionsSection">
                                <Button
                                    UNSAFE_style={{ cursor: "pointer" }}
                                    aria-label="Clone Object"
                                    variant="secondary"
                                    staticColor="black"
                                    style="fill"
                                    onPress={() => cloneObject(index)}
                                >
                                    <Copy />
                                </Button>
                            </section>
                        </div>
                    )
                })
                break
            default:
                view = null
        }

        return view
    }

    const handleAddButton = () => {
        addNewFields(keyName)
    }
    return (
        <DialogTrigger
            type="modal"
            mobileType="modal"
            isOpen={props.isDialogOpened}
            defaultOpen={false}
        >
            <ActionButton isHidden isQuiet></ActionButton>
            <Dialog size="L">
                <Heading>{props.operation} Voice Compliance Policies</Heading>

                <Divider />

                <Content>
                    <ButtonGroup UNSAFE_className="noBorder"></ButtonGroup>
                    <Button
                        variant="secondary"
                        staticColor="black"
                        style="fill"
                        UNSAFE_style={{
                            float: "right",
                            cursor: "pointer",
                        }}
                        aria-label="multidelete"
                        onPress={(keyName) => handleMultiDelete(keyName)}
                        isDisabled={!keyName || !objectSelected.size}
                        marginStart={"10px"}
                    >
                        <Delete />
                    </Button>

                    <Button
                        aria-label="AddObject"
                        variant="secondary"
                        staticColor="black"
                        style="fill"
                        UNSAFE_style={{ float: "right", cursor: "pointer" }}
                        onPress={(keyName) => handleAddButton(keyName)}
                        isDisabled={!keyName}
                    >
                        <Add />
                    </Button>

                    <Flex
                        direction={"row"}
                        gap={"size-300"}
                        justifyContent={"space-between"}
                        // key={ele.key}
                    >
                        <ComboBox
                        width={"50%"}
                            label="Voice Policy"
                            name="policy"
                            defaultItems={itemsArray}
                            selectedKey={keyName}
                            // inputValue={props.editData ? keyName : null}
                            onSelectionChange={(value) => {
                                handleKeySelection(value)
                            }}
                            isDisabled={props?.operation == "Edit"}
                            isRequired
                        >
                            {(item) => <Item key={item.name}>{item.name}</Item>}
                        </ComboBox>
                    </Flex>

                    <Divider size="S" marginY={"20px"} />

                    {renderView(keyName)}
                </Content>

                <Footer alignSelf={"flex-start"}>
                    <Flex flexBasis={"100%"}>
                        <View>
                            <ButtonGroup>
                                <Button
                                    variant="secondary"
                                    onPress={() =>
                                        props?.handleCloseDialog(false)
                                    }
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onPress={handleFormSubmit}
                                    isDisabled={hasEmptyValues(data)}
                                >
                                    Submit
                                </Button>
                            </ButtonGroup>
                        </View>
                    </Flex>
                </Footer>
            </Dialog>
        </DialogTrigger>
    )
}

export default AddVoiceCompliancee
