import React, { useState, useEffect } from "react"
import {
    Provider,
    lightTheme,
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    Heading,
    Content,
    ButtonGroup,
    ComboBox,
    View,
    Flex,
    Item,
    NumberField,
} from "@adobe/react-spectrum"
import Add from "@spectrum-icons/workflow/Add"
import Delete from "@spectrum-icons/workflow/Delete"

const BulkEditProficiency = (props) => {
    const [rows, setRows] = useState([
        { attributeName: "", attributeValue: "", level: "1" },
    ])
    const [nameArray, setNameArray] = useState([]);

    const convertArrayToInput = (values = []) => {
        return values.length
            ? values.map((value, index) => ({ id: index, name: value }))
            : [];
    };
    
    useEffect(() => {
      const updatedNameArray = convertArrayToInput(Object.keys(props.attributeList));
      setNameArray(updatedNameArray);
  }, []);

  
    const handleNameSelection = (value, rowIndex) => {
        // //console.log({value,rowIndex});
    //   const selectedName = nameArray?.filter((ele) => ele?.id === value)[0]?.name;
      // const updatedNameArray = nameArray.filter((ele) => ele.name !== selectedName);
      setRows((prevRows) => {
          const updatedRows = [...prevRows];
          updatedRows[rowIndex].attributeName = value;
          return updatedRows;
      });
        // setNameArray(updatedNameArray);
    }

    const handleValueSelection = (value, rowIndex) => {
        // //console.log({value, rowIndex});
        setRows((prevRows) => {
            const updatedRows = [...prevRows]
            // const valueName = convertArrayToInput(
            //     props.attributeList[rows[rowIndex].attributeName]
            // )
            // //console.log("valueName ",valueName);
            updatedRows[rowIndex].attributeValue = value
            return updatedRows
        })
    }

    const handleLevelSelection = (value, rowIndex) => {
        setRows((prevRows) => {
            const updatedRows = [...prevRows]
            updatedRows[rowIndex].level = value
            return updatedRows
        })
    }

    const addNewRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            {
                attributeName: "",
                attributeValue: "",
                level: "1",
            },
        ])
    }

    const deleteRow = (index) => {
        setRows((prevRows) => {
            const updatedRows = [...prevRows]
            if (updatedRows.length > 1) {
                updatedRows.splice(index, 1)
            }
            return updatedRows
        })
    }

    // const handleUpdate = () => {
    //     const data = {
    //         agents: props.selectedAgents,
    //         agentProficiencies: rows,
    //     }
    //     props.handleCloseDialog(true, data,"update")
    // }

    // const handleRemove = () => {
    //   const data = {
    //     agents: props.selectedAgents,
    //     agentProficiencies: rows,
    // }
    // props.handleCloseDialog(true, data,"remove")
    // }

    const handleUpdate = (type) => {
        const data = {
            users: props.selectedAgents,
            action: type === "update" ? "add_selective" : "remove_selective",
            configKey : "proficiency",
            configValues: rows,
        }
        // setRows([{ queue_name: "", id: "", channel: "", delay: "0", priority: "1" }]);
        props.handleCloseDialog(true, data);
    }

    const checkAllFieldsNotEmpty = (item) => {
        return Object.values(item).every((value) => value !== "")
    }

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog width={"700px"}>
                        <Heading>Bulk {props.operation} Proficiency</Heading>
                        <Divider />
                        <Content>
                            <Flex
                                direction={"column"}
                                maxHeight={"60vh"}
                                height={"25vh"}
                                // UNSAFE_className="boxInside"
                            >
                                {rows.map((row, index) => (
                                    <Flex
                                        key={index}
                                        direction={"column"}
                                        justifyContent={"center"}
                                        gap={"size-100"}
                                        marginTop={"size-100"}
                                    >
                                        <View UNSAFE_className="feild">
                                            <Flex>
                                                <View>
                                                    <ComboBox
                                                        label={index === 0 && "Attribute Name"}
                                                        defaultItems={nameArray.length ? nameArray : nameArray}
                                                        selectedKey={
                                                            row?.attributeName
                                                        }
                                                        onSelectionChange={(
                                                            value
                                                        ) =>
                                                            handleNameSelection(
                                                                value,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        {(item) => (
                                                            <Item key={item?.name}>
                                                                {item?.name}
                                                            </Item>
                                                        )}
                                                    </ComboBox>
                                                    <ComboBox
                                                        marginX={"5px"}
                                                        label={index === 0 && "Attribute Value"}
                                                        defaultItems={convertArrayToInput(
                                                            props.attributeList[
                                                                rows[index]
                                                                    ?.attributeName
                                                            ]
                                                        )}
                                                        selectedKey={
                                                            row?.attributeValue
                                                        }
                                                        onSelectionChange={(
                                                            value
                                                        ) =>
                                                            handleValueSelection(
                                                                value,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        {(item) => (
                                                            <Item key={item.name}>
                                                                {item.name}
                                                            </Item>
                                                        )}
                                                    </ComboBox>
                                                    <NumberField
                                                        label={index === 0 && "Level"}
                                                        defaultValue={1}
                                                        minValue={1}
                                                        maxValue={5}
                                                        onChange={(value) =>
                                                            handleLevelSelection(
                                                                value,
                                                                index
                                                            )
                                                        }
                                                    />
                                                </View>

                                                <View
                                                    UNSAFE_style={{
                                                        marginTop:  index=== 0 ? "5%" : "0%",
                                                    }}
                                                >
                                                    <Button
                                                        marginX={"5px"}
                                                        aria-label="deleteValue"
                                                        variant="secondary"
                                                        staticColor="black"
                                                        style="fill"
                                                        onPress={() =>
                                                            deleteRow(index)
                                                        }
                                                    >
                                                        <Delete />
                                                    </Button>
                                                    {index ===
                                                    rows.length - 1 ? (
                                                        <Button
                                                            // marginTop={"5%"}
                                                            aria-label="AddObject"
                                                            variant="secondary"
                                                            staticColor="black"
                                                            style="fill"
                                                            onPress={() =>
                                                                addNewRow()
                                                            }
                                                        >
                                                            <Add />
                                                        </Button>
                                                    ) : null}
                                                </View>
                                            </Flex>
                                        </View>
                                    </Flex>
                                ))}
                            </Flex>
                        </Content>
                        <ButtonGroup>
                            <Button
                                variant="secondary"
                                onPress={props.handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onPress={() => handleUpdate("remove")}
                                isDisabled={!rows.every(checkAllFieldsNotEmpty)}
                            >
                                Remove
                            </Button>
                            <Button
                                variant="primary"
                                onPress={()=>handleUpdate("update")}
                                isDisabled={!rows.every(checkAllFieldsNotEmpty)}
                            >
                                Add / Update
                            </Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default BulkEditProficiency
