import { notify } from "../constants/constants"

const alertPlace = (index) => {
    if (index === 0) return "1st"
    if (index === 1) return "2nd"
    if (index === 2) return "3rd"
    return `${index + 1}th`
}

function validateAlertConfiguration(config) {
    const errors = []

    if (!config.queueName) errors.push("Queue Name is required.")
    if (!config.channel) errors.push("Channel is required.");

    const alertTypes = config.alertTypes

    const alertTypeKeys = ["abandoned", "wait", "onQueueUsers", "voicemail"]

    alertTypeKeys.forEach((alertType) => {
        if (alertTypes[alertType]) {
            const alerts = alertTypes[`${alertType}Alerts`]

            if (!alerts || alerts.length === 0) {
                errors.push(
                    `${capitalizeFirstLetter(
                        alertType
                    )} alerts are enabled but no configurations are present.`
                )
            } else {
                alerts.forEach((alert, index) => {
                    validateAlert(alert, alertType, index, errors)
                })
            }
        }
    })

    return errors
}

function validateAlert(alert, alertType, index, errors) {
    if (alert.alertOption.slack) {
        validateSlack(alert, alertType, index, errors)
    }

    if (alert.alertOption.email) {
        validateEmail(alert, alertType, index, errors)
    }

    if (alert.attribute.customAttribute) {
        validateCustomAttribute(alert, alertType, index, errors)
    }

    if (alert.schedule.custom) {
        validateSchedule(alert, alertType, index, errors)
    }
}

function validateSlack(alert, alertType, index, errors) {
    if (!alert.recipient.slacks || alert.recipient.slacks.length === 0) {
        errors.push(
            `${capitalizeFirstLetter(alertType)} alert at[ ${alertPlace(
                index
            )}]: Slack is enabled but no Slack channels are configured.`
        )
    } else {
        alert.recipient.slacks.forEach((slack, slackIndex) => {
            if (!slack.channelName || !slack.channelId) {
                errors.push(
                    `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                        index
                    )}]: Slack channel [${alertPlace(
                        slackIndex
                    )}] has empty channel name or channel Id.`
                )
            }
        })
    }
}

function validateEmail(alert, alertType, index, errors) {
    if (
        !alert.recipient.emails ||
        alert.recipient.emails.length === 0 ||
        alert.recipient.emails.some((email) => email.trim() === "")
    ) {
        errors.push(
            `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                index
            )}]: Email is enabled but no email recipients are configured.`
        )
    } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        alert.recipient.emails.forEach((email, emailIndex) => {
            if (!emailRegex.test(email.trim())) {
                errors.push(
                    `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                        index
                    )}]: Invalid email format for recipient at [${alertPlace(emailIndex)}] position.`
                )
            }
        })
    }
}

function validateCustomAttribute(alert, alertType, index, errors) {
    if (
        !alert.attribute.customAttributes ||
        alert.attribute.customAttributes.length === 0
    ) {
        errors.push(
            `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                index
            )}]: Custom attribute is enabled but no custom attributes are configured.`
        )
    } else {
        alert.attribute.customAttributes.forEach((customAttr, attrIndex) => {
            if (
                !customAttr.attributeName ||
                customAttr.attributeName.trim() === ""
            ) {
                errors.push(
                    `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                        index
                    )}]: Custom attribute at [${alertPlace(
                        attrIndex
                    )}] position has an empty attribute name.`
                )
            }
            if (
                !customAttr.attributeValues ||
                customAttr.attributeValues.length === 0 ||
                customAttr.attributeValues.some((value) => value.trim() === "")
            ) {
                errors.push(
                    `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                        index
                    )}]: Custom attribute at [${alertPlace(
                        attrIndex
                    )}] position has empty attribute values.`
                )
            }
            if (
                customAttr.attributeValue &&
                customAttr.attributeValue.trim() !== ""
            ) {
                errors.push(
                    `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                        index
                    )}]: Custom attribute at ${attrIndex} position has an unsaved attribute value.`
                )
            }
        })
    }
}

function validateSchedule(alert, alertType, index, errors) {
    if (
        !alert.schedule.customSchedules ||
        alert.schedule.customSchedules.length === 0
    ) {
        errors.push(
            `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                index
            )}]: Custom schedule is enabled but no custom schedules are configured.`
        )
    } else {
        alert.schedule.customSchedules.forEach((schedule, scheduleIndex) => {
            if (
                !schedule.timezone ||
                !schedule.days ||
                !schedule.startTime ||
                !schedule.endTime
            ) {
                errors.push(
                    `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                        index
                    )}]: Custom schedule at [${alertPlace(
                        scheduleIndex
                    )}] position is incomplete.`
                )
            }

            if (schedule.startTime !== "*" && schedule.endTime !== "*") {
                const start = new Date(`1970-01-01T${schedule.startTime}`)
                const end = new Date(`1970-01-01T${schedule.endTime}`)
                if (start >= end) {
                    errors.push(
                        `${capitalizeFirstLetter(alertType)} alert [${alertPlace(
                            index
                        )}]: Custom schedule at [${alertPlace(
                            scheduleIndex
                        )}] position has start time greater than or equal to end time.`
                    )
                }
            }
        })
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

function submitAlertConfiguration(config) {
    const validationErrors = validateAlertConfiguration(config)

    if (validationErrors.length > 0) {
        validationErrors.forEach((error) => {
            notify(error, "error", {containerId:'modalToast'})
        })
        return false
    }
    notify("Alert configuration submitted successfully!", "success",{containerId:'modalToast'})

    return true
}

export { validateAlertConfiguration, submitAlertConfiguration }
