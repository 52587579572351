import React, { useEffect, useRef } from "react"
import { Flex, View, Heading, Button, Divider } from "@adobe/react-spectrum"
import CustomAttribute from "./CustomAttribute"
import SlackAndEmail from "./SlackAndEmail"
import ScheduleParam from "./ScheduleParam"
import DeleteButton from "./DeleteButton"

const AddAbandoned = ({ alertName, abandonedState, updateAbandonedState, isEditable , queueName}) => {
    const addNewAbandoned = () => {
        const newItem = {
            id: Math.floor(100000 + Math.random() * 900000),
            alertOption: {
                slack: true,
                email: true,
            },
            recipient: {
                emails: [" "],
                slacks: [
                    {
                        channelName: "",
                        channelId: "",
                    },
                ],
            },
            attribute: {
                customAttribute: false,
                customAttributes: [
                    {
                        attributeValues: [],
                        attributeName: "ORGID",
                        attributeValue: "",
                        items: [],
                    },
                ],
            },
            schedule: {
                customSchedules: [
                    {
                        timezone: "IST",
                        days: "MONDAY",
                        startTime: "*",
                        threshold: 0,
                        endTime: "*",
                    },
                ],
                custom: false,
                threshold: 0,
                everyday: true,
            },
        }
        updateAbandonedState([newItem, ...abandonedState])
    }

    const handleDelete = (index) => {
        const updatedState = abandonedState.filter((_, i) => i !== index)
        updateAbandonedState(updatedState)
    }

    // Slack and Email related functions
    const updateAlertOption = (index, option, value) => {
        const updatedState = [...abandonedState]
        updatedState[index].alertOption[option] = value
        if (value === false) {
            if (option === "email") {
                updatedState[index].recipient.emails = [" "]
            } else if (option === "slack") {
                updatedState[index].recipient.slacks = [
                    {
                        channelName: "",
                        channelId: "",
                    },
                ]
            }
        }
        updateAbandonedState(updatedState)
    }

    const addSlackChannel = (index) => {
        const updatedState = [...abandonedState]
        updatedState[index].recipient.slacks.unshift({
            channelName: "",
            channelId: "",
        })
        updateAbandonedState(updatedState)
    }

    const updateSlackChannel = (index, slackIndex, field, value) => {
        const updatedState = [...abandonedState]
        updatedState[index].recipient.slacks[slackIndex][field] = value
        updateAbandonedState(updatedState)
    }

    const deleteSlackChannel = (index, slackIndex) => {
        const updatedState = [...abandonedState]
        updatedState[index].recipient.slacks.splice(slackIndex, 1)
        updateAbandonedState(updatedState)
    }

    const addEmail = (index) => {
        const updatedState = [...abandonedState]
        updatedState[index].recipient.emails.unshift("")
        updateAbandonedState(updatedState)
    }

    const updateEmail = (index, emailIndex, value) => {
        const updatedState = [...abandonedState]
        updatedState[index].recipient.emails[emailIndex] = value
        updateAbandonedState(updatedState)
    }

    const deleteEmail = (index, emailIndex) => {
        const updatedState = [...abandonedState]
        updatedState[index].recipient.emails.splice(emailIndex, 1)
        updateAbandonedState(updatedState)
    }

    // Custom Attribute related functions
    const addNewCustomAttribute = (index) => {
        const updatedState = [...abandonedState]
        updatedState[index].attribute.customAttributes.unshift({
            attributeValues: [],
            attributeName: "",
            attributeValue: "",
            items: [],
        })
        updateAbandonedState(updatedState)
    }

    const deleteCustomAttribute = (abandonedIndex, attrIndex) => {
        const updatedState = [...abandonedState]
        updatedState[abandonedIndex].attribute.customAttributes.splice(
            attrIndex,
            1
        )
        updateAbandonedState(updatedState)
    }

    const handleCustomAttributeCheckboxChange = (index) => {
        const updatedState = [...abandonedState]
        updatedState[index].attribute.customAttribute = !updatedState[index]
            .attribute.customAttribute
        if (!updatedState[index].attribute.customAttribute) {
            updatedState[index].attribute.customAttributes = [
                {
                    attributeValues: [],
                    attributeName: "ORGID",
                    attributeValue: "",
                    items: [],
                },
            ]
        }

        updateAbandonedState(updatedState)
    }

    const updateCustomAttributeState = (
        abandonedIndex,
        newCustomAttributeState
    ) => {
        const updatedState = [...abandonedState]
        updatedState[abandonedIndex].attribute = newCustomAttributeState
        updateAbandonedState(updatedState)
    }

    // Schedule related functions
    const updateScheduleParamOption = (index, option, value) => {
        const updatedState = [...abandonedState]
        updatedState[index].schedule[option] = value

        if (option === "everyday") {
            if (value === true) {
                updatedState[index].schedule.custom = false
                updatedState[index].schedule.customSchedules = [
                    {
                        timezone: "IST",
                        days: "MONDAY",
                        startTime: "*",
                        threshold: 0,
                        endTime: "*",
                    },
                ]
            } else {
                updatedState[index].schedule.threshold = 0
            }
        }

        if (option === "custom") {
            if (value === true) {
                updatedState[index].schedule.everyday = false
                updatedState[index].schedule.threshold = 0
            } else {
                updatedState[index].schedule.customSchedules = [
                    {
                        timezone: "IST",
                        days: "MONDAY",
                        startTime: "*",
                        threshold: 0,
                        endTime: "*",
                    },
                ]
            }
        }

        if (
            !updatedState[index].schedule.everyday &&
            !updatedState[index].schedule.custom
        ) {
            updatedState[index].schedule.everyday = true
            updatedState[index].schedule.customSchedules = [
                {
                    timezone: "IST",
                    days: "MONDAY",
                    startTime: "*",
                    threshold: 0,
                    endTime: "*",
                },
            ]
        }

        updateAbandonedState(updatedState)
    }

    const addNewCustomSchedule = (index) => {
        const updatedState = [...abandonedState]
        updatedState[index].schedule.customSchedules.unshift({
            timezone: "IST",
            days: "MONDAY",
            startTime: "*",
            threshold: 0,
            endTime: "*",
        })
        updateAbandonedState(updatedState)
    }

    const deleteCustomSchedule = (index, scheduleIndex) => {
        const updatedState = [...abandonedState]
        updatedState[index].schedule.customSchedules.splice(scheduleIndex, 1)
        updateAbandonedState(updatedState)
    }

    const handleAllDayChange = (index, scheduleIndex, value) => {
        const updatedState = [...abandonedState]
        updatedState[index].schedule.customSchedules[
            scheduleIndex
        ].startTime = value ? "*" : "00:00:00"
        updatedState[index].schedule.customSchedules[
            scheduleIndex
        ].endTime = value ? "*" : "23:59:59"
        updateAbandonedState(updatedState)
    }

    const handleDaysChange = (index, scheduleIndex, value) => {
        const updatedState = [...abandonedState]
        const currentSchedule =
            updatedState[index].schedule.customSchedules[scheduleIndex]

        let daysArray = [...value]

        daysArray.sort((a, b) => {
            const order = [
                "MONDAY",
                "TUESDAY",
                "WEDNESDAY",
                "THURSDAY",
                "FRIDAY",
                "SATURDAY",
                "SUNDAY",
            ]
            return order.indexOf(a) - order.indexOf(b)
        })

        if (daysArray.length === 0) {
            daysArray.push("MONDAY")
        }

        // Update the state
        updatedState[index].schedule.customSchedules[
            scheduleIndex
        ].days = daysArray.join(",")

        updateAbandonedState(updatedState)
    }

    const handleTimeChange = (index, scheduleIndex, timeType, value) => {
        const hour = String(value.hour).padStart(2, "0")
        const minute = String(value.minute).padStart(2, "0")
        const second = String(value.second).padStart(2, "0")

        const updatedState = [...abandonedState]
        updatedState[index].schedule.customSchedules[scheduleIndex][
            timeType
        ] = `${hour}:${minute}:${second}`

        updateAbandonedState(updatedState)
    }

    const handleTimezoneChange = (index, scheduleIndex, value) => {
        const updatedState = [...abandonedState]
        updatedState[index].schedule.customSchedules[
            scheduleIndex
        ].timezone = value
        updateAbandonedState(updatedState)
    }

    const handleScheduleConditionChange = (index, scheduleIndex, value) => {
        const updatedState = [...abandonedState]
        updatedState[index].schedule.customSchedules[
            scheduleIndex
        ].scheduleCondition = value
        updateAbandonedState(updatedState)
    }

    return (
        <View>
            <View>
                <Heading
                    UNSAFE_style={{
                        textAlign: "center",
                        // marginBottom: "size-200",
                        color: "#707070",
                        fontWeight: 700,
                    }}
                    level={3}
                >
                    Abandoned Alert
                </Heading>
                <View
                    UNSAFE_style={{
                        textAlign: "center",
                        fontSize: "12px",
                        color: "#707070",
                    }}
                >
                    {`( ${queueName} )`}
                </View>
                <Flex justifyContent={"end"}>
                    <Button
                        variant="cta"
                        style="fill"
                        UNSAFE_className="pointer"
                        UNSAFE_style={{
                            borderRadius: "4px",
                            marginRight: "20px",
                        }}
                        onPress={addNewAbandoned}
                        isDisabled={!isEditable}
                    >
                        Add New
                    </Button>
                </Flex>
            </View>
            <View
                UNSAFE_style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                {abandonedState.map((obj, index) => (
                    <View
                        key={obj.id}
                        padding="size-300"
                        paddingTop={"0"}
                        marginBottom="size-300"
                        marginStart={"size-500"}
                        UNSAFE_style={{
                            border: "1px solid #d9d9d9",
                            borderRadius: "8px",
                        }}
                        width={"fit-content"}
                        margin={"5px auto"}
                    >
                        <View backgroundColor={"grey-400"} UNSAFE_style={{textAlign:"center"}}>ABC</View>
                        <SlackAndEmail
                            index={index}
                            state={obj.recipient}
                            alertOption={obj.alertOption}
                            updateAlertOption={(option, value) =>
                                updateAlertOption(index, option, value)
                            }
                            addSlackChannel={() => addSlackChannel(index)}
                            updateSlackChannel={(slackIndex, field, value) =>
                                updateSlackChannel(
                                    index,
                                    slackIndex,
                                    field,
                                    value
                                )
                            }
                            deleteSlackChannel={(slackIndex) =>
                                deleteSlackChannel(index, slackIndex)
                            }
                            addEmail={() => addEmail(index)}
                            updateEmail={(emailIndex, value) =>
                                updateEmail(index, emailIndex, value)
                            }
                            deleteEmail={(emailIndex) =>
                                deleteEmail(index, emailIndex)
                            }
                            handleDelete={() => handleDelete(index)}
                            abandonedState={abandonedState}
                            isEditable={isEditable}
                        />
                        <Divider
                            size="S"
                            marginY={"15px"}
                            height={"0.5px"}
                            width={"100%"}
                        />
                        <CustomAttribute
                            index={index}
                            state={obj.attribute}
                            updateAbandonedState={(newState) =>
                                updateCustomAttributeState(index, newState)
                            }
                            addNewCustomAttribute={() =>
                                addNewCustomAttribute(index)
                            }
                            deleteCustomAttribute={(attrIndex) =>
                                deleteCustomAttribute(index, attrIndex)
                            }
                            handleCustomAttributeCheckboxChange={() =>
                                handleCustomAttributeCheckboxChange(index)
                            }
                            isEditable={isEditable}
                        />
                        <Divider
                            size="S"
                            marginY={"15px"}
                            height={"0.5px"}
                            width={"100%"}
                        />
                        <ScheduleParam
                            alertName={alertName}
                            index={index}
                            state={obj.schedule}
                            updateScheduleParamOption={(option, value) =>
                                updateScheduleParamOption(index, option, value)
                            }
                            addNewCustomSchedule={() =>
                                addNewCustomSchedule(index)
                            }
                            deleteCustomSchedule={(scheduleIndex) =>
                                deleteCustomSchedule(index, scheduleIndex)
                            }
                            handleDaysChange={(scheduleIndex, value) =>
                                handleDaysChange(index, scheduleIndex, value)
                            }
                            handleTimeChange={(
                                scheduleIndex,
                                timeType,
                                value
                            ) =>
                                handleTimeChange(
                                    index,
                                    scheduleIndex,
                                    timeType,
                                    value
                                )
                            }
                            handleTimezoneChange={(scheduleIndex, value) =>
                                handleTimezoneChange(
                                    index,
                                    scheduleIndex,
                                    value
                                )
                            }
                            handleScheduleConditionChange={(
                                scheduleIndex,
                                value
                            ) =>
                                handleScheduleConditionChange(
                                    index,
                                    scheduleIndex,
                                    value
                                )
                            }
                            handleAllDayChange={(scheduleIndex, value) =>
                                handleAllDayChange(index, scheduleIndex, value)
                            }
                            isEditable={isEditable}
                        />
                    </View>
                ))}
            </View>
        </View>
    )
}

export default AddAbandoned
