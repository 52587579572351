import React from "react"
import {
    View,
    Flex,
    Text,
    Heading,
    Button,
    Divider,
    CheckboxGroup,
    Checkbox,
} from "@adobe/react-spectrum"
import Draw from "@spectrum-icons/workflow/Draw"
import Delete from "@spectrum-icons/workflow/Delete"
import Visibility from "@spectrum-icons/workflow/Visibility"

const QueueCard = ({
    uniqueKey,
    queue,
    onEdit,
    onDelete,
    styles,
    editPermission,
    deletePermission
}) => {
    console.log("editPermission ",editPermission);
    
    const alerts = queue.alertTypes
    // const channel = queue?.channel
    const { abandoned, wait, onQueueUser, voicemail } = alerts
    const enabledAlerts = []
    if (abandoned) enabledAlerts.push("abandoned")
    if (wait) enabledAlerts.push("wait")
    if (onQueueUser) enabledAlerts.push("onQueueUser")
    if (voicemail) enabledAlerts.push("voicemail")

    return (
        <View UNSAFE_className={styles.card} key={uniqueKey}>
            <Flex
                direction="column"
                gap="size-200"
                UNSAFE_style={{ textAlign: "center" }}
            >
                <Heading
                    UNSAFE_style={{ wordBreak: "break-all", fontWeight: 700 }}
                    level={5}
                >
                    {queue.queueName}
                </Heading>
                <Divider size="S" />
            </Flex>
            <View>
                <CheckboxGroup
                    label={<h5>Enabled Alerts</h5>}
                    defaultValue={enabledAlerts}
                    isEmphasized
                    isReadOnly
                    // isDisabled={!enabledAlerts.includes}
                >
                    <Checkbox
                        value="abandoned"
                        isDisabled={!enabledAlerts.includes("abandoned")}
                    >
                        Abandoned
                    </Checkbox>
                    <Checkbox
                        value="wait"
                        isDisabled={!enabledAlerts.includes("wait")}
                    >
                        Wait
                    </Checkbox>
                    <Checkbox
                        value="onQueueUser"
                        isDisabled={!enabledAlerts.includes("onQueueUser")}
                    >
                        OnQueueUser
                    </Checkbox>
                    <Checkbox
                        value="voicemail"
                        isDisabled={!enabledAlerts.includes("voicemail")}
                    >
                        Voicemail
                    </Checkbox>
                </CheckboxGroup>
            </View>
            <Divider size="S" />
            <Flex
                direction="row"
                justifyContent={"space-between"}
                marginTop={"10px"}
            >
                <Button
                    UNSAFE_className="pointer"
                    UNSAFE_style={{ width: "50px" }}
                    variant="cta"
                    style="outline"
                    onPress={() => onEdit(queue)}
                >
                    {editPermission ? <Draw /> : <Visibility />}
                </Button>

                <Button
                    UNSAFE_className="pointer"
                    UNSAFE_style={{ width: "50px" }}
                    variant="negative"
                    onPress={() => onDelete(queue?.queueId)}
                    isDisabled={!deletePermission}
                >
                    <Delete />
                </Button>
            </Flex>
        </View>
    )
}

export default QueueCard
