import "./style.css"
import React, { useEffect, useState, useRef } from "react"
import {
    Flex,
    Heading,
    ProgressCircle,
    View,
    SearchField,
    NumberField,
    Button,
    ActionGroup,
    Item,
    TableView,
    TableHeader,
    Row,
    Column,
    TableBody,
    Cell,
    ActionButton,
    DialogTrigger,
    Dialog,
    Divider,
    Content,
    Text,
    TextField,
    Footer,
    IllustratedMessage,
    Header,
    TooltipTrigger,
    Tooltip,
    AlertDialog,
    Picker,
    ComboBox,
    TextArea,
} from "@adobe/react-spectrum"
import { connect } from "react-redux"
import TableViewContainer from "../../utilities/components/TableViewContanire"
import DevicePreview from "@spectrum-icons/workflow/DevicePreview"
import {
    getCaseContactList,
    getCaseContactDetail,
    searchCaseContact,
    reRouteCaseContact,
    getProductIssueReasons,
    rerouteWithUpdatedData,
} from "../../utilities/api/srsRules-api"
import {
    SRSRoles,
    caseHistoryNumericIDLength,
    caseHistoryRequestType,
    notify,
} from "../../constants/constants"
import InfoOutline from "@spectrum-icons/workflow/InfoOutline"
import Copy from "@spectrum-icons/workflow/Copy"
import { renderEmptyState } from "../../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const CaseHistory = ({ user, permissions }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [currentText, setCurrentText] = useState("")
    const [filteredList, setFilteredList] = useState([])
    const [prefferedTimeRange, setPrefferedTimeRange] = useState("hour")
    const [prefferedNumberRange, setPrefferedNumberRange] = useState(1)
    const [selectedCaseContactDetail, setSelectedCaseContactDetail] = useState(
        {}
    )
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [selectedCaseId, setSelectedCaseId] = useState("")
    const [generatedCaseId, setGeneratedCaseId] = useState("")
    const [updatedContactId, setUpdatedContactId] = useState("")
    const [previousRequestType, setPreviousRequestType] = useState(
        caseHistoryRequestType.durationRequest
    )
    const [issueReasonList, setIssueReasonList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [priorityList, setPriorityList] = useState([])
    const [levelList, setLevelList] = useState([])
    // const [textArea, setTextArea] = useState('');

    const columns = [
        { name: "Case Id", key: "srs_case_id", minWidth: "15%" },
        { name: "T3 Case ID", key: "t3_case_id", minWidth: "15%" },
        { name: "Contact Id", key: "aws_contact_id", minWidth: "20%" },
        { name: "Product Name", key: "PRODUCTNAME", minWidth: "15%" },
        { name: "Queue", key: "CRMQUEUE", minWidth: "15%" },
        { name: "Contact Time", key: "contact_time", minWidth: "15%" },
    ]

    const teamMembers = [
        { key: "tarunt", name: "Tarun Tyagi" },
        { key: "rchaudha", name: "Ravikant Chaudhary" },
        { key: "richay", name: "Richa Yadav" },
        { key: "hmudgal", name: "Hemant Kumar Mudgal" },
        { key: "manoverm", name: "Manoj kumar Verma" },
        { key: "apoorvshukla", name: "Apoorv Shukla" },
    ]

    const remapIssueReasonDataToPickerInput = (array = []) => {
        return array.map((ele) => ({
            id: `${ele?.code}-${ele?.text}`,
            name: ele?.text,
        }))
    }

    function decodeBase64(base64String) {
        if (!base64String) return ""
        const decodedString = atob(base64String)
        return decodedString.replace(/^"|"$/g, "")
    }

    const getCaseConnectDetails = (caseId, contactId, productCode = "") => {
        setIsLoading(true)
        setSelectedCaseId(caseId)
        //Handle requests both
        Promise.allSettled([
            getCaseContactDetail(caseId, contactId),
            getProductIssueReasons(productCode),
        ])
            .then((resp) => {
                const [caseContactDetail, productIssueReason] = resp
                if (
                    caseContactDetail.status == "fulfilled" &&
                    productIssueReason.status == "fulfilled"
                ) {
                    const [selectedProduct] = caseContactDetail.value
                    const decodedDescription = decodeBase64(
                        selectedProduct?.case_description_base64
                    )
                    const updatedNAMEDSUPPORTENGINEER = selectedProduct?.NAMEDSUPPORTENGINEER.split(
                        "@"
                    )[0]
                    const updatedProduct = {
                        ...selectedProduct,
                        case_description_base64: decodedDescription,
                        NAMEDSUPPORTENGINEER: updatedNAMEDSUPPORTENGINEER,
                    }
                    setSelectedCaseContactDetail(updatedProduct)
                    const {
                        PRIORITYTEXT,
                        REGIONTEXT,
                        ISSUEREASONTEXT,
                        LEVEL,
                    } = JSON.parse(productIssueReason.value)
                    setIssueReasonList(
                        remapIssueReasonDataToPickerInput(ISSUEREASONTEXT)
                    )
                    setRegionList(remapIssueReasonDataToPickerInput(REGIONTEXT))
                    setPriorityList(
                        remapIssueReasonDataToPickerInput(PRIORITYTEXT)
                    )
                    setLevelList(remapIssueReasonDataToPickerInput(LEVEL))
                } else {
                    notify("Something Went Wrong!", "error")
                }
            })
            .catch((err) => {
                console.log({ err })
                notify(err?.message || "Something Went Wrong!", "error")
            })
            .finally(() => {
                setIsLoading(false)
                setShowDetailsModal(true)
            })
    }

    const formatDateValue = (value) => {
        const date = new Date(value)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const dateValue = date.getDate()
        const hour = date.getHours()
        const min = date.getMinutes()
        const sec = date.getSeconds()
        return `${dateValue}-${month}-${year} ${hour}:${min}:${sec}`
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup>
                            <Item key="view">
                                <DevicePreview />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "contact_time":
                return (
                    <Cell>
                        {item[columnKey] &&
                        !(typeof item[columnKey] !== "string")
                            ? formatDateValue(item[columnKey])
                            : "-"}
                    </Cell>
                )
            case "srs_case_id":
                return (
                    <Cell>
                        <ActionButton
                            UNSAFE_className="caseID-text"
                            isQuiet
                            onPress={() =>
                                getCaseConnectDetails(
                                    item?.srs_case_id,
                                    item?.aws_contact_id,
                                    item?.PRODUCTCODE
                                )
                            }
                        >
                            {item[columnKey]}
                        </ActionButton>
                    </Cell>
                )
            default:
                return <Cell>{item[columnKey] || "-"}</Cell>
        }
    }

    const getCaseHistoryData = async () => {
        try {
            setIsLoading(true)
            setPreviousRequestType(caseHistoryRequestType.durationRequest)
            getCaseContactList(prefferedTimeRange, prefferedNumberRange)
                .then((resp) => {
                    setFilteredList(resp.sort((a, b) => new Date(b.contact_time) - new Date(a.contact_time)))
                })
                .catch((err) => {
                    console.log({ err })
                    notify(err?.message || "Something Went Wrong!", "error")
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } catch (error) {
            console.log({ error })
            notify(JSON.stringify(error), "error")
        }
    }

    const retryCaseConnect = () => {
        setIsLoading(true)
        const { aws_contact_id, srs_case_id } = selectedCaseContactDetail
        let updatedAttributes = {}
        for (let key in selectedCaseContactDetail) {
            if (key !== "aws_contact_id" && key !== "srs_case_id") {
                Object.assign(updatedAttributes, {
                    [key]:
                        key === "NAMEDSUPPORTENGINEER"
                            ? `${selectedCaseContactDetail[key]}@adobe.com`
                            : selectedCaseContactDetail[key],
                })
            }
        }

        // RegionCode, PriorityCode
        const [REGIONCODE] = regionList
            .find(
                (region) =>
                    region.name === selectedCaseContactDetail?.REGIONTEXT
            )
            ?.id.split("-") || [""]
        const [PRIORITYCODE] = priorityList
            .find(
                (region) =>
                    region.name === selectedCaseContactDetail?.PRIORITYTEXT
            )
            ?.id.split("-") || [""]

        updatedAttributes = { ...updatedAttributes, REGIONCODE, PRIORITYCODE }

        const result = {
            aws_contact_id,
            case_id: srs_case_id,
            updatedAttributes,
        }
        rerouteWithUpdatedData(user.userid, result)
            .then((resp) => {
                const { response } = resp
                setUpdatedContactId(response.split(" ")[5])
                notify(response || "Re-Route Success!", "success")
            })
            .catch((err) => {
                notify(err?.message || "Something Went Wrong!", "error")
            })
            .finally(() => {
                setIsLoading(false)
                setShowDetailsModal(false)
            })
    }

    const prefixPaddedValues = (numeric, category = "E") => {
        const totalLength = caseHistoryNumericIDLength[category]
        return numeric.padStart(totalLength, "0")
    }

    const getSearchResults = (caseId) => {
        setIsLoading(true)
        setPreviousRequestType(caseHistoryRequestType.searchFilter)
        searchCaseContact(caseId)
            .then((resp) => {
                setFilteredList(resp.sort((a, b) => new Date(b.contact_time) - new Date(a.contact_time)))
            })
            .catch((err) => {
                notify(JSON.stringify(err), "error")
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const keyEventTrigger = (e) => {
        const { key } = e
        let fullSearchValue = ""
        if (key == "Enter") {
            const isHyphenated = currentText.includes("-")
            if (isHyphenated) {
                const [firstHalf, secondHalf] = currentText.split("-")
                fullSearchValue = `${firstHalf}-${prefixPaddedValues(
                    secondHalf,
                    firstHalf
                )}`
            } else {
                fullSearchValue = `E-${prefixPaddedValues(currentText)}`
            }
            setGeneratedCaseId(fullSearchValue)
        }
    }

    const onSearchClear = () => {
        setCurrentText("")
        setGeneratedCaseId("")
        getCaseHistoryData()
    }

    const copyData = (data) => {
        navigator.clipboard.writeText(data)
        setUpdatedContactId("")
        notify(`Successfully copied contact ID to clipboard`, "success")
    }

    const caseHistoryRequestTypeSwitch = () => {
        switch (previousRequestType) {
            case "duration":
                getCaseHistoryData()
                break
            default:
                getSearchResults(generatedCaseId)
        }
    }

    const changeArrayToPickerInput = (array = []) => {
        return array.map((value) => ({ name: value }))
    }

    const handleSelectedStateChange = (key, selected) => {
        // console.log({ key, selected })
        setSelectedCaseContactDetail((prev) => ({
            ...prev,
            ...{ [key]: selected },
        }))
    }

    useEffect(() => {
        if (updatedContactId == "") {
            caseHistoryRequestTypeSwitch()
        }
    }, [updatedContactId])

    useEffect(() => {
        const timer = setTimeout(() => {
            // call to a function
            if (generatedCaseId) {
                getSearchResults(generatedCaseId)
            }
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [generatedCaseId])

    useEffect(() => {
        getCaseHistoryData()
    }, [])

    return (
        <>
            <div
                className={isLoading ? "display-block" : "display-none"}
                style={{
                    paddingTop: "10%",
                    paddingLeft: "45%",
                    position: "absolute",
                    zIndex: "10",
                }}
            >
                <ProgressCircle
                    aria-label="Loading…"
                    isIndeterminate
                    size="L"
                />
            </div>
            <div align="center">
                <Heading variant="pageTitle">Case History</Heading>
            </div>
            <TableViewContainer>
                <Flex
                    direction={"row"}
                    justifyContent={"space-between"}
                    gap="size-100"
                    UNSAFE_className="searchMobile"
                >
                    <View>
                        <Flex direction={"row"}>
                            <SearchField
                                isQuiet
                                width={"80%"}
                                onClear={() => onSearchClear()}
                                aria-label="Search"
                                onChange={(value) => setCurrentText(value)}
                                onKeyDown={keyEventTrigger}
                            />
                            <TooltipTrigger placement="bottom" delay={0}>
                                <ActionButton aria-label="SearchInfo" isQuiet>
                                    <InfoOutline aria-label="info" size="XXS" />
                                </ActionButton>
                                <Tooltip variant="info">
                                    Press "Enter / Return" key to search for
                                    Case ID.
                                </Tooltip>
                            </TooltipTrigger>
                        </Flex>
                    </View>
                    <View>
                        <Flex
                            gap={"size-300"}
                            direction={"row"}
                            justifyContent={"end"}
                            alignContent={"center"}
                        >
                            <NumberField
                                value={prefferedNumberRange}
                                isQuiet
                                minValue={1}
                                maxValue={30}
                                onChange={setPrefferedNumberRange}
                                width={"10%"}
                                aria-label="Numeric Preferrence"
                            />
                            <ActionGroup
                                isEmphasized
                                selectionMode="single"
                                density="compact"
                                disallowEmptySelection
                                selectedKeys={[prefferedTimeRange]}
                                onAction={setPrefferedTimeRange}
                                UNSAFE_className="ActionGroup_CursorPointer"
                            >
                                <Item key="hour" aria-label="Brush">
                                    Hour
                                </Item>
                                <Item key="day" aria-label="Select">
                                    Day
                                </Item>
                                <Item key="week" aria-label="Select">
                                    Week
                                </Item>
                                <Item key="month" aria-label="Select Region">
                                    Month
                                </Item>
                            </ActionGroup>
                            <Button
                                UNSAFE_className="cursorPointer"
                                type="submit"
                                variant="primary"
                                onPress={getCaseHistoryData}
                            >
                                GO
                            </Button>
                        </Flex>
                    </View>
                </Flex>
                <Flex width="100%" height="100%" direction="column">
                    <TableView
                        aria-label="Case History"
                        selectionMode="none"
                        renderEmptyState={renderEmptyState}
                        height="70vh"
                        overflowMode="wrap"
                    >
                        <TableHeader columns={columns}>
                            {(column) => (
                                <Column
                                    key={column.key}
                                    align={
                                        column.key === "actions"
                                            ? "end"
                                            : "start"
                                    }
                                >
                                    {column.name}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody>
                            {filteredList.map((item, index) => (
                                <Row key={index}>
                                    {(columnKey) => getCell(columnKey, item)}
                                </Row>
                            ))}
                        </TableBody>
                    </TableView>
                </Flex>
            </TableViewContainer>
            <DialogTrigger
                type="modal"
                mobileType="modal"
                isOpen={showDetailsModal}
                isDismissable
                onOpenChange={setShowDetailsModal}
            >
                <></>
                <Dialog size="L" isDismissable>
                    <Header justifySelf={"flex-start"}>
                        <Flex direction={"column"} columnGap={"0px"}>
                            <View>
                                <Heading level={6} margin={"5px 0px"}>
                                    Case ID : {selectedCaseId}
                                </Heading>
                            </View>
                            {selectedCaseContactDetail?.case_title && (
                                <View>
                                    {/* <Heading level={6} margin={"5px 0px"}>Case Title : {selectedCaseContactDetail?.case_title}</Heading>  */}
                                    <Heading level={6} margin={"5px 0px"}>
                                        Case Title :{" "}
                                        <DialogTrigger
                                            type="popover"
                                            placement="right top"
                                        >
                                            <ActionButton
                                                UNSAFE_className="case_description"
                                                autoFocus={false}
                                            >
                                                <View marginStart={"10px"}>
                                                    {
                                                        selectedCaseContactDetail?.case_title
                                                    }
                                                </View>
                                            </ActionButton>
                                            <Dialog maxHeight={"500px"}>
                                                <Heading level={5}>
                                                    Case Description
                                                </Heading>
                                                <Divider />
                                                <Content>
                                                    <Text>
                                                        {
                                                            selectedCaseContactDetail?.case_description_base64
                                                        }
                                                    </Text>
                                                </Content>
                                            </Dialog>
                                        </DialogTrigger>
                                    </Heading>
                                </View>
                            )}
                            <View>
                                <Heading level={6} margin={"5px 0px"}>
                                    Contact ID :{" "}
                                    {selectedCaseContactDetail?.aws_contact_id ||
                                        "-"}
                                </Heading>
                            </View>
                            <View>
                                <Heading level={6} margin={"5px 0px"}>
                                    Contact Time :{" "}
                                    {selectedCaseContactDetail?.contact_time &&
                                    !(
                                        typeof selectedCaseContactDetail?.contact_time !==
                                        "string"
                                    )
                                        ? formatDateValue(
                                              selectedCaseContactDetail?.contact_time
                                          )
                                        : " - "}
                                </Heading>
                            </View>
                            {/* <View>
                       <Heading level={6} margin={"5px 0px"}>Case Description : {selectedCaseContactDetail?.case_description_base64}</Heading> 
                    </View> */}
                        </Flex>
                    </Header>
                    <Divider />
                    <Content>
                        <div
                            className={
                                isLoading ? "display-block" : "display-none"
                            }
                            style={{
                                paddingTop: "10%",
                                paddingLeft: "45%",
                                position: "absolute",
                                zIndex: "10",
                            }}
                        >
                            <ProgressCircle
                                aria-label="Loading…"
                                isIndeterminate
                                size="L"
                            />
                        </div>
                        <Flex
                            direction={"column"}
                            gap={"size-200"}
                            UNSAFE_className="Content__Text--textWrap"
                        >
                            <Flex
                                width={"100%"}
                                direction={"row"}
                                alignContent={"center"}
                                gap={"4px"}
                            >
                                <Flex width={"33%"} direction={"column"}>
                                    <View>
                                        <strong>Name</strong>
                                    </View>
                                    <View>
                                        {selectedCaseContactDetail?.FIRSTNAME ||
                                            "-"}{" "}
                                        {selectedCaseContactDetail?.LASTNAME ||
                                            ""}
                                    </View>
                                </Flex>
                                <Flex width={"33%"} direction={"column"}>
                                    <View>
                                        <strong>Email</strong>
                                    </View>
                                    <View>
                                        {selectedCaseContactDetail?.EMAIL ||
                                            "-"}
                                    </View>
                                </Flex>
                                <Flex width={"33%"} direction={"column"}>
                                    <View>
                                        <strong>Org</strong>
                                    </View>
                                    <View>
                                        {selectedCaseContactDetail?.ORGID ||
                                            "-"}
                                    </View>
                                </Flex>
                            </Flex>
                            <Flex
                                width={"100%"}
                                direction={"row"}
                                alignContent={"center"}
                                gap={"4px"}
                            >
                                <Flex width={"33%"} direction={"column"}>
                                    <View>
                                        <strong>CRM Queue</strong>
                                    </View>
                                    <View marginTop={"5px"}>
                                        {selectedCaseContactDetail?.CRMQUEUE ||
                                            "-"}
                                    </View>
                                </Flex>
                                <Flex
                                    width={"33%"}
                                    direction={"column"}
                                    alignContent={"start"}
                                >
                                    <View>
                                        <strong>Product Code</strong>
                                    </View>
                                    <View marginTop={"5px"}>
                                        {selectedCaseContactDetail?.PRODUCTCODE ||
                                            "-"}
                                    </View>
                                </Flex>
                                <Flex
                                    width={"33%"}
                                    direction={"column"}
                                    alignContent={"start"}
                                >
                                    <View>
                                        <strong>Preferred TSE</strong>
                                    </View>
                                    <View marginTop={"-5px"}>
                                        {/* {selectedCaseContactDetail?.NAMEDSUPPORTENGINEER ||
                                            "-"} */}
                                        <ComboBox
                                            isQuiet
                                            width={"100%"}
                                            placeholder="select or type..."
                                            aria-label="NAMEDSUPPORTENGINEER"
                                            allowsCustomValue
                                            defaultItems={teamMembers}
                                            selectedKey={
                                                selectedCaseContactDetail?.NAMEDSUPPORTENGINEER
                                            }
                                            onSelectionChange={(selected) =>
                                                handleSelectedStateChange(
                                                    "NAMEDSUPPORTENGINEER",
                                                    selected
                                                )
                                            }
                                        >
                                            {(item) => (
                                                <Item key={item.key}>
                                                    {`${item.name} (${item.key})`}
                                                </Item>
                                            )}
                                        </ComboBox>
                                    </View>
                                </Flex>
                            </Flex>
                            <Divider
                                height={"2px"}
                                UNSAFE_style={{ backgroundColor: "#d9d9d9" }}
                            />
                            <Flex
                                width={"100%"}
                                direction={"row"}
                                alignContent={"start"}
                                gap={"20px"}
                            >
                                <Flex width={"50%"} direction={"column"}>
                                    <View>
                                        <strong>Priority</strong>
                                    </View>
                                    <View>
                                        <Picker
                                            width={"100%"}
                                            aria-label="PRIORITYTEXT"
                                            items={priorityList}
                                            selectedKey={
                                                selectedCaseContactDetail?.PRIORITYTEXT
                                            }
                                            onSelectionChange={(selected) =>
                                                handleSelectedStateChange(
                                                    "PRIORITYTEXT",
                                                    selected
                                                )
                                            }
                                            isDisabled={
                                                !permissions.includes(
                                                    SRSRoles.caseHistoryAdmin
                                                )
                                            }
                                        >
                                            {(item) => (
                                                <Item key={item.name}>
                                                    {item.name}
                                                </Item>
                                            )}
                                        </Picker>
                                    </View>
                                </Flex>
                                <Flex
                                    width={"50%"}
                                    direction={"column"}
                                    alignContent={"start"}
                                >
                                    <View>
                                        <strong>Support Level</strong>
                                    </View>
                                    <View>
                                        <Picker
                                            width={"100%"}
                                            aria-label="LEVEL"
                                            items={levelList}
                                            selectedKey={
                                                selectedCaseContactDetail?.LEVEL
                                            }
                                            onSelectionChange={(selected) =>
                                                handleSelectedStateChange(
                                                    "LEVEL",
                                                    selected
                                                )
                                            }
                                            isDisabled={
                                                !permissions.includes(
                                                    SRSRoles.caseHistoryAdmin
                                                )
                                            }
                                        >
                                            {(item) => (
                                                <Item key={item.name}>
                                                    {item.name}
                                                </Item>
                                            )}
                                        </Picker>
                                    </View>
                                </Flex>
                            </Flex>
                            <Flex
                                width={"100%"}
                                direction={"row"}
                                alignContent={"start"}
                                gap={"20px"}
                            >
                                <Flex width={"50%"} direction={"column"}>
                                    <View>
                                        <strong>Issue Reason</strong>
                                    </View>
                                    <View>
                                        <Picker
                                            width={"100%"}
                                            aria-label="ISSUEREASONTEXT"
                                            items={issueReasonList}
                                            selectedKey={
                                                selectedCaseContactDetail?.ISSUEREASONTEXT
                                            }
                                            onSelectionChange={(selected) =>
                                                handleSelectedStateChange(
                                                    "ISSUEREASONTEXT",
                                                    selected
                                                )
                                            }
                                            isDisabled={
                                                !permissions.includes(
                                                    SRSRoles.caseHistoryAdmin
                                                )
                                            }
                                        >
                                            {(item) => (
                                                <Item key={item.name}>
                                                    {item.name}
                                                </Item>
                                            )}
                                        </Picker>
                                    </View>
                                </Flex>
                                <Flex
                                    width={"50%"}
                                    direction={"column"}
                                    alignContent={"start"}
                                >
                                    <View>
                                        <strong>Region</strong>
                                    </View>
                                    <View>
                                        <Picker
                                            width={"100%"}
                                            aria-label="Region"
                                            items={regionList}
                                            selectedKey={
                                                selectedCaseContactDetail?.REGIONTEXT
                                            }
                                            onSelectionChange={(selected) =>
                                                handleSelectedStateChange(
                                                    "REGIONTEXT",
                                                    selected
                                                )
                                            }
                                            isDisabled={
                                                !permissions.includes(
                                                    SRSRoles.caseHistoryAdmin
                                                )
                                            }
                                        >
                                            {(item) => (
                                                <Item key={item.name}>
                                                    {item.name}
                                                </Item>
                                            )}
                                        </Picker>
                                    </View>
                                </Flex>
                            </Flex>
                            <Flex
                                width={"100%"}
                                direction={"row"}
                                alignContent={"start"}
                                gap={"20px"}
                            >
                                <TextArea
                                    minWidth={"100%"}
                                    height={"100px"}
                                    maxHeight={"100px"}
                                    label={<View UNSAFE_style={{color:"#2f2f2f", fontWeight:"800", margin:"0",padding:"0"}}><strong>Contact Details</strong></View>}
                                    value={`AIC AGENT ACTIVITY : ${selectedCaseContactDetail?.AIC_AGENT_ACTIVITY} \n \n AIC CONTACT HISTORY: ${selectedCaseContactDetail?.AIC_CONTACT_HISTORY}`}
                                />
                            </Flex>
                        </Flex>
                    </Content>
                    <Footer
                        alignSelf={"flex-end"}
                        UNSAFE_style={{ paddingTop: "10px" }}
                    >
                        <Flex
                            flexBasis={"100%"}
                            justifyContent={"right"}
                            alignItems={"self-end"}
                        >
                            <DialogTrigger>
                                <ActionButton
                                    UNSAFE_className="ActionButtonBlue"
                                    isDisabled={
                                        isLoading ||
                                        !permissions.includes(
                                            SRSRoles.caseHistoryAdmin
                                        )
                                    }
                                >
                                    Re-Route
                                </ActionButton>
                                <AlertDialog
                                    variant="confirmation"
                                    title="Confirm Re-Route"
                                    primaryActionLabel="Re-Route"
                                    cancelLabel="Cancel"
                                    onPrimaryAction={retryCaseConnect}
                                >
                                    Are you sure you want to Re-Route Case?
                                </AlertDialog>
                            </DialogTrigger>
                        </Flex>
                    </Footer>
                </Dialog>
            </DialogTrigger>
            <DialogTrigger
                type="modal"
                mobileType="modal"
                isOpen={!!updatedContactId}
                isDismissable
                onOpenChange={setUpdatedContactId}
            >
                <></>
                <Dialog size="M" isDismissable>
                    <Header justifySelf={"flex-start"}>
                        <Heading level={4}>
                            Successfully Re-Routed Case ({selectedCaseId})
                        </Heading>
                    </Header>
                    <Divider />
                    <Content>
                        <Flex
                            direction={"row"}
                            gap={"size-100"}
                            justifyContent={"center"}
                            alignContent={"center"}
                        >
                            <View UNSAFE_className="textVerticallyCenter">
                                <Flex direction={"column"}>
                                    <strong>Updated Contact ID : </strong>
                                    {updatedContactId}
                                </Flex>
                            </View>
                            <Divider size="S" orientation="vertical" />
                            <View UNSAFE_className="textVerticallyCenter">
                                <Button
                                    aria-label="copy contact button"
                                    onPress={() => copyData(updatedContactId)}
                                    UNSAFE_className="cursorPointer"
                                >
                                    <Copy />
                                </Button>
                            </View>
                        </Flex>
                    </Content>
                </Dialog>
            </DialogTrigger>
        </>
    )
}

export default connect(mapStateToProps)(CaseHistory)
