import React, { useEffect, useState, useRef } from 'react';
import Delete from '@spectrum-icons/workflow/Delete';
import Draw from '@spectrum-icons/workflow/Draw';
import 'react-schedule-job/dist/index.css'
import { connect } from 'react-redux';
import '../index.css';
import {
    Flex, lightTheme, Provider, SearchField, ActionGroup, Item, Text,
    Content, IllustratedMessage, Badge, TableView, TableHeader, Column,
    TableBody, Cell, Row, Heading, ProgressCircle, View
} from '@adobe/react-spectrum';
import DeleteDialog from './deleteDialog';
import AddUserDialog from "./AddUserDialog";
import { addUserNew, deleteUsers, getAllPolicies, getAllUsers, getRolesList, getUsers, saveUser } from '../utilities/api/admin-api';
import ErrorDialog from './ErrorDialog';
import { notify } from '../constants/constants';
import { renderEmptyState } from '../utilities/components/CommonUseComponents';

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        rolesAndPermissions: state.login.rolesAndPermissions,
    };
}

/**
 * A component for managing user data and performing user operations.
 * @param {{object}} props - The component props.
 * @returns The UserManagement component.
 */
const UserManagement = ({ user, rolesAndPermissions }) => {
    const havingDeletePermission = rolesAndPermissions["ADMIN"]?.delete?.filter((ele) => ele.subService === "CARE USERS")[0]?.resources.type
    const havingEditPermission = rolesAndPermissions["ADMIN"]?.edit?.filter((ele) => ele.subService === "CARE USERS")[0]?.resources.type
    // const havingViewPermission = rolesAndPermissions["ADMIN"]?.view?.filter((ele) => ele.subService === "CARE USERS")[0]?.resources.type
    const havingAddPermission = rolesAndPermissions["ADMIN"]?.add?.filter((ele) => ele.subService === "CARE USERS")[0]?.resources.type



    const [allUsers, setAllUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentText, setCurrentText] = useState('');
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [operation, setOperation] = useState('');
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isLoadingLoader, setIsLoadingLoader] = useState(false)
    const [allUsersNew, setAllUsersNew] = useState([]);
    const [allRolesNew, setAllRolesNew] = useState([]);
    const [allPoliciesNew, setAllPoliciesNew] = useState([]);

    const columns = [
        { name: 'Username', key: 'ldap' },
        { name: 'Roles', key: 'roleNames' },
        { name: 'Policies', key: 'policies' },
        { name: 'Actions', key: 'actions' }
    ];

    const currentUserSelected = useRef('');
    const editData = useRef(false);
    const isLoading = useRef(false);

    const generateKey = (pre) => {
        return `${ pre }_${ new Date().getTime() }`;
    }

    function getAllUsersData() {
        isLoading.current = true;
        setIsLoadingLoader(true);
        getUsers()
            .then((users) => {
                isLoading.current = false;
                setAllUsers(users);
                setFilteredUsers(users)
                setIsLoadingLoader(false);

            }).catch((error) => {
                isLoading.current = false;
                setIsLoadingLoader(false);
                console.log(error)
                //setErrorMessage('Error occured!');
            })
    }

    const getRoles = (roles) => {
        const roleNames = [];
        roles.forEach(element => {
            roleNames.push(element.name);
        });
        return roleNames.join(',');
    }

    const getRolesBadge = (roles) => {
        const badges = [];
        roles?.forEach(element => {
            badges.push((<Badge aria-label={`${generateKey(element)}`} key={generateKey(element)} variant="neutral">{element}</Badge>));
        });
        return badges;
    }

    const getPermissionBadge = (roles) => {
        const badges = [];
        roles?.forEach(element => badges.push((<Badge aria-label={`${generateKey(element)}`} key={generateKey(element)} variant="neutral">{element}</Badge>)))
        return badges;
    }

    const getPermissions = (roles) => {
        const permissionNames = [];
        roles?.forEach(role => {
            role.permissions.forEach(permission => {
                permissionNames.push(permission);
            })
        });
        const uniquePermissionNames = permissionNames.filter((v, i, a) => a.indexOf(v) === i);
        return uniquePermissionNames.join(',');
    }

    function deleteSelectedUsers(usersToDelete) {
        isLoading.current = true;
        setIsLoadingLoader(true);
        deleteUsers(usersToDelete, user?.userid)
            .then((res) => {
                isLoading.current = false;
                setIsLoadingLoader(false);
                notify('User deleted successfully', 'success');
                if (res.error) {
                    setErrorMessage(res.error);
                }
                else {
                    getAllUsersData();
                    setSelectedUsers(new Set())
                    getAllUserAssociatedData();
                }

            }).catch((error) => {
                setIsLoadingLoader(false);
                isLoading.current = false;
                console.log(error)
               // setErrorMessage('Error occured!');
            })
    }

    const getAllUserAssociatedData = () => {
        // isLoading.current = true;
        setIsLoadingLoader(true);
        Promise.allSettled([getAllUsers(user?.userid),getRolesList(user?.userid), getAllPolicies(user?.userid)]).then(([userResponse, roleResponse, policyResponse])=>{
            // console.log({userResponse, roleResponse, policyResponse});
            setIsLoadingLoader(false);
            if(roleResponse.status === 'fulfilled'){
                setAllRolesNew(roleResponse.value);
            }
            if(policyResponse.status === 'fulfilled'){
                setAllPoliciesNew(policyResponse.value);
            }
            if(userResponse.status === 'fulfilled'){
                const usersFinalArray = userResponse.value.map(user => ({...user , ...{roleNames: roleResponse.value.filter(role => user.roleIds.includes(role.id)).map(role=>role.name), policies: policyResponse.value.filter(policyObj => Array.from(new Set(roleResponse.value.filter(role => user.roleIds.includes(role.id)).map(role=>role.policyIds).flat())).includes(policyObj.id)).map(policy => policy.name) }}));
                // console.log({usersFinalArray});
                setAllUsersNew(usersFinalArray);
                setFilteredUsers(usersFinalArray);
                // isLoading.current = false;
            }
        }).catch(err=>{
            console.log({err})
        });
    }

    useEffect(() => {
        getAllUserAssociatedData();
    }, []);
    
    const filterFunction = (value) => {
        if (!allUsersNew.length) return; 
        const filteredUsers = allUsersNew.filter(user => 
            user?.ldap?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredUsers(filteredUsers);
    };
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (currentText) {
                filterFunction(currentText);
            } else {
                setFilteredUsers(allUsersNew);
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [currentText, allUsersNew]);
    
    const filterUsers = (value) => {
        setCurrentText(value);
    };


    const performAction = (value, username) => {
        switch (value) {
            case 'add':
                editData.current = null;
                setOperation("Add User")
                setIsAddDialogOpen(true);
                break;
            case 'edit':
                editData.current = allUsersNew.find((user) => user.ldap == username);
                setOperation("Edit User")
                setIsAddDialogOpen(true);
                break;
            case 'delete':
                currentUserSelected.current = username;
                setIsDeleteDialogOpen(true);
                break;
            case 'bulkDelete':
                currentUserSelected.current = username;
                setIsDeleteDialogOpen(true);
                break;
        }
    }

    const closeDeleteDialog = (isDeletedConfirmed) => {
        setIsDeleteDialogOpen(false);
        if (isDeletedConfirmed) {
            let usersToDelete = [];
            if (currentUserSelected.current instanceof Set) {
                currentUserSelected.current.forEach((username) => {
                    usersToDelete.push(username);
                });
            }
            else {
                usersToDelete = [currentUserSelected.current];
            }

            if (selectedUsers == 'all') {
                usersToDelete = allUsers.map(user => user.username)
            }
            deleteSelectedUsers(usersToDelete);
        }
    }

    const closeAddUserDialog = (isAddConfirmed, userData, error) => {
        console.log({isAddConfirmed, userData, error});
        
        setIsAddDialogOpen(false);
        setShowError(error);
        if (isAddConfirmed) {
            isLoading.current = true;
            setIsLoadingLoader(true);
            addUserNew(user?.userid, userData).then((response)=>{
                isLoading.current = false;
                setIsLoadingLoader(false);
                notify('User added/updated successfully', 'success');
                getAllUserAssociatedData();
            }).catch(err=>{ 
                isLoading.current = false;
                setIsLoadingLoader(false);
                notify('Error occured!', 'error');
            })
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case 'actions':
                return (
                    <Cell>
                        <ActionGroup onAction={(value) => performAction(value, item.ldap)} 
                         disabledKeys={[
                                !havingEditPermission && "edit",
                                !havingDeletePermission && "delete",
                            ]}
                             >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                );
            case 'roleNames':
                return (
                    <Cell>
                        <Flex direction="row" gap={8} wrap="wrap" maxWidth={500}>
                            {getRolesBadge(item[columnKey])}
                        </Flex>
                    </Cell>
                )
            case 'policies':
                return (
                    <Cell>
                        <Flex direction="row" gap={8} wrap="wrap" maxWidth={580}>
                            {getPermissionBadge(item['policies'])}
                        </Flex>
                    </Cell>
                )
            default:
                return (
                    <Cell>{item[columnKey]}</Cell>
                );
        }
    }

    const closeErrorDialog = () => {
        setShowError(false);
      }
    return (
        <>
        {isLoadingLoader ? (
                <View
                    UNSAFE_style={{
                        paddingTop: "10%",
                        paddingLeft: "45%",
                        position: "absolute",
                        zIndex: "10",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
                </View>
            ) : null}

            <div align="center">
                <Heading variant="pageTitle">Care Users Details</Heading>
            </div>

            <div
                style={{
                    padding: '25px',
                    border: '0.8px solid',
                    borderRadius: '0.7em',
                    marginTop: '30px',
                }}
            >

                <Provider theme={lightTheme}>
                    <Flex direction="row" gap="size-100" UNSAFE_className="searchMobile">
                    <SearchField isQuiet
                            onClear={() => setCurrentText('')}
                            onChange={filterUsers}
                            aria-label="Search"
                            value={currentText}
                            width={'20%'}
                        />
                        <ActionGroup width={'80%'} alignItems="end" onAction={(value) => performAction(value, selectedUsers)} 
                        disabledKeys={[!havingAddPermission && "add", !havingDeletePermission && "bulkDelete", selectedUsers.size === 0 && "bulkDelete"]}>
                        
                            <Item key="add">+ Add</Item>
                            <Item key="bulkDelete" ><Delete /><Text>Delete</Text></Item>
                        </ActionGroup>


                    </Flex>

                    <TableView aria-label="spam users"
                        selectionMode={!havingDeletePermission ? "none" : "multiple"}
                        selectedKeys={selectedUsers}
                        onSelectionChange={setSelectedUsers}
                        height={'45vh'}
                        renderEmptyState={renderEmptyState}
                        density="spacious"
                        overflowMode="wrap"
                    >

                        <TableHeader columns={columns}>
                            {(column) => (
                                <Column key={column.key} align={column.key === 'actions' ? 'end' : 'start'}
                                    minWidth={column.key === 'permissions' ? '50%' : '10%'}>
                                    {column.name}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody items={filteredUsers} loadingState={isLoading.current ? 'loading' : ''}>
                            {item => (
                                <Row key={item.ldap}>
                                    {(columnKey) => getCell(columnKey, item)}
                                </Row>
                            )}
                        </TableBody>
                    </TableView>
                </Provider>
                {isdeleteDialogOpen && <DeleteDialog
                    isDialogOpened={isdeleteDialogOpen}
                    handleCloseDialog={(isDeletedConfirmed) => closeDeleteDialog(isDeletedConfirmed)}
                    entity="User"
                />}
            </div>


            {errorMessage && (<div
                style={{
                    fontSize: 'large', color: 'red', marginTop: '1%',
                }}
                align="center"
            >
                {' '}
                <b> {errorMessage}</b>
            </div>)}
            {isAddDialogOpen && <AddUserDialog
                isDialogOpened={isAddDialogOpen}
                editData={editData.current}
                handleCloseDialog={(isAddConfirmed, userData, error) => closeAddUserDialog(isAddConfirmed, userData,error)}
                operation={operation}
                allUsers={allUsers}
                roles={allRolesNew}
                policies={allPoliciesNew}
            />
            }

            {showError &&
                <ErrorDialog
                    isDialogOpened={showError}
                    handleCloseDialog={() => closeErrorDialog()}
                    msg="Username already exists"
                />
            }
        </>
    );
}

export default connect(mapStateToProps)(UserManagement);
