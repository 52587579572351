import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, TextField, View
} from '@adobe/react-spectrum';

const AddDxPrompts = (props) => {
    const [language, setLanguage] = useState('');
    const [promptName, setPromptName] = useState('');
    const [text, setText] = useState('')

    useEffect(() => {
        if (props.editData) {
            setLanguage(props?.editData?.language);
            setPromptName(props?.editData?.promptName);
            setText(props?.editData?.text)
        }
    }, []);

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    }


    const onSaveHandler = () => {
        const body = {
            language : language.trim(),
            promptName : promptName.trim(),
            text : text.trim(),
        }
        props.handleCloseDialog(true,body);
    }


    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="S">
                        <Heading>{`${props.operation} DxPrompt`}</Heading>
                        <Divider />
                        <Content>

                            <Form isRequired>
                                <View>Language</View>
                                <TextField autoFocus
                                    value={language}
                                    onChange={setLanguage}
                                    isQuiet
                                    isDisabled={props?.editData}
                                />

                                <div style={{ marginTop: '30px' }}></div>

                                <View>Prompt Name</View>
                                <TextField 
                                    value={promptName}
                                    onChange={setPromptName}
                                    isQuiet
                                    isDisabled={false}
                                />
                                <div style={{ marginTop: '30px' }}></div>
                                <View>Text</View>
                                <TextField 
                                    value={text}
                                    onChange={setText}
                                    isQuiet
                                    isDisabled={false}
                                />
                            
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>Cancel</Button>
                            <Button variant="primary" onPress={onSaveHandler} isDisabled={false}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddDxPrompts;