import React from 'react';
import { Item, TabList, TabPanels, Tabs, Well } from '@adobe/react-spectrum'
import UserManagement from './UserManagement';
import RoleManagement from './RoleManagement';
import TimezoneManagement from './TimezoneManagement';
import { connect } from 'react-redux';
import Permissions from './Permissions';
import Policies from './Policies';
// import ConnectionType from './ConnectionType';
import { TabsDisplay } from "../utilities/components/CommonUseComponents"


function mapStateToProps(state) {
    return {
        login: state.login,
        rolesAndPermissions : state.login.rolesAndPermissions
    };
}

// class AdminOperations extends React.Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             loaded: true,
//         };
//     }

//     render() {
//         return (
//             <Well
//                 style={{
//                     minHeight: '75vh',
//                     marginRight: '1%',
//                     marginLeft: '1%',
//                 }}
//             >

//                 <Tabs aria-label="Admin Operations">
//                     <TabList>
//                         <Item key="users">CARE USERS</Item>
//                         <Item key="roles">CARE ROLES</Item>
//                         <Item key="policy">POLICIES</Item>
//                         <Item key="timezone">TIMEZONES</Item>
//                         {/* <Item key="permissions">PERMISSIONS</Item> */}
//                     </TabList>
//                     <TabPanels>
//                         <Item key="policy">
//                             <Policies />
//                         </Item>
//                         <Item key="users">
//                             <UserManagement />
//                         </Item>
//                         <Item key="roles">
//                             <RoleManagement />
//                         </Item>
//                         <Item key="timezone">
//                             <TimezoneManagement />
//                         </Item>
//                         {/* <Item key="permissions">
//                             <Permissions/>
//                         </Item> */}
//                     </TabPanels>
//                 </Tabs>
//             </Well>
//         );
//     }
// }

const AdminOperations = ({currentComponent, directList , permissions , user , rolesAndPermissions}) => {
    const rawdata = rolesAndPermissions
    const permissionsArrayForRouting = React.useMemo(() => {
        const result = new Set();
        if (rawdata) {
            Object.keys(rawdata).forEach((service) => {
                result.add(service);
                Object.keys(rawdata[service]).forEach((permissionType) => {
                    rawdata[service][permissionType].forEach((entry) => {
                        result.add(entry.subService);
                    });
                });
            });
        }
        return Array.from(result);
    }, [rawdata]);

    const modifiedData = permissionsArrayForRouting.flatMap((item) => {
        return item.startsWith("ROUTING") ? ["ROUTING FLOW CONFIG",item] : item
    })
    
    const uniqueData = [...new Set(modifiedData)]
    const arrayOfUniqueData = Array.from(uniqueData)

    console.log("arrayOfUniqueData from ADMIN OPERATION",arrayOfUniqueData);
    

    return (
        <Well
            UNSAFE_style={{
                minHeight: "75vh",
                marginRight: "1%",
                marginLeft: "1%",
            }}
        >
          {arrayOfUniqueData.length > 0  && (
                <TabsDisplay
                    permissions={arrayOfUniqueData}
                    directList={directList}
                    user={user}
                />
            )}
        </Well>
    )


}

export default connect(mapStateToProps)(AdminOperations);