import { CARE_PASSWORD, SERVER_URL, CARE_USERNAME } from '../../constants/constants';
import { get, post } from './methods';


/**
 * @export getAllSRSRules
 * @return {*} 
 */
export function getAllSRSRules(){
    return get({
        url: `${SERVER_URL}/srsRules/get/all`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

/**
 * @export getSRSRule
 * @param {*} ruleId
 * @return {*} Promise
 */
export async function getSRSRule(ruleId){
    return get({
        url: `${SERVER_URL}/srsRules/get/all`,
        queryParams:{
            ruleId
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

/**
 * @export addSRSRule
 * @param {*} data
 * @param {*} ldap
 * @return {*} 
 */
export async function addSRSRule(data, ldap){
    try{
        return post({
            url: `${SERVER_URL}/srsRules/add`,
            json: data,
            username: CARE_USERNAME,
            password: CARE_PASSWORD,
            queryParams: {
                ldap
            }
        }).catch((error)=> { console.log({error});throw error.text()});
        // return response;
    }catch(err){
        console.log({err}, err.body);
        throw err;
    }
}

/**
 * @export EditSRSRULES
 * @param {*} data
 * @param {*} ldap
 * @return {*} 
 */
export async function editSRSRule(data,ldap){
    const response = await post({
        url: `${SERVER_URL}/srsRules/edit`,
        json: data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        queryParams: {
            ldap
        }
    });
    return response;
}

/**
 * @export Delete
 * @param {*} data
 * @param {*} ldap
 * @return {*} 
 */
export async function deleteSRSRule(data,ldap){
    const response = await post({
        url: `${SERVER_URL}/srsRules/delete`,
        json: data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        queryParams: {
            ldap
        }
    });
    return response;
}


/**
 * @export ReorderSRSRULES
 * @param {*} data
 * @param {*} ldap
 * @return {*} 
 */
export async function reorderSRSRule(data,ldap){
    const response = await post({
        url: `${SERVER_URL}/srsRules/rearrange`,
        json: data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        queryParams: {
            ldap
        },
        isJsonResponse:false
    });
    return response;
}


/**
 * 
 * @param {*} ldap 
 * @param {*} unit 
 * @param {*} duration 
 * @returns Promise
 */
export async function getCaseContactList(unit, duration){
    return get({
        url: `${SERVER_URL}/care/v1/case-contact/get`,
        queryParams:{
            unit, 
            duration
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

/**
 * 
 * @param {*} caseId 
 * @param {*} contactId 
 * @returns Promise
 */
export async function getCaseContactDetail(caseId, contactId){
    return get({
        url: `${SERVER_URL}/care/v1/get/case/${caseId}/contact/${contactId}`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

/**
 * 
 * @param {*} caseId 
 * @returns Promise
 */
export async function searchCaseContact(caseId){
    return get({
        url: `${SERVER_URL}/care/v1/get/case/${caseId}`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

/**
 * 
 * @param {*} caseId 
 * @param {*} contactId 
 * @returns Promise
 */
export function reRouteCaseContact(caseId, contactId) {
    return post({
        url: `${SERVER_URL}/care/v1/re-route/case/${caseId}/contact/${contactId}`,
        json: {},
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

/**
 * 
 * @returns Promise
 */
export function getProductIssueReasons(productCode){
    return get({
        url: `${SERVER_URL}/care/v1/product-issueReasons`,
        queryParams:{productCode},
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse:true
    });
}

/**
 * rerouteWithUpdatedData
 * @param {*} data 
 * @returns Promise
 */
export function rerouteWithUpdatedData(ldap, data) {
    return post({
        url: `${SERVER_URL}/care/v1/re-route`,
        json: JSON.stringify(data),
        queryParams: {
            ldap
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
        reqContentType: 'application/json'
    });
}