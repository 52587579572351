import React from "react"
import { Item, TabList, TabPanels, Tabs, Well } from "@adobe/react-spectrum"
import AlertServiceSearch from "./AlertServiceSearch"
import MonitorQueueList from "./MonitorQueueList"
import EditMonitorQueueList from "./EditMonitorQueue"
import AlertServiceList from "../AlertServiceNew/AlertServiceList"
import BuAlertList from "../AlertBuMonitoring/BuAlertList"
import { connect } from "react-redux"
import { TabsDisplay } from "../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess
                : [],
        rolesAndPermissions: state.login.rolesAndPermissions,
    }
}

const AlertService = ({user, directList, rolesAndPermissions }) => {
    const rawdata = rolesAndPermissions
    const permissionsArrayForRouting = React.useMemo(() => {
        const result = new Set()
        if (rawdata) {
            Object.keys(rawdata).forEach((service) => {
                result.add(service)
                Object.keys(rawdata[service]).forEach((permissionType) => {
                    rawdata[service][permissionType].forEach((entry) => {
                        result.add(entry.subService)
                    })
                })
            })
        }
        return Array.from(result)
    }, [rawdata])    

    return (
        <>
            <Well
                style={{
                    minHeight: "75vh",
                    marginRight: "1%",
                    marginLeft: "1%",
                }}
            >
                {permissionsArrayForRouting.length > 0 && (
                    <TabsDisplay
                        permissions={permissionsArrayForRouting}
                        directList={directList}
                        user={user}
                    />
                )}
            </Well>
        </>
    )
}

export default connect(mapStateToProps)(AlertService)
