import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import {
    Picker,
    Item,
    Button,
    Text,
    Flex,
    TagGroup,
    Divider,
    View,
    Switch,
    Heading,
    DialogTrigger,
    Content,
    ActionButton,
    Dialog
} from "@adobe/react-spectrum";
import { tabsRoutingList } from "../constants/constants";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown"
import ChevronUp from "@spectrum-icons/workflow/ChevronUp"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import Close from "@spectrum-icons/workflow/Close"
import GroupPermissions from "./GroupPermissions";

export const PermissionAccordion2 = ({ policy, formDataLifting, disabledServices, formData, queueList, groupList, isEdit , buList }) => {
    
    // console.log("Permissions Accordion2 Props Line 28",{policy, formDataLifting, disabledServices, formData, queueList, groupList, isEdit , buList});
    

    const [isExpanded, setIsExpanded] = useState(true); // State to handle expand/collapse
    const [isTabExpanded, setIsTabExpanded] = useState(true);
    const [areAllTabsExpanded, setAreAllTabsExpanded] = useState(false); // Master toggle state

    // const [selectedGroups, setSelectedGroups] = useState([]);
    const selectedGroupsRef = useRef([]);
    const [isGroupPermissionDialogue, setisGroupPermissionDialogue] = useState(false);

    const [groupSwitch, setgroupSwitch] = useState([]);
    const [queuesSwitch, setqueuesSwitch] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [tab, settab] = useState("");
    const [selectedReources, setselectedReources] = useState([]);

    const [policyTabs, setpolicyTabs] = useState({});
    const [selectedservice, setselectedService] = useState('');
    const [tabName , setTabName] = useState('');
    

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleTabExpand = (tabIndex) => {
        setIsTabExpanded((prev) => ({
            ...prev,
            [tabIndex]: !prev[tabIndex], // Toggle the state for the clicked tab
        }));
    };
    const toggleAllTabsExpand = (isSelected) => {
        setAreAllTabsExpanded(isSelected); // Set master state based on switch
        setIsTabExpanded(Object.values(policyTabs).reduce((acc, tab, index) => {
            acc[index] = isSelected; // Expand or collapse all tabs
            return acc;
        }, {}));
    };

    const permissionSingle = {
        tab: '',
        resources: {

        }
    };

    const permissionSet = {
        add: [permissionSingle],
        view: [permissionSingle],
        edit: [permissionSingle],
        delete: [permissionSingle],
        enforceUpdate: [permissionSingle],
        clone: [permissionSingle]
    };

    // const emptyPermissions = {
    //     add: [{
    //         "tab": "",
    //         "resources": {}
    //     }],
    //     view: [{
    //         "tab": "",
    //         "resources": {}
    //     }],
    //     edit: [{
    //         "tab": "",
    //         "resources": {}
    //     }],
    //     delete: [{
    //         "tab": "",
    //         "resources": {}
    //     }],
    //     additionalPermissions: [{
    //         "tab": "",
    //         "resources": {}
    //     }],
    //     enforceUpdate: [{
    //         "tab": "",
    //         "resources": {}
    //     }],
    //     clone: [{
    //         "tab": "",
    //         "resources": {}
    //     }]
    // };


    const flattenRoute = (node) => {
        // If no children, return an empty array
        if (node) {
            if (!node.children) {
                return [];
            }

            return node.children.reduce((acc, child) => {
                // Add the current child
                acc.push(child);

                // If the child has its own children, recursively flatten them and add to the accumulator
                if (child.children) {
                    acc = acc.concat(flattenRoute(child));
                }

                return acc;
            }, []);
        } else {
            return [];
        }

    }

    const getTabsList = (service) => {
        let tabList = (service && service != '') ? flattenRoute(tabsRoutingList.filter(tabs => tabs.permission.toLowerCase() === service.toLowerCase()).shift()).map(tabsRoutingList => ({ id: tabsRoutingList.permission, name: tabsRoutingList.title })) : false;
        return tabList;
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // List and other updates
    const getServiceItems = () => {
        return tabsRoutingList?.map(tabsRoutingList => ({ id: tabsRoutingList.permission, name: tabsRoutingList.title }));
    };

    const updatePolicyValue = (newValue) => {
        formDataLifting(previous => {
            let policyIndex;
            policyIndex = previous?.policies?.findIndex((element) => element?.service == newValue);
            if (policyIndex <= 0)
                policyIndex = previous.policies.findIndex((element) => element.service == policy.service);

            previous.policies[policyIndex].service = newValue;
            previous.policies[policyIndex].permissions = { ...permissionSet };
            let updatedPolicyTabs = {};
            updatedPolicyTabs[""] = {
                tab: "", resources: []

            }
            setpolicyTabs(updatedPolicyTabs);
            setselectedService(newValue);
            return JSON.parse(JSON.stringify(previous));
        })
    }; 
    
    const deletePolicy = (service) => {
        formDataLifting(previous => {
            previous.policies = previous.policies.filter((element) => element.service != policy.service);
            return JSON.parse(JSON.stringify(previous))
        })
    };

    const handleRemoveTab = (tabName) => {
        formDataLifting(previous => {
            let policyIndex = previous.policies.findIndex((element) => element.service == policy.service);
            Object.keys(previous.policies[policyIndex].permissions).forEach((permType) => {
                previous.policies[policyIndex].permissions[permType] = previous.policies[policyIndex].permissions[permType].filter(
                    (item) => item.tab !== tabName
                );
            });
            return JSON.parse(JSON.stringify(previous));
        })
        let updatedPolicyTabs = { ...policyTabs };
        delete updatedPolicyTabs[tabName];
        setpolicyTabs(updatedPolicyTabs);
    };

    const getAllTabs = () => {        
        let updatedPolicyTabs = { ...policyTabs };
        let ispolicyTabsUpdated = false;

        Object.keys(policy?.permissions).forEach((permType) => {
            policy?.permissions[permType]?.forEach((perm) => {
                const { tab, resources } = perm;
                setTabName(tab);                
                
                let resourceTypes = [];

                if (resources.queues) {
                    resourceTypes.push("queues");
                }
                if (resources.groups) {
                    resourceTypes.push("groups");
                }
                if (resources.type) {
                    resourceTypes.push("type");
                }
                if(resources.bu){
                    resourceTypes.push("bu");
                }

                if (!updatedPolicyTabs[tab]) {
                    updatedPolicyTabs[tab] = {
                        tab,
                        resources: resourceTypes
                    };
                }
                ispolicyTabsUpdated = true;
            });

        });

        if (!ispolicyTabsUpdated) {
            updatedPolicyTabs[""] = {
                tab: "",
                resources: []
            }
        }
        if (!policy?.permissions) {
            updatedPolicyTabs[""] = {
                tab: ""
            }
        }
        setpolicyTabs(updatedPolicyTabs);

        // // Convert the Set to an array and return it
        // return Object.values(policyTabs);
    };



   

    const permissionContainsResource = (selectedPermission, tab, resource) => {
        return getTabpermission(tab, selectedPermission)?.resources?.[resource];
    };

    const getTabpermission = (tab, perm) => {
        return policy?.permissions[perm]?.find((value) => value.tab == tab.tab);
    };

    const anyPermissionsPresent = (tabName) => {
        let groupIndex = selectedGroupsRef.current.findIndex((e) => e.tab == tabName);
        if(groupIndex > -1) {
            return Object.values(selectedGroupsRef.current[groupIndex].permissions).some(permissionArray => permissionArray.length > 0);
        }
        return true;
    };

    const handleRemoveAllGroups = (tabName) => {
        formDataLifting(previous => {
            let policyIndex = previous.policies.findIndex((element) => element.service == policy.service);
            const selectedPermissions = previous.policies[policyIndex].permissions;
            let selectedGroups = selectedGroupsRef.current;

            Object.keys(selectedPermissions).forEach((key) => {
                let tabIndex = selectedPermissions[key].findIndex((element) => element?.tab == tabName);
                if (tabIndex > -1) {
                    // const resourceKey = getResourceKey();
                    selectedPermissions[key].splice(tabIndex, 1);

                    // selectedPermissions[key][tabIndex].resources[resourceKey] = [];

                    let groupIndex = selectedGroups.findIndex((e) => e.tab == tabName);
                    if (groupIndex > -1) {
                        selectedGroups[groupIndex].permissions[key] = [];
                    }
                }

            });

            //   const {resources} = previous.policies[policyIndex].permissions[permission][index];
            //   const [resourceKey] = Object.keys(resources);
            //   previous.policies[policyIndex].permissions[permission][index].resources[resourceKey] = [];
            return JSON.parse(JSON.stringify(previous))
        });

    };

    const handleRemoveGroup = (tabIndex, group) => {
        const newTabs = [...tabs];
        newTabs[tabIndex].selectedGroups = newTabs[tabIndex].selectedGroups.filter(
            (g) => g !== group
        );
        setTabs(newTabs);
    };

    const permissionDialogue = (tab) => {        
        settab(tab.tab);
        setselectedReources([...tab.resources]);
        setisGroupPermissionDialogue(true);
    };

    const closePermissionsModal = () => {
        setisGroupPermissionDialogue(false);
    }

    const getPermissionList = () => {
        const permissionsSet = new Set();
        if (selectedReources.includes("groups")) {
            groupList.forEach(item => permissionsSet.add(item));
        }
        if (selectedReources.includes("queues")) {
            queueList.forEach(item => permissionsSet.add(item));
        }
        if (selectedReources.includes("bu")) {            
            buList.forEach(item => permissionsSet.add(item));
        }
        return Array.from(permissionsSet);
    };

    const getResourceKey = (currentTab) => {        
        let resourceKey = 'type'; 
        
        if (policy.service === 'ALERT') {
            switch (currentTab) {
                case 'QUEUE MONITORING':
                    resourceKey = 'queues';
                    break;
                case 'OTHERS MONITORING':
                    resourceKey = 'bu';
                    break;
                default:
                    resourceKey = null;
            }
        } else if (policy.service === 'AMAZON CONNECT') {
            resourceKey = 'groups';
        }

        return resourceKey;
    };

    const saveGroups = (tabName="") => {
        formDataLifting(previous => {
            let policyIndex = previous.policies.findIndex((element) => element.service == policy.service);
            const selectedPermissions = previous.policies[policyIndex].permissions;
            Object.keys(selectedPermissions).forEach((key) => {
                let selectedGroups = selectedGroupsRef.current;
                let tempTabName = tabName == "" ? tab : tabName;
                let groupIndex = selectedGroups.findIndex((e) => e.tab == tempTabName);
                let selectedGroupValues = selectedGroups[groupIndex]?.permissions[key];
                if (selectedGroupValues && selectedGroupValues.length > 0) {
                    let tabIndex = selectedPermissions[key].findIndex((element) => element.tab == tempTabName);
                    if (tabIndex == -1) {
                        tabIndex = selectedPermissions[key].findIndex((element) => element.tab == "");
                    }
                    let newResourceKey = 'type';
                    switch (policy.service) {
                        case 'AMAZON CONNECT':
                            newResourceKey = 'groups';
                            break;

                        case 'ALERT':
                            newResourceKey = tempTabName === "QUEUE MONITORING" ? 'queues' : tempTabName === "OTHERS MONITORING" ? 'bu' : null;
                            break;

                        default:
                            newResourceKey = 'type';
                            break;
                    }
                    if (tabIndex > -1) {
                        const { resources } = selectedPermissions[key][tabIndex];
                        const [resourceKey] = Object.keys(resources);
                        if (resourceKey) {
                            // const updatedArray = Array.from(new Set([...(resources[resourceKey] || []), ...selectedGroupValues]));
                            const updatedArray = Array.from(new Set([...selectedGroupValues]));
                            previous.policies[policyIndex].permissions[key][tabIndex].resources[resourceKey] = updatedArray;
                        } else {
                            previous.policies[policyIndex].permissions[key][tabIndex].tab = tempTabName;
                            previous.policies[policyIndex].permissions[key][tabIndex].resources = { [newResourceKey]: selectedGroupValues };
                        }
                    }
                    else {
                        let tabIndex = selectedPermissions[key].findIndex(item =>
                            !Object.values(policyTabs).some(sItem => sItem.tab === item.tab)
                        );
                        if (tabIndex > -1) {
                            selectedPermissions[key].splice(tabIndex, 1);
                        }
                        previous.policies[policyIndex].permissions[key].push({
                            "tab": tempTabName, "resources": { [newResourceKey]: selectedGroupValues }
                        });
                    }
                }
                else {
                    let tabIndex = selectedPermissions[key].findIndex((element) => element.tab == tempTabName);
                    if (tabIndex > -1) {
                        selectedPermissions[key].splice(tabIndex, 1);
                        // previous.policies[policyIndex].permissions[key] = selectedPermissions;
                    }
                    else {
                        tabIndex = selectedPermissions[key].findIndex(item =>
                            !Object.values(policyTabs).some(sItem => sItem.tab === item.tab)
                        );
                        if (tabIndex > -1) {
                            selectedPermissions[key].splice(tabIndex, 1);
                        }
                    }
                }

            });            
            return JSON.parse(JSON.stringify(previous));

        });
        closePermissionsModal();
    };

    const updateSelectedTabs = (newTab, previousTabValue, tabIndex) => {
        let resourceKey = getResourceKey();
        
        // Special handling for ALERT service
        if (policy.service === 'ALERT') {
            if (newTab === 'QUEUE MONITORING') {
                resourceKey = 'queues';
            } else if (newTab === 'OTHERS MONITORING') {
                resourceKey = 'bu';
            }
        }

        setpolicyTabs(prevPolicyTabs => {
            const updatedPolicyTabs = { ...prevPolicyTabs };

            Object.keys(updatedPolicyTabs).forEach(tabName => {
                if (tabName === previousTabValue) {
                    updatedPolicyTabs[newTab] = { 
                        ...updatedPolicyTabs[tabName], 
                        tab: newTab, 
                        resources: [resourceKey] 
                    };
                    delete updatedPolicyTabs[tabName];
                }
            });

            return updatedPolicyTabs;
        });

        let newGroups = selectedGroupsRef.current;
        let groupIndex = newGroups.findIndex((e) => e.tab == previousTabValue);
        if (groupIndex >= 0) {
            newGroups.splice(groupIndex, 1);
        }
        const permissionArray = resourceKey == "type" ? ["*"] : [];
        let groupObject = {
            tab: newTab,
            permissions: {
                view: permissionArray,
                edit: permissionArray,
                add: permissionArray,
                delete: permissionArray,
                enforceUpdate: permissionArray,
                clone: permissionArray,
                other: permissionArray
            }
        };
        newGroups.push(groupObject);
        selectedGroupsRef.current = newGroups;
        if (previousTabValue === "") {
            toggleTabExpand(tabIndex);
        }
        if(resourceKey == "type") {
            saveGroups(newTab);
        }
    };

   

    // tarun
    const addNewTab = () => {
        let updatedPolicyTabs = { ...policyTabs };
            updatedPolicyTabs[""] = {
                tab: "",
                resources: []
            };
        setpolicyTabs(updatedPolicyTabs);
    };

   
    useEffect(() => {
        if (typeof formDataLifting === 'function') {
            formDataLifting((previous) => {
                return previous
            })
        }
        getAllTabs();
        Object.keys(policy?.permissions).map((perm) => {
            policy?.permissions[perm]?.forEach((element) => {
                // Pass the current element's tab directly to getResourceKey
                let resourceKey = getResourceKey(element.tab);
                let groupValues = element?.resources[resourceKey];
                if (groupValues) {
                    let newGroups = selectedGroupsRef.current;
                    let groupIndex = newGroups.findIndex((e) => e.tab == element.tab);
                    if (groupIndex < 0) {
                        let groupObject = {
                            tab: element.tab,
                            permissions: {
                                view: [],
                                edit: [],
                                add: [],
                                delete: [],
                                enforceUpdate: [],
                                clone: [],
                                other: []
                            }
                        };
                        groupObject.permissions[perm].push(...groupValues);
                        newGroups.push(groupObject);
                    }
                    else {
                        let groupObject = newGroups[groupIndex];
                        groupObject.permissions[perm].push(...groupValues);
                        newGroups.splice(groupIndex, 1);
                        newGroups.push(groupObject);
                    }
                    selectedGroupsRef.current = newGroups;
                }
            });
        });
    }, [])

    return (
        <>
            <View width={'100%'}>
                <View UNSAFE_style={{ border: "2px solid #b2b2b2", borderRadius: '10px' }}>
                    <Flex
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="size-1000"
                        height={"50px"}
                        UNSAFE_style={{ backgroundColor: "#b2b2b2", padding: '0px 10px' }}
                    >
                        <Picker
                            items={getServiceItems() || [{ id: '', name: 'Select Service' }]}
                            selectedKey={policy.service || ''}
                            onSelectionChange={(value) => updatePolicyValue(value)}
                            labelPosition="side"
                            label={
                                <Text
                                    UNSAFE_style={{
                                        fontWeight: "600",
                                        color: "black",
                                    }}
                                >
                                    Select Service
                                </Text>
                            }
                            disabledKeys={disabledServices}
                            isDisabled={isEdit}
                        >
                            {(item) => (<Item key={item.id}>{item.name}</Item>)}
                        </Picker>
                        <Flex gap="size-100">
                            {!isEdit && (<Button onPress={() => deletePolicy(policy.service)} style="outline" UNSAFE_style={{padding:"0px 10px"}}>Delete</Button>)}
                            <Button onPress={toggleExpand}>
                                {isExpanded ? <ChevronUp /> : <ChevronDown />}
                            </Button>
                        </Flex>
                    </Flex>
                    {/*added the Add tab buttomn and Expand all toggle*/}
                    <Flex justifyContent="space-between" alignItems="center" margin="size-150">
                        <Text UNSAFE_style={{ fontWeight: 600 }}>
                            Expand to view the sub services and associated permissions
                        </Text>
                        <div>
                            {!isEdit && (
                                <Button
                                    variant="accent"
                                    style="fill"
                                    UNSAFE_style={{ marginRight: "10px" }}
                                    onPress={() => addNewTab()}
                                >
                                    Add
                                </Button>)}
                            <Switch
                                isSelected={areAllTabsExpanded}
                                onChange={toggleAllTabsExpand}
                            >
                                Expand All
                            </Switch>
                        </div>
                    </Flex>

                    {isExpanded && (
                        <>
                            {Object.values(policyTabs)
                                .map((tab, tabIndex) => (
                                    
                                    
                                    <>
                                        <div style={{ border: "1px solid rgb(178, 178, 178)", borderRadius: "10px", margin: "10px" }} key={tabIndex}>
                                            <View padding={"size-200"} key={tabIndex}>
                                                {/* {tabIndex !== 0 && <Divider size="M" marginBottom="size-300" />} */}
                                                <Flex direction="column" gap="size-200">
                                                    <Flex
                                                        direction="row"
                                                        gap="size-200"
                                                        alignItems="center"
                                                        justifyContent={"space-between"}
                                                    >
                                                        <Picker
                                                            items={getTabsList(policy.service) || [{ id: '', name: 'Select Tabs' }]}
                                                            selectedKey={tab.tab}
                                                            labelPosition="side"
                                                            label={
                                                                <Text
                                                                    UNSAFE_style={{
                                                                        fontWeight: "600",
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    Sub Service
                                                                </Text>
                                                            }
                                                            onSelectionChange={(key) => updateSelectedTabs(key, tab.tab, tabIndex)}
                                                            disabledKeys={Object.values(policyTabs)?.map(ele => ele?.tab)}
                                                            isDisabled={isEdit}
                                                        >
                                                            {(item) => (item.name !== "ROUTING FLOW CONFIG" &&  ( <Item key={item.id}>{item.name}</Item>) )}
                                                        </Picker>
                                                        <view>
                                                            {(!isEdit) && (
                                                                <Button UNSAFE_style={{ marginRight: "10px" }} onPress={() => handleRemoveTab(tab.tab)} isDisabled={isEdit} variant="negative">
                                                                   <Delete UNSAFE_style={{color:"red"}} />
                                                                </Button>
                                                            )}

                                                            <Button onPress={() => toggleTabExpand(tabIndex)}>
                                                                {isTabExpanded[tabIndex] ? <ChevronUp /> : <ChevronDown />}
                                                            </Button>
                                                        </view>
                                                    </Flex>
                                                </Flex>
                                            </View>

                                            <View>

                                                {isTabExpanded[tabIndex] && (
                                                    <View UNSAFE_style={{ borderTop: "1px solid rgb(178, 178, 178)" }}>


                                                        {(!isEdit && tab.resources.length > 0 ) && (
                                                            <Flex marginTop="size-200" marginStart="size-200" direction="row" gap="size-600" alignItems="center">
                                                                <Text
                                                                    UNSAFE_style={{
                                                                        fontWeight: "600",
                                                                        // color: "black",
                                                                        fontSize: "14px",
                                                                        // marginTop: "10px",
                                                                        // paddingLeft: "25px"
                                                                    }}
                                                                >
                                                                    <p>Resources</p>
                                                                </Text>
                                                            </Flex>
                                                        )}

                                                        {(tab.resources.length == 0 ) && (
                                                            <View padding="size-300">
                                                                <Text UNSAFE_style={{
                                                                    fontWeight: "600",
                                                                    color: "black",
                                                                }}> No Permissions</Text>
                                                            </View>
                                                        )}

                                                        {/* Toggle Group and Queues */}
                                                        {/* {(tab.resources.length > 0 && !tab.resources?.includes("type")) && (
                                                            <Flex marginTop="size-100" marginStart="size-200" direction="row" alignItems="center">
                                                                <View marginEnd="size-300" paddingBottom="size-100">
                                                                    <Text>Selected Resources</Text>
                                                                </View>
                                                                {tab.resources.includes("groups") && (
                                                                    <Switch
                                                                        isSelected={isGroupSwitchEnabled(tab.tab)}
                                                                        onChange={() => toggleGroupSwitch(tab.tab)}
                                                                    >
                                                                        Group
                                                                    </Switch>
                                                                )}
                                                                {tab.resources.includes("queues") && (
                                                                    <Switch
                                                                        isSelected={isQueueSwitchEnabled(tab.tab)}
                                                                        onChange={() => toggleQueuesSwitch(tab.tab)}
                                                                    >
                                                                        Queues
                                                                    </Switch>
                                                                )}
                                                            </Flex>
                                                        )} */}
                                    

                                                        <View paddingX="size-300">
                                                            <Flex direction="column" gap="size-300">

                                                                {tab.resources.map((resourceType) => {   
                                                                      const groupsResource = ["USERS", "SUPERVISORS", "GROUP CONFIG"];
                                                                      const queuesResource = ["QUEUE MONITORING"];
                                                                      const buResource = ["OTHERS MONITORING"];                                                       
                                                                    
                                                                      const resourceName = groupsResource?.includes(tab.tab) ? 'Group Permissions' 
                                                                      : queuesResource?.includes(tab.tab) ? 'Queue Permissions' 
                                                                      : buResource?.includes(tab.tab) ? 'BU Permissions' 
                                                                      : 'All Permissions';
                                                                     
                                                                    const isSwitchEnabled = true;
                                                                    return (isSwitchEnabled) && (
                                                                        <View key={resourceType}>
                                                                            <Flex justifyContent={"space-between"}>
                                                                                <Text
                                                                                    UNSAFE_style={{
                                                                                        fontWeight: "800",
                                                                                        color: "black",
                                                                                    }}
                                                                                >
                                                                                    <h4>{resourceName}</h4>
                                                                                </Text>

                                                                               
                                                                                <Flex justifyContent={"end"}>
                                                                                    <View >
                                                                                        {(!isEdit && anyPermissionsPresent(tab.tab)) && (<Button onPress={() => handleRemoveAllGroups(tab.tab)} variant="primary" style="outline" marginEnd={"10px"}>
                                                                                             <Close UNSAFE_style={{color:"red"}} />
                                                                                        </Button>)}
                                                                                    </View>
                                                                                    <View>
                                                                                        {!isEdit && (<Button
                                                                                            variant="primary"
                                                                                            style="outline"
                                                                                            // UNSAFE_style={{ backgroundColor: "#b2b2b2" }}
                                                                                            onPress={() => permissionDialogue(tab)}
                                                                                        >
                                                                                            {/* Edit permission */}
                                                                                            <Draw />
                                                                                        </Button>)}
                                                                                    </View>
                                                                                </Flex>
                                                                            </Flex>
                                                                            <Flex direction="column" UNSAFE_style={{ paddingLeft: "35px", paddingBottom: "10px" }}>
                                                                                {Object.keys(policy?.permissions).map((perm) =>
                                                                                //perm  == add, view, edit, delete, enforceUpdate, clone  || tab= {tab:"tab name", resources:["groups" or "queues" or "type"]}  resourceType=groups,queues,type
                                                                                        permissionContainsResource(perm, tab, resourceType) ? (
                                                                                        <Flex direction="column" key={perm}>
                                                                                            <Text
                                                                                                UNSAFE_style={{
                                                                                                    fontWeight: "600",
                                                                                                    color: "black",
                                                                                                    marginBottom: "-10px"
                                                                                                }}
                                                                                            >
                                                                                                <h6>{capitalizeFirstLetter(perm)}</h6>
                                                                                            </Text>
                                                                                            <TagGroup UNSAFE_className="taggroup-permissions" UNSAFE_style={{marginLeft:"10px"}}>
                                                                                                {getTabpermission(tab, perm)?.resources[resourceType]?.some(item => item === "*") ? (
                                                                                                    <Item
                                                                                                        key={`all-${resourceType}`}
                                                                                                        style={{ backgroundColor: "#b2b2b2", padding: '16px 8px' }}
                                                                                                    >
                                                                                                        <span style={{color:"blue",fontSize:"12px"}}>All</span>
                                                                                                    </Item>
                                                                                                ) : (
                                                                                                    getTabpermission(tab, perm)?.resources[resourceType]?.map((item, index) => (
                                                                                                        <Item
                                                                                                            key={index}
                                                                                                            style={{ backgroundColor: "#b2b2b2", padding: '16px 8px' }}
                                                                                                        >
                                                                                                            <span style={{color:"blue",fontSize:"12px"}}>{item}</span>
                                                                                                        </Item>
                                                                                                    ))
                                                                                                )}
                                                                                            </TagGroup>
                                                                                        </Flex>
                                                                                    ) : null
                                                                                )}
                                                                            </Flex>
                                                                        </View>
                                                                    );
                                                                })}

                                                            </Flex>
                                                        </View>
                                                    </View>
                                                )}
                                            </View>
                                        </div>
                                    </>
                                ))
                            }
                        </>
                    )}

                    <DialogTrigger
                        type="fullscreenTakeover"
                        isDismissable={true}
                        defaultOpen={false}
                        isOpen={isGroupPermissionDialogue}>
                        <ActionButton ishidden isQuiet></ActionButton>

                        {() => (
                            <Dialog aria-label="permission label" width="100%" height="100%">

                                <Heading level={6} >
                                    <Flex width={'100%'} marginTop={'size-200'} justifyContent={'space-between'} alignItems={'center'}>
                                        <div>
                                            Permissions
                                        </div>
                                        <View >
                                            <Button aria-label="close button" variant="primary" onPress={() => closePermissionsModal()} marginEnd={"5px"}>Cancel</Button>
                                            <Button aria-label="save button" variant="cta" onPress={() => saveGroups()}>Save</Button>
                                        </View>

                                    </Flex>
                                </Heading>
                                <Divider />
                                <Content>
                                    <GroupPermissions permissionList={getPermissionList()} selectedGroupsRef={selectedGroupsRef} tab={tab} service={selectedservice}/>

                                </Content>
                            </Dialog>
                        )}
                    </DialogTrigger>

                </View>
            </View>
        </>

    )
}