import { ALERT_SERVER_URL, AlertServiceToken } from "../../constants/constants"
import { getLocalStoreValue } from "./methods"

// Add new Alert
export function addNewAlert(data) {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/add`, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStoreValue(AlertServiceToken)}`,
            "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
        },
        body: JSON.stringify([data]),
    })
        .then((response) => response.json())
        .catch((err) => err)
}

export function updateAlert(data) {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/update`, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStoreValue(AlertServiceToken)}`,
            "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err)
}

export function getMonitoringQueuesNew() {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/getQueues`, {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStoreValue(AlertServiceToken)}`,
            "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err)
}

export function getAllMonitoredQueue() {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/get/all`, {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStoreValue(AlertServiceToken)}`,
            "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err)
}

// Delete
export async function deleteAlert(queueIds, username) {
    return fetch(
        `${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/delete?` +
            new URLSearchParams({
                ldap: username,
            }).toString(),
        {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getLocalStoreValue(
                    AlertServiceToken
                )}`,
                "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
            },
            body: queueIds,
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }
            return response.json() // Assuming the response is in JSON format
        })
        .catch((err) => err)
}

// GET Dual SYSTEM USERS
export function getAllDualSystemUsers() {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/dualSystem/get/all`, {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStoreValue(AlertServiceToken)}`,
            "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err)
}

// Add Dual System Users
export async function addNewDualSystemUser(data, username) {
    return fetch(
        `${ALERT_SERVER_URL.baseUrl}/dualSystem/add?` +
            new URLSearchParams({
                ldap: username,
            }).toString(),
        {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getLocalStoreValue(
                    AlertServiceToken
                )}`,
                "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
            },
            body: data,
        }
    )
        .then((response) => response)
        .catch((err) => err)
}

// Remove Dual System Users
export async function deleteDualSystemUser(data, username) {
    return fetch(
        `${ALERT_SERVER_URL.baseUrl}/dualSystem/delete?` +
            new URLSearchParams({
                ldap: username,
                usernames: JSON.stringify(data),
            }).toString(),
        {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getLocalStoreValue(
                    AlertServiceToken
                )}`,
                "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
            },
        }
    )
        .then((response) => response)
        .catch((err) => err)
}



/* BU unit alert */

export function addNewBUAlert(data) {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/bu/add`, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStoreValue(AlertServiceToken)}`,
            "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
        },
        body: JSON.stringify([data]),
    })
        .then((response) => response.json())
        .catch((err) => err)
}

export function updateBUAlert(data) {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/bu/update`, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStoreValue(AlertServiceToken)}`,
            "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err)
}

export function getMonitoringBUNew() {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/bu/getBUs`, {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStoreValue(AlertServiceToken)}`,
            "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err)
}

export function getAllMonitoredBU() {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/bu/get/all`, {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStoreValue(AlertServiceToken)}`,
            "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err)
}

export async function deleteBUAlert(buIds, username) {
    return fetch(
        `${ALERT_SERVER_URL.baseUrl}/alert/v1/monitoring/bu/delete?` +
            new URLSearchParams({
                ldap: username,
            }).toString(),
        {
            method: "POST",
            mode: "cors",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getLocalStoreValue(
                    AlertServiceToken
                )}`,
                "x-api-key": `${ALERT_SERVER_URL.xAPIKey}`,
            },
            body: buIds,
        }
    )
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
            }
            return response.json() // Assuming the response is in JSON format
        })
        .catch((err) => err)
}