import React, { useState, useEffect } from "react"
import {
    Provider,
    lightTheme,
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    Heading,
    Content,
    ButtonGroup,
    ComboBox,
    View,
    Flex,
    Item,
    NumberField,
} from "@adobe/react-spectrum"
import Add from "@spectrum-icons/workflow/Add"
import Delete from "@spectrum-icons/workflow/Delete"

const BulkEditQueue = (props) => {
    const [rows, setRows] = useState([
        { queue_name: "", id: "", channel: "", delay: "0", priority: "1" },
    ])

    const channels = [
        { id: "task", name: "task" },
        { id: "chat", name: "chat" },
        { id: "voice", name: "voice" },
    ]

    const addNewRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            { queue_name: "", id: "", channel: "", delay: "0", priority: "1" },
        ])
    }

    const deleteRow = (index) => {
        setRows((prevRows) => {
            const updatedRows = [...prevRows]
            if (updatedRows.length > 1) {
                updatedRows.splice(index, 1)
            }
            return updatedRows
        })
    }

    const handleUpdate = (type) => {
        const data = {
            users: props.selectedAgents,
            action: type === "update" ? "add_selective" : "remove_selective",
            configKey: "queues_assigned",
            configValues: rows,
        }
        // setRows([{ queue_name: "", id: "", channel: "", delay: "0", priority: "1" }]);
        props.handleCloseDialog(true, data)
    }

    const checkAllFieldsNotEmpty = (item) => {
        return Object.values(item).every((value) => value !== "")
    }

    const updateQueues = (value, index, name) => {
        // //console.log({value, index, name});
        const queueCopy = [...rows]
        if (name === "queue_name") {
            const temp = props?.queueList.find((ele) => ele?.name === value)?.id
            queueCopy[index]["id"] =  temp
            queueCopy[index]["queue_name"] = value
        } else {
            queueCopy[index][name] = value
        }
        setRows(queueCopy)
    }

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog width={"900px"} height={"500px"}>
                        <Heading>Bulk {props?.operation} Queue</Heading>
                        <Divider />
                        <Content>
                            <Flex
                                direction={"column"}
                                maxHeight={"60vh"}
                                height={"25vh"}
                                // UNSAFE_className="boxInside"
                            >
                                {rows?.map((row, index) => (
                                    <Flex
                                        key={index}
                                        direction={"column"}
                                        justifyContent={"center"}
                                        gap={"size-100"}
                                        marginTop={"size-100"}
                                    >
                                        <View UNSAFE_className="feild">
                                            <Flex>
                                                <View width={"90%"}>
                                                    <ComboBox
                                                        label={
                                                            index === 0 &&
                                                            "Queue Name"
                                                        }
                                                        marginX={"5px"}
                                                        defaultItems={
                                                            props?.queueList
                                                        }
                                                        selectedKey={
                                                            row?.queue_name
                                                        }
                                                        width={"300px"}
                                                        onSelectionChange={(
                                                            item
                                                        ) =>
                                                            updateQueues(
                                                                item,
                                                                index,
                                                                "queue_name"
                                                            )
                                                        }
                                                    >
                                                        {(item) => (
                                                            <Item key={item.name}>
                                                                {item.name}
                                                            </Item>
                                                        )}
                                                    </ComboBox>
                                                    <ComboBox
                                                        marginX={"5px"}
                                                        width={"125px"}
                                                        label={
                                                            index === 0 &&
                                                            "Channel"
                                                        }
                                                        defaultItems={channels}
                                                        selectedKey={
                                                            row?.channel
                                                        }
                                                        onSelectionChange={(
                                                            value
                                                        ) =>
                                                            updateQueues(
                                                                value,
                                                                index,
                                                                "channel"
                                                            )
                                                        }
                                                    >
                                                        {(item) => (
                                                            <Item>
                                                                {item.name}
                                                            </Item>
                                                        )}
                                                    </ComboBox>
                                                    <NumberField
                                                        marginX={"5px"}
                                                        label={
                                                            index === 0 &&
                                                            "Priority"
                                                        }
                                                        value={row?.priority}
                                                        minValue={1}
                                                        maxValue={99}
                                                        onChange={(value) =>
                                                            updateQueues(
                                                                value,
                                                                index,
                                                                "priority"
                                                            )
                                                        }
                                                    />
                                                    <NumberField
                                                        label={
                                                            index === 0 &&
                                                            "Delay"
                                                        }
                                                        marginX={"5px"}
                                                        value={row?.delay}
                                                        minValue={0}
                                                        maxValue={9999}
                                                        onChange={(value) =>
                                                            updateQueues(
                                                                value,
                                                                index,
                                                                "delay"
                                                            )
                                                        }
                                                    />
                                                   
                                                </View>
                                                <View
                                                        UNSAFE_style={{
                                                            marginTop:
                                                                index === 0
                                                                    ? "3.5%"
                                                                    : null,
                                                                    display:"flex",
                                                                    marginLeft:"-10px"
                                                        }}
                                                    >
                                                        <Button
                                                            marginX={"5px"}
                                                            aria-label="deleteValue"
                                                            variant="secondary"
                                                            staticColor="black"
                                                            style="fill"
                                                            onPress={() =>
                                                                deleteRow(index)
                                                            }
                                                        >
                                                            <Delete />
                                                        </Button>
                                                        {index ===
                                                        rows.length - 1 ? (
                                                            <Button
                                                                // marginTop={"5%"}
                                                                aria-label="AddObject"
                                                                variant="secondary"
                                                                staticColor="black"
                                                                style="fill"
                                                                onPress={() =>
                                                                    addNewRow()
                                                                }
                                                            >
                                                                <Add />
                                                            </Button>
                                                        ) : null}
                                                    </View>
                                            </Flex>
                                        </View>
                                    </Flex>
                                ))}
                            </Flex>
                        </Content>
                        <ButtonGroup>
                            <Button
                                variant="secondary"
                                onPress={props.handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onPress={() => handleUpdate("remove")}
                                isDisabled={!rows.every(checkAllFieldsNotEmpty)}
                            >
                                Remove
                            </Button>
                            <Button
                                variant="primary"
                                onPress={() => handleUpdate("update")}
                                isDisabled={!rows.every(checkAllFieldsNotEmpty)}
                            >
                                Add / Update
                            </Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default BulkEditQueue
