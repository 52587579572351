import React, { useState, useEffect, useMemo } from "react"
import {
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    Heading,
    Content,
    ButtonGroup,
    Form,
    TextField,
    Checkbox,
    ComboBox,
    Item,
    Flex,
    View,
    NumberField,
    Text,
    ProgressCircle,
    TabList,
    Tabs,
    TabPanels,
    Picker
} from "@adobe/react-spectrum"
import Delete from "@spectrum-icons/workflow/Delete"
import Copy from "@spectrum-icons/workflow/Copy"
import {
    getAgentGroupProficiency,
    getQueuesDataApi,
    getQueueList,
    getProficiencyList,
} from "../../../utilities/api/phoenix-api"
import { notify } from "../../../constants/constants"
import "./AgentGroupStyle.css"

const AddAgentGroup = (props) => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [agentLdap, setagentLdap] = useState("")
    const [groupName, setgroupName] = useState("")
    const [isActive, setIsActive] = useState(false)
    const [isManager, setIsManager] = useState(false)
    const [doesExists, setDoesExists] = useState(false)
    const [objectSelected, setObjectSelected] = useState(new Set([]))
    const [showProficiency, setShowProficiency] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [formStage, setFormStage] = useState("stage1")
    const [isDisabledAgentLdap, setIsDisabledAgentLdap] = useState(false)
    const [prevQueues, setPrevQueues] = useState([])
    const [prevProf, setPrevProf] = useState([])
    const [agentQueues, setAgentQueues] = useState([{ name: "", id: "" }])
    const [nonGrpQueues, setNonGrpQueues] = useState([{ name: "", id: "" }])
    const [proficiencyList, setProficiencyList] = useState({})
    const [nonGroupProficiencyList, setNonGroupProficiencyList] = useState({})
    const singleQueue = {
        queueId: "",
        queueName: "",
        channel: "",
        delay: 0,
        priority: 1,
    }
    const [queues, setQueues] = useState([])
    const [nonGroupQueues, setNonGroupQueues] = useState([])
    const [valueFromProps, setValueFromProps] = useState({
        userinfo: props?.editData,
    })
    const [instance, setInstance] = useState()
    const [selectedProfile, setSelectedProfile] = useState("supervisor")

    const channels = [
        { id: "TASK", name: "TASK" },
        { id: "CHAT", name: "CHAT" },
        { id: "VOICE", name: "VOICE" },
    ]
    // UserProfiency
    const singleProfiency = {
        attributeName: "",
        attributeValue: "",
        level: 1,
    }

    const [userProficencies, setUserProfiencies] = useState([])
    const [nonGroupProficencies, setNonGroupProfiencies] = useState([])

    const [defaultOutboundQueue, setDefaultOutboundQueue] = useState([
        { name: "", id: "" },
    ]);

    const [dualAccess, setDualAccess] = useState(false);

    const convertArrayToInput = (values = []) => {
        return values?.length
            ? values?.map((value, index) => ({ id: index, name: value }))
            : []
    }

    useEffect(() => {
        if (agentLdap && groupName) {
            getQueueList(props?.editData?.agentGroupName)
                .then((res) => {
                    setAgentQueues(res?.groupQueues)
                    setNonGrpQueues(res?.nonGroupQueues)
                })
                .catch((err) => {
                    //console.log("error", err)
                })

            getQueuesDataApi(agentLdap, groupName)
                .then((res) => {
                    if (
                        typeof valueFromProps === "object" &&
                        valueFromProps !== null
                    ) {
                        const updatedValue = { ...valueFromProps, queues: res }
                        setValueFromProps(updatedValue)
                    }
                    setQueues(res?.groupQueues)
                    setPrevQueues(res?.groupQueues)
                    setDefaultOutboundQueue([res?.defaultOutboundQueue])
                    setNonGroupQueues(res?.nonGroupQueues)
                })
                .catch((err) => console.log("error", err))

            getProficiencyList(props.editData.agentGroupName)
                .then((resp) => {
                    setProficiencyList(resp?.groupProficiencies)
                    setNonGroupProficiencyList(resp?.nonGroupProficiencies)

                    // const mappedProficiencyList = resp
                    //     .map((ele) => ({
                    //         [ele.attributeName]: resp
                    //             .filter(
                    //                 (content) =>
                    //                     content.attributeName ===
                    //                     ele.attributeName
                    //             )
                    //             .map((content) => content.attributeValue),
                    //     }))
                    //     .reduce((a, v) => ({ ...a, ...v }), {})
                })
                .catch((err) => console.log("error", err))
        }
    }, [props.editData && agentLdap])

    useEffect(() => {
        if (props.editData) {
            setFirstName(props?.editData?.firstName)
            setLastName(props?.editData?.lastName)
            setagentLdap(props?.editData?.agentLdap)
            setgroupName(props?.editData?.agentGroupName)
            setDualAccess(props?.editData?.dualAccess)
            setInstance(props?.editData?.instance)
            setIsLoading(true)
            getAgentGroupProficiency({
                agentLdap: props.editData.agentLdap,
                group: props.editData.agentGroupName,
            })
                .then((resp) => {
                    setShowProficiency(true)
                    setIsLoading(false)
                    setUserProfiencies(resp.groupProficiencies)
                    setNonGroupProfiencies(resp.nonGroupProficiencies)
                    // const mapped = resp.agentProficiency.map((proficiency) => ({
                    //     ...proficiency,
                    // }))
                    // const updatedValues = {
                    //     ...valueFromProps,
                    //     proficiencies: mapped,
                    // }
                    // setValueFromProps(updatedValues)
                    // setUserProfiencies(resp.groupProficiencies)
                    // setNonGroupProfiencies(resp.nonGroupProficiencies)
                    // setPrevProf(mapped)
                })
                .catch((err) => {})
                .finally(() => {})
        }
        if (props.cloneInfo) {
            setFirstName(props.cloneInfo.firstName)
            setLastName(props.cloneInfo.lastName)
            setagentLdap(props.cloneInfo.agentLdap)
            setDualAccess(props?.editData?.dualAccess)
            setIsDisabledAgentLdap(true)
        }
    }, [])

    useEffect(() => {
        const [agentCheck] = props.allAgentsList.filter(
            (item) => item.agentLdap == props.cloneInfo?.agentLdap
        )
        if (agentCheck && !props.editData) {
            const { agentGroupList } = agentCheck
            const [groupNameCheck] = agentGroupList.filter(
                (item) => item.groupName == groupName
            )
            getAgentGroupProficiency({ agentLdap: agentCheck?.agentLdap })
                .then((resp) => {
                    setShowProficiency(true)
                    const mapped = resp.agentProficiency.map((proficiency) => ({
                        ...proficiency,
                    }))
                    setUserProfiencies(mapped)
                    setNonGroupProfiencies(mapped)
                })
                .catch((err) => {})
                .finally(() => {})
            if (groupNameCheck) {
                setDoesExists(true)
            } else {
                setDoesExists(false)
            }
            setIsActive(false)
        } else {
            if (!props.editData) setIsActive(true)
            setDoesExists(false)
        }
    }, [agentLdap, groupName])

    const isLdapAlreadyExist = () => {
        const res = props.allAgentsList.some(
            (item) => item.agentLdap.toLowerCase() === agentLdap.toLowerCase()
        )
        if (res) {
            notify("Agent ldap already exist", "warn")
        }
        return res
    }

    // const isAdminCheck = () => {
    //     if (props.editData) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    const resetState = () => {
        setFirstName("")
        setLastName("")
        setagentLdap("")
        setgroupName("")
        setIsActive(false)
        setIsManager(false)
        setUserProfiencies([singleProfiency])
        setQueues([singleQueue])
        setNonGroupQueues([singleQueue])
        setNonGroupProfiencies([singleProfiency])
        setDualAccess(false)
    }

    const onCloseHandler = () => {
        resetState()
        props.handleCloseDialog(false)
    }
    const onSaveHandler = () => {
        const submitData = {}
        Object.assign(submitData, {
            userInfo: {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                agentLdap: agentLdap
                    .toLowerCase()
                    .trim()
                    .split("@adobe.com")
                    .join(""),
                agentGroupName: groupName.trim(),
                dualAccess: dualAccess,
                isAgentProfile: true,
                isGroupSupervisor: false,
                profile: selectedProfile,
            },
        })
        props.handleCloseDialog(true, submitData.userInfo);
        resetState()
    }

    const updateProficiency = (index, name, value = "", type) => {
        if (type === "proficiency") {
            let proficiencyClone = JSON.parse(JSON.stringify(userProficencies))
            proficiencyClone[index][name] = value
            setUserProfiencies(proficiencyClone)
        } else if (type === "nonGroupProficiency") {
            let proficiencyClone = JSON.parse(
                JSON.stringify(nonGroupProficencies)
            )
            proficiencyClone[index][name] = value
            setNonGroupProfiencies(proficiencyClone)
        }
    }

    const deleteProficiency = (index, name) => {
        if (name === "proficiency") {
            let proficiencyClone = JSON.parse(JSON.stringify(userProficencies))
            proficiencyClone.splice(index, 1)
            setUserProfiencies(proficiencyClone)
        } else if (name === "nonGroupProficiency") {
            let proficiencyClone = JSON.parse(
                JSON.stringify(nonGroupProficencies)
            )
            proficiencyClone.splice(index, 1)
            setNonGroupProfiencies(proficiencyClone)
        }
    }

    const deleteQueue = (index, name) => {
        if (name === "queues") {
            const updatedQueues = [...queues]
            updatedQueues.splice(index, 1)
            setQueues(updatedQueues)
        } else if (name === "nonGroupQueue") {
            const updatedQueues = [...nonGroupQueues]
            updatedQueues.splice(index, 1)
            setNonGroupQueues(updatedQueues)
        }
    }

    const updateQueues = (value, index, type, name) => {
        if (type === "queues") {
            const queueCopy = [...queues]
            if (name === "queueName") {
                const temp = props.queueList.allQueues.find(
                    (ele) => ele.name === value
                )?.id
                queueCopy[index]["queueId"] = temp
                queueCopy[index]["queueName"] = value
            } else {
                queueCopy[index][name] = value
            }
            setQueues(queueCopy)
        } else if (type === "nonGroupQueue") {
            const queueCopy = [...nonGroupQueues]
            if (name === "queueName") {
                const temp = props.queueList.allQueues.find(
                    (ele) => ele.name === value
                )?.id
                queueCopy[index]["queueId"] = temp
                queueCopy[index]["queueName"] = value
            } else {
                queueCopy[index][name] = value
            }
            setNonGroupQueues(queueCopy)
        }
    }

    // const getAllAttributeValues = (value, index) => {
    //     updateProficiency(index, "update", "attributeName", value)
    // }

    const handleCloneProficiency = (index) => {
        const prof = { ...userProficencies[index] }
        const clonedProficiency = [
            ...userProficencies.slice(0, index + 1),
            prof,
            ...userProficencies.slice(index + 1),
        ]
        setUserProfiencies(clonedProficiency)
    }

    const handleCloneQueue = (index, name) => {
        if (name === "queues") {
            const que = { ...queues[index] }
            const clonedQueue = [
                ...queues.slice(0, index + 1),
                que,
                ...queues.slice(index + 1),
            ]
            setQueues(clonedQueue)
        } else if (name === "nonGroupQueue") {
            const que = { ...nonGroupQueues[index] }
            const clonedQueue = [
                ...nonGroupQueues.slice(0, index + 1),
                que,
                ...nonGroupQueues.slice(index + 1),
            ]
            setNonGroupQueues(clonedQueue)
        }
    }

    const hasEmptyValues = (obj) => {
        return Object.values(obj).every((value) => value !== "")
    }

    const checkIsEmpty = (name) => {
        if (name === "proficiency") {
            return userProficencies.every((ele) => hasEmptyValues(ele))
        } else if (name === "queues") {
            return queues.every((ele) => hasEmptyValues(ele))
        }
    }

    const getRowProficiency = (obj, index, type) => {
        const hasEditUserProficienciesPermission =
            props?.editData?.agentGroupName === groupName &&
            props?.userPermissions?.some(
                (ele) =>
                    ele?.group === props?.editData?.agentGroupName &&
                    ele?.permissions?.includes("edit_user_proficiencies")
            )

        // const hasViewAllUsersPermission = props?.commonPermissions[0]?.permissions?.includes('view_all_users');

        const hasEditUserAdditionalProficienciesPermission =
            props?.editData?.agentGroupName === groupName &&
            props?.userPermissions?.some(
                (ele) =>
                    ele?.group === props?.editData?.agentGroupName &&
                    ele?.permissions?.includes(
                        "edit_user_additional_proficiencies"
                    )
            )

        // const isDisabled =
        //     type === "proficiency"
        //         ? !hasEditUserProficienciesPermission
        //         : !hasEditUserAdditionalProficienciesPermission

        return (
            <View key={index} UNSAFE_className="feild">
                <Flex justifyContent={"start"} alignItems={"center"}>
                    <ComboBox
                        label={index === 0 && "Attribute Name"}
                        defaultItems={convertArrayToInput(
                            Object.keys(
                                type === "proficiency"
                                    ? proficiencyList
                                    : nonGroupProficiencyList
                            )
                        )}
                        selectedKey={obj.attributeName}
                        onSelectionChange={(value) =>
                            updateProficiency(
                                index,
                                "attributeName",
                                value,
                                type
                            )
                        }
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                        marginEnd={"30px"}
                        width={"70%"}
                    >
                        {(item) => <Item key={item.name}>{item.name}</Item>}
                    </ComboBox>
                    <ComboBox
                        label={index === 0 && "Attribute Value"}
                        defaultItems={
                            type === "proficiency"
                                ? convertArrayToInput(
                                      proficiencyList[
                                          userProficencies[index]?.attributeName
                                      ]
                                  )
                                : convertArrayToInput(
                                      nonGroupProficiencyList[
                                          nonGroupProficencies[index]
                                              ?.attributeName
                                      ]
                                  )
                        }
                        selectedKey={obj.attributeValue}
                        onSelectionChange={(value) =>
                            updateProficiency(
                                index,
                                "attributeValue",
                                value,
                                type
                            )
                        }
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                        marginX={"30px"}
                        width={"70%"}
                    >
                        {(item) => <Item key={item.name}>{item.name}</Item>}
                    </ComboBox>
                    <NumberField
                        label={index === 0 && "Level"}
                        value={obj.level}
                        minValue={1}
                        maxValue={5}
                        onChange={(value) =>
                            updateProficiency(index, "level", value, type)
                        }
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                        marginX={"20px"}
                        width={"10%"}
                    />
                    <Button
                        marginTop={index === 0 ? "3%" : "0"}
                        marginX={"10px"}
                        aria-label="cloneValue"
                        variant="secondary"
                        staticColor="black"
                        style="fill"
                        onPress={() => handleCloneProficiency(index, type)}
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                    >
                        <Copy />
                    </Button>

                    <Button
                        marginTop={index === 0 ? "3%" : "0"}
                        aria-label="deleteValue"
                        variant="secondary"
                        staticColor="black"
                        style="fill"
                        onPress={() => deleteProficiency(index, type)}
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                    >
                        <Delete />
                    </Button>
                </Flex>
            </View>
        )
    }

    const getQueueCard = (queue, index, type) => {
        const hasEditUserQueuesPermission =
            props?.editData?.agentGroupName === groupName &&
            props?.userPermissions?.some(
                (ele) =>
                    ele?.group === props?.editData?.agentGroupName &&
                    ele?.permissions?.includes("edit_user_queues")
            )

        // const hasViewAllUsersPermission = props?.commonPermissions[0]?.permissions?.includes('view_all_users');

        const hasEditUserAdditionalQueuesPermission =
            props?.editData?.agentGroupName === groupName &&
            props?.userPermissions?.some(
                (ele) =>
                    ele?.group === props?.editData?.agentGroupName &&
                    ele?.permissions?.includes("edit_user_additional_queues")
            )

        // const isDisabled =
        //     type === "queues"
        //         ? !hasEditUserQueuesPermission
        //         : !hasEditUserAdditionalQueuesPermission

        return (
            <View key={`${index}-${queue?.queueName}`}>
                <Flex>
                    <ComboBox
                        label={index === 0 && "Queue Name"}
                        defaultItems={
                            type === "queues" ? agentQueues : nonGrpQueues
                        }
                        selectedKey={queue?.queueName}
                        onSelectionChange={(item) =>
                            updateQueues(item, index, type, "queueName")
                        }
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                        marginEnd={"30px"}
                        width={"40%"}
                    >
                        {(item) => <Item key={item.name}>{item.name}</Item>}
                    </ComboBox>
                    <ComboBox
                        label={index === 0 && "Channel"}
                        defaultItems={channels}
                        selectedKey={queue.channel}
                        onSelectionChange={(value) =>
                            updateQueues(value, index, type, "channel")
                        }
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                        width={"15%"}
                        marginEnd={"30px"}
                    >
                        {(item) => <Item>{item?.name}</Item>}
                    </ComboBox>
                    <NumberField
                        label={index === 0 && "Delay"}
                        value={queue?.delay}
                        minValue={0}
                        maxValue={9999}
                        onChange={(value) =>
                            updateQueues(value, index, type, "delay")
                        }
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                        marginX={"30px"}
                        width={"10%"}
                    />
                    <NumberField
                        label={index === 0 && "Priority"}
                        value={queue?.priority}
                        minValue={1}
                        maxValue={99}
                        onChange={(value) =>
                            updateQueues(value, index, type, "priority")
                        }
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                        marginX={"30px"}
                        width={"10%"}
                    />
                    <Button
                        marginTop={index === 0 ? "3%" : "0"}
                        marginX={"10px"}
                        aria-label="cloneValue"
                        variant="secondary"
                        staticColor="black"
                        style="fill"
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                        onPress={() => handleCloneQueue(index, type)}
                    >
                        <Copy />
                    </Button>
                    <Button
                        marginTop={index === 0 ? "3%" : "0"}
                        aria-label="deleteValue"
                        variant="secondary"
                        staticColor="black"
                        style="fill"
                        // isDisabled={!props.isAdminInEdit && isDisabled}
                        onPress={() => deleteQueue(index, type)}
                    >
                        <Delete />
                    </Button>
                </Flex>
            </View>
        )
    }

    // const getCard = (proficiency, index, name) => {
    //     return getRowProficiency(proficiency, index, name)
    // }

    const addNewRow = (name) => {
        name === "proficiency"
            ? setUserProfiencies((previous) => [singleProfiency, ...previous])
            : name === "nonGroupProficiency"
            ? setNonGroupProfiencies((previous) => [
                  singleProfiency,
                  ...previous,
              ])
            : name === "queues"
            ? setQueues((prev) => [
                  {
                      queueName: "",
                      channel: "",
                      delay: 0,
                      priority: 1,
                  },
                  ...prev,
              ])
            : name === "nonGroupQueue"
            ? setNonGroupQueues((prev) => [
                  {
                      queueName: "",
                      channel: "",
                      delay: 0,
                      priority: 1,
                  },
                  ...prev,
              ])
            : null
    }

    const handleDefaultOutboundQueue = (val) => {
        const temp = agentQueues.find((ele) => ele.name === val)
        setDefaultOutboundQueue([temp])
    }

    const fetchGroups = () => {
        const { operation, groups, groupsHavingAddPermissions, groupsHavingEditPermissions } = props;
        const permissions = operation === "Add" ? groupsHavingAddPermissions : groupsHavingEditPermissions;
    
        return permissions?.length && permissions[0] !== "*"
            ? permissions.map((ele) => ({ name: ele }))
            : groups;
    };

    return (
        <DialogTrigger
            type="fullscreen"
            UNSAFE_className="fullscreen"
            mobileType="fullscreen"
            isOpen={props?.isDialogOpened}
            defaultOpen={false}
        >
            <ActionButton isHidden isQuiet></ActionButton>

            <>
                <View>
                    <View UNSAFE_className="color-header" width={'100%'}>
                        {props.editData && (
                            <>
                                <Flex width={'100%'} justifyContent={'center'}>
                                    <Text UNSAFE_style={{maxWidth:'60%', width:'fit-content', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', fontWeight: "600"}}>
                                        {`${props?.editData.firstName} ${props?.editData.lastName}`}
                                    </Text>
                                    <Text  UNSAFE_style={{ maxWidth:'30%', width:'fit-content', whiteSpace:'nowrap', fontWeight: "600"}}>
                                        ( {agentLdap} ) {":"} {props?.editData.agentGroupName}
                                    </Text>
                                </Flex>
                            </>
                        )}
                    </View>
                </View>
                <Tabs aria-label="agent group" orientation="vertical">
                    <View
                        UNSAFE_style={{
                            height: "83vh",
                            borderRight: "1px solid grey",
                            maxHeight: "83vh",
                            minHeight: "83vh",
                            minWidth: "16%",
                            paddingRight: "0px",
                        }}
                    >
                        <TabList
                            UNSAFE_style={{
                                // position: "absolute",
                                // height: "95.5%",
                                // width: "20%",
                                minWidth: "100%",
                                paddingRight: "0px",
                                // overflowY:"auto"
                                // marginTop: "5%",
                            }}
                        >
                            <Item key="userInfo">Supervisor Info</Item>
                        </TabList>
                    </View>
                    <TabPanels
                        UNSAFE_style={{
                            // position: "absolute",
                            // width: "70%",
                            // marginLeft: "25%",
                            padding: "0% 3% 0% 3%",
                        }}
                    >
                        <Item key="userInfo">
                            <View
                                marginY={"20px"}
                                UNSAFE_style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                }}
                            >
                                <Text>
                                    <strong>Supervisor Information</strong>
                                </Text>
                            </View>
                            <Flex
                                direction={"row"}
                                gap="size-150"
                                marginY={"10px"}
                            >
                                <TextField
                                    width={"48%"}
                                    label="First Name"
                                    autoFocus
                                    value={firstName}
                                    onChange={setFirstName}
                                    maxLength={100}
                                    isDisabled={
                                        props?.editData &&
                                        props?.canViewInformation
                                    }
                                    // isDisabled={
                                    //     props.editData &&
                                    //     !props.isAdminInEdit &&
                                    //     !props?.userPermissions
                                    //         ?.find(
                                    //             (ele) =>
                                    //                 ele?.group ===
                                    //                 props?.editData
                                    //                     ?.agentGroupName
                                    //         )
                                    //         ?.permissions?.includes(
                                    //             "edit_userinfo"
                                    //         )
                                    // }
                                />
                                <TextField
                                    width={"48%"}
                                    label="Last Name"
                                    value={lastName}
                                    onChange={setLastName}
                                    maxLength={100}
                                    isDisabled={
                                        props?.editData &&
                                        props?.canViewInformation
                                    }
                                    // isDisabled={
                                    //     props.editData &&
                                    //     !props.isAdminInEdit &&
                                    //     !props?.userPermissions
                                    //         ?.find(
                                    //             (ele) =>
                                    //                 ele?.group ===
                                    //                 props?.editData
                                    //                     ?.agentGroupName
                                    //         )
                                    //         ?.permissions?.includes(
                                    //             "edit_userinfo"
                                    //         )
                                    // }
                                />
                            </Flex>
                            <Flex
                                direction={"row"}
                                gap="size-150"
                                // marginY={"20px"}
                            >
                                <TextField
                                    label="User Ldap"
                                    value={agentLdap}
                                    width={"48%"}
                                    onChange={setagentLdap}
                                    isDisabled={
                                        props?.editData 
                                    }
                                  
                                />
                                <ComboBox
                                    label="Group Name"
                                    width={"48%"}
                                    // defaultItems={
                                    //     // props?.groups
                                    //     props.isAdminInEdit
                                    //         ? props.groups
                                    //         : !props.isAdminInEdit &&
                                    //           props.filteredGroups.length > 0
                                    //         ? props?.filteredGroups
                                    //         : []
                                    // }
                                    defaultItems={fetchGroups() || []}
                                    selectedKey={groupName}
                                    inputValue={groupName}
                                    onSelectionChange={setgroupName}
                                    onInputChange={setgroupName}
                                    marginBottom={"size-100"}
                                    isDisabled={
                                        props.editData
                                    }
                                >
                                    {(item) => (
                                        <Item key={item.name}>{item.name}</Item>
                                    )}
                                </ComboBox>
                            </Flex>
                            {props.operation === "Edit" && (
                                    <Flex
                                        direction={"row"}
                                        gap="size-150"
                                        marginBottom={"20px"}
                                    >
                                        <Picker
                                            label="Switch Profile"
                                            placeholder="Selected Role"
                                            width={"48%"}
                                            items={[
                                               
                                                {
                                                    id: "supervisor",
                                                    name: "Supervisor",
                                                },
                                                { id: "user", name: "User" },
                                            ]}
                                            selectedKey={selectedProfile}
                                            defaultSelectedKey={selectedProfile}
                                            onSelectionChange={setSelectedProfile}
                                            marginBottom={"size-100"}
                                            isDisabled={
                                                props?.editData &&
                                                props?.canViewInformation
                                            }
                                            // isDisabled={
                                            //     props.editData &&
                                            //     !props.isAdminInEdit &&
                                            //     !props?.userPermissions
                                            //         ?.find(
                                            //             (ele) =>
                                            //                 ele?.group ===
                                            //                 props?.editData
                                            //                     ?.agentGroupName
                                            //         )
                                            //         ?.permissions?.includes(
                                            //             "edit_userinfo"
                                            //         )
                                            // }
                                        >
                                            {(item) => <Item>{item.name}</Item>}
                                        </Picker>
                                    </Flex>
                                )}
                            {/* {!props.editData && !filteredGroups.length && (
                                <Text UNSAFE_style={{ color: "red" }}>
                                    No don't have permission to add new user
                                </Text>
                            )} */}

                            <Divider size="S" orientation="horizontal" />
                            <Flex
                                direction={"row"}
                                justifyContent={"space-between"}
                            >
                                {/* <Checkbox
                                    isSelected={isActive}
                                    onChange={(value) => setIsActive(value)}
                                >
                                    <strong>isActive</strong>
                                </Checkbox> */}
                                {/* <Checkbox
                                    isSelected={isManager}
                                    onChange={(value) => setIsManager(value)}
                                    isDisabled={
                                        props.editData &&
                                        !props.isAdminInEdit &&
                                        !props?.userPermissions
                                            ?.find(
                                                (ele) =>
                                                    ele?.group ===
                                                    props?.editData
                                                        ?.agentGroupName
                                            )
                                            ?.permissions?.includes(
                                                "edit_userinfo"
                                            )
                                    }
                                >
                                    <strong>Group Supervisor</strong>
                                </Checkbox> */}
                            </Flex>
                            {/* <View UNSAFE_style={{height:"10%",backgroundColor:'green'}}>
                                asndfn
                        </View> */}
                            <ButtonGroup
                                UNSAFE_style={{
                                    display: "block",
                                    // float: "inline-end",
                                    textAlign: "end",
                                    marginTop:'20px'
                                }}
                            >
                                <Button
                                    variant="secondary"
                                    onPress={onCloseHandler}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    isDisabled={
                                        !firstName?.trim() ||
                                        !lastName?.trim() ||
                                        !agentLdap?.trim() ||
                                        !groupName?.trim() 
                                        ||
                                           ( props?.editData &&
                                            props?.canViewInformation)
                                        
                                    }
                                    variant="primary"
                                    onPress={onSaveHandler}
                                >
                                    Submit
                                </Button>
                            </ButtonGroup>
                        </Item>
                    </TabPanels>
                </Tabs>
            </>
        </DialogTrigger>
    )
}

export default AddAgentGroup
