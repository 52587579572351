import React, { useState, useEffect } from "react"
import {
    Provider,
    lightTheme,
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    CheckboxGroup,
    Checkbox,
    Heading,
    Content,
    ButtonGroup,
    Form,
    TextField,
    ListView,
    Item,
    Flex,
    Text,
} from "@adobe/react-spectrum"
import { getOrgsAndDivisions } from "../utilities/api/admin-api"

const AddRoleDialog = (props) => {
    const [name, setName] = useState("")
    const [permissions, setPermissions] = useState([])
    const [orgList, setOrgList] = useState(new Set())
    const [divisionList, setDivisionList] = useState(new Set())
    const [orgIds, setOrgIds] = useState(new Set())
    const [divisionIds, setDivisionIds] = useState(new Set())
    const [role, setRole] = useState(new Set())
    const [desc, setDesc] = useState("")

    const getAllOrgsAndDivisions = (props) => {
        getOrgsAndDivisions()
            .then((res) => {
                if (res.length > 0) {
                    var orgs = []
                    var divList = []
                    res.forEach((org) => {
                        orgs.push({
                            id: org.id,
                            name: org.name,
                            divisions: org.divisions,
                        })
                    })
                    orgs.forEach((org) => {
                        setOrgList((prev) => new Set(prev.add(org)))
                    })

                    if (props.operation === "Edit Role") {
                        const selectedOrgs = props.editData.orgs
                        selectedOrgs.forEach((selectedOrg) => {
                            const divs = orgs
                                .filter((org) => org.id == selectedOrg.id)
                                .map((org) => org.divisions)
                            divList = divList.concat(...divs)
                        })

                        divList.forEach((division) => {
                            setDivisionList(
                                (prev) => new Set(prev.add(division))
                            )
                        })
                    }
                }
            })
            .catch((error) => {
                console.log("No roles found " + error)
            })
    }
    useEffect(() => {
        if (props.editData) {
            setPermissions(props.editData.permissions)

            props.editData.orgs.forEach((org) => {
                setOrgIds((prev) => new Set(prev.add(org.id)))
            })
            props.editData.divisions.forEach((division) => {
                setDivisionIds((prev) => new Set(prev.add(division.id)))
            })

            setName(props.editData.name)
            setRole(props.editData)
            setDesc(props.editData.description)
        }

        getAllOrgsAndDivisions(props)
    }, [])

    const onCloseHandler = () => {
        props.handleCloseDialog(false, null, false)
    }

    const onSaveHandler = () => {
        if (
            props.operation == "Add Role" &&
            props.allRoles.filter(
                (r) => r.name.toLowerCase().trim() == name.toLowerCase().trim()
            ).length > 0
        ) {
            props.handleCloseDialog(false, null, true)
        } else {
            var orgIdArr = []
            var divArr = []

            orgIds.forEach((orgId) => orgIdArr.push(orgId))
            divisionIds.forEach((divId) => divArr.push(divId))

            var urlencoded = new URLSearchParams()
            urlencoded.append("id", role.id)
            urlencoded.append("name", name)
            urlencoded.append("description", desc)
            urlencoded.append("permissions", permissions)
            urlencoded.append("updatedOrgIds", orgIdArr)
            urlencoded.append("updatedDivisionIds", divArr)

            props.handleCloseDialog(true, urlencoded, false)
        }
    }

    const onOrgSelection = (selectedOrgIds) => {
        const orgArr = []
        let divList = []
        const divIdsToShow = []
        setDivisionList(new Set())
        setOrgIds(selectedOrgIds)
        orgList.forEach((org) => orgArr.push(org))

        selectedOrgIds.forEach((orgId) => {
            setOrgIds((prev) => new Set(prev.add(orgId)))
            const divs = orgArr
                .filter((org) => org.id == orgId)
                .map((org) => org.divisions)
            divList = divList.concat(...divs)
        })
        const divisionsToShow = new Set()
        divList.forEach((division) => {
            divisionsToShow.add(division)
            divIdsToShow.push(division.id)
        })
        setDivisionList(divisionsToShow)
        setDivisionIds(
            new Set([...divisionIds].filter((id) => divIdsToShow.includes(id)))
        )
    }

    const getOrgForDivision = (divisionId) => {
        let orgName = ""
        orgList.forEach((org) => {
            org.divisions.forEach((division) => {
                if (division.id == divisionId) {
                    orgName = org.name
                }
            })
        })
        return orgName
    }

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="fullscreen"
                    mobileType="fullscreen"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="L">
                        <Heading>{props.operation}</Heading>
                        <Divider />
                        <Content>
                            <Form>
                                <Flex gap="size-150" wrap>
                                    <Flex wrap direction="column">
                                        <TextField
                                            autoFocus
                                            placeholder="Name"
                                            value={name}
                                            onChange={setName}
                                            isQuiet
                                        />
                                    </Flex>

                                    <Flex
                                        wrap
                                        direction="column"
                                        width="size-4500"
                                    >
                                        <TextField
                                            placeholder="Description"
                                            width="200%"
                                            value={desc}
                                            onChange={setDesc}
                                            isQuiet
                                        />
                                    </Flex>
                                </Flex>
                                <div style={{ marginTop: "30px" }}></div>

                                <Heading level={3}>Permissions</Heading>
                                <Heading
                                    level={5}
                                    UNSAFE_style={{
                                        textDecoration: "underline",
                                    }}
                                >
                                    Config
                                </Heading>

                                <Flex gap="size-200" wrap>
                                    <CheckboxGroup
                                        label="Amazon Connect"
                                        isEmphasized
                                        value={permissions}
                                        onChange={setPermissions}
                                    >
                                        <Checkbox value="Agent Group Mapping">
                                            Agent Group Mapping
                                        </Checkbox>
                                        <Checkbox value="Agent Group Mapping Admin">
                                            Agent Group Mapping Admin
                                        </Checkbox>
                                        <Checkbox value="Agent Group Config Mapping">
                                            Agent Group Config Mapping
                                        </Checkbox>
                                        <Checkbox value="Agent Group Config Mapping Admin">
                                            Agent Group Config Mapping Admin
                                        </Checkbox>
                                        {/* <Checkbox value="Default Config Mapping">Default Config Mapping</Checkbox>
                                        <Checkbox value="Default Config Mapping Admin">Default Config Mapping Admin</Checkbox> */}
                                        <Checkbox value="Voice Compliance">
                                            Voice Compliance
                                        </Checkbox>
                                        <Checkbox value="Voice Compliance Admin">
                                            Voice Compliance Admin
                                        </Checkbox>
                                        {/* <Checkbox value="Routing Flow Config">Routing Flow Config</Checkbox>
                                        <Checkbox value="Routing Flow Config Admin">Routing Flow Config Admin</Checkbox> */}
                                        <Checkbox value="Attribute List Mapping">
                                            Attribute List Mapping
                                        </Checkbox>
                                        <Checkbox value="Attribute List Mapping Admin">
                                            Attribute List Mapping Admin
                                        </Checkbox>
                                        <Checkbox value="User Group Queues & Proficiencies Access">
                                            User Group Queues & Proficiencies
                                            Access
                                        </Checkbox>
                                        <Checkbox value="User Additional Queues & Proficiencies Access">
                                            User Additional Queues &
                                            Proficiencies Access
                                        </Checkbox>
                                        <Checkbox value="Group Config Enforce Update Access">
                                            Group Config Enforce Update Access
                                        </Checkbox>
                                        <Checkbox value="Group Config Add Access">
                                            Group Config Add Access
                                        </Checkbox>
                                        <Checkbox value="Group Config Clone Access">
                                            Group Config Clone Access
                                        </Checkbox>
                                        <Checkbox value="AC Supervisior">
                                            AC Supervisior
                                        </Checkbox>
                                        <Checkbox value="AC Supervisior Admin">
                                            AC Supervisior Admin
                                        </Checkbox>
                                    </CheckboxGroup>
                                    <CheckboxGroup
                                        label="Routing Flow Config"
                                        isEmphasized
                                        value={permissions}
                                        onChange={setPermissions}
                                    >
                                        <Checkbox value="Timeout">
                                            Timeout
                                        </Checkbox>
                                        <Checkbox value="Timeout Admin">
                                            Timeout Admin
                                        </Checkbox>
                                        <Checkbox value="Ani Bypass">
                                            Ani Bypass
                                        </Checkbox>
                                        <Checkbox value="Ani Bypass Admin">
                                            Ani Bypass Admin
                                        </Checkbox>
                                        <Checkbox value="Holiday Schedules">
                                            Holiday Schedules
                                        </Checkbox>
                                        <Checkbox value="Holiday Schedules Admin">
                                            Holiday Schedules Admin
                                        </Checkbox>
                                        <Checkbox value="Emergency">
                                            Emergency
                                        </Checkbox>
                                        <Checkbox value="Emergency Admin">
                                            Emergency Admin
                                        </Checkbox>
                                        <Checkbox value="Dx Prompts">
                                            Dx Prompts
                                        </Checkbox>
                                        <Checkbox value="Dx Prompts Admin">
                                            Dx Prompts Admin
                                        </Checkbox>
                                        <Checkbox value="Dnis">Dnis</Checkbox>
                                        <Checkbox value="Dnis Admin">
                                            Dnis Admin
                                        </Checkbox>
                                        <Checkbox value="Did Magento">
                                            Did Magento
                                        </Checkbox>
                                        <Checkbox value="Did Magento Admin">
                                            Did Magento Admin
                                        </Checkbox>
                                        <Checkbox value="Srs Mock Data">
                                            Srs Mock Data
                                        </Checkbox>
                                        <Checkbox value="Srs Mock Data Admin">
                                            Srs Mock Data Admin
                                        </Checkbox>
                                        <Checkbox value="Spam Callers">
                                            Spam Callers
                                        </Checkbox>
                                        <Checkbox value="Spam Callers Admin">
                                            Spam Callers Admin
                                        </Checkbox>
                                        <Checkbox value="Operational Hours">
                                            Operational Hours
                                        </Checkbox>
                                    </CheckboxGroup>
                                    <CheckboxGroup
                                        label="SRS"
                                        isEmphasized
                                        value={permissions}
                                        onChange={setPermissions}
                                    >
                                        <Checkbox value="Case History">
                                            Case History
                                        </Checkbox>
                                        <Checkbox value="Case History Admin">
                                            Case History Admin
                                        </Checkbox>
                                        <Checkbox value="SRS Rules">
                                            SRS Rules
                                        </Checkbox>
                                        <Checkbox value="SRS Rules Admin">
                                            SRS Rules Admin
                                        </Checkbox>
                                        <Checkbox value="System Test Data">
                                            System Test Data
                                        </Checkbox>
                                        <Checkbox value="System Test Data Admin">
                                            System Test Data Admin
                                        </Checkbox>
                                        <Checkbox value="SRS Product Family">
                                            SRS Product Family
                                        </Checkbox>
                                        <Checkbox value="SRS Product Family Admin">
                                            SRS Product Family Admin
                                        </Checkbox>
                                    </CheckboxGroup>
                                    <CheckboxGroup
                                        label="Alert"
                                        isEmphasized
                                        value={permissions}
                                        onChange={setPermissions}
                                    >
                                        <Checkbox value="Alert Service">
                                            Alert Service
                                        </Checkbox>
                                        <Checkbox value="Alert Service Admin">
                                            Alert Service Admin
                                        </Checkbox>
                                    </CheckboxGroup>
                                    <CheckboxGroup
                                        label="Reports"
                                        isEmphasized
                                        value={permissions}
                                        onChange={setPermissions}
                                    >
                                        <Checkbox value="User Profile">
                                            User Profile
                                        </Checkbox>
                                    </CheckboxGroup>

                                    {/* <CheckboxGroup label="Config" isEmphasized
                                        value={permissions}
                                        onChange={setPermissions}
                                    >
                                        <Checkbox value="Dual System">Dual System</Checkbox>
                                        <Checkbox value="Dual System Admin">Dual System Admin</Checkbox> 
                                        <Checkbox value="Interaction Report Attributes Data">Interaction Report Attributes Data</Checkbox>
                                        <Checkbox value="Interaction Report Attributes Data Admin">Interaction Report Attributes Data Admin</Checkbox> 
                                    </CheckboxGroup> */}
                                    {/* <CheckboxGroup label="Reports" isEmphasized
                                        value={permissions}
                                        onChange={setPermissions}
                                    >
                                        <Checkbox value="Compliance">Compliance</Checkbox>
                                        <Checkbox value="Telecom">Telecom</Checkbox>
                                        <Checkbox value="User">User</Checkbox>
                                    </CheckboxGroup> */}
                                    {/* <CheckboxGroup label="Search" isEmphasized
                                        value={permissions}
                                        onChange={setPermissions}
                                    >
                                        <Checkbox value="Call Search">Call Search</Checkbox>
                                        <Checkbox value="Interaction Details">Interaction Details</Checkbox>
                                    </CheckboxGroup> */}
                                    {/* <CheckboxGroup label="Real Time Dashboard" isEmphasized
                                        value={permissions}
                                        onChange={setPermissions}
                                    >
                                        <Checkbox value="RT Dashboard">Real Time Dashboard</Checkbox>
                                    </CheckboxGroup> */}
                                </Flex>
                                <Heading
                                    level={5}
                                    UNSAFE_style={{
                                        textDecoration: "underline",
                                    }}
                                >
                                    Admin
                                </Heading>

                                <CheckboxGroup
                                    label="Admin"
                                    isEmphasized
                                    value={permissions}
                                    onChange={setPermissions}
                                >
                                    <Checkbox value="Admin">Admin</Checkbox>
                                </CheckboxGroup>

                                <div style={{ marginTop: "30px" }}></div>

                                <Flex gap="size-400" wrap>
                                    <div style={{ width: "40%" }}>
                                        {orgList && (
                                            <>
                                                <Heading level={5}>
                                                    Organizations
                                                </Heading>
                                                <ListView
                                                    selectionStyle="checkbox"
                                                    items={orgList}
                                                    selectedKeys={orgIds}
                                                    selectionMode="multiple"
                                                    density="compact"
                                                    onSelectionChange={(
                                                        value
                                                    ) => onOrgSelection(value)}
                                                    isQuiet
                                                >
                                                    {(item) => (
                                                        <Item key={item.id}>
                                                            {item.name}
                                                        </Item>
                                                    )}
                                                </ListView>
                                            </>
                                        )}
                                    </div>

                                    <div style={{ width: "40%" }}>
                                        {divisionList.size > 0 && (
                                            <>
                                                <Heading level={5}>
                                                    Divisions
                                                </Heading>
                                                <ListView
                                                    items={divisionList}
                                                    selectedKeys={divisionIds}
                                                    selectionMode="multiple"
                                                    density="compact"
                                                    onSelectionChange={
                                                        setDivisionIds
                                                    }
                                                    isQuiet
                                                >
                                                    {(item) => (
                                                        <Item key={item.id}>
                                                            <Text>
                                                                {item.name}
                                                            </Text>
                                                            <Text slot="description">
                                                                {getOrgForDivision(
                                                                    item.id
                                                                )}
                                                            </Text>
                                                        </Item>
                                                    )}
                                                </ListView>
                                            </>
                                        )}
                                    </div>
                                </Flex>
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onPress={onSaveHandler}
                                isDisabled={
                                    name.length === 0 ||
                                    desc.length === 0 ||
                                    permissions.size === 0
                                }
                            >
                                Save
                            </Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default AddRoleDialog
