import React, { useState, useEffect } from "react"
import {
    Provider,
    lightTheme,
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    Heading,
    Content,
    ButtonGroup,
    Form,
    Item,
    View,
    Switch,
    ComboBox,
} from "@adobe/react-spectrum"

const AddSrsMockData = (props) => {
    const [channel, setChannel] = useState("")
    const [testMode, setTestMode] = useState(false)

    const channels = [
        // {id:"case", name:"case"},
        { id: "chat", name: "chat" },
        { id: "voice", name: "voice" },
    ]

    useEffect(() => {
        if (props.editData) {
            setChannel(props.editData.channel)
            setTestMode(props.editData.testMode === "true" ? true : false)
        }
    }, [])

    const onCloseHandler = () => {
        props.handleCloseDialog(false)
    }

    const onSaveHandler = () => {
        const urlencoded = new URLSearchParams()
        urlencoded.append("channel", channel.trim())
        urlencoded.append("testMode", testMode)
        props.handleCloseDialog(true, urlencoded)
    }

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="S">
                        <Heading>{`${props.operation} Srs Mock Data`}</Heading>
                        <Divider />
                        <Content>
                            <Form isRequired>
                                <View>Channel</View>
                                <ComboBox
                                    autoFocus
                                    defaultItems={channels}
                                    selectedKey={channel}
                                    onSelectionChange={setChannel}
                                    isQuiet
                                    isDisabled={props?.editData}
                                >
                                    {(item) => (
                                        <Item key={item?.name}>
                                            {item?.name}
                                        </Item>
                                    )}
                                </ComboBox>

                                <div style={{ marginTop: "30px" }}></div>

                                <View>Test Mode</View>
                                <Switch
                                    isSelected={testMode}
                                    onChange={setTestMode}
                                />
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onPress={onSaveHandler}
                                isDisabled={false}
                            >
                                Save
                            </Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default AddSrsMockData
