import { CARE_PASSWORD, SERVER_URL, CARE_USERNAME, ALERT_SERVER_URL } from '../../constants/constants';
import { get, post } from './methods';


export function getUsers() {
    return get({
        url: `${SERVER_URL}/api/v1/user/users`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

export function getRoles() {
    return get({
        url: `${SERVER_URL}/api/v1/user/roles`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

export function getOrgsAndDivisions() {
    return get({
        url: `${SERVER_URL}/api/v1/user/orgsDivisions`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}


export function saveRole(data,username) {
    return post({
        url: `${SERVER_URL}/api/v1/user/saveRole`,
        json: data,
        queryParams: {
            ldap: username
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

export function saveUser(data,username) {
    return post({
        url: `${SERVER_URL}/api/v1/user/saveUser`,
        json: data,
        queryParams: {
            ldap: username
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true
    });
}


export function deleteUsers(usernames,ldap) {
    return post({
        url: `${SERVER_URL}/care/v1/user/delete`,
        queryParams: {
            users: usernames,
            ldap: ldap
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
    });
}

export function deleteRoles(roleIds,ldap) {
    return post({
        url: `${SERVER_URL}/care/v1/roles/delete`,
        queryParams: {
            roleIds: roleIds,
            ldap: ldap
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
    });
}

export function isRoleActive(roleIds) {
    return get({
        url: `${SERVER_URL}/api/v1/user/isRoleActive`,
        queryParams: {
            roleIds: roleIds
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

export function getUserPermissions(userInfo) {
    return get({
        url: `${SERVER_URL}/api/v1/user/userAuthorizedAccess`,
        queryParams: {
            ldap: userInfo.userid,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    });
}

export function getTimezones(key) {
    return get({
        url: `${SERVER_URL}/api/v1/config/get`,
        queryParams: {
            key: key,
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

export function saveTimezones(data, key, username) {
    return post({
        url: `${SERVER_URL}/api/v1/config/save`,
        queryParams: {
            key: key,
            ldap: username
        },
        json: data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true
    });
}

export function getIMSToken(ldap) {
    return fetch(`${ALERT_SERVER_URL.baseUrl}/token?`+new URLSearchParams({
        ldap
    }).toString(), {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': `${ALERT_SERVER_URL.xAPIKey}`
        },
    }).then((response) => response.json()).catch((err) => err);
}



/* Permissions tab api */


//GET all permissions api
export function getAllPermissionsAPI() {
    return get({
        url: `${SERVER_URL}/care/v1/permissions/roles/get/all`,
        // queryParams: {
        //     key: key,
        // },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

// ADD permissions api
export function addPermissionsAPI(ldap, body){
    return post({
        url: `${SERVER_URL}/care/v1/permissions/roles/add`,
        queryParams: {
            ldap,
        },
        json:JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: 'application/json'
    });
}

// // EDIT permissions api
// export function editPermissionsAPI(ldap, body){
//     return post({
//         url: `${SERVER_URL}/care/v1/permissions/edit`,
//         queryParams: {
//             ldap,
//         },
//         json:JSON.stringify(body),
//         username: CARE_USERNAME,
//         password: CARE_PASSWORD,
//         isJsonResponse: false,
//         isTextResponse: true,
//         reqContentType: 'application/json'
//     });
// }

// DELETE role permissions api
export function deletePermissionsAPI(ldap, body){
    return post({
        url: `${SERVER_URL}/care/v1/permissions/roles/delete`,
        queryParams: {
            ldap,
        },
        json:body,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: 'application/x-www-form-urlencoded'

    });
}


// Admin Roles New API 
/**
 * Add new roles
 * @param {*} ldap 
 * @param {*} data 
 * @returns 
 */
export function addNewRole(ldap,data){
    return post({
        url: `${SERVER_URL}/care/v1/roles/add`,
        queryParams: {
            ldap,
        },
        json: JSON.stringify(data),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}

/**
 * 
 */
export function getRolesList(){
    return get({
        url: `${SERVER_URL}/care/v1/roles/get/all`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}


// 
export function getGroupsInstanceLinked(){
    // /care/v1/groups/all/queues
    return get({
        url: `${SERVER_URL}/care/v1/groups/all/queues`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

// 
export function getQueuesInstanceLinked(){
    return get({
        url: `${SERVER_URL}/care/v1/groups/all/proficiency`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    })
}

// Default Instance Value
export function getDefaultInstance(ldap){
    return get({
        url: `${SERVER_URL}/care/v1/groups/default-instance`,
        queryParams:{
            ldap
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

// New API's For roles For POlicies and Roles

export const getAllPolicies = (ldap) => {
    return get({
        url: `${SERVER_URL}/care/v1/policies/get/all`,
        queryParams:{
            ldap
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
}

export const addPolicy = (ldap,body) => {
    return post({
        url: `${SERVER_URL}/care/v1/policies/add`,
        queryParams: {
            ldap,
        },
        json:JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: 'application/json'
    });
}

export const deletePolicy = (ldap, policyIds) => {
    return post({
        url: `${SERVER_URL}/care/v1/policies/delete`,
        queryParams: {
            ldap,
            policyIds
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: 'application/x-www-form-urlencoded'
    });
}

export const addNewRoles = (ldap,body) => {
    return post({
        url: `${SERVER_URL}/care/v1/roles/add`,
        queryParams: {
            ldap,
        },
        json:JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: 'application/json'
    });
}

// Updated Users API

export const getAllUsers = (ldap) => {
    return get({
        url: `${SERVER_URL}/care/v1/user/get/all`,
        queryParams:{
            ldap
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
};




export const addUserNew = (ldap, body) => {
    return post({
        url: `${SERVER_URL}/care/v1/user/add`,
        queryParams: {
            ldap,
        },
        json:JSON.stringify(body),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: 'application/json'
    });
}

export const getUsersDetailsPermissions = (ldap='richay') => {
    return get({
        url: `${SERVER_URL}/care/v1/user/get`,
        queryParams:{
            ldap
        },
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
    });
};

