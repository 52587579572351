import React, {useEffect, useState} from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Flex, View, Checkbox
} from '@adobe/react-spectrum';

const DeleteAgentGroup = (props) => {
    const [selected, setSelected] = useState(false);
    const onCloseHandler = () => {
        props.handleCloseDialog(false, selected);
    }
    const onDeleteConfirmHandler = () => {
        props.handleCloseDialog(true, selected);
    }
return (
    <Provider theme={lightTheme}>
        <div>
            <DialogTrigger
                type="modal"
                mobileType="modal"
                isOpen = {props.isDialogOpened}
                defaultOpen= {false}>
                <ActionButton isHidden isQuiet></ActionButton>
                <Dialog size="M">
                    <Heading>Delete Supervisior</Heading>
                    <Divider />
                    <Content>
                        <Flex direction={'column'} gap={'size-200'}>
                            <View>
                                Are you sure, you want to delete this supervisor ?
                            </View>
                        </Flex>
                    </Content>
                    <ButtonGroup>
                        <Button variant="secondary" onPress={onCloseHandler}>Cancel</Button>
                        <Button variant="secondary" onPress={onDeleteConfirmHandler}>Confirm</Button>
                    </ButtonGroup>
                </Dialog>
            </DialogTrigger>
        </div>
    </Provider>
    );
                    
};

export default DeleteAgentGroup;