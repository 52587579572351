import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, TextField, View, NumberField
} from '@adobe/react-spectrum';

const AddTimeOutDialog = (props) => {
    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (props.editData) {
            setName(props.editData.timeoutName);
            setValue(props.editData.timeoutValue);
            setDescription(props.editData.timeoutDescription);
        }
    }, []);

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    }


    const onSaveHandler = () => {
        props.handleCloseDialog(true, name, value, description);
    }


    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="S">
                        <Heading>{`${props.operation} Timeout`}</Heading>
                        <Divider />
                        <Content>

                            <Form isRequired>
                                <View>Timeout Name</View>
                                <TextField autoFocus
                                    value={name}
                                    onChange={setName}
                                    isQuiet
                                    isDisabled={props?.editData?.timeoutName}
                                />

                                <div style={{ marginTop: '30px' }}></div>
                                <View>Timeout Description</View>
                                <TextField
                                    value={description}
                                    onChange={setDescription}
                                    isQuiet
                                />

                                <div style={{ marginTop: '30px' }}></div>

                                <View>Timeout Value</View>
                                <NumberField
                                    value={value}
                                    onChange={setValue}
                                    minValue={0}
                                    width={"50%"}
                                    isQuiet />
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>Cancel</Button>
                            <Button variant="primary" onPress={onSaveHandler} isDisabled={name === ""}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddTimeOutDialog;