import React from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup
} from '@adobe/react-spectrum';

const ErrorDialog = (props) => {
    const onCloseHandler = () => {
        props.handleCloseDialog();
    }
    
return (
    <Provider theme={lightTheme}>
        <div>
            <DialogTrigger
                type="modal"
                mobileType="modal"
                isOpen = {props.isDialogOpened}
                defaultOpen= {false}>
                <ActionButton isHidden isQuiet></ActionButton>
                <Dialog size="S">
                    <Heading>Error</Heading>
                    <Divider />
                    <Content>{props.msg}</Content>
                    <ButtonGroup>
                        <Button variant="secondary" onPress={onCloseHandler}>Ok</Button>
                    </ButtonGroup>
                </Dialog>
            </DialogTrigger>
        </div>
    </Provider>
    );
                    
};

export default ErrorDialog;