import {
    Flex,
    View,
    Checkbox,
    CheckboxGroup,
    Heading,
    DialogTrigger,
    ActionButton,
    Button,
    Item,
    TabList,
    TabPanels,
    Tabs,
    ComboBox,
    Text as SpectrumText,
    TextField,
    Grid,
    Divider,
} from "@adobe/react-spectrum"
import React from "react"
import Delete from "@spectrum-icons/workflow/Delete"

const DeleteButton = ({onClick, isDisabled , operation}) => {
    return (
        <Button
            style="outline"
            variant="negative"
            UNSAFE_style={{ border: "none", cursor: "pointer" }}
            onPress={onClick}
            isDisabled={isDisabled && operation !== "add"}
        >
            <Delete />
        </Button>
    )
}

export default DeleteButton
