import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, TextField
} from '@adobe/react-spectrum';

const AddCaller = (props) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [remark, setRemark] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isPhoneValidState, setIsPhoneValidState] = useState('');

    useEffect(() => {
        if (props.editData) {
            setPhoneNumber(props.editData.phoneNumber);
            setRemark(props.editData.remark);
            setIsPhoneValid(props.editData.phoneNumber && (/^[+]?[0-9]{7,15}$/.test(props.editData.phoneNumber)) ? true: false)
        }
    }, []);

    const handlePhoneChange = ((value) => {
        setPhoneNumber(value);
        setIsPhoneValid(value && (/^[+]?[0-9]{7,15}$/.test(value)) ? true: false)
        if(value == ''){
            setIsPhoneValidState('');
        }else{
            setIsPhoneValidState((/^[+]?[0-9]{7,15}$/.test(value)?'valid':'invalid'));
        }
    });

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    }
    const onSaveHandler = () => {
        const contactData = {
            phoneNumber: phoneNumber,
            remark: remark
        }
        props.handleCloseDialog(true, contactData);
    }
    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="M">
                        <Heading>{props.operation} Caller</Heading>
                        <Divider />
                        <Content>
                            <Form isRequired>
                                <TextField label="Phone Number" autoFocus
                                    value={phoneNumber}
                                    validationState={isPhoneValidState}
                                    errorMessage={isPhoneValid ? '' : 'Phone number is invalid'}
                                    onChange={handlePhoneChange}
                                    isQuiet />
                                <TextField label="Remark" isRequired={false}
                                    value={remark || ''}
                                    onChange={setRemark}
                                    isQuiet />
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button aria-label='cancel' variant="secondary" onPress={onCloseHandler}>Cancel</Button>
                            <Button aria-label='submit' variant="primary" onPress={onSaveHandler} isDisabled={!isPhoneValid}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddCaller;