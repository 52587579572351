import React from 'react';
import { Provider, lightTheme } from '@adobe/react-spectrum';
import { Route ,Redirect } from 'react-router-dom';
import LoginCallback from '../login/LoginCallBack';
import HeaderComponent from './HeaderComponent';
import Ccp from './Ccp';
import UtilComponent from './UtilComponent';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../login/Login';

function mapStateToProps(state) {
    // console.log("State from App ",state);
    
    return {
        login: state.login,
    };
}

const App = (props) => {
 
    return (
        <Provider theme={lightTheme} colorScheme="light">
            <HeaderComponent userInfo={props?.login?.userInfo} />
            <Route path="/" exact>
                    <Redirect to="/login" />
            </Route>
            <Route path="/login" exact={true} component={Login} />
            <Route path="/authenticate/login" component={LoginCallback} />
            <Route path="/menu" exact={true} component={UtilComponent} />
            <Route path="/ccp" exact={true} component={Ccp} />
            <ToastContainer enableMultiContainer  containerId='defaultToast' />
        </Provider>
    );
}

export default connect(mapStateToProps)(App);
