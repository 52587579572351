import React, { useEffect } from 'react'
import {ProgressCircle ,View} from '@adobe/react-spectrum'

const Ccp = () => {

    useEffect(()=>{
        location.replace("https://d33jobqjgv1din.cloudfront.net/")
    },[])

  return (
        <View  padding="size-300" margin={"14% 46%"}>
           <ProgressCircle aria-label="Loading…" staticColor="blue" isIndeterminate size='L'/>
        </View>
  )
}

export default Ccp;