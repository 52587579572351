import React from "react"
import {
    Content,
    Heading,
    Button,
    Dialog,
    Divider,
    DialogContainer,
    ButtonGroup,
} from "@adobe/react-spectrum"

const DeleteConfirmDialog = ({isDeleteDialogOpen,setIsDeleteDialogOpen,confirmDelete,queueToDelete}) => {
    return (
        <DialogContainer onDismiss={() => setIsDeleteDialogOpen(false)}>
            {isDeleteDialogOpen && (
                <Dialog>
                    <Heading>Confirm Delete</Heading>
                    <Divider />
                    <Content>
                        Are you sure you want to delete the alert for BU "
                        <b>{queueToDelete?.buName}</b>"?
                    </Content>
                    <ButtonGroup>
                        <Button
                            variant="secondary"
                            onPress={() => setIsDeleteDialogOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant="negative" onPress={confirmDelete}>
                            Delete
                        </Button>
                    </ButtonGroup>
                </Dialog>
            )}
        </DialogContainer>
    )
}

export default DeleteConfirmDialog
