import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, TextField, View,
} from '@adobe/react-spectrum';

const AddTimezoneDialog = (props) => {
    const [abbr, setAbbr] = useState('');
    const [name, setName] = useState('');
    const [oldTimezone, setOldTimezone] = useState('');

    useEffect(() => {
        if (props.editData) {
            setOldTimezone(props.editData);
            setAbbr(props.editData.abbr);
            setName(props.editData.name);
        }
    }, []);

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    }


    const onSaveHandler = () => {
        var updatedTimezone = {
            abbr: abbr,
            name: name
        }

        props.handleCloseDialog(true, updatedTimezone, oldTimezone);
    }


    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="S">
                        <Heading>{props.operation}</Heading>
                        <Divider />
                        <Content>

                            <Form isRequired>
                                <View>Abbreviation</View>
                                <TextField autoFocus
                                    value={abbr}
                                    onChange={setAbbr}
                                    isQuiet
                                />

                                <div style={{ marginTop: '30px' }}></div>

                                <View>Name</View>
                                <TextField
                                    value={name}
                                    onChange={setName}
                                    isQuiet
                                />
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>Cancel</Button>
                            <Button variant="primary" onPress={onSaveHandler}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddTimezoneDialog;