import React, { useEffect, useState, useRef } from "react"
import {
    getDxPromptsApi ,addDxPromptsApi ,deleteDxPromptsApi
} from "../../../../utilities/api/phoenix-api"
import {
    Flex,
    lightTheme,
    Provider,
    SearchField,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    ProgressCircle,
} from "@adobe/react-spectrum"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import { PheonixRoles, notify } from "../../../../constants/constants"
import { connect } from "react-redux"
import AddDxPrompts from "./AddDxPrompts";
import { renderEmptyState } from "../../../../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
                rolesAndPermissions: state.login.rolesAndPermissions,
        
    }
}


const DxPrompts = ({ user, rolesAndPermissions }) => {
    const havingDeletePermission = rolesAndPermissions["AMAZON CONNECT"]?.delete?.filter((ele) => ele.subService === "ROUTING FLOW CONFIG:DX PROMPTS")[0]?.resources.groups
    const havingEditPermission = rolesAndPermissions["AMAZON CONNECT"]?.edit?.filter((ele) => ele.subService === "ROUTING FLOW CONFIG:DX PROMPTS")[0]?.resources.groups
    // const havingViewPermission = rolesAndPermissions["AMAZON CONNECT"]?.view?.filter((ele) => ele.subService === "ROUTING FLOW CONFIG:DX PROMPTS")[0]?.resources.groups
    const havingAddPermission = rolesAndPermissions["AMAZON CONNECT"]?.add?.filter((ele) => ele.subService === "ROUTING FLOW CONFIG:DX PROMPTS")[0]?.resources.groups


    const [prompts, setPromtps] = useState([])
    const [filteredPrompt, setFilteredPrompts] = useState([])
    // const [errorMessage, setErrorMessage] = useState("")
    const [currentText, setCurrentText] = useState(null)
    const [selectedDxPrompts, setSelectedDxPrompts] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    // const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
   
    const ldap = user?.userid
    // const isAdmin = props?.permissions?.includes(PheonixRoles.dxPromptsAdmin)

    // const columns = [
    //     { name: "Language", key: "language" },
    //     { name: "Prompt Name", key: "promptName" },
    //     { name: "Text", key: "text" },

    // ]

    const columnsAdmin = [
        { name: "Language", key: "language" },
        { name: "Prompt Name", key: "promptName" },
        { name: "Text", key: "text" },
        { name: "Actions", key: "actions" },
    ]

    // const currentTimeoutSelected = useRef("")
    const editData = useRef(false)

    const getAllDxPrompts = () => {
        setIsLoading(true)
        getDxPromptsApi()
            .then((res) => {
                setPromtps(res)
                setFilteredPrompts(res);
                setIsLoading(false)
            })
            .catch(() => {
                console.log("Error in getting Dx Prompts")
            })
    }

    useEffect(() => {
        getAllDxPrompts()
    }, [])

    const deleteDxPrompts = (ldap, name, multi) => {
        if (multi === "multi") {
            setIsLoading(true)
            // const formatedData = name.join()
            const urlencoded = new URLSearchParams()
            for (const key in name) {
                urlencoded.append(key, name[key])
            }
            deleteDxPromptsApi(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllDxPrompts()
                    setSelectedDxPrompts(new Set())
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in Multi deleting DxPrompts")
                    setIsLoading(false)
                })
        } else {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append(`${name.language}`, name.promptName)
            deleteDxPromptsApi(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllDxPrompts()
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in deleting DxPrompts")
                    setIsLoading(false)
                })
        }
    }

    const performAction = (value, item) => {
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = prompts.find(
                    (val) => val.language === item.language && val.promptName === item.promptName
                )
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "delete":
                deleteDxPrompts(ldap, item)
                // currentTimeoutSelected.current = item
                // setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                const mappedData = {}
                selectedDxPrompts.forEach((item) => {
                    const [language, promptName] = item.split(":")
                    if (!mappedData[language]) {
                        mappedData[language] = promptName
                    } else {
                        mappedData[language] += "," + promptName
                    }
                })
                deleteDxPrompts(ldap, mappedData, "multi")
                // setIsDeleteDialogOpen(true)
                break
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) =>
                                performAction(value, {language : item.language , promptName: item.promptName})
                            }   
                            disabledKeys={[
                                !havingEditPermission && "edit",
                                !havingDeletePermission && "delete",
                            ]}                     >
                            <Item key="edit" >
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "language":
                return <Cell>{item[columnKey]}</Cell>
            case "promptName":
                return <Cell>{item[columnKey]}</Cell>
            case "text":
                return <Cell>{item[columnKey]}</Cell>
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    // const isDuplicateTimeoutName = (name) => {
    //     return timeOut.some((ele) => ele.timeoutName === name);
    // };

    const closeAddTimeOutDialog = (isAddConfirmed, body) => {
        // if(isDuplicateTimeoutName(timeoutName)){
        //     notify(`${timeoutName} already exist`,"error");
        //     return;
        // }
        setIsAddDialogOpen(false)
        if (isAddConfirmed) {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("language", body.language)
            urlencoded.append("promptName", body.promptName)
            urlencoded.append("text", body.text)
            addDxPromptsApi(ldap, urlencoded)
                .then((res) => {
                    getAllDxPrompts()
                    notify(res, "success")
                })
                .catch(() => notify("Error in Adding Dx Prompts", "error"))

            setIsLoading(false)
        }
    }

    
    const filterDxPromptsList = (value) => {
        if(value === ""){
            setFilteredPrompts(prompts)
        }else {
            const getFilteredData = filteredPrompt.filter((val) =>
                val.language.toLowerCase().includes(value.toLowerCase())
            )
            setFilteredPrompts(getFilteredData)
        } 
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if(currentText !== null){
                filterDxPromptsList(currentText)
            }
        },500)
        return () => clearTimeout(timer)
    }, [currentText])

    const searchDxPrompts = (value) => {
        setCurrentText(value)
    }

    return (
        <>
           {isLoading ?  <div
                // className={isLoading ? "display-block" : "display-none"}
                style={{
                    paddingTop: "10%",
                    paddingLeft: "45%",
                    position: "absolute",
                    zIndex:"10"
                }}
            >
                
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
             
            </div>:null}

            <div align="center">
                <Heading variant="pageTitle">DX PROMPTS</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "70vw",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                        <SearchField
                        aria-label="search"
                            value={currentText}
                            onChange={searchDxPrompts}
                            isQuiet
                            onClear={() => setCurrentText('')}
                            width={"30%"}
                        />

                        {
                            <ActionGroup
                                width={"80%"}
                                alignItems="end"
                                onAction={(value) => performAction(value, null)}
                                disabledKeys={[!havingAddPermission && "add", !havingDeletePermission && "bulkDelete",]}

                                // disabledKeys={
                                //     selectedDxPrompts.size ||
                                //     selectedDxPrompts == "all"
                                //         ? []
                                //         : ["bulkDelete"]
                                // }
                            >
                                <Item key="add">+ Add</Item>
                                <Item key="bulkDelete">
                                    <Delete />
                                    <Text>Delete</Text>
                                </Item>
                            </ActionGroup>
                        }
                    </Flex>

                    <Flex
                        width="100%"
                        height="100%"
                        direction="column"
                        gap="size-150"
                    >
                        <TableView
                            aria-label="Timeout"
                            selectionMode={!havingDeletePermission ? "none" : "multiple"}
                            selectedKeys={selectedDxPrompts}
                            onSelectionChange={setSelectedDxPrompts}
                            height={"45vh"}
                            renderEmptyState={renderEmptyState}
                            density="spacious"
                            overflowMode="wrap"
                        >
                            <TableHeader columns={columnsAdmin}>
                                {(column) => (
                                    <Column
                                        key={column.key}
                                        align={column.key === "actions" ? "end" : "start"}
                                        // width={column.key === "timeoutName" ? "50%" : column.key === "actions" ? "23%" : "auto"}
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={filteredPrompt}>
                                {(item) => (
                                    <Row key={`${item.language}:${item.promptName}`}>
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                )}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Provider>

                {isAddDialogOpen && (
                    <AddDxPrompts
                        isDialogOpened={isAddDialogOpen}
                        editData={editData.current}
                        operation={operation}
                        handleCloseDialog={(isAddConfirmed,body ) =>
                            closeAddTimeOutDialog(isAddConfirmed,body)
                        }
                        
                    />
                )}
            </div>
        </>
    )
}

export default connect(mapStateToProps)(DxPrompts);