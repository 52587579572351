import {
    USER_INFO,
    USER_LOGOUT,
    SUPPORT_INDEX,
    MAIN_INDEX,
    SET_MENU,
    USER_AUTHORIZATION,
    ADOBE_IO_TOKEN,
    USER_ROLES_AND_PERMISSIONS
} from '../constants/constants';

export function updateUserInfo(userInfo) {
    return {
        type: USER_INFO,
        userInfo,
    };
}

export function logout() {
    return {
        type: USER_LOGOUT,
    };
}

export function navigateToIndex(mainIndex, supportIndex) {
    return {
        type: SUPPORT_INDEX,
        supportIndex,
        mainIndex,
    };
}

export function changeMenuIndex(index) {
    return {
        type: MAIN_INDEX,
        index,
    };
}
export function setMenu(menuData, menuKeys) {
    return {
        type: SET_MENU,
        menuData,
        menuKeys
    };
}

export function updateUserPermissions(authorizedAccess) {
    return {
        type: USER_AUTHORIZATION,
        authorizedAccess,
    };
}

// export function updatedUserPermissionsKeys(Value){
//     console.log({USER_AUTHORIZATION, Value});
//     return {
//         type: USER_AUTHORIZATION,
//         Value,
//     };
// }

export function setIMSToken(token){
    return {
        type:ADOBE_IO_TOKEN,
        token:token
    }
}

export function userRolesAndPermissions(payload){
    return {
        type: USER_ROLES_AND_PERMISSIONS,
        payload,
    };
}


