import {
    CARE_PASSWORD,
    SERVER_URL,
    CARE_USERNAME,
} from "../../constants/constants"
import { fileUploadMultipart, get, post } from "./methods"


export function getUserProfileReport(data) {
    // console.log("data", data);
    
    return post({
        url: `${SERVER_URL}/care/v1/queues/users`,
        json: JSON.stringify(data),
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: false,
        isTextResponse: true,
        reqContentType: "application/json",
    })
}