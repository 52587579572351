import {
    Flex,
    View,
    Checkbox,
    NumberField,
    TimeField,
    ContextualHelp,
    CheckboxGroup,
    Text,
    Content,
    Heading,
    DialogTrigger,
    ActionButton,
    Button,
    Dialog,
    Divider,
    AlertDialog,
    SearchField,
    Radio,
    RadioGroup,
    Badge,
    IllustratedMessage,
    DialogTriggerType,
} from "@adobe/react-spectrum"
import React, { useEffect, useState } from "react"
import {
    Accordion,
    AccordionItem,
} from "../utilities/components/Accordion/Accordion"
import { Time } from "@internationalized/date"
import EditMonitorQueueList from "./EditMonitorQueue"
import {
    deleteAlert,
    getAllMonitoredQueue,
    updateAlert,
} from "../utilities/api/alertService-api"
import { connect } from "react-redux"
import AlertOptions from "./AlertOptions"
import { ALERT_TYPES } from "../constants/constants"
import Draw from "@spectrum-icons/workflow/Draw"
import Delete from "@spectrum-icons/workflow/Delete"
import ChevronDown from "@spectrum-icons/workflow/ChevronDown"
import ChevronUp from "@spectrum-icons/workflow/ChevronUp"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
        token: state.login.token,
    }
}

const MonitorQueueList = (props) => {
    // const [openDialogue, setOpenDialogue] = useState(false);

    const [currentText, setCurrentText] = useState("")
    const queueList = [
        {
            queueId: "",
            queueName: "",
            updatedBy: "",

            alertTypes: {
                onQueueUsersAlertOptions: {
                    slack: true,
                    email: true,
                },
                convWait: true,
                convWaitAlerts: [
                    {
                        convWaitId: Math.floor(
                            100000 + Math.random() * 900000
                        ),
                        convWaitAlertOption: {
                            slack: true,
                            email: true,
                        },
                        convWaitSchedules: [
                            {
                                timezone: "IST",
                                days: "",
                                startTime: "all",
                                endTime: "*",
                                convWaitConditions: 0,
                            },
                        ],
                        convWaitEveryday: true,
                        convWaitAttribute: false,
                        globalAlert: true,
                        convWaitAttributes: [
                            {
                                attributeName: "",
                                attributeValue: "",
                                attributeValues: [],
                            },
                        ],
                        customAlert: false,
                        alertInfo: {
                            slackWebhooks: [],
                            emailRecipients: [],
                        },
                        convWaitCustom: false,
                        convWaitConditions: "60",
                    },
                ],
                voiceMail: false,
                userThresholdSchedules: [
                    {
                        timezone: "IST",
                        days: "all",
                        startTime: "all",
                        endTime: "all",
                        onQueueUserThreshold: 0,
                    },
                ],
                convAbandoned: true,
                voiceMailAlertOptions: {
                    slack: true,
                    email: true,
                },

                onQueueUsers: false,
            },
            channel: "",
            alertInfo: {
                slackWebhooks: [],
                emailRecipients: [],
            },
            updatedDate: "",
        },
    ]
    const [queueListNew, setQueueListNew] = useState(queueList)
    const [filteredQueues, setFilteredQueues] = useState(queueList)
    const [selectedQueueList, setSelectedQueueList] = useState([])

    const compareQueueNames = (a, b) => {
        if (a.queueName < b.queueName) {
            return -1
        }
        if (a.queueName > b.queueName) {
            return 1
        }
        return 0
    }

    const fetchMonitoringQueueList = () => {
        getAllMonitoredQueue()
            .then((resp) => {
                const data = resp.response
                    .filter((item) => item.queueName && item.queueName != "")
                    .sort(compareQueueNames)
                setSelectedQueueList(data.map((ele) => ele.queueId))
                setQueueListNew(data)
                setFilteredQueues(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchMonitoringQueueList()
        return () => {
            console.log("Monitor Unmounted")
        }
    }, [])

    const filterQueues = (value) => {
        setCurrentText(value)
        const filteredQueues = queueListNew.filter((q) =>
            q.queueName?.toLowerCase().includes(value.toLowerCase())
        )
        setFilteredQueues(filteredQueues)
    }

    // const addNewQueue = () => {
    //     console.log('Open Dialog');
    //     setOpenDialogue(true);
    // };

    return (
        <>
            <Flex
                direction="row"
                justifyContent="space-between"
                UNSAFE_style={{ padding: "1%" }}
            >
                <SearchField
                    isQuiet
                    onClear={() => setCurrentText("")}
                    onChange={filterQueues}
                    placeholder="Search"
                    value={currentText}
                    width={"15%"}
                />

                <DialogTrigger
                    isDismissable
                    onOpenChange={(isOpen) => {
                        !isOpen ? fetchMonitoringQueueList() : {}
                    }}
                >
                    <ActionButton
                        marginTop="size-100"
                        UNSAFE_className={
                            props.permissions.includes("Alert Service Admin")
                                ? "clickable"
                                : ""
                        }
                        isDisabled={
                            !props.permissions.includes("Alert Service Admin")
                        }
                    >
                        Add Alert
                    </ActionButton>
                    {(close) => (
                        <Dialog>
                            <Heading>Add Alert</Heading>
                            <Divider />
                            <Content>
                                <EditMonitorQueueList
                                    queue={queueList[0]}
                                    selectedQueues={selectedQueueList}
                                    user={props.user}
                                    isUpdate={false}
                                    close={close}
                                ></EditMonitorQueueList>
                            </Content>
                        </Dialog>
                    )}
                </DialogTrigger>
            </Flex>
            {!!filteredQueues.length && (
                <Accordion>
                    {filteredQueues.map((item) => (
                        <AccordionItem
                            key={`${item.queueId}-acc`}
                            title={item.queueName}
                            queue={item}
                        >
                            <MonitorQueueItem
                                key={`${item.queueId}-mqi`}
                                queue={item}
                                user={props.user}
                                permissions={props.permissions}
                                reloadList={() => fetchMonitoringQueueList()}
                                selectedQueues={selectedQueueList}
                            ></MonitorQueueItem>
                        </AccordionItem>
                    ))}
                </Accordion>
            )}
            {filteredQueues.length < 1 && (
                <IllustratedMessage>
                    <Heading>No results</Heading>
                    <Content>No results found</Content>
                </IllustratedMessage>
            )}
        </>
    )
}

const MonitorQueueItem = (props) => {
    // console.log("QUEEEEEEE", props.queue)
    const keyTextCSS = {
        fontSize: "14px",
        fontWeight: "600",
        color: "black",
    }

    const keyTextSecondaryCSS = {
        fontSize: "14px",
        fontWeight: "600",
        color: "black",
    }

    const keyTextTertiaryCSS = {
        marginTop: "8px",
        fontSize: "13px",
        fontWeight: "600",
        color: "black",
    }

    const [editable, SetEditable] = useState(false)
    const [queue, setQueue] = useState(props.queue)
    const [showDetails, setShowDetails] = useState({})
    const [showOnQueueDetails, setShowOnQueueDetails] = useState(false)
    const [showCallWaitDetails, setShowCallWaitDetails] = useState(false)

    const updateAlertOptions = (queue) => {
        const queueData = { ...queue }
        //  console.log(queueData, queue);
        ALERT_TYPES.forEach((key) => {
            const optionKey = `${key}AlertOptions`
            if (queueData.alertTypes[key]) {
                if (!queueData.alertTypes[optionKey]) {
                    queueData.alertTypes[optionKey] = {
                        slack: true,
                        email: true,
                    }
                }
            }
        })
        setQueue(queueData)
    }

    useEffect(() => {
        updateAlertOptions(props.queue)
    }, [props.queue])

    useEffect(() => {
        updateAlertOptions(props.queue)
    }, [])

    // const openEditModel = (queue) => {
    //     SetEditable(true);
    // }
    const updateQueue = (data) => {
        updateAlert(data)
            .then(() => {
                SetEditable(false)
            })
            .catch((err) => {
                console.log({ err })
            })
    }
    const deleteQueue = (queueIds) => {
        var urlencoded = new URLSearchParams()
        urlencoded.append("queueIds", queueIds)
        deleteAlert(urlencoded, props.user.userid)
            .then((resp) => {
                console.log({ resp })
                props.reloadList()
            })
            .catch((err) => {
                console.log({ err })
            })
    }

    const capitalizeFirstLetter = (string) => {
        // return string
        return string?.charAt(0)?.toUpperCase() + string?.slice(1)
    }

    const renderItems = (items) => {
        return items.length > 2 ? (
            <Flex direction="row" alignItems="center" gap="size-100">
                <View>
                    {items.slice(0, 2).map((item, index) => (
                        <View key={index}>{item}</View>
                    ))}
                </View>
                <DialogTrigger type="popover" placement="right top">
                    <ActionButton
                        height={"30px"}
                        UNSAFE_style={{ cursor: "pointer" }}
                    >
                        Show All
                    </ActionButton>
                    <Dialog>
                        <Content
                            height={"auto"}
                            UNSAFE_style={{ display: "flex", flexWrap: "wrap" }}
                        >
                            {items.map((item, index) => (
                                <Badge
                                    key={index}
                                    variant="neutral"
                                    UNSAFE_style={{
                                        margin: "2px 2px",
                                        background: "rgb(102, 102, 102)",
                                        color: "white",
                                        padding: "3px 0px 0px 10px",
                                        borderRadius: "4px",
                                        height: "30px",
                                        display: "block",
                                    }}
                                >
                                    <Text
                                        UNSAFE_style={{
                                            padding: "0px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item}
                                    </Text>
                                </Badge>
                            ))}
                        </Content>
                    </Dialog>
                </DialogTrigger>
            </Flex>
        ) : (
            items.map((item, index) => <View key={index}>{item}</View>)
        )
    }

    return (
        <>
            <Flex
                direction="column"
                gap="size-100"
                UNSAFE_style={{
                    padding: "10px",
                    borderRadius: "5px",
                    marginBottom: "5px",
                }}
            >
                <Flex direction="row" justifyContent="end" gap="size-100">
                    <DialogTrigger
                        isDismissable
                        onOpenChange={(isOpen) => {
                            !isOpen ? props.reloadList() : {}
                        }}
                    >
                        <ActionButton
                            UNSAFE_className={
                                props.permissions.includes(
                                    "Alert Service Admin"
                                )
                                    ? "clickable"
                                    : ""
                            }
                            isDisabled={
                                !props.permissions.includes(
                                    "Alert Service Admin"
                                )
                            }
                        >
                            <Draw />
                        </ActionButton>
                        {(close) => (
                            <Dialog>
                                <Heading>Edit Alert</Heading>
                                <Divider />
                                <Content>
                                    <EditMonitorQueueList
                                        queue={props.queue}
                                        user={props.user}
                                        selectedQueues={props.selectedQueues}
                                        isUpdate={true}
                                        close={close}
                                    ></EditMonitorQueueList>
                                </Content>
                            </Dialog>
                        )}
                    </DialogTrigger>
                    <DialogTrigger>
                        <ActionButton
                            aria-label="Delete"
                            UNSAFE_className={
                                props.permissions.includes(
                                    "Alert Service Admin"
                                )
                                    ? "clickable"
                                    : ""
                            }
                            isDisabled={
                                !props.permissions.includes(
                                    "Alert Service Admin"
                                )
                            }
                        >
                            <Delete />
                        </ActionButton>
                        <AlertDialog
                            variant="confirmation"
                            title="Delete record?"
                            primaryActionLabel="Confirm"
                            cancelLabel="Cancel"
                            onPrimaryAction={() =>
                                deleteQueue(
                                    queue && queue.queueName
                                        ? queue.queueId
                                        : props.queue.queueId
                                )
                            }
                        >
                            Are you sure you want to delete this record?
                        </AlertDialog>
                    </DialogTrigger>
                </Flex>
                <Flex direction="row" gap="size-800" marginStart="size-250">
                    <Flex gap="size-100">
                        <View minWidth="250px">
                            <Text UNSAFE_style={keyTextCSS}>Queue</Text>
                        </View>
                        <Text>
                            {queue && queue.queueName
                                ? queue.queueName
                                : props.queue.queueName}
                        </Text>
                    </Flex>
                </Flex>
                <Flex direction="row" gap="size-100" marginStart="size-250">
                    <Flex gap="size-100">
                        <View minWidth="250px">
                            <Text UNSAFE_style={keyTextCSS}>Channel</Text>
                        </View>
                        <Text>
                            {queue && queue.channel
                                ? capitalizeFirstLetter(queue?.channel)
                                : capitalizeFirstLetter(props?.queue?.channel)}
                        </Text>
                    </Flex>
                </Flex>
                <Flex direction="row" gap="size-100" marginStart="size-250">
                    <View alignSelf="center" minWidth="250px">
                        <Text UNSAFE_style={keyTextCSS}>Email</Text>
                        <ContextualHelp variant="help">
                            <Heading>Email</Heading>
                            <Content>
                                <Text>
                                    Provide Email and press enter to add more.
                                </Text>
                            </Content>
                        </ContextualHelp>
                    </View>
                    <View>
                        {queue?.alertInfo?.emailRecipients
                            ? renderItems(queue.alertInfo.emailRecipients)
                            : renderItems(
                                  props.queue?.alertInfo?.emailRecipients || []
                              )}
                    </View>
                </Flex>
                <Flex
                    direction="row"
                    gap="size-100"
                    marginStart="size-250"
                    width={"95%"}
                >
                    <View alignSelf="center" minWidth="250px">
                        <Text UNSAFE_style={keyTextCSS}>Slack Webhook URL</Text>
                        <ContextualHelp variant="help">
                            <Heading>Webhook URL of Slack channel</Heading>
                            <Content>
                                <Text>
                                    Webhook URL of Slack channel where
                                    notifications will be triggered (Your Slack
                                    workspace admin can generate the webhook for
                                    your slack channel).
                                    Provide webhook URL and press enter to add more.
                                </Text>
                            </Content>
                        </ContextualHelp>
                    </View>
                    <View UNSAFE_style={{ overflow: "auto" }}>
                        {queue?.alertInfo?.slackWebhooks
                            ? renderItems(queue.alertInfo.slackWebhooks)
                            : renderItems(
                                  props.queue?.alertInfo?.slackWebhooks || []
                              )}
                    </View>
                </Flex>
            </Flex>
            <View
                UNSAFE_style={{
                    border: "1px solid #c3c3c3",
                    padding: "10px",
                    borderRadius: "5px",
                }}
            >
                <h4 style={{ textAlign: "center" }}>Alert Types</h4>
                <Flex direction="column" marginStart="size-250">
                    {props.queue.channel?.toLowerCase() == "call" && (
                        <Flex direction={"row"} alignContent={"center"}>
                            <View minWidth="250px">
                                <Checkbox
                                    UNSAFE_className="checkBoxLabelBeforeHelp"
                                    isSelected={
                                        props.queue.alertTypes.voiceMail
                                    }
                                    aria-label="voiceMail"
                                >
                                    <Text UNSAFE_style={keyTextCSS}>
                                        Voicemail
                                    </Text>
                                </Checkbox>
                                <ContextualHelp
                                    UNSAFE_className="helpContextIcon"
                                    variant="help"
                                >
                                    <Heading>Voicemail</Heading>
                                    <Content>
                                        <Text>
                                            To receive notification when
                                            voicemail is received.
                                        </Text>
                                    </Content>
                                </ContextualHelp>
                            </View>
                            {props.queue.alertTypes.voiceMail ? (
                                <AlertOptions
                                    email={
                                        queue.alertTypes.voiceMailAlertOptions
                                            ?.email
                                    }
                                    slack={
                                        queue.alertTypes.voiceMailAlertOptions
                                            ?.slack
                                    }
                                    onEmailChange={() => {}}
                                    onSlackChange={() => {}}
                                />
                            ) : (
                                <View
                                    UNSAFE_style={{
                                        visibility: "hidden",
                                        width: "132px",
                                    }}
                                ></View>
                            )}
                        </Flex>
                    )}
                    {props.queue.channel?.toLowerCase() != "case" && (
                        <Flex direction={"row"} alignContent={"center"}>
                            <View minWidth="250px">
                                <Checkbox
                                    UNSAFE_className="checkBoxLabelBeforeHelp"
                                    isSelected={
                                        props.queue.alertTypes.convAbandoned
                                    }
                                    aria-label="convAbandoned"
                                >
                                    <Text UNSAFE_style={keyTextCSS}>
                                        {capitalizeFirstLetter(
                                            props?.queue?.channel
                                        )}{" "}
                                        Abandoned
                                    </Text>
                                </Checkbox>
                                <ContextualHelp
                                    UNSAFE_className="helpContextIcon"
                                    variant="help"
                                >
                                    <Heading>
                                        {capitalizeFirstLetter(
                                            props?.queue?.channel
                                        )}{" "}
                                        Abandoned
                                    </Heading>
                                    <Content>
                                        <Text>
                                            To receive notification when{" "}
                                            {props.queue.channel} is abandoned
                                            by customer.
                                        </Text>
                                    </Content>
                                </ContextualHelp>
                            </View>
                            {props.queue.alertTypes.convAbandoned && (
                                <AlertOptions
                                    email={
                                        queue.alertTypes
                                            .convAbandonedAlertOptions?.email
                                    }
                                    slack={
                                        queue.alertTypes
                                            .convAbandonedAlertOptions?.slack
                                    }
                                    onEmailChange={() => {}}
                                    onSlackChange={() => {}}
                                />
                            )}
                        </Flex>
                    )}
                    <Flex direction={"column"}>
                        <Flex direction="row">
                            <View minWidth="250px">
                                <Checkbox
                                    UNSAFE_className="checkBoxLabelBeforeHelp"
                                    isSelected={props.queue.alertTypes.convWait}
                                    aria-label="convWait"
                                >
                                    <Text UNSAFE_style={keyTextCSS}>
                                        {" "}
                                        {capitalizeFirstLetter(
                                            props?.queue?.channel
                                        )}{" "}
                                        Wait
                                    </Text>
                                </Checkbox>
                                <ContextualHelp
                                    UNSAFE_className="helpContextIcon"
                                    variant="help"
                                >
                                    <Heading>
                                        {capitalizeFirstLetter(
                                            props?.queue?.channel
                                        )}{" "}
                                        Wait
                                    </Heading>
                                    <Content>
                                        <Text>
                                            To enable {props.queue.channel} wait
                                            notification. If checkbox is ticked
                                            and wait condition is provided as 60
                                            seconds then a notification will be
                                            triggered when call is ringing and
                                            not answered by any agent in 60
                                            seconds.
                                        </Text>
                                    </Content>
                                </ContextualHelp>
                            </View>
                            <Flex justifyContent={"space-between"}>
                                {/* This View is just to fill the space */}
                                <View
                                    UNSAFE_style={{
                                        visibility: "hidden",
                                        width: "132px",
                                    }}
                                ></View>
                                <Button
                                    variant="primary"
                                    UNSAFE_className="cursor"
                                    onClick={() =>
                                        setShowCallWaitDetails(
                                            !showCallWaitDetails
                                        )
                                    }
                                >
                                    {showCallWaitDetails ? (
                                        <ChevronUp UNSAFE_className="noBorder cursor" />
                                    ) : (
                                        <ChevronDown UNSAFE_className="noBorder cursor" />
                                    )}
                                </Button>
                            </Flex>
                        </Flex>
                        {showCallWaitDetails &&
                            queue.alertTypes.convWait &&
                            queue.alertTypes.convWaitAlerts.map(
                                (alert, index) => (
                                    <View
                                        marginStart={"250px"}
                                        UNSAFE_style={{
                                            border: "1px solid #c3c3c3",
                                            // padding: "10px",
                                            borderRadius: "5px",
                                            marginBottom: "5px",
                                            // width: "75%",
                                            // marginLeft: "4%",
                                        }}
                                    >
                                        <View>
                                            {alert.convWaitAlertOption && (
                                                <AlertOptions
                                                    email={
                                                        alert
                                                            .convWaitAlertOption
                                                            ?.email
                                                    }
                                                    slack={
                                                        alert
                                                            .convWaitAlertOption
                                                            ?.slack
                                                    }
                                                    onEmailChange={() => {}}
                                                    onSlackChange={() => {}}
                                                />
                                            )}
                                        </View>
                                       {alert.customAlert &&  <Divider
                                            height={"1px"}
                                            size="M"
                                            margin={"10px"}
                                        />}
                                      {alert.customAlert && 
                                      <>
                                      <Text 
                                       UNSAFE_style={{
                                        // marginTop: "-28px",
                                        marginLeft: "16px",
                                        display: "block",
                                        background: "white",
                                        // width: "100px",
                                        padding: "0px 0px 0px 4px",
                                        fontSize: "12x",
                                        fontWeight: "800",
                                    }}
                                      >Custom Alert</Text>
                                       <Flex
                                            direction="row"
                                            gap="size-100"
                                            marginStart="size-250"
                                            UNSAFE_style={{
                                                paddingRight: "6px",
                                            }}
                                        >
                                            <View
                                                alignSelf="center"
                                                minWidth="200px"
                                            >
                                                <Text
                                                    UNSAFE_style={
                                                        keyTextSecondaryCSS
                                                    }
                                                >
                                                    Email
                                                </Text>
                                                <ContextualHelp variant="help">
                                                    <Heading>Email</Heading>
                                                    <Content>
                                                        <Text>
                                                            Provide Email and press enter to add more.
                                                        </Text>
                                                    </Content>
                                                </ContextualHelp>
                                            </View>
                                            <View UNSAFE_style={{wordBreak:"break-all"}}>
                                                {alert?.alertInfo
                                                    ?.emailRecipients
                                                    &&
                                                    renderItems(
                                                          alert.alertInfo
                                                              .emailRecipients
                                                      )
                                                 
                                                      }
                                            </View>
                                        </Flex>
                                        <Flex
                                            direction="row"
                                            gap="size-100"
                                            marginStart="size-250"
                                            UNSAFE_style={{
                                                paddingRight: "6px",
                                            }}
                                        >
                                            <View
                                                alignSelf="center"
                                                minWidth="200px"
                                            >
                                                <Text
                                                    UNSAFE_style={
                                                        keyTextSecondaryCSS
                                                    }
                                                >
                                                    {" "}
                                                    Slack Webhook URL
                                                </Text>
                                                <ContextualHelp variant="help">
                                                    <Heading>
                                                        Webhook URL of Slack
                                                        channel
                                                    </Heading>
                                                    <Content>
                                                        <Text>
                                                            Webhook URL of Slack
                                                            channel where
                                                            notifications will
                                                            be triggered (Your
                                                            Slack workspace
                                                            admin can generate
                                                            the webhook for your
                                                            slack channel).
                                                            Provide webhook URL and press enter to add more.
                                                        </Text>
                                                    </Content>
                                                </ContextualHelp>
                                            </View>
                                            <View
                                                UNSAFE_style={{
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                {alert?.alertInfo?.slackWebhooks
                                                    &&
                                                    renderItems(
                                                          alert.alertInfo
                                                              .slackWebhooks
                                                      )
                                                   
                                                      }
                                            </View>
                                        </Flex>
                                        </>}
                                        {alert.convWaitAttribute && <Divider
                                            height={"1px"}
                                            size="M"
                                            margin={"10px 10px"}
                                        ></Divider>}
                                       {alert.convWaitAttribute &&
                                        <Text
                                            UNSAFE_style={{
                                                // marginTop: "-28px",
                                                marginLeft: "16px",
                                                display: "block",
                                                background: "white",
                                                // width: "100px",
                                                padding: "0px 0px 0px 4px",
                                                fontSize: "12x",
                                                fontWeight: "800",
                                            }}
                                        >
                                            Custom Attributes
                                        </Text>}

                                        <Flex
                                            direction={"column"}
                                            marginTop={"10px"}
                                        >
                                            {alert.convWaitAttribute &&
                                                alert.convWaitAttributes.map(
                                                    (attr, index) => (
                                                        <View>
                                                            <Flex
                                                                marginStart={
                                                                    "20px"
                                                                }
                                                                direction={
                                                                    "row"
                                                                }
                                                                gap={"size-100"}
                                                                key={`${attr.attributeName}-${index}`}
                                                            >
                                                                <Text
                                                                    UNSAFE_style={{
                                                                        ...keyTextSecondaryCSS,
                                                                        minWidth:
                                                                            "200px",
                                                                    }}
                                                                >
                                                                    {
                                                                        attr.attributeName
                                                                    }
                                                                </Text>
                                                                <Text UNSAFE_style={{
                                                    wordBreak: "break-word",
                                                }}>
                                                                    {attr.attributeValues.join(
                                                                        ","
                                                                    )}
                                                                </Text>
                                                            </Flex>
                                                        </View>
                                                    )
                                                )}
                                        </Flex>
                                        <Divider
                                            height={"1px"}
                                            size="M"
                                            margin={"10px"}
                                        />
                                        <View
                                            UNSAFE_style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                padding: "0px 20px",
                                                textAlign: "end",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <RadioGroup
                                                UNSAFE_style={{
                                                    display: "block",
                                                }}
                                                // label="Schedule"
                                                labelPosition="side"
                                                value={
                                                    alert.convWaitEveryday
                                                        ? "everyDay"
                                                        : "custom"
                                                }
                                                orientation={"vertical"}
                                                isReadOnly
                                            >
                                                <Radio value="everyDay">
                                                    <Text
                                                        UNSAFE_style={
                                                            keyTextSecondaryCSS
                                                        }
                                                    >
                                                        Everyday
                                                    </Text>
                                                </Radio>
                                                <Radio value="custom">
                                                    <Text
                                                        UNSAFE_style={
                                                            keyTextSecondaryCSS
                                                        }
                                                    >
                                                        Custom
                                                    </Text>
                                                </Radio>
                                            </RadioGroup>
                                            <Button
                                                variant="primary"
                                                UNSAFE_className="cursor"
                                                UNSAFE_style={{
                                                    border: "none",
                                                }}
                                                onClick={() =>
                                                    setShowDetails({
                                                        ...showDetails,
                                                        [index]: !showDetails[
                                                            index
                                                        ],
                                                    })
                                                }
                                            >
                                                {showDetails[index] ? (
                                                    <ChevronUp UNSAFE_className="noBorder cursor" />
                                                ) : (
                                                    <ChevronDown UNSAFE_className="noBorder cursor" />
                                                )}
                                            </Button>
                                        </View>
                                        {showDetails[index] && (
                                            <Flex
                                                direction={"row"}
                                                UNSAFE_style={{
                                                    padding: "0px 6px",
                                                    marginLeft: "215px",
                                                }}
                                            >
                                                {alert.convWaitEveryday && (
                                                    <Flex
                                                        direction={"row"}
                                                        marginStart="size-250"
                                                        UNSAFE_style={{
                                                            width: "-webkit-fill-available",

                                                            border:
                                                                "1px solid #c3c3c3",
                                                            borderRadius: "5px",
                                                            marginBottom: "5px",
                                                            padding: "0px 10px",
                                                            minHeight: "100px",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <NumberField
                                                            UNSAFE_className="custom-NumerField"
                                                            maxWidth={"500px"}
                                                            width={"500px"}
                                                            value={
                                                                alert.convWaitConditions
                                                            }
                                                            label="Schedule Condition"
                                                            labelPosition="side"
                                                            labelAlign="end"
                                                            minValue={0}
                                                            maxValue={600}
                                                            isRequired
                                                            aria-label="seconds"
                                                            formatOptions={{
                                                                style: "unit",
                                                                unit: "second",
                                                            }}
                                                            isQuiet
                                                            isReadOnly
                                                        />
                                                    </Flex>
                                                )}
                                                {alert.convWaitCustom && (
                                                    <Flex
                                                        direction={"column"}
                                                      
                                                        UNSAFE_style={{width:"-webkit-fill-available"}}
                                                    >
                                                        <Flex
                                                            direction={"row"}
                                                            justifyContent="space-between"
                                                        >
                                                            {/* <h5>Schedules</h5> */}
                                                        </Flex>
                                                        <View >
                                                            {alert?.convWaitSchedules &&
                                                                alert.convWaitSchedules?.map(
                                                                    (
                                                                        schedule,
                                                                        index
                                                                    ) => (
                                                                        <Flex
                                                                            key={`main-${index}`}
                                                                            direction={
                                                                                "column"
                                                                            }
                                                                            // marginStart="size-600"
                                                                            UNSAFE_style={{
                                                                                border:
                                                                                    "1px solid #c3c3c3",
                                                                                borderRadius:
                                                                                    "5px",
                                                                                marginBottom:
                                                                                    "5px",
                                                                                    // marginLeft: "202px",
                                                                                padding:
                                                                                    "0px 10px 20px",
                                                                            }}
                                                                        >
                                                                            <Flex
                                                                                direction={
                                                                                    "row"
                                                                                }
                                                                                marginTop="size-100"
                                                                                gap={
                                                                                    "size-400"
                                                                                }
                                                                            >
                                                                                <Text
                                                                                    width={
                                                                                        "size-2000"
                                                                                    }
                                                                                    UNSAFE_style={
                                                                                        keyTextTertiaryCSS
                                                                                    }
                                                                                >
                                                                                    Days
                                                                                </Text>
                                                                                <Flex
                                                                                    direction={
                                                                                        "column"
                                                                                    }
                                                                                >
                                                                                    <Flex
                                                                                        direction={
                                                                                            "row"
                                                                                        }
                                                                                        justifyContent="space-between"
                                                                                    >
                                                                                        {alert
                                                                                            ?.convWaitSchedules
                                                                                            ?.length &&
                                                                                            props.isUpdate && (
                                                                                                <DialogTrigger>
                                                                                                    <ActionButton aria-label="Delete">
                                                                                                        <Delete />
                                                                                                    </ActionButton>
                                                                                                    <AlertDialog
                                                                                                        variant="confirmation"
                                                                                                        title="Delete record?"
                                                                                                        primaryActionLabel="Confirm"
                                                                                                        cancelLabel="Cancel"
                                                                                                    >
                                                                                                        Are
                                                                                                        you
                                                                                                        sure
                                                                                                        you
                                                                                                        want
                                                                                                        to
                                                                                                        delete
                                                                                                        this
                                                                                                        record?
                                                                                                    </AlertDialog>
                                                                                                </DialogTrigger>
                                                                                            )}
                                                                                    </Flex>
                                                                                    <CheckboxGroup
                                                                                        defaultValue={[]}
                                                                                        value={
                                                                                            schedule.days &&
                                                                                            schedule.days.split(
                                                                                                ","
                                                                                            )
                                                                                        }
                                                                                        isDisabled={
                                                                                            schedule.days &&
                                                                                            schedule.days.toLowerCase() ===
                                                                                                "all"
                                                                                        }
                                                                                        orientation="horizontal"
                                                                                        id={`day-${index}`}
                                                                                    >
                                                                                        <Checkbox
                                                                                            id={`monday-${index}`}
                                                                                            value="MONDAY"
                                                                                        >
                                                                                            Mon
                                                                                        </Checkbox>
                                                                                        <Checkbox
                                                                                            id={`tuesday-${index}`}
                                                                                            value="TUESDAY"
                                                                                        >
                                                                                            Tue
                                                                                        </Checkbox>
                                                                                        <Checkbox
                                                                                            id={`wensday-${index}`}
                                                                                            value="WEDNESDAY"
                                                                                        >
                                                                                            Wed
                                                                                        </Checkbox>
                                                                                        <Checkbox
                                                                                            id={`thursday-${index}`}
                                                                                            value="THURSDAY"
                                                                                        >
                                                                                            Thu
                                                                                        </Checkbox>
                                                                                        <Checkbox
                                                                                            id={`friday-${index}`}
                                                                                            value="FRIDAY"
                                                                                        >
                                                                                            Fri
                                                                                        </Checkbox>
                                                                                        <Checkbox
                                                                                            id={`sat-${index}`}
                                                                                            value="SATURDAY"
                                                                                        >
                                                                                            Sat
                                                                                        </Checkbox>
                                                                                        <Checkbox
                                                                                            id={`sunday-${index}`}
                                                                                            value="SUNDAY"
                                                                                        >
                                                                                            Sun
                                                                                        </Checkbox>
                                                                                    </CheckboxGroup>
                                                                                </Flex>
                                                                            </Flex>
                                                                            <Flex
                                                                                direction={
                                                                                    "row"
                                                                                }
                                                                                rowGap="size-800"
                                                                                gap={
                                                                                    "size-400"
                                                                                }
                                                                            >
                                                                                <Text
                                                                                    width={
                                                                                        "size-2000"
                                                                                    }
                                                                                    UNSAFE_style={
                                                                                        keyTextTertiaryCSS
                                                                                    }
                                                                                >
                                                                                    Start
                                                                                    Time
                                                                                </Text>
                                                                                <Flex
                                                                                    direction={
                                                                                        "column"
                                                                                    }
                                                                                >
                                                                                    <Checkbox
                                                                                        isSelected={
                                                                                            schedule.startTime.toLowerCase() ===
                                                                                            "all"
                                                                                        }
                                                                                        aria-label="allday"
                                                                                    >
                                                                                        All
                                                                                        Day
                                                                                    </Checkbox>
                                                                                    <TimeField
                                                                                        value={
                                                                                            new Time(
                                                                                                schedule.startTime.split(
                                                                                                    ":"
                                                                                                )[0],
                                                                                                schedule.startTime.split(
                                                                                                    ":"
                                                                                                )[1],
                                                                                                schedule.startTime.split(
                                                                                                    ":"
                                                                                                )[2]
                                                                                            )
                                                                                        }
                                                                                        isDisabled={
                                                                                            schedule.startTime.toLowerCase() ===
                                                                                            "all"
                                                                                        }
                                                                                        granularity="second"
                                                                                        hourCycle={
                                                                                            24
                                                                                        }
                                                                                        aria-label="time"
                                                                                        isQuiet
                                                                                    />
                                                                                </Flex>
                                                                            </Flex>
                                                                            <Flex
                                                                                direction={
                                                                                    "row"
                                                                                }
                                                                                gap={
                                                                                    "size-400"
                                                                                }
                                                                            >
                                                                                <Text
                                                                                    width={
                                                                                        "size-2000"
                                                                                    }
                                                                                    UNSAFE_style={
                                                                                        keyTextTertiaryCSS
                                                                                    }
                                                                                >
                                                                                    End
                                                                                    Time
                                                                                </Text>
                                                                                <TimeField
                                                                                    value={
                                                                                        new Time(
                                                                                            schedule.endTime.split(
                                                                                                ":"
                                                                                            )[0],
                                                                                            schedule.endTime.split(
                                                                                                ":"
                                                                                            )[1],
                                                                                            schedule.endTime.split(
                                                                                                ":"
                                                                                            )[2]
                                                                                        )
                                                                                    }
                                                                                    isDisabled={
                                                                                        schedule.startTime.toLowerCase() ===
                                                                                        "all"
                                                                                    }
                                                                                    hourCycle={
                                                                                        24
                                                                                    }
                                                                                    aria-label="endtime"
                                                                                    granularity="second"
                                                                                    isQuiet
                                                                                />
                                                                            </Flex>
                                                                            <Flex
                                                                                direction={
                                                                                    "row"
                                                                                }
                                                                                gap={
                                                                                    "size-400"
                                                                                }
                                                                            >
                                                                                <Text
                                                                                    width={
                                                                                        "size-2000"
                                                                                    }
                                                                                    UNSAFE_style={
                                                                                        keyTextTertiaryCSS
                                                                                    }
                                                                                >
                                                                                    Timezone
                                                                                    <Text
                                                                                        UNSAFE_style={
                                                                                            keyTextTertiaryCSS
                                                                                        }
                                                                                    >
                                                                                        *
                                                                                    </Text>
                                                                                </Text>
                                                                                <Text>
                                                                                    {" "}
                                                                                    <h5 className="schedule-label">
                                                                                        {
                                                                                            schedule.timezone
                                                                                        }
                                                                                    </h5>
                                                                                </Text>
                                                                            </Flex>
                                                                            <Flex
                                                                                direction={
                                                                                    "row"
                                                                                }
                                                                                gap={
                                                                                    "size-400"
                                                                                }
                                                                            >
                                                                                <Text
                                                                                    width={
                                                                                        "size-2000"
                                                                                    }
                                                                                    UNSAFE_style={
                                                                                        keyTextTertiaryCSS
                                                                                    }
                                                                                >
                                                                                    Schedule
                                                                                    condition
                                                                                    <Text
                                                                                        UNSAFE_style={
                                                                                            keyTextTertiaryCSS
                                                                                        }
                                                                                    >
                                                                                        *
                                                                                    </Text>
                                                                                </Text>
                                                                                <NumberField
                                                                                    minValue={
                                                                                        0
                                                                                    }
                                                                                    maxValue={
                                                                                        600
                                                                                    }
                                                                                    value={
                                                                                        schedule.convWaitConditions
                                                                                    }
                                                                                    aria-label="threshold"
                                                                                    height="0px"
                                                                                    width="size-3000"
                                                                                    formatOptions={{
                                                                                        style:
                                                                                            "unit",
                                                                                        unit:
                                                                                            "second",
                                                                                    }}
                                                                                    isQuiet
                                                                                    isReadOnly
                                                                                />
                                                                            </Flex>

                                                                            {/* {queue.alertTypes
                                                        .convWaitSchedules
                                                        .length > 1 &&
                                                        queue.alertTypes
                                                            .convWaitSchedules
                                                            .length !=
                                                            index + 1 && (
                                                            <Divider size="M" />
                                                        )} */}
                                                                        </Flex>
                                                                    )
                                                                )}
                                                        </View>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        )}
                                    </View>
                                )
                            )}
                    </Flex>
                    <Flex direction={"column"}>
                        <Flex direction={"row"} alignContent={"start"}>
                            <View minWidth="250px">
                                <Checkbox
                                    UNSAFE_className="checkBoxLabelBeforeHelp"
                                    isSelected={
                                        props.queue.alertTypes.onQueueUsers
                                    }
                                    aria-label="onQueueAgent"
                                >
                                    <Text UNSAFE_style={keyTextCSS}>
                                        OnQueue Agent{" "}
                                    </Text>
                                </Checkbox>
                                <ContextualHelp
                                    UNSAFE_className="helpContextIcon"
                                    variant="help"
                                >
                                    <Heading>OnQueueAgent</Heading>
                                    <Content>
                                        <Text>
                                            To receive notifications if minimum
                                            number of agents (mentioned in
                                            Available Agent Threshold below) are
                                            not available to take call in the
                                            selected queue.
                                        </Text>
                                    </Content>
                                </ContextualHelp>
                            </View>
                            <Flex justifyContent={"space-between"}>
                                {props.queue.alertTypes.onQueueUsers ? (
                                    <AlertOptions
                                        email={
                                            queue.alertTypes
                                                .onQueueUsersAlertOptions?.email
                                        }
                                        slack={
                                            queue.alertTypes
                                                .onQueueUsersAlertOptions?.slack
                                        }
                                        onEmailChange={() => {}}
                                        onSlackChange={() => {}}
                                    />
                                ) : (
                                    <View
                                        UNSAFE_style={{
                                            visibility: "hidden",
                                            width: "132px",
                                        }}
                                    ></View>
                                )}
                                <Button
                                    variant="primary"
                                    UNSAFE_className="cursor"
                                    onClick={() =>
                                        setShowOnQueueDetails(
                                            !showOnQueueDetails
                                        )
                                    }
                                >
                                    {showOnQueueDetails ? (
                                        <ChevronUp UNSAFE_className="noBorder cursor" />
                                    ) : (
                                        <ChevronDown UNSAFE_className="noBorder cursor" />
                                    )}
                                </Button>
                            </Flex>
                        </Flex>
                        {showOnQueueDetails &&
                            props.queue.alertTypes.onQueueUsers && (
                                <Flex
                                    direction={"column"}
                                    // marginStart="size-250"
                                >
                                    {/* <h5>Schedules</h5> */}
                                    <View>
                                        {props.queue.alertTypes.userThresholdSchedules.map(
                                            (schedule, index) => (
                                                <Flex
                                                    key={`${props.queue.queueId}-${index}`}
                                                    direction={"column"}
                                                    marginStart="250px"
                                                    UNSAFE_style={{
                                                        // width: "75%",
                                                        border:
                                                            "1px solid #c3c3c3",
                                                        padding: "10px",
                                                        borderRadius: "5px",
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    {/* <View > */}
                                                    <Flex direction={"row"}>
                                                        <Text
                                                            width={"size-2400"}
                                                        >
                                                            <h5 className="schedule-label">
                                                                Days
                                                            </h5>
                                                        </Text>
                                                        <Flex
                                                            direction={"column"}
                                                        >
                                                            <Checkbox
                                                                isSelected={
                                                                    schedule.days &&
                                                                    schedule.days.toLowerCase() ===
                                                                        "all"
                                                                }
                                                                isDisabled={
                                                                    schedule.days &&
                                                                    schedule.days.toLowerCase() !==
                                                                        "all"
                                                                }
                                                                aria-label="All"
                                                            >
                                                                All
                                                            </Checkbox>
                                                            <CheckboxGroup
                                                                defaultValue={[]}
                                                                value={
                                                                    schedule.days &&
                                                                    schedule.days.split(
                                                                        ","
                                                                    )
                                                                }
                                                                isDisabled={
                                                                    schedule.days &&
                                                                    schedule.days.toLowerCase() ===
                                                                        "all"
                                                                }
                                                                orientation="horizontal"
                                                                aria-label="SelectDay"
                                                            >
                                                                <Checkbox
                                                                    aria-label="SelectDay"
                                                                    value="MONDAY"
                                                                    isDisabled
                                                                >
                                                                    Mon
                                                                </Checkbox>
                                                                <Checkbox
                                                                    aria-label="SelectDay"
                                                                    value="TUESDAY"
                                                                    isDisabled
                                                                >
                                                                    Tue
                                                                </Checkbox>
                                                                <Checkbox
                                                                    aria-label="SelectDay"
                                                                    value="WEDNESDAY"
                                                                    isDisabled
                                                                >
                                                                    Wed
                                                                </Checkbox>
                                                                <Checkbox
                                                                    aria-label="SelectDay"
                                                                    value="THURSDAY"
                                                                    isDisabled
                                                                >
                                                                    Thu
                                                                </Checkbox>
                                                                <Checkbox
                                                                    aria-label="SelectDay"
                                                                    value="FRIDAY"
                                                                    isDisabled
                                                                >
                                                                    Fri
                                                                </Checkbox>
                                                                <Checkbox
                                                                    aria-label="SelectDay"
                                                                    value="SATURDAY"
                                                                    isDisabled
                                                                >
                                                                    Sat
                                                                </Checkbox>
                                                                <Checkbox
                                                                    aria-label="SelectDay"
                                                                    value="SUNDAY"
                                                                    isDisabled
                                                                >
                                                                    Sun
                                                                </Checkbox>
                                                            </CheckboxGroup>
                                                        </Flex>
                                                    </Flex>
                                                    <Flex
                                                        direction={"row"}
                                                        rowGap="size-800"
                                                    >
                                                        <Text
                                                            width={"size-2400"}
                                                        >
                                                            <h5 className="schedule-label">
                                                                Start Time
                                                            </h5>
                                                        </Text>
                                                        <Flex
                                                            direction={"column"}
                                                        >
                                                            <Checkbox
                                                                isSelected={
                                                                    schedule.startTime.toLowerCase() ===
                                                                    "all"
                                                                }
                                                                aria-label="starttime"
                                                                isDisabled
                                                            >
                                                                All Day
                                                            </Checkbox>
                                                            <TimeField
                                                                value={
                                                                    new Time(
                                                                        schedule.startTime.split(
                                                                            ":"
                                                                        )[0],
                                                                        schedule.startTime.split(
                                                                            ":"
                                                                        )[1],
                                                                        schedule.startTime.split(
                                                                            ":"
                                                                        )[2]
                                                                    )
                                                                }
                                                                aria-label="starttime"
                                                                isDisabled
                                                                granularity="second"
                                                                hourCycle={24}
                                                                isQuiet
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                    <Flex direction={"row"}>
                                                        <Text
                                                            width={"size-2400"}
                                                        >
                                                            <h5 className="schedule-label">
                                                                End Time
                                                            </h5>
                                                        </Text>
                                                        <TimeField
                                                            value={
                                                                new Time(
                                                                    schedule.endTime.split(
                                                                        ":"
                                                                    )[0],
                                                                    schedule.endTime.split(
                                                                        ":"
                                                                    )[1],
                                                                    schedule.endTime.split(
                                                                        ":"
                                                                    )[2]
                                                                )
                                                            }
                                                            aria-label="endtime"
                                                            granularity="second"
                                                            isDisabled
                                                            hourCycle={24}
                                                            isQuiet
                                                        />
                                                    </Flex>
                                                    <Flex direction={"row"}>
                                                        <Text
                                                            width={"size-2400"}
                                                        >
                                                            <h5 className="schedule-label">
                                                                Timezone
                                                            </h5>
                                                        </Text>
                                                        <Text>
                                                            {" "}
                                                            <h5 className="schedule-label">
                                                                {
                                                                    schedule.timezone
                                                                }
                                                            </h5>
                                                        </Text>
                                                    </Flex>
                                                    <Flex direction={"row"}>
                                                        <Flex
                                                            alignItems={
                                                                "center"
                                                            }
                                                            direction={"row"}
                                                            width={"size-2400"}
                                                        >
                                                            <Text>
                                                                <h5 className="schedule-label">
                                                                    Agent
                                                                    Threshold{" "}
                                                                </h5>
                                                            </Text>
                                                            <ContextualHelp variant="help">
                                                                <Heading>
                                                                    Agent
                                                                    Threshold
                                                                </Heading>
                                                                <Content>
                                                                    <Text>
                                                                        Occurred
                                                                        notification
                                                                        will be
                                                                        triggered
                                                                        if this
                                                                        Threshold
                                                                        is
                                                                        matched
                                                                        and
                                                                        Resolved
                                                                        notification
                                                                        is
                                                                        triggered
                                                                        once
                                                                        available
                                                                        agent
                                                                        count go
                                                                        beyond
                                                                        this
                                                                        threshold.
                                                                    </Text>
                                                                </Content>
                                                            </ContextualHelp>
                                                        </Flex>
                                                        <Text>
                                                            <h5 className="schedule-label">
                                                                {" "}
                                                                {
                                                                    schedule.onQueueUserThreshold
                                                                }
                                                            </h5>
                                                        </Text>
                                                    </Flex>
                                                    {props.queue.alertTypes
                                                        .userThresholdSchedules
                                                        .length > 1 &&
                                                        props.queue.alertTypes
                                                            .userThresholdSchedules
                                                            .length !=
                                                            index + 1 && (
                                                            <Divider size="M" />
                                                        )}
                                                </Flex>
                                            )
                                        )}
                                    </View>
                                </Flex>
                            )}
                        <Flex>
                            {editable && (
                                <Button
                                    onPress={() => updateQueue(props.queue)}
                                >
                                    Update
                                </Button>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </View>
        </>
    )
}

export default connect(mapStateToProps)(MonitorQueueList)
