import React, { useEffect, useState } from "react"
import {
    Button,
    Flex,
    Content,
    Heading,
    ActionButton,
    DialogTrigger,
    Dialog,
    Divider,
    Footer,
    View,
    ButtonGroup,
    Checkbox,
    Item,
    Picker,
    Well,
    MenuTrigger,
    Menu,
    Text,
    ComboBox,
    SearchField,
    NumberField,
    Badge,
} from "@adobe/react-spectrum"
import Add from "@spectrum-icons/workflow/Add"
import Delete from "@spectrum-icons/workflow/Delete"
import ChevronDown from "@spectrum-icons/workflow/ChevronDown"
import CloseCircle from "@spectrum-icons/workflow/CloseCircle"
import InfoOutline from "@spectrum-icons/workflow/InfoOutline"
import {getQueueList , getProficiencyList } from "../../../utilities/api/phoenix-api"

const EnforceUpdate = (props) => {
    const [configKey, setConfigKey] = useState()
    const [action, setAction] = useState()
    const [selectedGroup, setSelectedGroup] = useState()
    const [users, setUsers] = useState()
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false)
    // const [fields, setFields] = useState([{ configKey: "", action: "" }])
    const [selectedUser, setSelectedUser] = useState(new Set())
    const [alreadyExist, setAlreadyExist] = useState(false)
    const singleProficiency = {
        attributeName: "",
        attributeValue: "",
        level: "1",
    }
    const singleQueue = {
        queue_name: "",
        channel: "",
        priority: "1",
        delay: "0",
    }
    const [proficiencies, setProficiencies] = useState([singleProficiency])
    const [queues, setQueues] = useState([singleQueue])
    const [attributeList, setAttributeList] = useState([])
    const [queueOfGroup, setQueueOfGroup] = useState([])

    const attributes = [
        { id: "after_call_work", name: "after_call_work" },
        { id: "queues_assigned", name: "queues_assigned" },
        {
            id: "routing_profile_concurrency",
            name: "routing_profile_concurrency",
        },
        { id: "default_outbound_queue", name: "default_outbound_queue" },
        { id: "proficiency", name: "proficiency" },
    ]

    useEffect(() => {
        if (props.usersLdapWithGroup && selectedGroup) {
            const filteredUsers = props.usersLdapWithGroup
                .filter((ele) => ele.groupName === selectedGroup)
                .map((ele) => ({
                    id: ele.ldap,
                    ldap: ele.ldap,
                    name: ele.name,
                }))
                // setUsers([])
            setUsers(filteredUsers)
        }
    }, [selectedGroup, props.usersLdapWithGroup])
    const [searchTerm, setSearchTerm] = useState("")

    const handleSearchChange = (value) => {
        setSearchTerm(value)
    }

    const filteredUsers = users?.filter((user) =>
        user.name.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        user.ldap.toLowerCase().includes(searchTerm?.toLowerCase()) 
    )

    // useEffect(() => {
    //     if (fields?.length > 0) {
    //         const attributesSelected = fields
    //             .map((field) => field.configKey)
    //             .filter((ele) => ele !== "")

    //         //console.log("attributesSelected ", attributesSelected)

    //         setAttributes((prevAttributes) => {
    //             const result = prevAttributes.filter(
    //                 (ele) => !attributesSelected.includes(ele.name)
    //             )
    //             //console.log("result ", result)
    //             return result
    //         })
    //     }
    // }, [fields])

    useEffect(() => {
        setSelectedUser(new Set())
    }, [selectedGroup])

    useEffect(() => {
        if (selectedGroup) {
          Promise.all([
            getQueueList(selectedGroup),
            getProficiencyList(selectedGroup),
          ])
            .then(([queueRes, proficiencyRes]) => {
              setQueueOfGroup(queueRes?.groupQueues);
              setAttributeList(proficiencyRes?.groupProficiencies);
            })
            .catch((err) => {
              //console.log("Error in fetching group specific data", err);
            });
        }
      }, [selectedGroup, getQueueList, getProficiencyList]);

     const convertArrayToInput = (values = []) => {
        return values.length
            ? values?.map((value, index) => ({ id: index, name: value }))
            : []
    }

    const actionsAll = [
        { id: "sync", name: "Sync" },
    ]
    const actions = [
        { id: "sync", name: "Sync" },
        { id: "add_all", name: "Add All" },
        { id: "remove_all", name: "Remove All" },
        { id: "add_selective", name: "Add Selective" },
        { id: "remove_selective", name: "Remove Selective" },
    ]

    // const checkDuplicacy = (index, value) => {
    //     return fields.some(
    //         (ele, idx) => idx !== index && ele.configKey === value
    //     )
    // }

    // const handleFieldChange = (index, name, value) => {
    //     const isExist = checkDuplicacy(index, value)
    //     const updatedFields = [...fields]
    //     if (isExist) {
    //         updatedFields[index][name] = ""
    //         setAlreadyExist(true)
    //         return
    //     }
    //     updatedFields[index][name] = value
    //     setFields(updatedFields)
    //     setAlreadyExist(false)
    // }

    // const addNewField = (index) => {
    //     const updatedFields = [...fields]
    //     updatedFields.splice(index + 1, 0, { configKey: "", action: "" })
    //     setFields(updatedFields)
    // }

    // const handleDeleteField = (index) => {
    //     if (fields.length > 1) {
    //         const updatedFields = [...fields]
    //         updatedFields.splice(index, 1)
    //         setFields(updatedFields)
    //     } else {
    //         //console.log("At least one field is required.")
    //     }
    // }

    useEffect(() => {
        if (users?.length > 0 && users?.length === selectedUser?.size) {
            setIsCheckboxSelected(true)
        } else {
            setIsCheckboxSelected(false)
        }
    }, [users, selectedUser])

    const handleCheckbox = () => {
        if (
            users?.length > 0 &&
            users?.length === selectedUser?.size &&
            !isCheckboxSelected
        ) {
            setIsCheckboxSelected(true)
        } else {
            setIsCheckboxSelected((prev) => !prev)
        }
    }

    const handleSubmit = () => {
        const users = Array.from(selectedUser)
        const formattedData = isCheckboxSelected
            ? {
                  group: selectedGroup,
                  configKey: configKey,
                  action: action,
                  configValues: configKey === "proficiency" ? proficiencies : configKey === "queues_assigned"  ? queues : [],
              }
            : {
                  group: selectedGroup,
                  users: users,
                  configKey: configKey,
                  action: action,
                  configValues:  configKey === "proficiency" ? proficiencies : configKey === "queues_assigned"  ? queues : [],
              }
              
        // //console.log("formattedData ", formattedData);
        props.enforceUpdate(formattedData)
        props.setEnforceUpdate(false)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (alreadyExist) {
                setAlreadyExist(false)
            }
        }, 5000)
        return () => clearTimeout(timer)
    }, [alreadyExist])

    // const checkFields = () => {
    //     // return fields.every((ele) => ele.configKey && ele.action)
    // }

    const handleAddNewField = (index, key) => {
        if (key === "proficiency") {
            const updatedProficiencies = [...proficiencies]
            updatedProficiencies.splice(0, 0, singleProficiency)
            setProficiencies(updatedProficiencies)
        } else if (key === "queues_assigned") {
            const updatedQueues = [...queues]
            updatedQueues.splice(0, 0, singleQueue)
            setQueues(updatedQueues)
        }
    }

    const handleDeleteField = (index, key) => {
        if (key === "proficiency") {
            if (proficiencies.length > 1) {
                const updatedProficiencies = [...proficiencies]
                updatedProficiencies.splice(index, 1)
                setProficiencies(updatedProficiencies)
            } else {
                //console.log("At least one field is required.")
            }
        } else if (key === "queues_assigned") {
            if (queues.length > 1) {
                const updatedQueues = [...queues]
                updatedQueues.splice(index, 1)
                setQueues(updatedQueues)
            } else {
                //console.log("At least one field is required.")
            }
        }
    }

    const handleSelectionChange = (type, index, key, value) => {
        if (type === "proficiency") {
            const updatedProficiencies = [...proficiencies]
            updatedProficiencies[index][key] = value
            setProficiencies(updatedProficiencies)
        } else if (type === "queues_assigned") {
            const updatedQueues = [...queues]
            updatedQueues[index][key] = value
            setQueues(updatedQueues)
        }
    }

    const checkIsDisabled = () => {
        const isProficiencyInvalid = configKey === "proficiency" && (action === "add_selective" || action === "remove_selective") && proficiencies.some((ele) => !ele.attributeName || !ele.attributeValue || !ele.level);
        const isQueuesAssignedInvalid = configKey === "queues_assigned" && (action === "add_selective" || action === "remove_selective") && queues.some((ele) => !ele.queue_name || !ele.channel || !ele.priority || !ele.delay);
        const isGroupOrUserInvalid = (selectedUser?.size === 0 ? !isCheckboxSelected : selectedGroup?.size) || !selectedGroup;
    
        return isGroupOrUserInvalid || !configKey || !action || isProficiencyInvalid || isQueuesAssignedInvalid;
    }


    const renderFields = (configKey, action) => {
        return configKey === "proficiency" && (action === "add_selective" || action === "remove_selective")
            ? proficiencies.map((proficiency, index) => (
                  <Flex key={index} gap={"10px"} marginY={"5px"}>
                      <ComboBox
                          label={index === 0 ? "Attribute Name" : null}
                          items={convertArrayToInput(
                            Object?.keys(
                                attributeList
                            )
                        )}
                          width={"35rem"}
                          selectedKey={proficiency.attributeName}
                          onSelectionChange={(value) =>
                              handleSelectionChange(
                                  "proficiency",
                                  index,
                                  "attributeName",
                                  value
                              )
                          }
                      >
                          {(item) => <Item key={item.name}>{item.name}</Item>}
                      </ComboBox>
                      <ComboBox
                          label={index === 0 ? "Attribute Value" : null}
                          width={"20rem"}
                          items={ proficiency.attributeName !== "" ? convertArrayToInput(attributeList[proficiency.attributeName]) : []}
                          selectedKey={proficiency.attributeValue}
                          onSelectionChange={(value) =>
                              handleSelectionChange(
                                  "proficiency",
                                  index,
                                  "attributeValue",
                                  value
                              )
                          }
                      >
                          {(item) => <Item key={item.name}>{item.name}</Item>}
                      </ComboBox>
                     {action !== "remove_selective" && <NumberField
                          label={index === 0 ? "Level" : null}
                          width={"8rem"}
                          value={proficiency.level}
                          onChange={(value) =>
                              handleSelectionChange(
                                  "proficiency",
                                  index,
                                  "level",
                                  value
                              )
                          }
                      />}

                      <View
                          UNSAFE_style={{
                              marginTop: index === 0 ? "28px" : null,
                              flexBasis: "20%",
                          }}
                      >
                          <Button
                              aria-label="deleteItem"
                              UNSAFE_className="cursor"
                              variant="secondary"
                              staticColor="black"
                              onPress={() =>
                                  handleDeleteField(index, "proficiency")
                              }
                          >
                              <Delete />
                          </Button>
                          {index == 0 && (
                              <Button
                                  aria-label="addItem"
                                  UNSAFE_className="cursor"
                                  variant="secondary"
                                  staticColor="black"
                                  marginStart={"5px"}
                                  onPress={() =>
                                      handleAddNewField(index, "proficiency")
                                  }
                              >
                                  <Add />
                              </Button>
                          )}
                      </View>
                  </Flex>
              ))
            : configKey === "queues_assigned" && (action === "add_selective" || action === "remove_selective")
            ? queues.map((queue, index) => (
                  <Flex key={index} gap={"10px"} marginY={"5px"}>
                      <ComboBox
                          label={index === 0 ? "Queue Name" : null}
                          defaultItems={queueOfGroup}
                          width={"35rem"}
                          selectedKey={queue.queue_name}
                          onSelectionChange={(value) =>
                              handleSelectionChange(
                                  "queues_assigned",
                                  index,
                                  "queue_name",
                                  value
                              )
                          }
                      >
                          {(item) => <Item key={item.name}>{item.name}</Item>}
                      </ComboBox>
                      <ComboBox
                          label={index === 0 ? "Channel" : null}
                          defaultItems={props?.channels}
                          width={"20rem"}
                          selectedKey={queue.channel}
                          onSelectionChange={(value) =>
                              handleSelectionChange(
                                  "queues_assigned",
                                  index,
                                  "channel",
                                  value
                              )
                          }
                      >
                          {(item) => <Item key={item.name}>{item.name}</Item>}
                      </ComboBox>
                     {action !== "remove_selective" && 
                     <NumberField
                          label={index === 0 ? "Priority" : null}
                          width={"8rem"}
                          minValue={1}
                          value={queue.priority}
                          onChange={(value) =>
                              handleSelectionChange(
                                  "queues_assigned",
                                  index,
                                  "priority",
                                  value
                              )
                          }
                      />}
                     {action !== "remove_selective" &&
                      <NumberField
                          label={index === 0 ? "Delay" : null}
                          width={"8rem"}
                          minValue={0}
                          value={queue.delay}
                          onChange={(value) =>
                              handleSelectionChange(
                                  "queues_assigned",
                                  index,
                                  "delay",
                                  value
                              )
                          }
                      />}

                      <View
                          UNSAFE_style={{
                              marginTop: index === 0 ? "28px" : null,
                              flexBasis: "20%",
                          }}
                      >
                          <Button
                              aria-label="deleteItem"
                              UNSAFE_className="cursor"
                              variant="secondary"
                              staticColor="black"
                              onPress={() =>
                                  handleDeleteField(index, "queues_assigned")
                              }
                          >
                              <Delete />
                          </Button>
                          {index == 0 && (
                              <Button
                                  aria-label="addItem"
                                  UNSAFE_className="cursor"
                                  variant="secondary"
                                  staticColor="black"
                                  marginStart={"5px"}
                                  onPress={() =>
                                      handleAddNewField(
                                          index,
                                          "queues_assigned"
                                      )
                                  }
                              >
                                  <Add />
                              </Button>
                          )}
                      </View>
                  </Flex>
              ))
            : null
    }

    const generateBadgesFromList = (selectedUserArray) => {
        if (selectedUserArray.length <= 4) {
            return selectedUserArray.map((val, index) => (
                <Badge
                    key={index}
                    variant="neutral"
                    UNSAFE_style={{
                        margin: "2px 2px",
                        background: "rgb(102, 102, 102)",
                        color: "white",
                        padding: "3px 0px 0px 10px",
                        borderRadius: "4px",
                        height: "30px",
                        display: "block",
                    }}
                >
                    <Text UNSAFE_style={{ padding: "0px", fontSize: "14px" }}>
                        {val}
                    </Text>
                    <Button
                        style="fill"
                        UNSAFE_style={{
                            marginTop: "-4px",
                            cursor: "pointer",
                            transform: "scale(0.7)",
                        }}
                        onPress={() => removeUser(val)}
                    >
                        <CloseCircle aria-label="Done" />
                    </Button>
                </Badge>
            ))
        } else {
            return (
                <>
                    {selectedUserArray.slice(0, 4).map((val, index) => (
                        <Badge
                            key={index}
                            variant="neutral"
                            UNSAFE_style={{
                                margin: "2px 2px",
                                background: "rgb(102, 102, 102)",
                                color: "white",
                                padding: "3px 0px 0px 10px",
                                borderRadius: "4px",
                                height: "30px",
                                display: "block",
                            }}
                        >
                            <Text
                                UNSAFE_style={{
                                    padding: "0px",
                                    fontSize: "14px",
                                }}
                            >
                                {val}
                            </Text>
                            <Button
                                style="fill"
                                UNSAFE_style={{
                                    marginTop: "-4px",
                                    cursor: "pointer",
                                    transform: "scale(0.7)",
                                }}
                                onPress={() => removeUser(val)}
                            >
                                <CloseCircle aria-label="Done" />
                            </Button>
                        </Badge>
                    ))}
                    {selectedUserArray.length > 4 && (
                        <DialogTrigger type="popover" placement="right top">
                            <ActionButton
                                height={"30px"}
                                marginTop={"2px"}
                                UNSAFE_style={{ cursor: "pointer" }}
                            >
                                Show All
                            </ActionButton>
                            <Dialog>
                                <Content
                                    height={"auto"}
                                    UNSAFE_style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {selectedUserArray.map((val, index) => (
                                        <Badge
                                            key={index}
                                            variant="neutral"
                                            UNSAFE_style={{
                                                margin: "2px 2px",
                                                background:
                                                    "rgb(102, 102, 102)",
                                                color: "white",
                                                padding: "3px 0px 0px 10px",
                                                borderRadius: "4px",
                                                height: "30px",
                                                display: "block",
                                            }}
                                        >
                                            <Text
                                                UNSAFE_style={{
                                                    padding: "0px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {val}
                                            </Text>
                                            <Button
                                                style="fill"
                                                UNSAFE_style={{
                                                    marginTop: "-4px",
                                                    cursor: "pointer",
                                                    transform: "scale(0.7)",
                                                }}
                                                onPress={() => removeUser(val)}
                                            >
                                                <CloseCircle aria-label="Done" />
                                            </Button>
                                        </Badge>
                                    ))}
                                </Content>
                            </Dialog>
                        </DialogTrigger>
                    )}
                </>
            )
        }
    }

    const removeUser = (ele) => {
        const updatedSelectedUser = new Set(selectedUser)
        updatedSelectedUser.delete(ele)
        setSelectedUser(updatedSelectedUser)
    }

    const sortItemsByName = (items) => {
        return items.slice().sort((a, b) => a?.name.localeCompare(b?.name));
    };

    return (
        <DialogTrigger
            type="modal"
            mobileType="modal"
            isOpen={props.isDialogOpened}
            defaultOpen={false}
        >
            <ActionButton isHidden isQuiet></ActionButton>
            <Dialog size="L" height={"80vh"} width={"65vw"}>
                <Heading>Enforce Update</Heading>
                <Divider />
                <Content>
                    <View>
                        <Flex direction="row" gap={"10px"}>
                            <View>
                                {/* <Text>Groups</Text> */}
                                <ComboBox
                                    defaultItems={sortItemsByName(props?.groups.map(ele => ({name:ele.name})))}
                                    selectedKey={selectedGroup}
                                    onSelectionChange={setSelectedGroup}
                                    placeholder="Select Group"
                                    width={"35rem"}
                                    // defaultSelectedKey={props?.groups[0]?.name}
                                >
                                    {(item) => <Item key={item.name}>{item.name}</Item>}
                                </ComboBox>
                            </View>
                            <Flex flexBasis={"70%"}>
                                {
                                    // !isCheckboxSelected &&
                                    users?.length ? (
                                        <>
                                            <View>
                                                {/* <Text>Users</Text> */}
                                                <MenuTrigger
                                                    closeOnSelect={false}
                                                    direction="bottom"
                                                    width={"20rem"}
                                                >
                                                    <ActionButton
                                                        isDisabled={
                                                            isCheckboxSelected
                                                        }
                                                        width={"20rem"}

                                                        // marginStart={"60px"}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "150px",
                                                                textAlign:
                                                                    "start",
                                                                paddingLeft:
                                                                    "6px",
                                                                color: "#222",
                                                                fontStyle:
                                                                    "italic",
                                                                opacity: isCheckboxSelected
                                                                    ? "0.5"
                                                                    : null,
                                                                cursor: isCheckboxSelected
                                                                    ? "not-allowed"
                                                                    : "initial",
                                                            }}
                                                        >
                                                            Select users
                                                        </div>
                                                        <ChevronDown />
                                                    </ActionButton>
                                                    <div
                                                        style={{
                                                            width: "20rem",
                                                        }}
                                                    >
                                                        <SearchField
                                                            width={"100%"}
                                                            onClear={() =>
                                                                setSearchTerm(
                                                                    ""
                                                                )
                                                            }
                                                            value={searchTerm}
                                                            onChange={
                                                                handleSearchChange
                                                            }
                                                        />
                                                        <Menu
                                                            items={
                                                                filteredUsers
                                                            }
                                                            selectionMode="multiple"
                                                            selectedKeys={
                                                                selectedUser
                                                            }
                                                            onSelectionChange={
                                                                setSelectedUser
                                                            }
                                                            maxHeight={"350px"}
                                                        >
                                                            {(item) => (
                                                                <Item
                                                                    key={
                                                                        item.ldap
                                                                    }
                                                                >
                                                                    {`${item.name} (${item.ldap})`}
                                                                </Item>
                                                            )}
                                                        </Menu>
                                                    </div>
                                                </MenuTrigger>
                                            </View>
                                        </>
                                    ) : 
                                    null
                                }

                                {/* This is just to show the "or" word with horizontal line both sides */}
                                {users?.length > 0 && (
                                    <View
                                        UNSAFE_style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "8%",
                                            height: "85%",
                                            margin: "0px 4px",
                                        }}
                                    >
                                        <View
                                            UNSAFE_style={{
                                                flex: 1,
                                                height: "1px",
                                                backgroundColor: "#d9d9d9",
                                            }}
                                        />
                                        <Text
                                            UNSAFE_style={{
                                                margin: "0 10px",
                                                fontSize: "9px",
                                            }}
                                        >
                                            or
                                        </Text>
                                        <View
                                            UNSAFE_style={{
                                                flex: 1,
                                                height: "1px",
                                                backgroundColor: "#d9d9d9",
                                            }}
                                        />
                                    </View>
                                )}
                                {users?.length > 0 && (
                                    <View>
                                        <Checkbox
                                            isSelected={isCheckboxSelected}
                                            onChange={handleCheckbox}
                                        >
                                            Apply to all Users
                                        </Checkbox>
                                    </View>
                                )}
                            </Flex>
                        </Flex>
                        {users?.length===0 ? <View UNSAFE_style={{fontSize:"12px",fontStyle:"italic",marginTop:"4px"}}>Note : No user is associated with this group</View> : null}

                        {!isCheckboxSelected && selectedUser.size > 0 ? (
                            <Well
                                UNSAFE_style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    maxHeight: "45px",
                                    // height: "100px",
                                    // border:"1px solid red",
                                    // overflowY: "scroll",
                                    width: "100%",
                                    padding: "5px 0px",
                                }}
                            >
                                {selectedUser === "all"
                                    ? "all"
                                    : generateBadgesFromList([...selectedUser])}
                            </Well>
                        ) : null}
                    </View>
                    <Divider size="M" marginY={"15px"} height={"0.1px"} />
                    <View>
                        {alreadyExist && (
                            <View
                                UNSAFE_style={{
                                    color: "red",
                                    marginLeft: "10px",
                                }}
                            >
                                Error : This config key is already selected
                            </View>
                        )}

                        {/* {fields.map((field, index) => (
                            <Flex direction="row" key={index} marginY={"5px"}>
                                <ComboBox
                                    label={index == 0 ? "Config Key" : null}
                                    items={attributes}
                                    marginX={"10px"}
                                    width={"225px"}
                                    selectedKey={field?.configKey}
                                    onSelectionChange={(id) =>
                                        handleFieldChange(
                                            index,
                                            "configKey",
                                            id
                                        )
                                    }
                                >
                                    {(item, index) => (
                                        <Item key={item.name}>
                                          
                                            {item.name}
                                        </Item>
                                    )}
                                </ComboBox>
                                <ComboBox
                                    label={index == 0 ? "Action" : null}
                                    items={
                                        field?.configKey ===
                                            "queues_assigned" ||
                                        field?.configKey === "proficiency"
                                            ? actions
                                            : actionsAll
                                    }
                                    width={"225px"}
                                    selectedKey={field.action}
                                    onSelectionChange={(id) =>
                                        handleFieldChange(index, "action", id)
                                    }
                                >
                                    {(item) => <Item>{item.name}</Item>}
                                </ComboBox>
                                <View>
                                    <Button
                                        aria-label="addKeyValue"
                                        variant="secondary"
                                        staticColor="black"
                                        marginX={"7px"}
                                        style="fill"
                                        marginTop={index == 0 ? "30px" : null}
                                        onPress={() => handleDeleteField(index)}
                                    >
                                        <Delete />
                                    </Button>
                                    {index == fields.length - 1 && (
                                        <Button
                                            aria-label="addKeyValue"
                                            variant="secondary"
                                            staticColor="black"
                                            onPress={() => addNewField(index)}
                                            marginTop={
                                                index == 0 ? "30px" : null
                                            }
                                            isDisabled={!attributes.length}
                                        >
                                            <Add />
                                        </Button>
                                    )}
                                </View>
                            </Flex>
                        ))} */}
                        <Flex direction="row" marginY={"5px"} gap={"10px"}>
                            <ComboBox
                                label={"Config Key"}
                                defaultItems={attributes}
                                width={"35rem"}

                                selectedKey={configKey}
                                onSelectionChange={setConfigKey}
                            >
                                {(item, index) => (
                                    <Item key={item.name}>{item.name}</Item>
                                )}
                            </ComboBox>
                            <ComboBox
                                label={"Action"}
                                defaultItems={
                                    configKey === "queues_assigned" ||
                                    configKey === "proficiency"
                                        ? actions
                                        : actionsAll
                                }
                                width={"20rem"}
                                selectedKey={action}
                                onSelectionChange={setAction}
                            >
                                {(item) => <Item>{item.name}</Item>}
                            </ComboBox>
                        </Flex>
                        <Divider size="M" marginY={"15px"} height={"0.1px"} />
                        <View width={"100%"}>{renderFields(configKey, action)}</View>
                    </View>
                </Content>

                <Footer>
                    <Flex flexBasis={"100%"} direction={"row-reverse"}>
                        <View>
                            <ButtonGroup>
                                <Button
                                    variant="secondary"
                                    onPress={() =>
                                        props.setEnforceUpdate(false)
                                    }
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onPress={handleSubmit}
                                    isDisabled={checkIsDisabled()}
                                >
                                    Submit
                                </Button>
                            </ButtonGroup>
                        </View>
                    </Flex>
                </Footer>
            </Dialog>
        </DialogTrigger>
    )
}

export default EnforceUpdate