import {
    Flex,
    View,
    Checkbox,
    CheckboxGroup,
    Heading,
    DialogTrigger,
    ActionButton,
    Button,
    Item,
    TabList,
    TabPanels,
    Tabs,
    ComboBox,
    Text as SpectrumText,
    TextField,
    Grid,
    Divider,
} from "@adobe/react-spectrum"
import React from "react"
import Add from "@spectrum-icons/workflow/Add"
import styles from "./AddAlert.module.css"

const AddButton = ({ onClick, isEditable , operation}) => {
    return (
        <Button UNSAFE_className={styles.addBtn} style="fill" variant="cta" onPress={onClick} isDisabled={!isEditable && operation !== "add"}>
            <Add UNSAFE_style={{ transform: "scale(1.5)" }} />
            {/* <SpectrumText
                UNSAFE_style={{
                    fontSize: "10px",
                    fontWeight: 400,
                    marginLeft: "0px",
                }}
            >
                {" "}
                Add {title}{" "}
            </SpectrumText> */}
        </Button>
    )
}

export default AddButton
