import React, { useEffect, useState, useRef } from "react"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import "react-schedule-job/dist/index.css"
import { connect } from "react-redux"
import "./style.css"
import DynamicFieldForm from "./DynamicFieldForm"
import DeleteDefaultConfigMapping from "./DeleteDefaultConfigMapping"
import {
    Flex,
    lightTheme,
    Provider,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    ProgressCircle,
    TooltipTrigger,
    Tooltip,
    ActionButton,
    View,
    useAsyncList
} from "@adobe/react-spectrum"

import { getDefaultConfig, deleteDefaultConfig } from "../../../utilities/api/phoenix-api";
import { notify, PheonixRoles } from "../../../constants/constants"
import { renderEmptyState } from "../../../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}
const DefaultConfigMapping = (props) => {
    const [allUsers, setAllUsers] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [allRecords, setAllRecords] = useState();
    const [allAttributes, setAllAttributes] = useState([]);

    const columns = [
        { name: "Config Key", key: "config_key", minWidth: 120 },
        { name: "Config Values", key: "config_values", minWidth: 120 }
    ];

    const columnsAdmin = [
        { name: "Config Key", key: "config_key", minWidth: 120 },
        { name: "Config Values", key: "config_values", minWidth: 120 },
        { name: "Actions", key: "actions" },
    ];

    const getAllDefaultConfig = (ldap) => {
        setIsLoading(true);
        let allAttributes = [];
        getDefaultConfig(ldap).then((resp)=>{
            setAllRecords(resp);
            const mappedData = resp.map((configData)=>{
                const [key] = Object.keys(configData);
                allAttributes = [...allAttributes, key];
                return {config_key: key, config_values:configData[key]}
            });
            setAllAttributes(allAttributes);
            setIsLoading(false);
            setAllUsers(mappedData);
        }).catch((error) => {
            if (error) {
                setIsLoading(false);
            }
        });
    }

    useEffect(() => {
        getAllDefaultConfig(props?.user?.userid);
    }, []);

    const currentUserSelected = useRef("");
    const editData = useRef(false);

    const updateDefaultConfig = (data) => {
        const urlencoded = new URLSearchParams(data);
        setIsLoading(true);
        deleteDefaultConfig(props.user.userid,urlencoded).then((resp)=>{
            notify(resp,'success')
            currentUserSelected.current = "";
            getAllDefaultConfig(props.user.userid);
        }).catch((err)=>{
            if (err) {
                setIsLoading(false);
                notify('Error in deleting','error')
            }
        })
    };

    const closeDeleteDialog = (isDeletedConfirmed) => {
        setIsDeleteDialogOpen(false);
        if (isDeletedConfirmed) {
            let { current } = currentUserSelected
            if (selectedGroup == "all") {
                const updatedConfigValues = allRecords.map((obj) => Object.keys(obj)[0]).join();
                updateDefaultConfig({configKeys:updatedConfigValues});
                setSelectedGroup(new Set());
            } else if (!currentUserSelected.current && selectedGroup.size > 0) {
                const groupToDelete = [...selectedGroup];
                const updatedConfigValues = allRecords.filter((ele,index)=>groupToDelete.includes(`${index}`)).map((obj) => Object.keys(obj)[0]).join();
                updateDefaultConfig({configKeys:updatedConfigValues});
                setSelectedGroup(new Set())
            } else {
                const {config_key} = current;
                updateDefaultConfig({configKeys:config_key});
                current = null;
            }
        }
    }

    const performAction = (value, item) => {
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = allUsers.find(
                    (user) =>
                        user.agent_group_name == item.agent_group_name &&
                        user.config_key == item.config_key
                )
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "delete":
                currentUserSelected.current = item
                setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                setIsDeleteDialogOpen(true)
                break
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) => performAction(value, item)}
                            UNSAFE_className="action-btn"
                        >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "agent_group_name":
                return <Cell>{item[columnKey]}</Cell>
            case "config_key":
                return <Cell>{item[columnKey]}</Cell>
            case "config_values":
                // return <Cell>{JSON.stringify(item[columnKey])}</Cell>
                return (
                    <Cell>
                        <TooltipTrigger delay={0}>
                            <ActionButton
                                width={"100%"}
                                UNSAFE_className="tooltipButton center"
                                isQuiet
                            >
                                {JSON.stringify(item[columnKey])}
                            </ActionButton>
                            <Tooltip>{JSON.stringify(item[columnKey])}</Tooltip>
                        </TooltipTrigger>
                    </Cell>
                )

            default:
                return (
                    <Cell>
                        <TooltipTrigger delay={0}>
                            <ActionButton
                                width={"100%"}
                                UNSAFE_className="tooltipButton"
                                aria-label={columnKey}
                                isQuiet
                            >
                                {item[columnKey]}
                            </ActionButton>
                            <Tooltip>{item[columnKey]}</Tooltip>
                        </TooltipTrigger>
                    </Cell>
                )
        }
    }

    const list = useAsyncList({
        async load() {
            return { items: allUsers }
        },
        async sort({ items, sortDescriptor }) {
            //console.log({items});
            setAllUsers(
                allUsers.slice().sort((a, b) => {
                    let cmp =
                        a[sortDescriptor.column] < b[sortDescriptor.column]
                            ? -1
                            : 1
                    if (sortDescriptor.direction === "descending") {
                        cmp *= -1
                    }
                    return cmp
                })
            )
            return {
                items: allUsers.slice().sort((a, b) => {
                    let cmp =
                        a[sortDescriptor.column] < b[sortDescriptor.column]
                            ? -1
                            : 1
                    if (sortDescriptor.direction === "descending") {
                        cmp *= -1
                    }
                    return cmp
                }),
            }
        },
    })
    return (
        <>
            { isLoading ? <View
                UNSAFE_className={isLoading ? "display-block" : "display-none"}
                UNSAFE_style={{
                    paddingTop: "10%",
                    paddingLeft: "45%",
                    position: "absolute",
                    zIndex: "10",
                }}
            >
                <ProgressCircle
                    aria-label="Loading…"
                    isIndeterminate
                    size="M"
                />
            </View> : null}

            <div align="center">
                <Heading variant="pageTitle">Default Config</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "90vw",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                {props.permissions.includes(PheonixRoles.defaultConfigAdmin) && (
                    <Flex
                    direction="row"
                    gap="size-100"
                    UNSAFE_className="searchMobile"
                >
                    {
                        <ActionGroup
                            width={"100%"}
                            alignItems="end"
                            onAction={(value) => performAction(value, null)}
                            disabledKeys={
                                selectedGroup.size || selectedGroup == "all"
                                    ? []
                                    : ["bulkDelete"]
                            }
                        >
                            <Item key="add">+ Add</Item>
                            <Item key="bulkDelete">
                                <Delete />
                                <Text>Delete</Text>
                            </Item>
                        </ActionGroup>
                    }
                </Flex>
                    )}
                    <Flex width="100%" height="100%" direction="column">
                        <TableView
                            aria-label="spam contacts"
                            selectionMode={props.permissions.includes(PheonixRoles.defaultConfigAdmin)?"multiple":"none"}
                            //   dragAndDropHooks={{}}
                            selectedKeys={selectedGroup}
                            onSelectionChange={setSelectedGroup}
                            renderEmptyState={renderEmptyState}
                            sortDescriptor={list.sortDescriptor}
                            onSortChange={list.sort}
                            height="70vh"
                            overflowMode="wrap"
                        >
                            <TableHeader columns={props.permissions.includes(PheonixRoles.defaultConfigAdmin)?columnsAdmin:columns}>
                                {(column) => (
                                    <Column
                                        allowsResizing
                                        minWidth={column.minWidth}
                                        key={column.key}
                                        allowsSorting={
                                            column.key === "agent_group_name" || column.key === "config_key"
                                        }
                                        align={column.key !== "actions" ? "start" : "end"}
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody>
                                {allUsers.map((item, index) => (
                                    <Row key={index}>
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                ))}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Provider>
            </div>

            {isdeleteDialogOpen && (
                <DeleteDefaultConfigMapping
                    isDialogOpened={isdeleteDialogOpen}
                    handleCloseDialog={(isDeletedConfirmed) =>
                        closeDeleteDialog(isDeletedConfirmed)
                    }
                />
            )}

            {isAddDialogOpen && (
                <DynamicFieldForm
                    isDialogOpened={isAddDialogOpen}
                    setIsAddDialogOpen={setIsAddDialogOpen}
                    userid={props.user.userid}
                    editData={editData?.current}
                    title={allRecords?.configKey}
                    allAttributes={allAttributes}
                    operation={operation}
                    allRecords={allRecords}
                    getAllLdapMethod={() => getAllDefaultConfig()}
                />
            )}
        </>
    )
}

export default connect(mapStateToProps)(DefaultConfigMapping)
