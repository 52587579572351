import React, { useState, useEffect } from "react"
import {
    Provider,
    lightTheme,
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    Heading,
    Content,
    ButtonGroup,
    ComboBox,
    View,
    Flex,
    Item,
    NumberField,
} from "@adobe/react-spectrum"

const BulkEditGroup = (props) => {
    const [allGroupsList, setAllGroupList] = useState([])
    const [groupName, setSelectedGroupName] = useState("")
    //console.log(" props?.groupList ", props?.groupList)

    useEffect(() => {
        setAllGroupList(
            props.groupList.map((ele) => ({
                id: ele,
                name: ele,
            }))
        )
    }, [props.groupList, setAllGroupList])

   const handleSubmit = () =>{
    //console.log("submit clicked");
    const data = {
        users: props.selectedAgents,
        configKey: "group_change",
        configValues: [{groupName : groupName}],
    }
    // setRows([{ queue_name: "", id: "", channel: "", delay: "0", priority: "1" }]);
    props.handleCloseDialog(true, data)
   }

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog width={"500px"} height={"300px"}>
                        <Heading>Bulk Change Group</Heading>
                        <Divider />
                        <Content>
                            <Flex
                                direction={"column"}
                                // maxHeight={"60vh"}
                                // height={"25vh"}
                                // UNSAFE_className="boxInside"
                            >
                                {
                                    <Flex
                                        key={groupName}
                                        direction={"column"}
                                        justifyContent={"center"}
                                        gap={"size-100"}
                                        marginTop={"size-100"}
                                    >
                                        <View UNSAFE_className="feild">
                                            <Flex>
                                                <View width={"90%"}>
                                                    <ComboBox
                                                        label={"Group Name"}
                                                        marginX={"5px"}
                                                        defaultItems={
                                                            allGroupsList
                                                        }
                                                        selectedKey={groupName}
                                                        width={"300px"}
                                                        onSelectionChange={
                                                            setSelectedGroupName
                                                        }
                                                    >
                                                        {(item) => (
                                                            <Item key={item.id}>
                                                                {item.name}
                                                            </Item>
                                                        )}
                                                    </ComboBox>
                                                </View>
                                            </Flex>
                                        </View>
                                    </Flex>
                                }
                            </Flex>
                        </Content>
                        <ButtonGroup>
                            <Button
                                variant="secondary"
                                onPress={props.handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onPress={handleSubmit}
                                isDisabled={!groupName}
                            >
                                Add / Update
                            </Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default BulkEditGroup
