import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './common/App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const container = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>,
    container
);
