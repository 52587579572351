import React, { useState, useEffect } from "react"
import {
    Provider,
    lightTheme,
    Dialog,
    Button,
    ActionButton,
    DialogTrigger,
    Divider,
    Heading,
    Content,
    ButtonGroup,
    Form,
    TextField,
    View,
    ComboBox,
    Item
} from "@adobe/react-spectrum"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import "./style.css"

const AddHolidaySchedule = (props) => {
    const [name, setName] = useState("")
    const [language, setLanguage] = useState("")
    const [date, setDate] = useState("")
    const [tz, setTz] = useState("America/Los_Angles")

    const envOptions = [
        {id:'Asia/Shanghai',name:'Asia/Shanghai'},
        {id:'Asia/Tokyo',name:'Asia/Tokyo'},
        {id:'Asia/Seoul',name:'Asia/Seoul'},
        {id:'Europe/Lisbon',name:'Europe/Lisbon'},
        {id:'Europe/Madrid',name:'Europe/Madrid'},
        {id:'America/Los_Angeles',name:'America/Los_Angeles'},
    ]

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        return [year, month, day].join("-")
    }

    useEffect(() => {
        if (props.editData) {
            setName(props.editData.name)
            setLanguage(props.editData.language)
            setDate(new Date(props.editData.date))
            setTz(props.editData.tz)
        }
    }, [])

    const onCloseHandler = () => {
        props.handleCloseDialog(false)
    }

    const onSaveHandler = () => {
        const newDate = formatDate(date)
        props.handleCloseDialog(true, language, name, newDate, tz)
    }

    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="M">
                        <Heading>{`${props.operation} Holiday Schedule`}</Heading>
                        <Divider />
                        <Content>
                            <Form isRequired>
                                <View UNSAFE_style={{ display: "flex",justifyContent:"space-around" }}>
                                    <View>
                                        <View>Language</View>
                                        <TextField
                                            autoFocus
                                            value={language}
                                            onChange={setLanguage}
                                            // isQuiet
                                            isDisabled={props.editData}
                                            width={"100%"}
                                            marginTop={"5px"}
                                        />
                                    </View>
                                    <View UNSAFE_style={{marginTop:'5px'}}>
                                        <View>Date</View>
                                        <DatePicker
                                            className={props.editData ? "datepickerDisabled" : "datepicker"}
                                            selected={date}
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(date) => setDate(date)}
                                            disabled={props.editData}
                                            
                                        />
                                    </View>
                                </View>

                                <div style={{ marginTop: "30px" }}></div>
                                <View UNSAFE_style={{ display: "flex",justifyContent:"space-around",marginLeft:"2px" }}>
                                    <View>
                                        <View>Name</View>
                                        <TextField
                                            value={name}
                                            onChange={setName}
                                            // isQuiet
                                            width={"100%"}
                                            marginTop={"5px"}


                                        />
                                    </View>
                                    <View>
                                        <View marginStart={"6px"}>Timezone</View>
                                        <ComboBox
                                         defaultItems={envOptions}
                                         selectedKey={tz}
                                         labelAlign	="start"
                                         marginTop={"5px"}
                                         marginStart={"5px"}
                                         onSelectionChange={selected => setTz(selected)}>
                                         {item => <Item>{item.name}</Item>}
                                         </ComboBox>
                                    </View>
                                </View>
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>
                                Cancel
                            </Button>
                            <Button variant="primary" onPress={onSaveHandler}>
                                Save
                            </Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default AddHolidaySchedule
