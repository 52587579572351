import {
    Flex,
    View,
    Content,
    Heading,
    Button,
    SearchField,
    IllustratedMessage,
    ProgressCircle,
} from "@adobe/react-spectrum"
import React, { useEffect, useState } from "react"
import { Time } from "@internationalized/date"
import {
    deleteBUAlert,
    getAllMonitoredBU,
    updateAlert,
    getMonitoringBUNew,
} from "../utilities/api/alertService-api"
import { connect } from "react-redux"
// import AlertOptions from "./AlertOptions"
import QueueCard from "./QueueCard"
import AddAlert from "./AddAlert"
import DeleteConfirmDialog from "./DeleteConfirmDialog"

import { ALERT_TYPES, notify } from "../constants/constants"
import styles from "./AlertServiceList.module.css"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
        token: state.login.token,
        rolesAndPermissions: state.login.rolesAndPermissions,
    }
}

const BuAlertList = ({ user, rolesAndPermissions }) => {
    const buHavingAddPermissions = rolesAndPermissions["ALERT"]?.add?.filter(
        (ele) => ele.subService === "OTHERS MONITORING"
    )[0]?.resources.bu
    const buHavingDeletePermissions = rolesAndPermissions[
        "ALERT"
    ]?.delete?.filter((ele) => ele.subService === "OTHERS MONITORING")[0]
        ?.resources.bu
    const buHavingViewPermissions = rolesAndPermissions["ALERT"]?.view?.filter(
        (ele) => ele.subService === "OTHERS MONITORING"
    )[0]?.resources.bu
    const buHavingEditPermissions = rolesAndPermissions["ALERT"]?.edit?.filter(
        (ele) => ele.subService === "OTHERS MONITORING"
    )[0]?.resources.bu

    const combinedPermissions = Array.from(
        new Set([
            // ...(buHavingAddPermissions || []),
            ...(buHavingDeletePermissions || []),
            ...(buHavingViewPermissions || []),
            ...(buHavingEditPermissions || []),
        ])
    )
    // console.log("combinedPermissions", combinedPermissions)
    // console.log("buHavingAddPermissions", buHavingAddPermissions)
    // console.log("buHavingDeletePermissions", buHavingDeletePermissions)
    // console.log("buHavingViewPermissions", buHavingViewPermissions)
    // console.log("buHavingEditPermissions", buHavingEditPermissions)


    const userLdap = user?.userid

    /* Permissions to change */
    // const adminPermission = props.permissions.includes("Alert Service Admin")
    const adminPermission = true
    const [isLoading, setIsLoading] = useState(false)
    const [queueList, setQueueList] = useState([])
    const [allQueues, setAllQueues] = useState([])
    const [filteredQueues, setFilteredQueues] = useState([])
    const [currentText, setCurrentText] = useState("")
    const [operation, setOperation] = useState()
    const [isDialogOpened, setIsDialogOpened] = useState(false)
    const [selectedAlert, setSelectedAlert] = useState([])
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [queueToDelete, setQueueToDelete] = useState(null)
    const [isEditable, setIsEditable] = useState(false)


    useEffect(() => {
        fetchMonitoringQueueList()
        getAllBUs()
    }, [])

    const compareQueueNames = (a, b) => {
        if (a.queueName < b.queueName) {
            return -1
        }
        if (a.queueName > b.queueName) {
            return 1
        }
        return 0
    }

    const compareQueueList = (a, b) => {
        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }
        return 0
    }

    const getAllBUs = async () => {
        try {
            const resp = await getMonitoringBUNew()
            // const data = resp.sort(compareQueueList)
            setQueueList(resp)
            return resp
        } catch (err) {
            console.error("Error fetching BUs:", err)
            throw err
        }
    }
    const fetchMonitoringQueueList = () => {
        setIsLoading(true)
        getAllMonitoredBU()
            .then((resp) => {
                const data = resp.response
                    .map((item) => ({
                        ...item,
                        channel:
                            item.channel.charAt(0).toUpperCase() +
                            item.channel.slice(1),
                    }))
                    .map((item) => {
                        [
                            "waitAlerts",
                            "voicemailAlerts",
                            "abandonedAlerts",
                            "onQueueUsersAlerts",
                        ].forEach((alertType) => {
                            if (item.alertTypes && item.alertTypes[alertType]) {
                                item.alertTypes[alertType] = item.alertTypes[
                                    alertType
                                ].map((alert) => {
                                    if (
                                        alert.attribute &&
                                        alert.attribute.customAttributes
                                    ) {
                                        alert.attribute.customAttributes = alert.attribute.customAttributes.map(
                                            (attr) => ({
                                                ...attr,
                                                attributeValue: "",
                                                items: attr.attributeValues
                                                    ? attr.attributeValues.map(
                                                          (value) => ({
                                                              name: value,
                                                          })
                                                      )
                                                    : [],
                                            })
                                        )
                                    }
                                    return alert
                                })
                            }
                        })

                        return item
                    })
                    .sort(compareQueueNames)

                setAllQueues(data)
                setFilteredQueues(data)
                // setSelectedQueueList(data.map((ele) => ele.queueId));
                // setQueueListNew(data);
                // setFilteredQueues(data);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const deleteBU = (buIds) => {
        var urlencoded = new URLSearchParams()
        urlencoded.append("buIds", buIds)
        deleteBUAlert(urlencoded, userLdap)
            .then((resp) => {
                notify(resp.response, "success")
                fetchMonitoringQueueList()
            })
            .catch((err) => {
                console.log({ err })
            })
    }

    const handleDeleteConfirmation = (queue) => {
        setQueueToDelete(queue)
        setIsDeleteDialogOpen(true)
    }

    const confirmDelete = () => {
        if (queueToDelete) {
            deleteBU(queueToDelete.buId)
        }
        setIsDeleteDialogOpen(false)
        setQueueToDelete(null)
    }

    const filterQueues = (value) => {
        setCurrentText(value)
        const searchText = value ? value.toLowerCase().trim() : ""
        const filteredData = allQueues.filter((item) => {
            const buName = item.buName ? item.buName.toLowerCase() : ""
            const channel = item.channel ? item.channel.toLowerCase() : ""
            return buName.includes(searchText) || channel.includes(searchText)
        })

        setFilteredQueues(filteredData)
    }

    const addAlert = () => {
        setSelectedAlert([])
        setIsDialogOpened(true)
        setOperation("add")
    }

    console.log("isEditable ", isEditable);

    const editAlert = (operation, value) => {
        console.log("value ", value);
        const isEdit=   buHavingEditPermissions && buHavingEditPermissions[0] === "*" ||  buHavingEditPermissions?.includes(value.buName) 
        setIsEditable(isEdit)
        setSelectedAlert([value])
        setIsDialogOpened(true)
        setOperation(operation)
    }

    const getFilteredBuAfterPermission = (
        queueList,
        buHavingAddPermissions
    ) => {
        if (
            !buHavingAddPermissions?.length ||
            buHavingAddPermissions[0] === "*"
        ) {
            return queueList
        }
        return queueList.filter((queue) =>
            buHavingAddPermissions?.includes(queue.name)
        )
    }

    if (isLoading) {
        return (
            <Flex
                UNSAFE_style={{
                    minHeight: "60vh",
                    width: "100%",
                    marginTop: "2%",
                    border: "1px solid #e0e0e0",
                }}
                justifyContent="center"
                alignItems="center"
            >
                <ProgressCircle aria-label="Loading…" isIndeterminate />
            </Flex>
        )
    }
    return (
        <View>
            <Flex
                marginTop={"1%"}
                direction={"row"}
                width={"100%"}
                justifyContent={"space-between"}
            >
                <SearchField
                    isQuiet
                    onClear={() => setCurrentText("")}
                    onChange={filterQueues}
                    placeholder="Search BU"
                    value={currentText}
                    width={"15%"}
                />
                <Button
                    variant="cta"
                    style="fill"
                    UNSAFE_className="pointer"
                    UNSAFE_style={{ borderRadius: "4px" }}
                    onPress={addAlert}
                    isDisabled={!buHavingAddPermissions?.length}
                >
                    Add Alert
                </Button>
            </Flex>

            <Flex
                UNSAFE_style={{
                    minHeight: "60vh",
                    width: "100%",
                    marginTop: "2%",
                    border: "1px solid #e0e0e0",
                }}
                justifyContent="start"
                alignItems="center"
            >
                {filteredQueues.length === 0 && (
                    <Flex
                        width={"100%"}
                        justifyContent={"center"}
                        alignContent={"center"}
                    >
                        <IllustratedMessage>
                            <Heading>No Alerts Found</Heading>
                            <Content>
                                <p>
                                    There are no alerts configured for any BU.
                                </p>
                            </Content>
                        </IllustratedMessage>
                    </Flex>
                )}
                <Flex
                    UNSAFE_style={{ padding: "0px 0px 0px 60px" }}
                    justifyContent="start"
                    wrap="wrap"
                >
                    {filteredQueues?.map(
                        (queue) =>
                            (combinedPermissions?.includes("*") ||
                                combinedPermissions?.includes(queue.buName)) && (
                                    <QueueCard
                                    styles={styles}
                                    uniqueKey={queue.queueId}
                                    queue={queue}
                                    onEdit={() => editAlert("edit", queue)}
                                    onDelete={() => handleDeleteConfirmation(queue)}
                                    adminPermission={adminPermission}
                                    editPermission={
                                        buHavingEditPermissions && buHavingEditPermissions[0] === "*" || 
                                        buHavingEditPermissions?.includes(queue.buName)
                                    }
                                    deletePermission={
                                        buHavingDeletePermissions && buHavingDeletePermissions[0] === "*" || 
                                        buHavingDeletePermissions?.includes(queue.buName)
                                    }
                                />
                            )
                    )}
                </Flex>
            </Flex>

            {(operation === "add" || operation === "edit") && (
                <AddAlert
                    isDialogOpened={isDialogOpened}
                    setIsDialogOpened={setIsDialogOpened}
                    operation={operation}
                    onClose={() => setOperation(null)}
                    // fetchMonitoringQueueList={fetchMonitoringQueueList}
                    user={user}
                    styles={styles}
                    // allQueues={queueList}
                    allQueues={getFilteredBuAfterPermission(
                        queueList,
                        buHavingAddPermissions
                    )}
                    selectedAlert={selectedAlert}
                    existingQueues={allQueues}
                    fetchMonitoringQueueList={fetchMonitoringQueueList}
                    adminPermission={adminPermission}
                    isEditable={isEditable}
                />
            )}

            {isDeleteDialogOpen && (
                <DeleteConfirmDialog
                    isDeleteDialogOpen={isDeleteDialogOpen}
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    confirmDelete={confirmDelete}
                    queueToDelete={queueToDelete}
                />
            )}
        </View>
    )
}

export default connect(mapStateToProps)(BuAlertList)
