import React from "react"
import { Item, TabList, TabPanels, Tabs, Well } from "@adobe/react-spectrum"
import { connect } from "react-redux"
import AniByPass from "./AniByPass/AniByPass"
import HolidaySchedule from "./HolidaySchedule/HolidaySchedule"
import Timeout from "./Timeout/Timeout"
import DxocEmergency from "./Emergency/DxocEmergency"
import DxPrompts from "./DxPrompts/DxPrompts"
import Dnis from "./Dnis/Dnis"
import DidMagento from "./DidMagento/DidMagento"
import SpamCallersList from "./spamContactsDashboard/SpamCallersList"
import SrsMockData from "./SrsMockData/SrsMockData"
import GroupCallDuration from "./GroupCallDuration/GroupCallDuration"
import { TabsDisplay } from "../../../utilities/components/CommonUseComponents"


function mapStateToProps(state) {
    //console.log(state.login)
    return {
        login: state.login,
        user: state.login.authorizedAccess?.username,
        permissions:
            null != state.login.authorizedAccess
                ? state.login.authorizedAccess.permissions
                : [],
        rolesAndPermissions : state.login.rolesAndPermissions
    }
}

const CommonConfig = ({currentComponent, directList , permissions , user , rolesAndPermissions}) => {
    const rawdata = rolesAndPermissions
    const permissionsArrayForRouting = React.useMemo(() => {
        const result = new Set();
        if (rawdata) {
            Object.keys(rawdata).forEach((service) => {
                result.add(service);
                Object.keys(rawdata[service]).forEach((permissionType) => {
                    rawdata[service][permissionType].forEach((entry) => {
                        result.add(entry.subService);
                    });
                });
            });
        }
        return Array.from(result);
    }, [rawdata]);

    const modifiedData = permissionsArrayForRouting.flatMap((item) => {
        return item.startsWith("ROUTING") ? ["ROUTING FLOW CONFIG",item] : item
    })
    
    const uniqueData = [...new Set(modifiedData)]
    const arrayOfUniqueData = Array.from(uniqueData)

    //console.log("COMMOM CONFIG" , props);
    return (
        <Well
            style={{
                minHeight: "75vh",
                marginRight: "1%",
                marginLeft: "1%",
            }}
        >
             {arrayOfUniqueData.length > 0  && (
                <TabsDisplay
                    permissions={arrayOfUniqueData}
                    directList={directList}
                    user={user}
                    hello="hello"
                />
            )}
          
        </Well>
    )
}

export default connect(mapStateToProps)(CommonConfig)
