import React from "react"
import {
    Flex,
    View,
    Checkbox,
    CheckboxGroup,
    Text as SpectrumText,
    TextField,
    Button,
    Heading,
} from "@adobe/react-spectrum"
import styles from "./AddAlert.module.css"
import DeleteButton from "./DeleteButton"
import AddButton from "./AddButton"

const SlackAndEmail = ({
    index,
    state,
    alertOption,
    updateAlertOption,
    addSlackChannel,
    updateSlackChannel,
    deleteSlackChannel,
    addEmail,
    updateEmail,
    deleteEmail,
    handleDelete,
    abandonedState,
    isEditable,
}) => {
    return (
        <View
            UNSAFE_style={{
                maxWidth: "100vw",
            }}
        >
            <View padding={"0 0 0 3rem"}>
                <Flex justifyContent={"space-between"}>
                    <Flex>
                        <View UNSAFE_className={styles.checkbox}  width={"120px"}>
                            <Heading level={6}>Other Recipients :</Heading>
                        </View>
                        <Checkbox
                            isSelected={alertOption.slack}
                            onChange={(isSelected) =>
                                updateAlertOption("slack", isSelected)
                            }
                            isDisabled={!isEditable}
                        >
                            <SpectrumText UNSAFE_className={styles.checkbox}>
                                Slack
                            </SpectrumText>
                        </Checkbox>
                        <Checkbox
                            isSelected={alertOption.email}
                            onChange={(isSelected) =>
                                updateAlertOption("email", isSelected)
                            }
                            isDisabled={!isEditable}
                        >
                            <SpectrumText UNSAFE_className={styles.checkbox}>
                                Email
                            </SpectrumText>
                        </Checkbox>
                    </Flex>
                    {/* <Button
                        variant="negative"
                        style="outline"
                        UNSAFE_className="pointer"
                        UNSAFE_style={{ borderRadius: "4px" }}
                        onPress={handleDelete}
                        isDisabled={!isEditable && abandonedState.length == 1}
                    >
                        Delete
                    </Button> */}
                </Flex>
                <View marginStart={"2rem"}>
                    {alertOption.slack && (
                        <View>
                            <SpectrumText UNSAFE_className={styles.label}>
                                Slack Recipients
                            </SpectrumText>
                            {state?.slacks?.map((slackChannel, slackIndex) => (
                                <Flex
                                    key={slackIndex}
                                    gap={"1rem"}
                                    UNSAFE_className={styles.placeholder}
                                    marginY={"4px"}
                                >
                                    <TextField
                                        width={"30rem"}
                                        UNSAFE_className={
                                            styles.customplaceholder
                                        }
                                        placeholder="Channel name"
                                        value={slackChannel.channelName}
                                        onChange={(value) =>
                                            updateSlackChannel(
                                                slackIndex,
                                                "channelName",
                                                value
                                            )
                                        }
                                        isDisabled={!isEditable}
                                    />
                                    <TextField
                                        width={"30rem"}
                                        placeholder="Slack webhook / Slack channel ID"
                                        value={slackChannel.channelId}
                                        onChange={(value) =>
                                            updateSlackChannel(
                                                slackIndex,
                                                "channelId",
                                                value
                                            )
                                        }
                                        isDisabled={!isEditable}
                                    />
                                    <DeleteButton
                                        onClick={() =>
                                            deleteSlackChannel(slackIndex)
                                        }
                                        isDisabled={
                                            !isEditable ||
                                            state.slacks.length == 1
                                        }
                                    />
                                    {slackIndex === 0 && (
                                        <AddButton
                                            title={"Slack"}
                                            onClick={addSlackChannel}
                                            isEditable={isEditable}
                                        />
                                    )}
                                </Flex>
                            ))}
                        </View>
                    )}
                    {alertOption.email && (
                        <View>
                            <SpectrumText UNSAFE_className={styles.label}>
                                Email Recipients
                            </SpectrumText>
                            {/* {state?.emails?.length === 0 && (
                                <Flex
                                    gap={"1rem"}
                                    UNSAFE_className={styles.placeholder}
                                    marginY={"4px"}
                                >
                                    <TextField
                                        width={"61rem"}
                                        placeholder="Email"
                                        onChange={(value) =>
                                            updateEmail(0, value)
                                        }
                                    />
                                    <DeleteButton
                                        // onClick={() => deleteEmail(emailIndex)}
                                        isDisabled={true}
                                    />
                                    <AddButton
                                        title={"Email"}
                                        onClick={addEmail}
                                    />
                                </Flex>
                            )} */}
                            {state?.emails?.map((email, emailIndex) => (
                                <Flex
                                    key={emailIndex}
                                    gap={"1rem"}
                                    UNSAFE_className={styles.placeholder}
                                    marginY={"4px"}
                                >
                                    <TextField
                                        width={"61rem"}
                                        placeholder="Email"
                                        type="email"
                                        value={email.trim()}
                                        onChange={(value) =>
                                            updateEmail(emailIndex, value)
                                        }
                                        isDisabled={!isEditable}
                                    />
                                    <DeleteButton
                                        onClick={() => deleteEmail(emailIndex)}
                                        isDisabled={
                                            !isEditable ||
                                            state.emails.length == 1
                                        }
                                    />
                                    {emailIndex === 0 && (
                                        <AddButton
                                            title={"Email"}
                                            onClick={addEmail}
                                            isEditable={isEditable}
                                        />
                                    )}
                                </Flex>
                            ))}
                        </View>
                    )}
                </View>
            </View>
        </View>
    )
}

export default SlackAndEmail
